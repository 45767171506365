import { AppPacks } from "../context";

export const deleteWhere = async (
  api: any,
  token: string,
  model: string,
  where: any
) => {
  try {
    await api.post(
      `/api/${model}/deleteWhere`,
      {
        where,
      },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
  } catch (error: any) {
    console.error("deleteWhere : ", error);
  }
};

export const deepComparison = (
  newArr: any,
  oldArr: any,
  parentKey: string | number,
  childKey: any = null
) => {
  let newValues = newArr.reduce((acc: any, curr: any) => {
    if (childKey) {
      return [...acc, curr[parentKey][childKey]];
    } else {
      return [...acc, curr[parentKey]];
    }
  }, []);

  let oldValues = oldArr.reduce((acc: any, curr: any) => {
    if (childKey) {
      return [...acc, curr[parentKey][childKey]];
    } else {
      return [...acc, curr[parentKey]];
    }
  }, []);

  return JSON.stringify(newValues) === JSON.stringify(oldValues);
};
