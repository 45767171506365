import React, { useCallback, useEffect, useState } from "react";
import "./TvaFollow.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";

import moment, { Moment } from "moment";
import config from "../../config";

import { useDispatch, useSelector } from "react-redux";
import { ErrorLogger } from "../../util/errorLogger";
import { Company, IODTVA, IS3, ITrackedTVA } from "../../interfaces";
import { useFormatter } from "../../util/hooks/useFormatter";
import useAxios from "../../util/hooks/useAxios";

import {
  ODTVATypes,
  ODTVA_OPTIONS,
  OptionType,
  TrackedTVAStatus,
  TrackedTVAStatusOptions,
  UserTypes,
  VAT_REGIME_OPTIONS_OBJECT,
} from "../../util/context";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import Cards from "./Cards";
import { RenderDateTime, gridStyle, i18n } from "../MesClients/MesClients";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import NumericEditor from "@inovua/reactdatagrid-community/NumericEditor";
import { FaEye } from "react-icons/fa";
import DataTable from "react-data-table-component";
import { BootyPagination } from "../../components/table/pagination";
import ViewIcon from "../../assets/ViewIcon";
import { BsTrashFill } from "react-icons/bs";
import { deleteFileFromList } from "../ClientInfo/InformationClient";
import {
  RiAlertFill,
  RiCheckLine,
  RiDownload2Fill,
  RiShareForwardFill,
} from "react-icons/ri";
import FileViewer from "../../components/FileViewer";
import { IoMdCreate } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import { AiOutlineRollback } from "react-icons/ai";
import { fetchAllClients } from "../../store/reducers/clients-list/clientsSlice";
import { fetchAllCompanies } from "../../store/reducers/companies-list/companiesSlice";

registerLocale("fr", fr);
moment.updateLocale("fr", {});

const { API_URL } = config[process.env.NODE_ENV];

type ICA_HT = {
  ca_ht_0: number;
  ca_ht_10: number;
  ca_ht_20: number;
  ca_ht_55: number;
  ca_ht_85: number;
};

type ICA_TVA = {
  ca_tva_0: number;
  ca_tva_10: number;
  ca_tva_20: number;
  ca_tva_55: number;
  ca_tva_85: number;
};
type Addon_ITrackedTVA = {
  ca_ht: ICA_HT;
  ca_tva: ICA_TVA;
};

type Custom_ITrackedTVA = ITrackedTVA & Addon_ITrackedTVA;

type SearchODTVAFormValues = {
  dateFrom?: string | null;
  dateTo?: string | null;
  status?: OptionType | null;
};

type EditTrackedVAFormValues = {
  status?: OptionType | null;
};

const customCellStyle = (cellProps: { value: any }) => {
  const { value } = cellProps;
  return {
    background: value < 0 ? "#43b59c" : "#fe0000",
    color: "#fff",
    fontWeight: "bold",
    "text-align": "center",
  };
};

const defaultStyle = {
  "text-align": "center",
};

const TvaFollow = ({
  context,
  company,
  callback,
}: {
  context?: string;
  company?: Company;
  callback?: () => void;
}) => {
  const [currentDate, setCurrentDate] = useState<string>(
    moment().format("YYYY-MM-DD hh:mm")
  );
  const [collectedTVA, setCollectedTVA] = useState<number | null>(0);
  const [detuctibleTVA, setDeductibleTVA] = useState<number | null>(0);
  const [tvas, setTVAs] = useState<Custom_ITrackedTVA[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [confirmEditModal, setConfirmEditModal] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [reportModal, setReportModal] = useState<boolean>(false);
  const [declareModal, setDeclareModal] = useState<boolean>(false);
  const [singleTrackedTVA, setSingleTrackedTVA] =
    useState<ITrackedTVA | null>();
  const [pending, setPending] = useState<boolean>(true);
  const [documentsModal, setDocumentsModal] = useState<boolean>(false);
  const [viewDocumentsTva, setViewDocumentsTva] = useState<ITrackedTVA | null>(
    null
  );
  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [tvaDocumentDelete, setTvaDocumentDelete] = useState<{
    key: string;
    isLoading: boolean;
  } | null>(null);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };
  let api = useAxios();

  const { id } = useParams();

  const getTVAs = async () => {
    setPending(true);
    try {
      const { data } = await api.post(
        `/api/TrackedTVA/DynamicAll`,
        {
          where: {
            companyId: id,
          },
          perPage: 20,
          pageIndex: 1,
          order: [
            ["declarationDate", "DESC"],
            ["id", "DESC"],
          ],
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      let formattedData = data.data.map((elt: ITrackedTVA) => ({
        ...elt,
        ca_ht: {
          ca_ht_0: elt.ca_ht_0,
          ca_ht_55: elt.ca_ht_55,
          ca_ht_85: elt.ca_ht_85,
          ca_ht_10: elt.ca_ht_10,
          ca_ht_20: elt.ca_ht_20,
        },
        ca_tva: {
          ca_tva_0: elt.ca_tva_0,
          ca_tva_55: elt.ca_tva_55,
          ca_tva_85: elt.ca_tva_85,
          ca_tva_10: elt.ca_tva_10,
          ca_tva_20: elt.ca_tva_20,
        },
      }));
      setTVAs(formattedData);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting tva data", error);
    }
  };

  const dispatch = useDispatch();

  const decalreTrackedTVA = async (id: string) => {
    toast.dismiss();
    try {
      // if (
      //   company?.regimeTVA === "Annually" &&
      //   (!company.nextDeleclaration ||
      //     (company.nextDeleclaration &&
      //       moment(singleTrackedTVA?.declarationDate).format("DD/MM/YYYY") ===
      //         moment(company.nextDeleclaration).format("DD/MM/YYYY")))
      // ) {
      //   toast.warning(
      //     "La date de la prochaine déclaration du client n'est pas définie!",
      //     {
      //       position: "bottom-right",
      //       autoClose: 2000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //     }
      //   );
      //   return;
      // }
      const data = await api.post(
        `/api/TrackedTVA/update`,
        {
          id,
          status: TrackedTVAStatus.DECLARED,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await getTVAs();
      if (creds.role === UserTypes.Admin) {
        dispatch(fetchAllCompanies());
      }
      if (creds.role === UserTypes.Cabinet) {
        dispatch(fetchAllClients(creds.user_id));
      }
      setSingleTrackedTVA(null);
      setDeclareModal(false);
      return data;
    } catch (error: any) {
      ErrorLogger("getting od tva data", error);
    }
  };

  const reportTrackedTVA = async (id: string) => {
    toast.dismiss();
    try {
      // if (
      //   company?.regimeTVA === "Annually" &&
      //   (!company.nextDeleclaration ||
      //     (company.nextDeleclaration &&
      //       moment(singleTrackedTVA?.declarationDate).format("DD/MM/YYYY") ===
      //         moment(company.nextDeleclaration).format("DD/MM/YYYY")))
      // ) {
      //   toast.warning(
      //     "La date de la prochaine déclaration du client n'est pas définie!",
      //     {
      //       position: "bottom-right",
      //       autoClose: 2000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //     }
      //   );
      //   return;
      // }
      const data = await api.post(
        `/api/TrackedTVA/update`,
        {
          id,
          status: TrackedTVAStatus.REPORTED,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await getTVAs();
      if (creds.role === UserTypes.Admin) {
        dispatch(fetchAllCompanies());
      }
      if (creds.role === UserTypes.Cabinet) {
        dispatch(fetchAllClients(creds.user_id));
      }
      setSingleTrackedTVA(null);
      setDeclareModal(false);
      return data;
    } catch (error: any) {
      ErrorLogger("getting od tva data", error);
    }
  };

  useEffect(() => {
    if (company && company?.firstDeleclaration && company?.regimeTVA) {
      getTVAs();
    }
  }, [company]);

  const { setDecimalDigits } = useFormatter();

  const {
    control: searchControl,
    register: searchRegister,
    handleSubmit: searchHandleSubmit,
    reset: searchReset,
    formState: { errors: searchErrors },
  } = useForm<SearchODTVAFormValues>({});

  const {
    control: editControl,
    register: editRegister,
    handleSubmit: editHandleSubmit,
    reset: editReset,
    formState: { errors: editErrors },
  } = useForm<EditTrackedVAFormValues>({});

  const editTrackedTVA = async () => {
    try {
      setEditLoading(true);

      let updateData: any = null;

      updateData = await api.post(
        `/api/TrackedTVA/Update`,
        {
          id: singleTrackedTVA?.id,
          status: TrackedTVAStatus.PENDING,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (
        updateData &&
        updateData.data.updated &&
        updateData.data.updated.length > 0 &&
        updateData.data.updated[0].view &&
        updateData.data.updated[0].view.status
      ) {
        toast.dismiss();
        toast.success("Votre modification a été effectuée avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      await getTVAs();
      setEditLoading(false);
      setConfirmEditModal(false);
      setSingleTrackedTVA(null);
    } catch (error: any) {
      ErrorLogger("editTrackedTVA", error);
      await getTVAs();
      setEditLoading(false);
      setConfirmEditModal(false);
      setSingleTrackedTVA(null);
    }
  };

  const actionPermissionChecker = (data: ITrackedTVA) => {
    switch (company?.regimeTVA) {
      case "Annually":
        const currentDateQ = moment(new Date()).get("Q");
        let limit = [];
        if (currentDateQ < 3) {
          limit = [
            moment().startOf("year").format(),
            moment().set("month", 5).endOf("month").format(),
          ];
        } else {
          limit = [
            moment().set("month", 6).startOf("month").format(),
            moment().endOf("year").format(),
          ];
        }

        return !moment(data.declarationDate).isBetween(limit[0], limit[1]);

      case "Monthly":
        return moment(data.declarationDate).isAfter(
          moment(new Date()),
          "month"
        );

      case "Quarterly":
        return moment(data.declarationDate).isAfter(moment(new Date()), "Q");

      default:
        return true;
    }
  };

  const columns = [
    {
      header: "Date de déclaration",
      name: "declarationDate",
      dateFormat: "DD/MM/YYYY",
      type: "date",
      minWidth: 180,
      render: ({ value }: { value: any }) =>
        value ? RenderDateTime(value) : null,
      editable: false,
      style: defaultStyle,
      sortable: false,
    },
    {
      header: "CA HT",
      name: "ca_ht",
      maxWidth: 200,
      className: "ca_inner_tva_cell",
      render: ({ data }: { data: Custom_ITrackedTVA }) => (
        <table className="table">
          <tbody>
            <tr>
              <td>0% : </td>
              <td>
                {data.ca_ht.ca_ht_0 ? data.ca_ht.ca_ht_0.toFixed(2) : 0} €
              </td>
            </tr>
            <tr>
              <td>5.5% : </td>
              <td>
                {data.ca_ht.ca_ht_55 ? data.ca_ht.ca_ht_55.toFixed(2) : 0} €
              </td>
            </tr>
            <tr>
              <td>8.5% : </td>
              <td>
                {data.ca_ht.ca_ht_85 ? data.ca_ht.ca_ht_85.toFixed(2) : 0} €
              </td>
            </tr>
            <tr>
              <td>10% : </td>
              <td>
                {data.ca_ht.ca_ht_10 ? data.ca_ht.ca_ht_10.toFixed(2) : 0} €
              </td>
            </tr>
            <tr>
              <td className="last_ca_cell">20% : </td>
              <td className="last_ca_cell">
                {data.ca_ht.ca_ht_20 ? data.ca_ht.ca_ht_20.toFixed(2) : 0} €
              </td>
            </tr>
          </tbody>
        </table>
      ),
      sortable: false,
      editable: false,
    },
    {
      header: "TVA sur CA",
      name: "ca_tva",
      maxWidth: 200,
      className: "ca_inner_tva_cell",
      render: ({ data }: { data: Custom_ITrackedTVA }) => (
        <table className="table">
          <tbody>
            <tr>
              <td>0% : </td>
              <td>
                {data.ca_tva.ca_tva_0 ? data.ca_tva.ca_tva_0.toFixed(2) : 0} €
              </td>
            </tr>
            <tr>
              <td>5.5% : </td>
              <td>
                {data.ca_tva.ca_tva_55 ? data.ca_tva.ca_tva_55.toFixed(2) : 0} €
              </td>
            </tr>
            <tr>
              <td>8.5% : </td>
              <td>
                {data.ca_tva.ca_tva_85 ? data.ca_tva.ca_tva_85.toFixed(2) : 0} €
              </td>
            </tr>
            <tr>
              <td>10% : </td>
              <td>
                {data.ca_tva.ca_tva_10 ? data.ca_tva.ca_tva_10.toFixed(2) : 0} €
              </td>
            </tr>
            <tr>
              <td className="last_ca_cell">20% : </td>
              <td className="last_ca_cell">
                {data.ca_tva.ca_tva_20 ? data.ca_tva.ca_tva_20.toFixed(2) : 0} €
              </td>
            </tr>
          </tbody>
        </table>
      ),
      sortable: false,
      editable: false,
    },
    {
      header: "TVA Charges",
      name: "chargeTVA",
      maxWidth: 120,
      render: ({ data }: { data: ITrackedTVA }) =>
        `${data.chargeTVA ? data.chargeTVA.toFixed(2) : 0} €`,
      sortable: false,
      editable: true,
      type: "number",
      style: defaultStyle,
    },
    {
      header: "TVA sur immo",
      name: "immoTVA",
      maxWidth: 120,
      render: ({ data }: { data: ITrackedTVA }) =>
        `${data.immoTVA ? data.immoTVA.toFixed(2) : 0} €`,
      sortable: false,
      editable: true,
      type: "number",
      style: defaultStyle,
    },
    {
      header: "Total TVA",
      name: "total",
      maxWidth: 100,
      render: ({ data }: { data: ITrackedTVA }) => {
        return (
          <>
            <div className="tva-recif-wrapper">
              <span>{`${data.total ? data.total.toFixed(2) : 0}` + " €"}</span>
              {data.trackedTVAUpdates && data.trackedTVAUpdates.length > 0 && (
                <>
                  <span id={`trackedTVAUpdates-${data.id}`}>
                    <RiAlertFill />
                  </span>

                  <UncontrolledTooltip
                    target={`trackedTVAUpdates-${data.id}`}
                    className="trackedTVAUpdatesi-popver-body"
                    placement="bottom"
                  >
                    <span>Rectifications</span>
                    <ul>
                      {data.trackedTVAUpdates.map((elt, index) => (
                        <li key={index}>
                          <>
                            {moment(elt.date).format("MMMM/YYYY").toUpperCase()}
                            : {setDecimalDigits(elt.value) + " €"}
                          </>
                        </li>
                      ))}
                    </ul>
                  </UncontrolledTooltip>
                </>
              )}
            </div>
          </>
        );
      },
      sortable: false,
      style: customCellStyle,
      editable: true,
      type: "number",
    },
    {
      header: "Report",
      name: "reportedTVA",
      maxWidth: 100,
      render: ({ data }: { data: ITrackedTVA }) =>
        `${data.reportedTVA ? data.reportedTVA.toFixed(2) : 0} €`,
      sortable: false,
      // editable: (editVAlue: number | null, cellProps: any) => {
      //   return cellProps.data.isDynamic ? false : true;
      // },
      editable: true,
      type: "number",
      style: defaultStyle,
    },
    {
      header: "TVA déclarée",
      name: "declaredTVA",
      maxWidth: 100,
      render: ({ data }: { data: ITrackedTVA }) =>
        `${data.declaredTVA ? data.declaredTVA.toFixed(2) : 0} €`,
      sortable: false,
      editable: true,
      type: "number",
      style: defaultStyle,
    },
    {
      header: "Écart",
      name: "difference",
      maxWidth: 100,
      render: ({ data }: { data: ITrackedTVA }) => {
        return (
          <>
            <div className="tva-recif-wrapper">
              <span>
                {`${data.difference ? data.difference : 0}` + " €"}
              </span>
              {data.trackedTVAUpdates && data.trackedTVAUpdates.length > 0 && (
                <>
                  <span id={`trackedTVAUpdates-${data.id}`}>
                    <RiAlertFill />
                  </span>

                  <UncontrolledTooltip
                    target={`trackedTVAUpdates-${data.id}`}
                    className="trackedTVAUpdatesi-popver-body"
                    placement="bottom"
                  >
                    <span>Rectifications</span>
                    <ul>
                      {data.trackedTVAUpdates.map((elt, index) => (
                        <li key={index}>
                          <>
                            {moment(elt.date).format("MMMM/YYYY").toUpperCase()}
                            : {setDecimalDigits(elt.value) + " €"}
                          </>
                        </li>
                      ))}
                    </ul>
                  </UncontrolledTooltip>
                </>
              )}
            </div>
          </>
        );
      },
      sortable: false,
      style: customCellStyle,
      editable: true,
      type: "number",
    },
    {
      header: "Joindre justificatif",
      name: "documents",
      className: "ca_inner_documents_cell",
      maxWidth: 120,
      render: ({ data }: { data: ITrackedTVA }) => {
        return (
          <>
            <div className="documents-uploader">
              <FormGroup className="form-file file-primary">
                <label className="file-box ">
                  <RiDownload2Fill size={20} />
                  <Input
                    type="file"
                    className="file-input"
                    onChange={async (e: any) => {
                      const options: any = {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      };
                      try {
                        toast.dismiss();

                        toast.warning(
                          "Cela peut prendre quelques secondes, veuillez patienter...",
                          options
                        );
                        const formData = new FormData();
                        formData.append("id", data?.id);
                        if (e.target.files && e.target.files.length > 0) {
                          for (
                            let index = 0;
                            index < e.target.files.length;
                            index++
                          ) {
                            const element = e.target.files[index];
                            formData.append("documents", element, element.name);
                          }
                        }

                        const { data: tvaData } = await api.post(
                          `/api/TrackedTVA/Update`,
                          formData,
                          {
                            headers: {
                              "x-access-token": creds.token,
                            },
                          }
                        );

                        toast.success(
                          "Votre mise à jour a été effectuée avec succès test",
                          options
                        );
                        await getTVAs();
                      } catch (error: any) {
                        ErrorLogger("updating a transaction's document", error);
                        toast.error(
                          "Quelque chose d'inattendu s'est produit, veuillez réessayer plus tard.",
                          options
                        );
                      }
                    }}
                    multiple
                  />
                </label>
              </FormGroup>
            </div>
            <div className="documents-viewer">
              {data.documents && data.documents.length > 0 && (
                <button className="form-file file-primary">
                  <FaEye
                    size={20}
                    onClick={() => {
                      setViewDocumentsTva(data);
                      setDocumentsModal(true);
                    }}
                  />
                </button>
              )}
            </div>
          </>
        );
      },
      sortable: false,
      editable: false,
    },
    {
      header: "Action",
      name: "action",
      className: "ca_inner_actions_cell",
      width: 200,
      minWidth: 200,
      render: ({ data }: { data: ITrackedTVA }) => (
        <>
          {actionPermissionChecker(data) ? (
            <></>
          ) : (
            <div className="table-action small-btns">
              {String(data.status) === TrackedTVAStatus.PENDING && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary btn-small"
                    onClick={() => {
                      setSingleTrackedTVA(data);
                      setDeclareModal(true);
                    }}
                    title="Déclarer"
                  >
                    <FiSend />
                  </button>
                </>
              )}
              {(String(data.status) === TrackedTVAStatus.DECLARED ||
                String(data.status) === TrackedTVAStatus.REPORTED) && (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary btn-small"
                    onClick={() => {
                      setSingleTrackedTVA(data);
                      setConfirmEditModal(true);
                    }}
                  >
                    <IoMdCreate />
                  </button>
                </>
              )}
            </div>
          )}
        </>
      ),
      sortable: false,
      editable: false,
    },
  ];

  const documentsColumns = [
    {
      name: "Document",
      selector: (row: any) => row.key,
      sortable: true,
      width: "80%",
      style: defaultStyle,
    },
    {
      name: "Action",
      button: true,
      cell: (row: IS3) => (
        <div className="table-action">
          <>
            <button
              className="btn btn-blue"
              onClick={() => {
                setUrl(row.url);
                setViewModal(true);
              }}
              disabled={
                tvaDocumentDelete! &&
                tvaDocumentDelete?.key === row.key &&
                tvaDocumentDelete?.isLoading
              }
            >
              <ViewIcon />
            </button>
            <button
              className="btn btn-red"
              onClick={async () => {
                await deleteTvaDocument(viewDocumentsTva!, row);
              }}
              disabled={
                tvaDocumentDelete! &&
                tvaDocumentDelete?.key === row.key &&
                tvaDocumentDelete?.isLoading
              }
            >
              {tvaDocumentDelete &&
              tvaDocumentDelete.key === row.key &&
              tvaDocumentDelete.isLoading ? (
                <Spinner color="danger" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                <BsTrashFill />
              )}
            </button>
          </>
        </div>
      ),
      width: "20%",
      style: defaultStyle,
    },
  ];

  const onEditComplete = useCallback(
    async (data: any) => {
      await updateTVA(data);
    },
    [tvas]
  );

  const deleteTvaDocument = async (row: ITrackedTVA, document: IS3) => {
    const options: any = {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    toast.dismiss();
    try {
      toast.warning(
        "Cela peut prendre quelques secondes, veuillez patienter...",
        options
      );
      setTvaDocumentDelete({
        key: document.key,
        isLoading: true,
      });

      let tvasArr: any = [];

      const updatedData = await deleteFileFromList(
        [document.key],
        "documents",
        "TrackedTVA",
        row.id,
        creds.token,
        api
      );

      tvasArr = tvas;

      if (viewDocumentsTva && viewDocumentsTva.id) {
        const tvaIndex = tvasArr.findIndex(
          (tr: ITrackedTVA) => tr.id === viewDocumentsTva.id
        );
        if (tvaIndex > -1) {
          setViewDocumentsTva({
            ...tvasArr[tvaIndex],
            documents: updatedData.documents,
          });
          await getTVAs();
        }
      }
      setTvaDocumentDelete(null);
      toast.success("Votre suppression a été effectuée avec succès", options);
    } catch (error: any) {
      ErrorLogger("deleting tva document", error);
      setTvaDocumentDelete(null);
      toast.error(
        "Quelque chose d'inattendu s'est produit, veuillez réessayer plus tard.",
        options
      );
    }
  };

  const updateTVA = async (data: any) => {
    const options: any = {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    toast.dismiss();
    try {
      setPending(true);
      let value = data.value;
      let payload: any = {
        id: data.rowId,
        [data.columnId]: parseFloat(value),
      };

      await api.post(`/api/TrackedTVA/Update`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      toast.success("Votre mise à jour a été effectuée avec succès", options);
      await getTVAs();
    } catch (error: any) {
      setPending(false);
      ErrorLogger("updateTVA", error);
      toast.error(
        "Quelque chose d'inattendu s'est produit, veuillez réessayer plus tard.",
        options
      );
    }
  };

  return (
    <div className="page page-odt-tva">
      <Cards id={company?.id} pack={company?.pack} />
      <div className=" section-facturation">
        <div className="card-Table table-secondary card">
          {company?.firstDeleclaration && company?.regimeTVA ? (
            <ReactDataGrid
              idProperty="id"
              columns={columns as any}
              rowHeight={185}
              dataSource={tvas}
              style={gridStyle}
              pagination
              defaultLimit={12}
              i18n={i18n}
              loading={pending}
              loadingText={"Récupération des données, veuillez patienter"}
              emptyText={"Pas de données disponibles"}
              showZebraRows={false}
              className="tva-follow-table"
              onEditComplete={onEditComplete}
              editable={true}
            />
          ) : (
            <div className="trackedTVA-warnings">
              {!company?.firstDeleclaration && (
                <p>
                  <RiAlertFill /> Veuillez ajouter la date de première
                  déclaration du client
                </p>
              )}
              {!company?.regimeTVA && (
                <p>
                  <RiAlertFill /> Veuillez ajouter le régime de TVA du client
                </p>
              )}
            </div>
          )}
        </div>
      </div>

      {/*
        declare
        */}
      <Modal
        className="modal-primary modal-dialog-centered"
        isOpen={declareModal}
        toggle={() => {
          setDeclareModal(false);
          setSingleTrackedTVA(null);
        }}
      >
        <ModalHeader
          toggle={() => {
            setDeclareModal(false);
            setSingleTrackedTVA(null);
          }}
        >
          Déclarer
        </ModalHeader>

        <ModalBody>
          <div className="content-text p-lg-5">
            <p className="msg-text">
              Vous êtes sur de vouloir déclarer la TVA du{" "}
              {moment(singleTrackedTVA?.declarationDate).format("DD/MM/YYYY")}?
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeclareModal(false);
              setSingleTrackedTVA(null);
            }}
            disabled={loading}
          >
            Non
          </Button>
          <Button
            color="primary"
            disabled={loading}
            onClick={async () => {
              try {
                setLoading(true);
                if (
                  singleTrackedTVA?.declaredTVA &&
                  singleTrackedTVA.declaredTVA !== 0
                ) {
                  await decalreTrackedTVA(singleTrackedTVA?.id!);
                } else {
                  await reportTrackedTVA(singleTrackedTVA?.id!);
                }
                setLoading(false);
              } catch (error) {
                setLoading(false);
                console.log("error decalring tracked tva : ", error);
              }
            }}
          >
            {loading ? (
              <Spinner color="light" type="border" size={"sm"}>
                Loading...
              </Spinner>
            ) : (
              "Oui"
            )}
          </Button>
        </ModalFooter>
      </Modal>
      {/*confirm edit
       */}
      <Modal
        className="modal-warning modal-dialog-centered"
        isOpen={confirmEditModal}
        toggle={() => {
          setConfirmEditModal(false);
          setSingleTrackedTVA(null);
        }}
      >
        <ModalHeader
          toggle={() => {
            setConfirmEditModal(false);
            setSingleTrackedTVA(null);
          }}
        >
          Confirmation
        </ModalHeader>

        <ModalBody>
          <div className="content-text p-lg-5">
            <p className="msg-text">
              Veuillez noter que cette modification supprimera tous les tvas
              suivants. Voulez-vous continuer ?
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            outline
            disabled={editLoading}
            onClick={() => {
              setConfirmEditModal(false);
              setSingleTrackedTVA(null);
            }}
          >
            Non
          </Button>
          <Button
            color="warning"
            onClick={async () => {
              await editTrackedTVA();
            }}
            disabled={editLoading}
          >
            {editLoading ? (
              <Spinner color="light" type="border" size={"sm"}>
                Loading...
              </Spinner>
            ) : (
              "Oui"
            )}
          </Button>
        </ModalFooter>
      </Modal>

      {/*
         tva documents
        */}
      <Modal
        className="modal-primary modal-dialog-centered "
        isOpen={documentsModal}
        toggle={() => {
          setDocumentsModal(false);
          setViewDocumentsTva(null);
        }}
      >
        <ModalHeader
          toggle={() => {
            setDocumentsModal(false);
            setViewDocumentsTva(null);
          }}
        >
          Justificatifs de la déclaration{" "}
          {moment(viewDocumentsTva?.declarationDate).format("DD/MM/YYYY")}
        </ModalHeader>

        <ModalBody>
          <div className="content-form-block">
            <DataTable
              columns={documentsColumns as any}
              data={viewDocumentsTva?.documents!}
              noDataComponent={<p>Il n'y a aucun data à afficher</p>}
              paginationComponent={(props) => {
                const customProps = { ...props, color: "primary" };
                return <BootyPagination {...customProps} />;
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <FileViewer
        url={url!}
        setUrl={setUrl}
        viewModal={viewModal}
        setViewModal={setViewModal}
      />
    </div>
  );
};

export default TvaFollow;
