import React, { useCallback, useEffect, useReducer, useState } from "react";
import "./SingleTicket.scss";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import UserInfo from "../../components/user-info/UserInfo";
import { colors, data } from "../../constants";
import BannerTop from "../../components/Banner/BannerTop";

import UserChat from "../../assets/images/avatar_empty.png";
import SendIconOrange from "../../assets/SendIconOrange";
import SendIconWhite from "../../assets/SendIconWhite";
import AttacheIcon from "../../assets/AttacheIcon";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Socket } from "socket.io-client";
import {
  IApplication,
  IMessage,
  IPermission,
  ITicket,
  User,
} from "../../interfaces";
import { ErrorLogger } from "../../util/errorLogger";
import axios from "axios";
import config from "../../config";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  closeNewNotifBadge,
  resetTicketNotifs,
} from "../../store/reducers/messages-notifs/messagesNotifsSlice";
import { BsTrashFill, BsXLg } from "react-icons/bs";
import ScrollToBottom from "react-scroll-to-bottom";
import moment from "moment";
import useAxios from "../../util/hooks/useAxios";
import { UserTypes } from "../../util/context";
import { setReloadTickets } from "../../store/reducers/utils/utilSlice";
import { toast, ToastContainer } from "react-toastify";
import FileViewer from "../../components/FileViewer";

const { API_URL, APPLICATION_ID } = config[process.env.NODE_ENV];
export type GeneralFormValues = {
  text?: string | null;
  attachement?: any | null;
};

export interface TicketsProps {}
const TicketsMsg: React.FC<TicketsProps> = ({}) => {
  const [inputFile, setInputFile] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [closeTicketModal, setCloseTicketModal] = useState(false);
  const [closeClientTicketModal, setCloseClientTicketModal] = useState(false);
  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [currentTicket, setCurrentTicket] = useState<ITicket>();
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const { socket }: { socket: { current: Socket<any> } } = useOutletContext();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const {
    watch,
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormValues>({});

  const { attachement: fileAttachement } = watch();

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const joinRoom = (room: string) => {
    socket.current.emit("join-room", { room });
  };
  let api = useAxios();

  const getCurrentTicket = async () => {
    try {
      setIsPageLoading(true);
      const { data } = await api.post(
        `/api/Ticket`,
        {
          id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (
        creds.role !== UserTypes.Client ||
        (data && data.company_id && data.company_id === creds.company_id)
      ) {
        setCurrentTicket(data);
        setIsPageLoading(false);
      } else {
        const options: any = {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        };

        toast.dismiss();

        toast.error("Vous n'avez pas accès à cette ressource", options);

        navigate("/");

        return;
      }
    } catch (error: any) {
      ErrorLogger("getting ticket inside ticket page", error);
    }
  };

  const deleteNotifications = async (ticket_id: string) => {
    try {
      await api.post(
        `/api/CustomNotification/deleteWhere`,
        {
          where: {
            ticket_id,
            user_id: creds.user_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      dispatch(closeNewNotifBadge());
    } catch (error: any) {
      ErrorLogger("deleting notifications", error);
    }
  };

  useEffect(() => {
    if (creds.token) {
      getCurrentTicket();
    }
    try {
      if (socket.current) {
        joinRoom(id as string);
        socket.current
          .off(`receive-message-${id}`)
          .on(`receive-message-${id}`, async (data: IMessage) => {
            setCurrentTicket((prevState) => {
              const res = {
                ...prevState,
                messages: [
                  ...(prevState?.messages as IMessage[]),
                  data as IMessage,
                ],
              } as ITicket;
              return res;
            });
          });
      }
    } catch (error) {
      console.error("error socket : ", error);
    }
    return () => {
      deleteNotifications(id!);
    };
  }, []);

  useEffect(() => {
    if (socket.current) {
      socket.current
        ?.off(`close-ticket-${id}-${creds.user_id}`)
        .on(`close-ticket-${id}-${creds.user_id}`, (data: any) => {
          setCloseClientTicketModal(true);
        });
    }
  }, [socket]);

  const leaveRoom = () => {
    dispatch(
      resetTicketNotifs({
        ticket_id: id,
      })
    );
    socket.current?.emit("leave-room", { room: currentTicket?.id });
  };

  const addDefaultSrc = (event: any) => {
    event.target.src = UserChat;
  };

  const createMessage: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      if (!form.text) {
        setErrorMessage({
          type: "empty_message",
          message: "Vous devez envoyer un texte de message valide",
        });
        return;
      }
      setLoading(true);

      let formData = null;

      if (inputFile) {
        formData = new FormData();
        formData.set("user_id", creds.user_id);
        formData.set("ticket_id", id as string);
        formData.set("text", form.text);
        if (inputFile) {
          formData.append(
            "attachement",
            inputFile as any,
            inputFile && (inputFile as any)!.name
              ? (inputFile as any)!.name
              : Date.now()
          );
        }
      } else {
        formData = {
          user_id: creds.user_id,
          ticket_id: id,
          text: form.text,
        };
      }

      const { data } = await api.post(`/api/Message/Create`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      await socket.current.emit("message", data);

      reset({
        text: null,
        attachement: null,
      });
      setInputFile(null);
      setLoading(false);
      if (data.id) {
        await api.post(`/api/helpers/sendTicketNotif`, data, {
          headers: {
            "x-access-token": creds.token,
          },
        });
      }
    } catch (error: any) {
      ErrorLogger("creating message", error);
      setLoading(false);
    }
  };

  const closeTicket = async () => {
    try {
      socket.current?.emit("close-ticket", {
        ticket_id: currentTicket?.id,
        user_id: creds.user_id,
      });
      await api.post(
        `/api/Ticket/Update`,
        {
          id,
          status: 100,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setCloseTicketModal(false);
      await getCurrentTicket();
    } catch (error: any) {
      ErrorLogger("creating message", error);
    }
  };

  const returnButton = async () => {
    leaveRoom();
    navigate("/tickets");
  };

  const { ref: textRef, ...text } = register("text");
  // const { ref: attachementRef, ...attachement } = register("attachement");
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event: any) => {
    (hiddenFileInput.current as any).value = null as unknown as string;
    (hiddenFileInput.current as any).click();
  };
  const handleChange = (event: { target: { files: any[] } }) => {
    const fileUploaded = event.target.files[0];
    setInputFile(fileUploaded);
  };

  return (
    <div className="page page-ticket-chat">
      {/* <div className="top-content">
        <Row>
          <Col lg={8} md={12}>
            <BannerTop banner={data.tickets} />
          </Col>
          <Col lg={4} md={12}>
            <UserInfo user={user} />
          </Col>
        </Row>
      </div> */}

      <div className="card-ticket-msg">
        <section className="msger">
          <div className="title-ticket title-secondary">
            <h3>{id?.toUpperCase()}</h3>
          </div>
          <ScrollToBottom className="msger-chat">
            <main>
              {currentTicket &&
                currentTicket.messages.length > 0 &&
                currentTicket.messages
                  .sort(
                    (a, b) =>
                      new Date(a.createdAt!).getTime() -
                      new Date(b.createdAt!).getTime()
                  )
                  .map((message, index) => {
                    return (
                      <div
                        className={`msg ${
                          message.user_id === creds.user_id ||
                          (message.user?.application_id !== APPLICATION_ID &&
                            message.user?.role !== UserTypes.Client)
                            ? "right"
                            : "left"
                        }-msg`}
                        key={index}
                      >
                        <div className="msg-img">
                          <img
                            src={
                              message.user?.profilePhoto
                                ? message.user?.profilePhoto.url
                                : UserChat
                            }
                            alt="img"
                            onError={addDefaultSrc}
                          />
                        </div>

                        <div className="msg-bubble">
                          <div className="msg-info">
                            <div className="msg-info-name">
                              {message.user?.role !== UserTypes.Client
                                ? message.user?.username
                                : message.user?.firstName &&
                                  message.user?.lastName &&
                                  message.user?.firstName !== "" &&
                                  message.user?.lastName !== ""
                                ? `${message.user?.firstName} ${message.user?.lastName}`
                                : `${message.user?.email}`}{" "}
                            </div>
                            <div className="msg-info-time">
                              {moment(message.createdAt).format(
                                "DD/MM/YYYY - HH:MM"
                              )}
                            </div>
                          </div>

                          <div className="msg-text single-msg-body">
                            {message.text}
                          </div>

                          {message.attachement && (
                            <div className="msg-info">
                              <div className="msg-info-link">
                                Pièce jointe :{" "}
                                <span
                                  onClick={() => {
                                    setUrl(message.attachement?.url!);
                                    setViewModal(true);
                                  }}
                                  className="msg-file"
                                >
                                  {message.attachement.key}
                                </span>
                                {/* <a
                                  href={message.attachement.url}
                                  target="_blank"
                                > */}
                                {/* </a> */}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
            </main>
          </ScrollToBottom>

          {isPageLoading && (
            <Row
              style={{
                position: "absolute",
                zIndex: 999,
                backgroundColor: "#fff",
                width: "100%",
                height: "145%",
              }}
            >
              <Col>
                <Spinner
                  style={{
                    top: "25%",
                    left: "45%",
                    position: "relative",
                  }}
                />
              </Col>
            </Row>
          )}

          {!currentTicket && !isPageLoading && (
            <div className="msger-inputarea">
              <div className="text-center">
                Il n'y a pas de ticket avec l'id {id?.toUpperCase()}
                <button
                  type="button"
                  className="btn-icon btn-icon-secondary btn btn-light"
                  onClick={async () => await returnButton()}
                >
                  <SendIconOrange />
                </button>
              </div>
            </div>
          )}

          {currentTicket && (
            <form
              onSubmit={handleSubmit(createMessage)}
              className="msger-inputarea"
            >
              <div className="top-send-msg">
                <button
                  type="button"
                  className="btn-icon btn-icon-secondary btn btn-light"
                  onClick={async () => await returnButton()}
                >
                  <SendIconOrange />
                </button>
                <div className="content-form-msg">
                  {currentTicket?.status === 100 ? (
                    <div className="resolved__ticket">Ce ticket est résolu</div>
                  ) : (
                    <>
                      <Input
                        className="msger-input form-control"
                        placeholder="Entrez votre message..."
                        type="textarea"
                        innerRef={textRef}
                        {...text}
                      />
                      <div className="bottom-action-msg">
                        <button
                          type="submit"
                          className="btn-icon btn-icon-secondary btn btn-blue p-2"
                        >
                          {loading ? (
                            <Spinner color="light" type="border" size={"sm"}>
                              Loading...
                            </Spinner>
                          ) : (
                            <SendIconWhite />
                          )}
                        </button>
                        <button
                          type="button"
                          className="btn-icon btn-icon-secondary btn btn-light"
                          onClick={handleClick}
                        >
                          <AttacheIcon />
                        </button>
                        {inputFile && (
                          <div className="msg-input-file-wrapper">
                            <span
                              className="msg-input-file-name"
                              onClick={() => {
                                setUrl(URL.createObjectURL(inputFile as any));
                                setViewModal(true);
                              }}
                            >
                              <pre>{(inputFile as any).name}</pre>
                            </span>
                            <button
                              type="button"
                              className="msg-input-file-delete btn-icon btn-icon-secondary btn btn-light"
                              onClick={() => setInputFile(null)}
                            >
                              <BsTrashFill
                                size={20}
                                stroke={colors.red}
                                fill={colors.red}
                              />
                            </button>
                          </div>
                        )}
                        <input
                          ref={hiddenFileInput}
                          onChange={handleChange as any}
                          type="file"
                          className="file-input"
                        />

                        {creds.role !== UserTypes.Client && (
                          <>
                            <Button
                              color="danger"
                              onClick={() => setCloseTicketModal(true)}
                              className="close__ticket"
                            >
                              Fermer Ticket
                            </Button>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </form>
          )}
        </section>
      </div>
      <div className="openbtn text-center">
        {/*close ticket from admin*/}
        <Modal
          className="modal-danger modal-dialog-centered"
          isOpen={closeTicketModal}
          toggle={() => {
            setCloseTicketModal(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setCloseTicketModal(false);
            }}
          >
            Fermer Ticket
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir fermer le ticket {id?.toUpperCase()} ?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={() => {
                setCloseTicketModal(false);
              }}
            >
              Non
            </Button>
            <Button color="danger" onClick={async () => await closeTicket()}>
              Oui
            </Button>
          </ModalFooter>
        </Modal>

        {/*close ticket for client*/}
        <Modal
          className="modal-warning modal-dialog-centered"
          isOpen={closeClientTicketModal}
        >
          <ModalHeader>Fermeture du ticket</ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Ce ticket a été fermé, veuillez cliquer sur OK pour passer à la
                page des tickets.
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              onClick={() => {
                navigate("/tickets");
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>

        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </div>
  );
};

export default TicketsMsg;
