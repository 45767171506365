import React, { PropsWithChildren, ReactElement } from "react";
import "./CardImage.scss";
import { ICardImage } from "../../interfaces";
import { Card } from "reactstrap";

function CardImage(props: PropsWithChildren<ICardImage>): ReactElement {
  return (
    <Card className={`card card-image ${props.className} `} onClick={props.onClick}>
      <div className="card-img">
        <img src={props.icon} alt="icon" />
      </div>
      <div className="card-body">
        <h3>{props.title}</h3>

        {props.children}
      </div>
    </Card>
  );
}

export default CardImage;
