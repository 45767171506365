import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../../config";
import { Company } from "../../../interfaces";
import useAxios from "../../../util/hooks/useAxios";
import { createAxiosInsatance } from "../../../util/helpers/createAxiosInsatance";
import { setCounter } from "../utils/utilSlice";
const { API_URL } = config[process.env.NODE_ENV];

const initialState = {
  companiesList: [] as Array<Company>,
  loading: "pending",
  currentRequestId: undefined as any,
  error: null as any,
};

export const fetchAllCompanies = createAsyncThunk(
  "applications/fetchAllCompanies",
  async (_, { getState, requestId, dispatch }) => {
    const thunkState = getState() as any;
    let api = createAxiosInsatance(thunkState.root.token, dispatch, setCounter);
    const { data } = await api!.post(
      `/api/Company/All`,
      {
        perPage: "Infinity",
      },
      {
        headers: {
          "x-access-token": thunkState.root.token,
        },
      }
    );
    return data.data;
  }
);

export const companiesListSlice = createSlice({
  name: "companiesList",
  initialState,
  reducers: {
    setcompaniesList: (state: any, action: any) => {
      state.companiesList = action.payload;
    },

    setCompanyUserStatus_Company: (state: any, action: any) => {
      const stateCompanies = JSON.parse(JSON.stringify(state.companiesList));

      const updatedState = stateCompanies.map((obj: Company) => {
        if (obj.id === action.payload.id) {
          return { ...obj, userStatus: action.payload.userStatus };
        }

        return obj;
      });

      state.companiesList = updatedState;
    },

    deleteCompany_Company: (state: any, action: any) => {
      const stateCompanies = JSON.parse(JSON.stringify(state.companiesList));

      const updatedState = stateCompanies.filter(
        (company: Company) => company.id !== action.payload.id
      );

      state.companiesList = updatedState;
    },

    resetCompaniesList: (state: any) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCompanies.pending, (state: any, action: any) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          // state.currentRequestId = action.meta.requestId;
        }
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchAllCompanies.fulfilled, (state: any, action: any) => {
        const { requestId } = action.meta;
        if (
          // state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.companiesList = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchAllCompanies.rejected, (state: any, action: any) => {
        const { requestId } = action.meta;
        if (
          // state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const {
  setCompanyUserStatus_Company,
  setcompaniesList,
  deleteCompany_Company,
  resetCompaniesList,
} = companiesListSlice.actions;
export default companiesListSlice.reducer;
