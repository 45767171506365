import React, {Dispatch, useCallback, useState} from "react";
import {Spinner} from "reactstrap";
import {IBridgeAccount, IBridgeItem} from "../../../../interfaces";

import {toastSettings} from "../../../../util/context";

import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";

import "moment/locale/fr";
import DataTable from "react-data-table-component";
import {BsTrashFill} from "react-icons/bs";
import {AiFillCheckCircle, AiFillTool, AiFillWarning} from "react-icons/ai";

import useAxios from "../../../../util/hooks/useAxios";

import "./SynchroniseModal.scss";
import {deleteBridgeItem, fixBridgeItem} from "../../../../api/bridgeItem";
import {BootyPagination} from "../../../../components/table/pagination";
import {getStatusDescription} from "../../../../util/function";
import CompteGestionIbanModal from "../CompteGestionIbanModal/CompteGestionIbanModal";
import {toast} from "react-toastify";

registerLocale("fr", fr);

type Props = {
  bridegItems: IBridgeItem[];
  setGestionModal: Dispatch<React.SetStateAction<boolean>>;
  gestionModal: boolean;
  getBridgeAcoounts: () => Promise<void>;
  setStopSyncModal: Dispatch<React.SetStateAction<boolean>>;
  creds: {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    application_id: string;
  };
  totalRows: number;
  handlePageChange: any;
  loading: boolean;
  currentPage: number;
  clientId: string;
};

const GestionCompte = ({
  bridegItems,
  getBridgeAcoounts,
  creds,
  totalRows,
  handlePageChange,
  loading,
  clientId,
}: Props) => {
  const [loadingState, setLoadingState] = useState({
    id: 0,
    status: false,
    role: "",
  });

  const [openIbanModal, setOpenIbanModal] = useState(false);
  const [ibans, setIbans] = useState<IBridgeAccount[]>([]);

  let api = useAxios();

  //function for gestion ibans
  const deleteItem = useCallback(
    async ({itemId}: {itemId: number}) => {
      setLoadingState({
        id: itemId,
        status: true,
        role: "delete",
      });
      try {
        await deleteBridgeItem({
          api,
          itemId: Number(itemId),
          clientId: clientId,
        });
        toast.dismiss();
        toast.success("votre opération est réussie", toastSettings);
        getBridgeAcoounts();
      } catch (error: any) {
        toast.dismiss();
        toast.error("votre opération a échoué", toastSettings);
      }
      setLoadingState({
        id: 0,
        status: false,
        role: "",
      });
    },
    [clientId]
  );

  const fixItem = async (row: IBridgeItem) => {
    toast.dismiss();
    toast.info("Votre operation est en progress", toastSettings);
    setLoadingState({
      id: row.id,
      status: true,
      role: "update",
    });

    try {
      const resp = await fixBridgeItem({
        api,
        itemId: row.id,
        status: row.status,
        from: creds.application_id,
        clientId,
      });

      if (!!resp?.data?.url) {
        window.location = resp?.data.url;
      }
      await getBridgeAcoounts();
    } catch (error) {
      toast.dismiss();
      toast.error("votre opération a échoué", toastSettings);
    } finally {
      setLoadingState({
        id: 0,
        status: false,
        role: "",
      });
    }
  };

  // function for gestion iban modal
  const handelIbanModal = useCallback(() => {
    return setOpenIbanModal((prevValue) => {
      return !prevValue;
    });
  }, []);

  const openGestionIbanModal = useCallback((row: IBridgeItem) => {
    // setIbans
    setIbans(
      row.bridge_accounts.map((elt) => ({
        ...elt,
        iban: elt.iban || "",
      }))
    );
    handelIbanModal();
  }, []);

  const columns = [
    {
      name: "Banque",
      sortable: true,
      width: "20%",
      cell: (row: any) => (
        <div
          style={{
            display: "flex",
            gap: "2px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.bankInfo && row.bankInfo.logo_url && (
            <img src={row.bankInfo.logo_url} className="bankImg" />
          )}
          {row.bankInfo && row.bankInfo.name && (
            <p style={{margin: 0, minWidth: "30px"}}>{row.bankInfo.name}</p>
          )}
        </div>
      ),
    },
    {
      name: "Status",
      width: "20%",
      cell: (row: IBridgeItem) => (
        <div
          style={{
            display: "flex",
            gap: "5px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.status === 0 ? (
            <AiFillCheckCircle color="green" width={"25px"} height={"25px"} />
          ) : (
            <AiFillWarning
              color="orange"
              style={{width: "25px", height: "25px"}}
            />
          )}
        </div>
      ),
    },
    {
      name: "Description",
      selector: (row: IBridgeItem) => row.status,
      width: "30%",
      cell: (row: IBridgeItem) => (
        <div
          style={{
            display: "flex",
            gap: "5px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.status === 0
            ? "Valider"
            : getStatusDescription(row.status.toString())}
        </div>
      ),
    },
    {
      name: "Actions",
      width: "30%",
      cell: (row: IBridgeItem) => (
        <div className="flex-wrap" style={{gap: "2vw"}}>
          {/* <button
            className="btn btn-blue"
            type="submit"
            disabled={loadingState.status}
            onClick={() => handelModal(row)}
            title="Synchroniser votre compte"
          >
            <FaSync />
          </button> */}

          <button
            className="btn btn-blue"
            onClick={async () => await fixItem(row)}
            disabled={loadingState.status || row.status === 0}
          >
            {loadingState.id === row.id && loadingState.role === "update" ? (
              <Spinner color="light" type="border" size={"sm"}>
                Loading...
              </Spinner>
            ) : (
              <AiFillTool />
            )}
          </button>
          {/* <button
            className="btn btn-green"
            title="Gestion iban"
            disabled={loadingState.status}
            onClick={() => openGestionIbanModal(row)}
          >
            <AiOutlineBank />
          </button> */}
          <button
            className="btn btn-red"
            onClick={() =>
              deleteItem({
                itemId: row.id,
              })
            }
            title="Supprimer le statut de devis"
            disabled={loadingState.status}
          >
            {loadingState.status &&
            loadingState.id === row.id &&
            loadingState.role === "delete" ? (
              <Spinner color="red" type="border" size={"sm"}>
                Loading...
              </Spinner>
            ) : (
              <BsTrashFill />
            )}
          </button>
        </div>
      ),
    },
  ];

  return (
    <div style={{position: "relative", marginBottom: "80px"}}>
      <CompteGestionIbanModal
        openIbanModal={openIbanModal}
        handelModal={handelIbanModal}
        ibans={ibans}
        getBridgeAccounts={getBridgeAcoounts}
        clientId={clientId}
      />
      <DataTable
        columns={columns}
        data={bridegItems}
        noDataComponent={<p>Il n'y a aucun data à afficher</p>}
        pagination
        progressComponent={
          <>
            <Spinner color="secondary" type="grow" className="mx-1">
              Loading...
            </Spinner>
            <Spinner color="secondary" type="grow" className="mx-1">
              Loading...
            </Spinner>
            <Spinner color="secondary" type="grow" className="mx-1">
              Loading...
            </Spinner>
          </>
        }
        paginationServer
        paginationComponent={(props) => {
          const customProps = {...props, color: "secondary"};
          return <BootyPagination {...customProps} />;
        }}
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        paginationPerPage={5}
        progressPending={loading}
      />
    </div>
  );
};

export default GestionCompte;
