import React, { useState } from "react";
import "./Tests.scss";
import {
  Company,
  IAvoir,
  IDevis,
  IFacture,
  IModel,
  ISubCategory,
  User,
} from "../../interfaces";
import "moment/locale/fr";
import Comp from "./Comp";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { BsCurrencyEuro } from "react-icons/bs";

export type TabsType = {
  label: string;
  index: number;
  Component: React.FC;
  type?: string;
  allowedPacks: String[];
}[];

// Tabs Array
const tabs: any = [
  {
    label: "Calcul d'un prix TTC à partir d'un prix HT",
    index: 0,
    Component: Comp,
  },
  {
    label: "Calcul d'un prix HT à partir d'un prix TTC",
    index: 1,
    Component: Comp,
  },
];

const PanelComp = (props: any) => {
  const { Component, ...rest } = props;
  return <Component {...rest} />;
};

const Facturation = ({
  client,
  context,
  company,
  callback,
}: {
  client?: User;
  context?: string;
  company?: Company;
  callback?: () => void;
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <div className="converter-wrapper">
      <div className="converter-content">
        <h1 className="converter-title">
          Calculateur de numéro <br /> de TVA intracommunautaire gratuit
          <br /> Siren, Siret, TVA, Numéro IC
        </h1>
        <p className="converter-description">
          Vous avez besoin d’un numéro de TVA d’un client ou d’un fournisseur ?
          Il vous suffit de renseigner les 9 chiffres du Siren ou les 14
          chiffres du Siret de l’entreprise concernée. <br /> Si celui-est
          valide. Son numéro de TVA FR s’affichera automatiquement.
        </p>
        <div className="converter-card card-Table table-primary card">
          <h3>
            Trouver le numero de TVa Intracommunautaire à partir du SIREN ou
            SIRET
          </h3>
          <Row className="converter-inputs">
            <Col md={6}>
              <FormGroup className="converter-input">
                <Input
                  id="sirent-siret-value"
                  name="amount"
                  className="form-secondary"
                  placeholder="Entrez un numéro SIREN ou SIRET"
                />
              </FormGroup>
            </Col>
            <Col md={6} className="converter-action-btn-wrapper">
              <Button
                color="secondary"
                className="converter-action-btn"
                id="converter-btn"
              >
                <span>Trouver numéro de TVA Intracommunautaire</span>
              </Button>
            </Col>
          </Row>
          <h3 id="tva-number-value"></h3>
        </div>
      </div>
    </div>
  );
};

export default Facturation;

// <div className="calculator-wrapper">
//   <div className="calculator-content">
//     <h1 className="calculator-title">CALCULATEUR PRIX HT OU TTC</h1>
//     <p className="calculator-description">
//       Vérifié le 05 Juillet 2022 – Direction de l’information légale et
//       administrative (Premier ministre) <br /> Ce calculateur permet de
//       calculer un prix toutes taxes comprises (TTC) à partir d’un prix hors
//       taxes (HT) et vice versa selon{" "}
//       <a href="">le taux de tva applicable</a>
//     </p>
//     <div className="calculator-card card-Table table-primary card">
//       <div className="tablist" role="tablist">
//         {tabs.map((tab: any, index: number) => (
//           <div
//             className={
//               selectedTab === index
//                 ? "calculator-tab-div active"
//                 : " calculator-tab-div"
//             }
//             onClick={() => {
//               setSelectedTab(index);
//             }}
//             key={index}
//             role="tab"
//             id={`calculator-tab-div-${index}`}
//           >
//             <span className="label-span">{tab.label}</span>
//           </div>
//         ))}
//       </div>
//       <div
//         role="tabpanel"
//         aria-labelledby={`btn-${selectedTab}`}
//         id={`tabpanel-${selectedTab}`}
//         className="tabpanel"
//       >
//         <PanelComp
//           Component={tabs[selectedTab].Component}
//           index={selectedTab}
//           context={context}
//           company={company}
//           client={client}
//           callback={callback}
//           reloadParent={null}
//           reload={null}
//           immobStateHandler={null}
//           payDiffStateHandler={null}
//           pack={null}
//         />
//       </div>
//     </div>
//   </div>
// </div>
