import React, { useCallback, useEffect } from "react";
import "./DepenseCharts.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-style";
import { CAChartTypes } from "../Dashboard";
import { ShadowPlugin } from "../LineChartsMu/LineChartsMu";
import { Spinner } from "reactstrap";
import { deepComparison } from "../../../util/helpers/utilities";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  {
    id: "DepenseChart",
    beforeDraw: (chart: { ctx: any }, args: any, options: any) => {
      const { ctx } = chart;
      ctx.shadowColor = "rgba(0, 0, 0, 0.25)";
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 10;
      ctx.shadowOffsetY = 10;
    },
  }
);

export const options = {
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "",
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.dataset.label || "";
          if (label) {
            label += ": ";
          }
          if (context.raw !== null) {
            label += parseFloat(context.raw).toFixed(2) + " €";
          }

          return label;
        },
      },
    },
  },
  responsive: true,
  // interaction: {
  //   mode: 'index' as const,
  //   intersect: false,
  // },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: true,
      },
      ticks: {
        callback: function (value: any) {
          return `${value} €`;
        },
      },
    },
  },
};

const labels = [
  "Jan",
  "Fev",
  "Mar",
  "Avr",
  "Mai",
  "Jun",
  "Jui",
  "Aou",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// export const data = {
//   labels: [] as string[],
//   datasets: [
//     {
//       label: "Estimation",
//       data: [],
//       backgroundColor: (...args: any) => {
//         return parseFloat(args[0].raw) > 0 ? "#4ab7a1" : "#fd6c80";
//       },
//       color: "red",
//       stroke: {
//         curve: "smooth",
//       },
//       borderRadius: 5,
//       shadowOffsetX: 3,
//       shadowOffsetY: 3,
//       shadowBlur: 10,
//       shadowColor: "rgba(0, 0, 0, 0.5)",
//     },
//   ] as {
//     label: string;
//     data: (number | null | undefined)[];
//     backgroundColor: any;
//     color: string;
//     stroke: {
//       curve: string;
//     };
//     borderRadius: number;
//     shadowOffsetX: number;
//     shadowOffsetY: number;
//     shadowBlur: number;
//     shadowColor: string;
//   }[],
// };

export const renderChart = (chartData: any, labels: any) => {
  return {
    labels: labels as string[],
    datasets: [
      {
        label: "Estimation",
        data: chartData,
        backgroundColor: (...args: any) => {
          return parseFloat(args[0].raw) > 0 ? "#4ab7a1" : "#fd6c80";
        },
        color: "red",
        stroke: {
          curve: "smooth",
        },
        borderRadius: 5,
        shadowOffsetX: 3,
        shadowOffsetY: 3,
        shadowBlur: 10,
        shadowColor: "rgba(0, 0, 0, 0.5)",
      },
    ] as {
      label: string;
      data: (number | null | undefined)[];
      backgroundColor: any;
      color: string;
      stroke: {
        curve: string;
      };
      borderRadius: number;
      shadowOffsetX: number;
      shadowOffsetY: number;
      shadowBlur: number;
      shadowColor: string;
    }[],
  };
};

const DepenseCharts = ({
  monthlyEstimationData,
  loading,
}: {
  monthlyEstimationData: CAChartTypes[];
  loading: boolean;
}) => {
  return (
    <>
      <div className="charts">
        {loading || monthlyEstimationData.length === 0 ? (
          <div className="loading-chart">
            <Spinner type="border" size={"md"} className="chart-spinner">
              Loading...
            </Spinner>
            <Bar
              options={options}
              data={{
                labels: [] as string[],
                datasets: [],
              }}
            />
          </div>
        ) : (
          <Bar
            options={options}
            data={renderChart(
              monthlyEstimationData.reduce(
                (acc, curr) => [...acc, curr.result.diffSpendingIt || 0],
                [] as number[]
              ),
              monthlyEstimationData.map(
                (elt) => `${elt.shortMonth}/${elt.year}`
              )
            )}
            redraw
          />
        )}
      </div>
    </>
  );
};


export default React.memo(DepenseCharts, (prevProps, nextProps) => {
  return deepComparison(
    nextProps.monthlyEstimationData,
    prevProps.monthlyEstimationData,
    "result",
    "Differential"
  );
});
