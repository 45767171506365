import React, { useEffect, useMemo, useState } from "react";
import "./Bilan.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import { RiDownload2Fill, RiUser3Line } from "react-icons/ri";
import AttacheIcon from "../../assets/AttacheIcon";
import { Company, IBilan, IS3, User } from "../../interfaces";
import axios from "axios";
import moment, { Moment } from "moment";
import config from "../../config";
import { useSelector } from "react-redux";
import { ErrorLogger } from "../../util/errorLogger";
import { SubmitHandler, useForm } from "react-hook-form";
import { resetTicketNotifs } from "../../store/reducers/messages-notifs/messagesNotifsSlice";
import EmptyBilan from "./EmptyBilan";
import FullBilan from "./FullBilan";
import useAxios from "../../util/hooks/useAxios";
import Tabs from "../../components/Tabs";
import ArchivedBilan from "./ArchivedBilan";
import Select from "react-select";
import { OptionType, UserTypes } from "../../util/context";
import BannerTop from "../../components/Banner/BannerTop";
import data from "../../constants/data";
import { SiPypy } from "react-icons/si";
import UserInfo from "../../components/user-info/UserInfo";
import CrownIcon from "../../assets/CrownIcon";
import { colors } from "../../constants";

moment.updateLocale("fr", {});

const { API_URL } = config[process.env.NODE_ENV];

export interface BilandProps { }

type GeneralFormValues = {
  etat: number | null;
  faitMarquant: string | null;
  relevesBancaires: IS3[] | null;
  emprunt: IS3[] | null;
  cotisationsObligatoires: IS3[] | null;
  cotisationsFacultatives: IS3[] | null;
  documentsFiscaux: IS3[] | null;
  documentsSupplementaires: IS3[] | null;
  year: number | null;
  liasseFiscale: IS3[] | null;
  FEC: IS3[] | null;
  recapIR: IS3[] | null;
  fakeLiasseFiscale: IS3[] | null;
  fakeFEC: IS3[] | null;
  fakeRecapIR: IS3[] | null;
  zipURL: IS3[] | null;
  fullRecap: IS3[] | null;
};

const Bilan = ({
  context,
  company,
  callback,
}: {
  context?: string;
  company?: Company;
  callback?: () => void;
}) => {
  const [currentBilan, setCurrentBilan] = useState<IBilan[]>([]);
  const [oldBilans, setOldBilans] = useState<IBilan[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [bilanYear, setBilanYear] = useState<OptionType>({
    label: `${moment().get("year")}`,
    value: moment().get("year"),
  });

  const [bilanYears, setBilanYears] = useState<OptionType[]>([]);

  const [displayYear, setDisplayYear] = useState<number>(
    moment().get("year") - 1
  );

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user, company: userCompany } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  const { newNotif } = useSelector(
    (state: { messagesNotifs: object }) => state.messagesNotifs
  ) as { newNotif: boolean };

  let api = useAxios();

  const getCurrentBilan = async () => {
    try {
      const { data } = await api.post(
        `/api/Bilan/all`,
        {
          where: {
            companyId: company && company.id ? company?.id : creds.company_id,
            // year: new Date().getFullYear() - 1,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setCurrentBilan(
        data.data.filter(
          (elt: { year: number; etat: number }) =>
            elt.year === new Date().getFullYear() - 1 && elt.etat < 3 && elt.etat
        )
      );
      setOldBilans(
        data.data.filter(
          (elt: { year: number; etat: number }) =>
            elt.year !== new Date().getFullYear() - 1 ||
            (elt.year === new Date().getFullYear() - 1 && elt.etat === 3)
        )
      );

      const isCurrentYearBilanClosed = data.data.filter(
        (elt: { year: number; etat: number }) =>
          elt.year === new Date().getFullYear() - 1 && elt.etat === 3
      );

      if (isCurrentYearBilanClosed.length > 0) {
        setDisplayYear(moment().get("year"));
      }
    } catch (error: any) {
      ErrorLogger("getting bilan data", error);
    }
  };

  const yearsOption = useMemo(() => {
    let years: OptionType[] = [];
    const allowedComp = company || userCompany;
    if (allowedComp?.createdAt) {
      for (
        let index = 0;
        index <=
        moment().get("year") - (moment(allowedComp.createdAt).get("year") - 1);
        index++
      ) {
        years.push({
          label: `${moment(allowedComp.createdAt).get("year") - 1 + index}`,
          value: moment(allowedComp.createdAt).get("year") - 1 + index,
        });
      }
    }
    return years;
  }, [company, userCompany]);

  const navigate = useNavigate();

  useEffect(() => {
    getCurrentBilan();
  }, []);

  useEffect(() => {
    if (creds.company_id) {
      getCurrentBilan();
    }
  }, [creds.company_id]);

  const BilanRender = () => (
    <>
      <h2>Bilan {displayYear} </h2>
      {currentBilan.length > 0 ? (
        <FullBilan
          bilan={currentBilan[0]}
          callback={getCurrentBilan}
          company={company && company.id ? company : userCompany}
        />
      ) : (
        <EmptyBilan
          callback={getCurrentBilan}
          company={company && company.id ? company : userCompany}
        />
      )}
    </>
  );

  return (
    <div className="page section-dashboard dashboard-selector-wrapper">
      {creds.role === UserTypes.Client ? (
        <>
          <div
            className={`top-content bilan-selector-wrapper ${creds.role === UserTypes.Client ? "client-resize-header" : ""
              }`}
          >
            <Row>
              <Col lg={8} md={12} className="blockBanner">
                <BannerTop banner={data.bilan} />
              </Col>
              <Col lg={4} md={12}>
                <Card className="blockUser">
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() => navigate("/tickets")}
                  >
                    {newNotif && <Badge color="primary"> </Badge>}
                    <CrownIcon stroke={colors.primaryColor} />
                  </Button>
                  <UserInfo user={user} />
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() =>
                      navigate({
                        pathname: "/profil/client",
                        // search: "?page=1&tab=1",
                      })
                    }
                  >
                    <RiUser3Line />
                  </Button>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="section-bilan">
        <div className=" card">
          <div className="container">
            <div className="create-indem__button action-top-tabs mt-3 bilan-year-select">
              {selectedTab === 2 && creds.role !== UserTypes.Client && (
                <FormGroup className="form-icon icon-start">
                  <Select
                    options={yearsOption}
                    closeMenuOnSelect={true}
                    classNamePrefix="select"
                    className="custom-select form-secondary"
                    onChange={(year) => setBilanYear(year!)}
                    placeholder="Année du bilan"
                    value={bilanYear}
                    noOptionsMessage={() => <p>Aucune option</p>}
                  />
                </FormGroup>
              )}
            </div>
            {creds.role === UserTypes.Client ? (
              <BilanRender />
            ) : (
              <Tabs
                selectedTab={selectedTab}
                onClick={setSelectedTab}
                tabs={[
                  {
                    label: "Courant",
                    index: 1,
                    Component: BilanRender,
                  },
                  {
                    label: "Archive",
                    index: 2,
                    Component: () => (
                      <ArchivedBilan
                        bilanYear={bilanYear}
                        oldBilans={oldBilans}
                        company={company && company.id ? company : userCompany!}
                      />
                    ),
                  },
                ]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bilan;
