import React, {useEffect, useState} from "react";
import "./GestionProspect.scss";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {SiPypy} from "react-icons/si";
import UserInfo from "../../components/user-info/UserInfo";
import {data} from "../../constants";
import {
  RiAddLine,
  RiCalendar2Line,
  RiContactsBookLine,
  RiUser3Line,
} from "react-icons/ri";
import BannerTop from "../../components/Banner/BannerTop";

import Select, {GroupBase} from "react-select";

import {BootyPagination} from "../../components/table/pagination";
import {
  BsFillPersonCheckFill,
  BsFillPersonDashFill,
  BsFillPersonXFill,
  BsFillTelephoneOutboundFill,
  BsPersonPlusFill,
  BsTelephoneXFill,
  BsTrashFill,
  BsXCircle,
} from "react-icons/bs";
import DataTable from "react-data-table-component";
import userAddIcon from "../../assets/images/svg/user-t1.svg";
import juridiqueIcons from "../../assets/images/svg/juridique-icon.svg";

import UserIcon from "../../assets/images/svg/user-orange.svg";
import UserIconLead from "../../assets/images/svg/user-lead.svg";
import AddIconLead from "../../assets/images/svg/userLead-icon.svg";
import UserProsp from "../../assets/images/svg/user-pres.svg";
import EmailIcon from "../../assets/images/svg/mail-orange.svg";
import KeyIcon from "../../assets/images/svg/key-orange.svg";
import PhoneIcon from "../../assets/images/svg/phone-orange.svg";
import LabelIcon from "../../assets/images/svg/banq-icon.svg";
import CalenderIconBlue from "../../assets/images/svg/calender-icon-blue.svg";

import {
  IApplication,
  ILead,
  IPermission,
  ProspectProps,
  User,
} from "../../interfaces";

import {IoMdClose, IoMdCreate} from "react-icons/io";
import EmailIconBlue from "../../assets/images/svg/mail-blue.svg";
import PhoneIconBleu from "../../assets/PhoneIcon";
import CheckIcon from "../../assets/CheckIcon";
import Badge from "../../components/badge/Status";
import Status from "../../components/badge/Status";
import LoadBoxIcon from "../../assets/LoadBoxIcon";
import {useDispatch, useSelector} from "react-redux";
import config from "../../config";
import axios from "axios";
import {ErrorLogger} from "../../util/errorLogger";

import moment from "moment";
import "moment/locale/fr";
import {
  ACQUISITION_CHANNEL_CODES,
  GENDER_OPTIONS,
  LEADS_STATUS_CODES,
  OptionType,
  PLAN_OPTIONS,
  UserTypes,
} from "../../util/context";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {STATUS_CODES} from "http";
import {useAuthorization} from "../../util/hooks/useAuthorization";

import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import {fetchAllCompanies} from "../../store/reducers/companies-list/companiesSlice";
import useAxios from "../../util/hooks/useAxios";
import * as yup from "yup";
import {ReactSVG} from "react-svg";
registerLocale("fr", fr);

const options = [
  {value: "chocolate", label: "Chocolate"},
  {value: "strawberry", label: "Strawberry"},
  {value: "vanilla", label: "Vanilla"},
];
const status = [
  {value: "encour", label: "En cours"},
  {value: "valider", label: "Valider"},
  {value: "rejeter", label: "rejeter"},
];

const {API_URL} = config[process.env.NODE_ENV];

type SearchLeadFormValues = {
  dateFrom?: string | null;
  dateTo?: string | null;
  status?: OptionType | null;
  acquisition_channel?: OptionType | null;
  text?: string;
};

type CreateLeadFormValues = {
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  email: string | null;
  acquisition_channel: OptionType | null;
  status: OptionType | null;
  notes: string | null;
  application_id?: string | null;
};

type CreateCompanyFormValues = {
  pack: OptionType | null;
  applicationId: string | null;
};

type CreateUserFormValues = {
  application: OptionType | null;
  gender: OptionType | null;
  user_email: string | null;
  user_firstName: string | null;
  user_lastName: string | null;
  password: string | null;
  user_phone: string | null;
  repeat_password: string | null;
  role: string | null;
  company: CreateCompanyFormValues | null;
};

type CreateNewCompanyFormValues = {
  application: OptionType | null;
  client: OptionType | null;
  company_email: string | null;
  name: string | null;
  pack: OptionType | null;
  siren: string | null;
};

type EditLeadFormValues = {
  edit_firstName?: string | null;
  edit_lastName?: string | null;
  edit_phone?: string | null;
  edit_email?: string | null;
  edit_acquisition_channel?: OptionType | null;
  edit_status?: OptionType | null;
  edit_notes?: string | null;
  application_id?: string | null;
};

const emailSchema = yup.string().email();

const passwordSchema = yup
  .string()
  .matches(
    new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
  );

type DynamicObject = {[key: string]: any};

export interface ClientsProps {}
const GestionProspect: React.FC<ClientsProps> = ({}) => {
  const [leadOpen, setLeadOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [choiceOpen, setChoiceOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState<Array<User>>([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [leads, setLeads] = useState<Array<ILead>>([]);
  const [singleLead, setSingleLead] = useState<ILead | null>();
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const [pending, setPending] = useState<boolean>(true);

  const onEditToggle = (lead: ILead) => {
    setSingleLead(lead);
    setEditOpen(true);
  };
  const onDeleteToggle = (lead: ILead) => {
    setSingleLead(lead);
    setDeleteOpen(true);
  };

  const orderStatus: any = {
    Lead: "primary",
    Interessé: "warning",
    valider: "success",
    refund: "danger",
  };

  const columns: any = [
    {
      name: "Prénom",
      selector: (row: any) => row.firstName,
      sortable: true,
    },
    {
      name: "Nom",
      selector: (row: any) => row.lastName,
      sortable: true,
    },
    {
      name: "E-mail",
      selector: (row: any) => row.email,
    },
    {
      name: "Nº Téléphone",
      selector: (row: any) => row.phone,
    },
    {
      name: "C. d'acquisition",
      selector: (row: any) =>
        ACQUISITION_CHANNEL_CODES.find(
          (elt) => elt.value === row.acquisition_channel
        )?.label,
      sortable: true,
    },
    {
      name: "Date de création",
      selector: (row: any) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
    },
    {
      name: "Statut",
      selector: (row: any) => row.status,
      sortable: true,
      cell: (row: ILead) => (
        <>
          {row.status === 100 && (
            <Status className="btn" type="primary" title="Lead">
              <CheckIcon />
            </Status>
          )}
          {row.status === 106 && (
            <Status className="btn" type="secondary" title="Aucune réponse">
              <BsXCircle color="white" />
            </Status>
          )}
          {row.status === 101 && (
            <Status className="btn" type="dark" title="Non intéressé">
              <BsFillPersonDashFill color="white" />
            </Status>
          )}
          {row.status === 102 && (
            <Status className="btn" type="success" title={UserTypes.Client}>
              <BsFillPersonCheckFill color="white" />
            </Status>
          )}
          {row.status === 103 && (
            <Status className="btn" type="danger" title="Hors cible">
              <BsFillPersonXFill color="white" />
            </Status>
          )}
          {row.status === 104 && (
            <Status className="btn" type="warning" title="Rappel">
              <BsFillTelephoneOutboundFill color="white" />
            </Status>
          )}
          {row.status === 105 && (
            <Status className="btn" type="info" title="Intéressé">
              <BsPersonPlusFill color="white" />
            </Status>
          )}
        </>
      ),
    },
    {
      name: "Action",
      button: true,
      cell: (row: ILead) => (
        <div className="table-action">
          <button className="btn btn-blue" onClick={() => onEditToggle(row)}>
            <IoMdCreate />
          </button>
          <button className="btn btn-red" onClick={() => onDeleteToggle(row)}>
            <BsTrashFill />
          </button>
        </div>
      ),
    },
  ];

  const creds = useSelector(
    (state: {root: object; user: object}) => state.root
  ) as {user_id: string; company_id: string; token: string};

  const {user} = useSelector(
    (state: {user: object; application: object; permissions: object}) =>
      state.user
  ) as {user: User};

  const {applications} = useSelector(
    (state: {applications: Object}) => state.applications
  ) as {applications: IApplication[]};

  let api = useAxios();

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    // await getAllLeads(page);
  };

  const getAllLeads = async () => {
    try {
      setLoading(true);

      const {data} = await api.post(
        `/api/Lead/All`,
        {
          perPage: 20,
          pageIndex: currentPage,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setLeads(data.data);
      setLoading(false);
      setTotalRows(data.count);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting all leads in gestion prospect", error);
    }
  };

  const getAllClients = async () => {
    try {
      const {data} = await api.post(
        `/api/User/All`,
        {
          where: {
            role: "Client",
          },
          perPage: "Infinity",
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setClients(data.data);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting all leads in gestion prospect", error);
    }
  };

  useEffect(() => {
    getAllClients();
  }, []);

  useEffect(() => {
    getAllLeads();
  }, [currentPage]);

  const searchLeads: SubmitHandler<SearchLeadFormValues> = async (
    form: SearchLeadFormValues
  ) => {
    try {
      setLoading(true);
      setPending(true);
      let payload: any = {};
      if (form.dateFrom || form.dateTo)
        payload.createdAt = {
          from: form.dateFrom
            ? moment(form.dateFrom).format()
            : moment("2019/01/01").format(),
          to: form.dateTo
            ? moment(form.dateTo).add(1, "day").format()
            : moment().add(1, "day").format(),
        };

      if (form.status) payload.status = form.status.value;
      if (form.acquisition_channel)
        payload.acquisition_channel = form.acquisition_channel.value;

      const {data} = await api.post(
        `/api/Lead/All`,
        {
          where: {
            ...payload,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setLeads(data.data);
      setLoading(false);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("searching leads in gestion prospect", error);
    }
  };

  const createLead: SubmitHandler<CreateLeadFormValues> = async (
    form: CreateLeadFormValues
  ) => {
    try {
      const isFormEmpty = Object.keys(form).every(
        (key: string) => !(form as {[key: string]: any})[key]
      );

      if (isFormEmpty) {
        setErrorMessage({
          type: "create_empty_form",
          message: "Veuillez remplir les champs",
        });
        return;
      }

      setCreateLoading(true);

      let payload: any = {};

      if (form.firstName) payload.firstName = form.firstName;
      if (form.email) payload.email = form.email;
      if (form.lastName) payload.lastName = form.lastName;
      if (form.notes) payload.notes = form.notes;
      if (form.phone) payload.phone = form.phone;

      if (form.acquisition_channel)
        payload.acquisition_channel = form.acquisition_channel.value;
      if (form.status) payload.status = form.status.value;

      await api.post(`/api/Lead/Create`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      setLeadOpen(false);
      resetLeadCreationForm();
      setCreateLoading(false);
    } catch (error: any) {
      ErrorLogger("creating lead", error);
      setCreateLoading(false);
    }
  };

  const editLead: SubmitHandler<EditLeadFormValues> = async (
    form: EditLeadFormValues
  ) => {
    try {
      setEditLoading(true);
      const hasNotChanged = Object.keys(form).every((elt: string) => {
        const key = elt.replace("edit_", "");
        if (
          typeof (form as DynamicObject)[`edit_${key}`] === "object" &&
          (form as DynamicObject)[`edit_${key}`] &&
          typeof (singleLead as DynamicObject)[`${key}`] === "object" &&
          (singleLead as DynamicObject)[`${key}`]
        ) {
          setEditLoading(false);
          return (
            (singleLead as DynamicObject)[`${key}`].value ===
            (form as DynamicObject)[`edit_${key}`].value
          );
        } else if (
          !(form as DynamicObject)[`edit_${key}`] &&
          !(singleLead as DynamicObject)[`${key}`]
        ) {
          setEditLoading(false);
          return true;
        } else {
          setEditLoading(false);
          return (
            (singleLead as DynamicObject)[`${key}`] ===
            (form as DynamicObject)[`edit_${key}`]
          );
        }
      });

      if (hasNotChanged) {
        setEditLoading(false);
        setErrorMessage({
          type: "edit_empty_form",
          message: "Veuillez modifier au moins une valeur",
        });
        return;
      }

      let payload: any = {};

      if (form.edit_firstName) payload.firstName = form.edit_firstName;
      if (form.edit_email) payload.email = form.edit_email;
      if (form.edit_lastName) payload.lastName = form.edit_lastName;
      if (form.edit_notes) payload.notes = form.edit_notes;
      if (form.edit_phone) payload.phone = form.edit_phone;

      if (form.edit_acquisition_channel)
        payload.acquisition_channel = form.edit_acquisition_channel.value;
      if (form.edit_status) payload.status = form.edit_status.value;

      await api.post(
        `/api/Lead/Update`,
        {
          id: singleLead?.id,
          ...payload,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setEditOpen(false);
      resetLeadEditForm();
      setEditLoading(false);
    } catch (error: any) {
      ErrorLogger("editing lead", error);
      setEditLoading(false);
    }
  };

  const deleteLead = async () => {
    try {
      setDeleteLoading(true);
      await api.post(
        `/api/Lead/deleteWhere`,
        {
          where: {
            id: [singleLead?.id],
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setLeads((prevState) =>
        prevState.filter((lead) => lead.id !== singleLead?.id)
      );
      setSingleLead(null);
      setDeleteOpen(false);
      setDeleteLoading(false);
    } catch (error: any) {
      ErrorLogger("deleting a lead", error);
      setDeleteLoading(false);
    }
  };

  const dispatch = useDispatch();

  const createUser: SubmitHandler<CreateUserFormValues> = async (
    form: CreateUserFormValues
  ) => {
    try {
      if (
        !form.user_email ||
        !form.password ||
        !form.application ||
        !form.company ||
        !form.company?.pack
      ) {
        setErrorMessage({
          type: "create_user_empty_form",
          message: "Veuillez remplir tous les champs",
        });
        return;
      }

      if (!emailSchema.isValidSync(form.user_email)) {
        setErrorMessage({
          type: "create_user_empty_form",
          message: "Vous devez fournir une adresse email valide",
        });
        return;
      }
      if (!passwordSchema.isValidSync(form.password)) {
        setErrorMessage({
          type: "create_user_empty_form",
          message:
            "Le mot de passe doit contenir au moins 8 caractères, au moins un majuscule, un minuscule, un chiffre et un caractère spécial de '! @ # $% ^ & *'",
        });
        return;
      }

      setCreateLoading(true);

      if (form.user_email) {
        const {data} = await api.post(
          `/api/User/All`,
          {
            where: {
              email: form.user_email,
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        if (data.data && Array.isArray(data.data) && data.data.length) {
          setCreateLoading(false);
          return setErrorMessage({
            type: "email_exists",
            message: "Cet email existe déjà",
          });
        }
      }

      if (
        form.password?.toLowerCase() !== form.repeat_password?.toLowerCase()
      ) {
        setCreateLoading(false);
        setErrorMessage({
          type: "passwords_not_match",
          message: "Les mots de passe ne correspondent pas",
        });
        return;
      }

      let payload: any = {
        companies: [],
      };

      let company: any = {};

      if (form.user_firstName) payload.firstName = form.user_firstName;
      if (form.user_email) {
        payload.email = form.user_email;
        company.email = form.user_email;
      }
      if (form.user_lastName) payload.lastName = form.user_lastName;
      if (form.user_phone) {
        payload.phone = form.user_phone;
        company.phone = form.user_phone;
      }
      if (form.password) payload.password = form.password;
      if (form.gender) payload.gender = form.gender.value;
      if (form.application) {
        company.applicationId = form.application.value;
        payload.application_id = form.application.value;
      }

      if (form.company?.pack) {
        company.pack = form.company?.pack.value;
      }

      company.bankSynced = "100";

      payload.companies = [{...company}];

      const {data: registredUser} = await api.post(
        `/api/auth/register`,
        {activated: true, role: UserTypes.Client, ...payload},
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (registredUser.email) {
        // await api.post(
        //   `/api/auth/sendWelcomeEmail`,
        //   {
        //     email: registredUser.email,
        //     application_id: registredUser.application_id,
        //   },
        //   {
        //     headers: {
        //       "x-access-token": creds.token,
        //     },
        //   }
        // );
      }

      setUserOpen(false);
      resetUserCreationForm();
      dispatch(fetchAllCompanies());
      setCreateLoading(false);
    } catch (error: any) {
      ErrorLogger("creating user", error);
      setCreateLoading(false);
    }
  };

  const createNewCompany: SubmitHandler<CreateNewCompanyFormValues> = async (
    form: CreateNewCompanyFormValues
  ) => {
    try {
      if (
        !form.company_email ||
        !form.application ||
        !form.client ||
        !form.pack
      ) {
        setErrorMessage({
          type: "create_company_empty_form",
          message: "Veuillez remplir tous les champs",
        });
        return;
      }

      if (!emailSchema.isValidSync(form.company_email)) {
        setErrorMessage({
          type: "create_company_empty_form",
          message: "Vous devez fournir une adresse email valide",
        });
        return;
      }

      setCreateLoading(true);

      if (form.company_email) {
        const {data} = await api.post(
          `/api/Company/All`,
          {
            where: {
              email: form.company_email,
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        if (data.data && Array.isArray(data.data) && data.data.length) {
          setCreateLoading(false);
          return setErrorMessage({
            type: "email_exists",
            message: "Cet email existe déjà",
          });
        }
      }

      let payload: any = {};

      if (form.name) payload.firstName = form.name;
      if (form.company_email) {
        payload.email = form.company_email;
      }
      if (form.siren) payload.lastName = form.siren;
      if (form.client) {
        payload.client_id = form.client.value;
      }
      if (form.application) {
        payload.applicationId = form.application.value;
      }
      if (form.pack) {
        payload.pack = form.pack.value;
      }

      payload.bankSynced = "100";

      await api.post(`/api/Company/Create`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      setCompanyOpen(false);
      resetNewCompanyCreationForm();
      dispatch(fetchAllCompanies());
      setCreateLoading(false);
    } catch (error: any) {
      ErrorLogger("creating user", error);
      setCreateLoading(false);
    }
  };

  const resetSearchForm = () => {
    searchReset({
      dateFrom: null,
      dateTo: null,
      status: null,
      acquisition_channel: null,
    });
    getAllLeads();
  };

  const resetLeadCreationForm = () => {
    createLeadReset({
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      acquisition_channel: null,
      status: null,
      notes: null,
    });
    getAllLeads();
  };

  const resetLeadEditForm = () => {
    editLeadReset({
      edit_firstName: null,
      edit_lastName: null,
      edit_phone: null,
      edit_email: null,
      edit_acquisition_channel: null,
      edit_status: null,
      edit_notes: null,
    });
    getAllLeads();
  };

  const resetUserCreationForm = () => {
    return createUserReset({
      application: null,
      gender: null,
      user_email: null,
      user_firstName: null,
      user_lastName: null,
      password: null,
      user_phone: null,
      repeat_password: null,
      role: null,
      company: null,
    });
  };

  const resetNewCompanyCreationForm = () => {
    return createCompanyReset({
      application: null,
      company_email: null,
      name: null,
      pack: null,
      client: null,
      siren: null,
    });
  };

  const {
    control: searchControl,
    register: searchRegister,
    handleSubmit: searchHandleSubmit,
    reset: searchReset,
    formState: {errors: searchErrors},
  } = useForm<SearchLeadFormValues>({});

  const {
    watch,
    control: createLeadControl,
    register: createLeadRegister,
    handleSubmit: createLeadHandleSubmit,
    reset: createLeadReset,
    formState: {errors: createLeadErrors},
  } = useForm<CreateLeadFormValues>({});

  const {
    control: createUserControl,
    register: createUserRegister,
    handleSubmit: createUserHandleSubmit,
    reset: createUserReset,
    formState: {errors: createUserErrors},
  } = useForm<CreateUserFormValues>({});

  const {
    control: createCompanyControl,
    register: createCompanyRegister,
    handleSubmit: createCompanyHandleSubmit,
    reset: createCompanyReset,
    formState: {errors: createCompanyErrors},
  } = useForm<CreateNewCompanyFormValues>({});

  const {
    control: editLeadControl,
    register: editLeadRegister,
    handleSubmit: editLeadHandleSubmit,
    reset: editLeadReset,
    formState: {errors: editLeadErrors},
  } = useForm<EditLeadFormValues>({});

  const {authenticatedRoute} = useAuthorization();
  const {permissions} = useSelector(
    (state: {user: object; application: object; permissions: object}) =>
      state.user
  ) as {permissions: IPermission[]};

  useEffect(() => {
    if (singleLead) {
      editLeadReset({
        edit_firstName: singleLead.firstName,
        edit_lastName: singleLead.lastName,
        edit_phone: singleLead.phone,
        edit_email: singleLead.email,
        edit_acquisition_channel: ACQUISITION_CHANNEL_CODES.find(
          (elt) => elt.value === singleLead.acquisition_channel
        ),
        edit_status: LEADS_STATUS_CODES.find(
          (elt) => elt.value === singleLead.status
        ),
        edit_notes: singleLead.notes,
      });
    }
  }, [singleLead]);

  // search lead form
  const {ref: dateToRef, ...dateTo} = searchRegister("dateTo");
  const {ref: dateFromRef, ...dateFrom} = searchRegister("dateFrom");

  // create lead form
  const {ref: firstNameRef, ...firstName} = createLeadRegister("firstName");
  const {ref: lastNameRef, ...lastName} = createLeadRegister("lastName");
  const {ref: phoneRef, ...phone} = createLeadRegister("phone");
  const {ref: emailRef, ...email} = createLeadRegister("email");
  const {ref: notesRef, ...notes} = createLeadRegister("notes");

  // create user form
  const {ref: user_firstNameRef, ...user_firstName} =
    createUserRegister("user_firstName");
  const {ref: user_lastNameRef, ...user_lastName} =
    createUserRegister("user_lastName");
  const {ref: user_phoneRef, ...user_phone} = createUserRegister("user_phone");
  const {ref: user_emailRef, ...user_email} = createUserRegister("user_email");
  const {ref: passwordRef, ...password} = createUserRegister("password");
  const {ref: repeat_passwordRef, ...repeat_password} =
    createUserRegister("repeat_password");

  // create company form
  const {ref: packRef, ...pack} = createCompanyRegister("pack");
  const {ref: sirenRef, ...siren} = createCompanyRegister("siren");
  const {ref: company_emailRef, ...company_email} =
    createCompanyRegister("company_email");
  const {ref: clientRef, ...client} = createCompanyRegister("client");
  const {ref: applicationRef, ...application} =
    createCompanyRegister("application");
  const {ref: nameRef, ...name} = createCompanyRegister("name");

  // edit lead form
  const {ref: edit_firstNameRef, ...edit_firstName} =
    editLeadRegister("edit_firstName");
  const {ref: edit_lastNameRef, ...edit_lastName} =
    editLeadRegister("edit_lastName");
  const {ref: edit_phoneRef, ...edit_phone} = editLeadRegister("edit_phone");
  const {ref: edit_emailRef, ...edit_email} = editLeadRegister("edit_email");
  const {ref: edit_notesRef, ...edit_notes} = editLeadRegister("edit_notes");

  return (
    <div className="page page-prospect">
      <div className="action-top-nav">
        <Button
          color="secondary"
          className="btn-creat-lead"
          onClick={() => setLeadOpen(true)}
        >
          <img src={AddIconLead} alt="icon" />
          <span>Créer un lead</span>
        </Button>
        <Button
          color="warning"
          className="btn-creat-user"
          onClick={() => setChoiceOpen(true)}
        >
          <img src={userAddIcon} alt="icon" />
          <span>Créer un client</span>
        </Button>
      </div>
      <div className="top-content">
        <Row>
          <Col lg={8} md={12}>
            <BannerTop banner={data.mesprospects} />
          </Col>
          <Col lg={4} md={12}>
            <UserInfo user={user} />
          </Col>
        </Row>
      </div>
      <Card className="card-filter prospects-filter">
        <form onSubmit={searchHandleSubmit(searchLeads)}>
          <Row className="align-items-end">
            <Col lg={8} md={12}>
              <div className="filterInner">
                <Row>
                  <Col lg={3} md={6}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="dated">Date de début</Label>
                      <Controller
                        control={searchControl}
                        name="dateFrom"
                        render={({field}) => (
                          <DatePicker
                            placeholderText="Date de début"
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {/* <Input
                        {...dateFrom}
                        innerRef={dateFromRef}
                        id="dated"
                        placeholder="Date de début"
                        type="date"
                        className="form-secondary"
                      /> */}
                      <span className="icon icon-secondary ">
                        <img src={CalenderIconBlue} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col lg={3} md={6}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="datef">Date de fin</Label>
                      <Controller
                        control={searchControl}
                        name="dateTo"
                        render={({field}) => (
                          <DatePicker
                            placeholderText="Date de fin"
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {/* <Input
                        {...dateTo}
                        innerRef={dateToRef}
                        id="dated"
                        placeholder="Date de fin"
                        type="date"
                        className="form-secondary"
                      /> */}
                      <span className="icon icon-secondary ">
                        <img src={CalenderIconBlue} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col lg={3} md={6}>
                    <FormGroup>
                      <Label for="exampleEmail">Statut</Label>
                      <Controller
                        name="status"
                        control={searchControl}
                        render={({field}) => (
                          <Select
                            {...field}
                            options={LEADS_STATUS_CODES}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={3} md={6}>
                    <FormGroup>
                      <Label for="exampleEmail">Canal d'acquisition</Label>
                      <Controller
                        name="acquisition_channel"
                        control={searchControl}
                        render={({field}) => (
                          <Select
                            {...field}
                            options={ACQUISITION_CHANNEL_CODES}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="actionsFilter mb-3">
                <Button color="secondary" type="submit">
                  <span>Filtrer</span>
                </Button>
                <Button
                  color="secondary"
                  outline
                  type="button"
                  onClick={() => resetSearchForm()}
                >
                  <span>Réinitialiser</span>
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </Card>
      <div>
        <Card className="card-Table table-primary">
          <DataTable
            columns={columns}
            data={leads}
            noDataComponent={<p>Il n'y a aucun data à afficher</p>}
            progressPending={pending}
            progressComponent={
              <>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
              </>
            }
            pagination
            paginationComponent={(props) => {
              const customProps = {...props, color: "primary"};
              return <BootyPagination {...customProps} />;
            }}
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            paginationPerPage={20}
          />

          <div className="openbtn text-center">
            {/*create lead*/}
            <Modal
              className="modal-secondary modal-dialog-centered modal-lg"
              isOpen={leadOpen}
              toggle={() => {
                setLeadOpen(false);
                resetLeadCreationForm();
                setCreateLoading(false);
                setErrorMessage(null);
              }}
            >
              <ModalHeader
                toggle={() => {
                  setLeadOpen(false);
                  resetLeadCreationForm();
                  setCreateLoading(false);
                  setErrorMessage(null);
                }}
              >
                Créer un nouveau lead
              </ModalHeader>
              <form onSubmit={createLeadHandleSubmit(createLead)}>
                <ModalBody>
                  <div className="content-form-block">
                    <Row>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="name">Nom</Label>
                          <Input
                            id="name"
                            innerRef={firstNameRef}
                            {...firstName}
                            placeholder="Nom"
                            type="text"
                            className="form-secondary"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-secondary ">
                            <img src={UserIconLead} alt="icon" />
                          </span>
                        </FormGroup>
                        {errorMessage?.type === "create_empty_form" && (
                          <Alert color="danger">{errorMessage?.message}</Alert>
                        )}
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="prenom">Prénom</Label>
                          <Input
                            id="prenom"
                            innerRef={lastNameRef}
                            {...lastName}
                            placeholder="Prénom"
                            type="text"
                            className="form-secondary"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-secondary ">
                            <img src={UserIconLead} alt="icon" />
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="email">Email</Label>
                          <Input
                            id="email"
                            innerRef={emailRef}
                            {...email}
                            placeholder="Email"
                            type="text"
                            className="form-secondary"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-secondary ">
                            <img src={EmailIconBlue} alt="icon" />
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="phone">Téléphone</Label>
                          <Input
                            id="phone"
                            innerRef={phoneRef}
                            {...phone}
                            placeholder="Téléphone"
                            type="text"
                            className="form-secondary"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-secondary ">
                            <PhoneIconBleu />
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="pwd">Canal d'aquisition</Label>
                          <Controller
                            name="acquisition_channel"
                            control={createLeadControl}
                            render={({field}) => (
                              <Select
                                {...field}
                                options={ACQUISITION_CHANNEL_CODES}
                                closeMenuOnSelect={true}
                                classNamePrefix="select"
                                className="custom-select form-secondary"
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="cpwd">Statut</Label>
                          <Controller
                            name="status"
                            control={createLeadControl}
                            render={({field}) => (
                              <Select
                                {...field}
                                options={LEADS_STATUS_CODES}
                                closeMenuOnSelect={true}
                                classNamePrefix="select"
                                className="custom-select form-secondary"
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="cpwd">Notes</Label>
                          <Input
                            innerRef={notesRef}
                            {...notes}
                            className="form-secondary text-area-custom"
                            type="textarea"
                            rows="15"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    outline
                    onClick={() => {
                      setLeadOpen(false);
                      resetLeadCreationForm();
                      setCreateLoading(false);
                      setErrorMessage(null);
                    }}
                  >
                    Annuler
                  </Button>
                  <Button color="secondary" type="submit">
                    {createLoading ? (
                      <Spinner color="light" type="border" size={"sm"}>
                        Loading...
                      </Spinner>
                    ) : (
                      "Enregistrer"
                    )}
                  </Button>
                </ModalFooter>
              </form>
            </Modal>

            {/*create user*/}
            <Modal
              className="modal-warning modal-dialog-centered modal-lg"
              isOpen={userOpen}
              toggle={() => {
                resetUserCreationForm();
                setUserOpen(false);
                setCreateLoading(false);
              }}
            >
              <ModalHeader
                toggle={() => {
                  resetUserCreationForm();
                  setUserOpen(false);
                  setCreateLoading(false);
                }}
              >
                Créer un nouveau client
              </ModalHeader>
              <form onSubmit={createUserHandleSubmit(createUser)}>
                <ModalBody>
                  <div className="content-form-block">
                    <Row>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="email">Email</Label>
                          <Input
                            id="email"
                            innerRef={user_emailRef}
                            {...user_email}
                            placeholder="Email"
                            type="text"
                            className="form-warning"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-warning ">
                            <img src={EmailIcon} alt="icon" />
                          </span>
                        </FormGroup>
                        {errorMessage?.type === "email_exists" && (
                          <Alert color="danger">{errorMessage?.message}</Alert>
                        )}
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="pack">Pack</Label>
                          <Controller
                            name="company.pack"
                            control={createUserControl}
                            render={({field}) => (
                              <Select
                                {...field}
                                options={PLAN_OPTIONS}
                                closeMenuOnSelect={true}
                                classNamePrefix="select"
                                className="custom-select form-warning"
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="pwd">Mot de passe utilisateur</Label>
                          <Input
                            id="pwd"
                            innerRef={passwordRef}
                            {...password}
                            placeholder="Mot de passe"
                            type="password"
                            className="form-warning"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-warning ">
                            <img src={KeyIcon} alt="icon" />
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="cpwd">Confirmer le mot de passe</Label>
                          <Input
                            id="cpwd"
                            innerRef={repeat_passwordRef}
                            {...repeat_password}
                            placeholder="Confirmer le mot de passe"
                            type="password"
                            className="form-warning"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-warning ">
                            <img src={KeyIcon} alt="icon" />
                          </span>
                        </FormGroup>
                        {errorMessage?.type === "passwords_not_match" && (
                          <Alert color="danger">{errorMessage?.message}</Alert>
                        )}
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="pack">Application associée</Label>
                          <Controller
                            name="application"
                            control={createUserControl}
                            render={({field}) => (
                              <Select
                                {...field}
                                options={applications.reduce(
                                  (acc, curr) => [
                                    ...acc,
                                    {
                                      label: curr.name,
                                      value: curr.id,
                                    } as OptionType,
                                  ],
                                  [] as OptionType[]
                                )}
                                closeMenuOnSelect={true}
                                classNamePrefix="select"
                                className="custom-select form-warning"
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      {errorMessage?.type === "create_user_empty_form" && (
                        <Alert color="danger">{errorMessage?.message}</Alert>
                      )}
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="warning"
                    outline
                    disabled={createLoading}
                    onClick={() => {
                      resetUserCreationForm();
                      setUserOpen(false);
                      setCreateLoading(false);
                    }}
                  >
                    Annuler
                  </Button>
                  <Button
                    color="warning"
                    type="submit"
                    disabled={createLoading}
                  >
                    {createLoading ? (
                      <Spinner color="light" type="border" size={"sm"}>
                        Loading...
                      </Spinner>
                    ) : (
                      "Enregistrer"
                    )}
                  </Button>
                </ModalFooter>
              </form>
            </Modal>

            {/*create company*/}
            <Modal
              className="modal-warning modal-dialog-centered modal-lg"
              isOpen={companyOpen}
              toggle={() => {
                resetNewCompanyCreationForm();
                setCompanyOpen(false);
                setCreateLoading(false);
              }}
            >
              <ModalHeader
                toggle={() => {
                  resetNewCompanyCreationForm();
                  setCompanyOpen(false);
                  setCreateLoading(false);
                }}
              >
                Créer une nouvelle entreprise
              </ModalHeader>
              <form onSubmit={createCompanyHandleSubmit(createNewCompany)}>
                <ModalBody>
                  <div className="content-form-block">
                    <Row>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="email">Email</Label>
                          <Input
                            id="email"
                            innerRef={company_emailRef}
                            {...company_email}
                            placeholder="Email"
                            type="text"
                            className="form-warning"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-warning ">
                            <img src={EmailIcon} alt="icon" />
                          </span>
                        </FormGroup>
                        {errorMessage?.type === "email_exists" && (
                          <Alert color="danger">{errorMessage?.message}</Alert>
                        )}
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="name">Nom commercial</Label>
                          <Input
                            id="name"
                            innerRef={nameRef}
                            {...name}
                            placeholder="Nom commercial"
                            type="text"
                            className="form-warning"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-warning ">
                            <ReactSVG src={juridiqueIcons} />
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="siren">Numéro de SIREN</Label>
                          <Input
                            id="siren"
                            innerRef={sirenRef}
                            {...siren}
                            placeholder="Nº de SIREN"
                            type="text"
                            className="form-warning"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-warning ">
                            <ReactSVG src={juridiqueIcons} />
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="pack">Application associée</Label>
                          <Controller
                            name="application"
                            control={createCompanyControl}
                            render={({field}) => (
                              <Select
                                {...field}
                                options={applications.reduce(
                                  (acc, curr) => [
                                    ...acc,
                                    {
                                      label: curr.name,
                                      value: curr.id,
                                    } as OptionType,
                                  ],
                                  [] as OptionType[]
                                )}
                                closeMenuOnSelect={true}
                                classNamePrefix="select"
                                className="custom-select form-warning"
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="pack">Client associée</Label>
                          <Controller
                            name="client"
                            control={createCompanyControl}
                            render={({field}) => (
                              <Select
                                {...field}
                                options={clients.reduce(
                                  (acc, curr) => [
                                    ...acc,
                                    {
                                      label:
                                        curr.firstName || curr.lastName
                                          ? `${curr.firstName || ""} ${
                                              curr.lastName || ""
                                            }`
                                          : curr.email,
                                      value: (curr as any).client_id,
                                    } as OptionType,
                                  ],
                                  [] as OptionType[]
                                )}
                                closeMenuOnSelect={true}
                                classNamePrefix="select"
                                className="custom-select form-warning"
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="pack">Pack</Label>
                          <Controller
                            name="pack"
                            control={createCompanyControl}
                            render={({field}) => (
                              <Select
                                {...field}
                                options={PLAN_OPTIONS}
                                closeMenuOnSelect={true}
                                classNamePrefix="select"
                                className="custom-select form-warning"
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      {errorMessage?.type === "create_company_empty_form" && (
                        <Alert color="danger">{errorMessage?.message}</Alert>
                      )}
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="warning"
                    outline
                    disabled={createLoading}
                    onClick={() => {
                      resetUserCreationForm();
                      setUserOpen(false);
                      setCreateLoading(false);
                    }}
                  >
                    Annuler
                  </Button>
                  <Button
                    color="warning"
                    type="submit"
                    disabled={createLoading}
                  >
                    {createLoading ? (
                      <Spinner color="light" type="border" size={"sm"}>
                        Loading...
                      </Spinner>
                    ) : (
                      "Enregistrer"
                    )}
                  </Button>
                </ModalFooter>
              </form>
            </Modal>

            {/*edit lead*/}
            <Modal
              className="modal-secondary modal-dialog-centered modal-lg"
              isOpen={editOpen}
              toggle={() => {
                setEditOpen(false);
                setSingleLead(null);
                setEditLoading(false);
                setErrorMessage(null);
              }}
            >
              <ModalHeader toggle={() => setEditOpen(false)}>
                Editer un lead
              </ModalHeader>
              <form onSubmit={editLeadHandleSubmit(editLead)}>
                <ModalBody>
                  <div className="content-form-block">
                    <Row>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="name">Nom</Label>
                          <Input
                            id="name"
                            innerRef={edit_firstNameRef}
                            {...edit_firstName}
                            placeholder="Nom"
                            type="text"
                            className="form-secondary"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-secondary ">
                            <img src={UserIconLead} alt="icon" />
                          </span>
                        </FormGroup>
                        {errorMessage?.type === "edit_empty_form" && (
                          <Alert color="danger">{errorMessage?.message}</Alert>
                        )}
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="prenom">Prénom</Label>
                          <Input
                            id="prenom"
                            innerRef={edit_lastNameRef}
                            {...edit_lastName}
                            placeholder="Prénom"
                            type="text"
                            className="form-secondary"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-secondary ">
                            <img src={UserIconLead} alt="icon" />
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="email">Email</Label>
                          <Input
                            id="email"
                            innerRef={edit_emailRef}
                            {...edit_email}
                            placeholder="Email"
                            type="text"
                            className="form-secondary"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-secondary ">
                            <img src={EmailIconBlue} alt="icon" />
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="phone">Téléphone</Label>
                          <Input
                            id="phone"
                            innerRef={edit_phoneRef}
                            {...edit_phone}
                            placeholder="Téléphone"
                            type="text"
                            className="form-secondary"
                            onChange={() => {
                              setErrorMessage(null);
                            }}
                          />
                          <span className="icon icon-secondary ">
                            <PhoneIconBleu />
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="pwd">Canal d'aquisition</Label>
                          <Controller
                            name="edit_acquisition_channel"
                            control={editLeadControl}
                            render={({field}) => (
                              <Select
                                {...field}
                                options={ACQUISITION_CHANNEL_CODES}
                                closeMenuOnSelect={true}
                                classNamePrefix="select"
                                className="custom-select form-secondary"
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="cpwd">Statut</Label>
                          <Controller
                            name="edit_status"
                            control={editLeadControl}
                            render={({field}) => (
                              <Select
                                {...field}
                                options={LEADS_STATUS_CODES}
                                closeMenuOnSelect={true}
                                classNamePrefix="select"
                                className="custom-select form-secondary"
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="cpwd">Notes</Label>
                          <Input
                            innerRef={edit_notesRef}
                            {...edit_notes}
                            className="form-secondary text-area-custom"
                            type="textarea"
                            rows="15"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    outline
                    onClick={() => {
                      setEditOpen(false);
                      setSingleLead(null);
                      setEditLoading(false);
                      setErrorMessage(null);
                    }}
                  >
                    Annuler
                  </Button>
                  <Button color="secondary" type="submit">
                    {editLoading ? (
                      <Spinner color="light" type="border" size={"sm"}>
                        Loading...
                      </Spinner>
                    ) : (
                      "Editer"
                    )}
                  </Button>
                </ModalFooter>
              </form>
            </Modal>

            {/*delete a lead*/}
            <Modal
              className="modal-danger"
              isOpen={deleteOpen}
              toggle={() => {
                setSingleLead(null);
                setDeleteOpen(false);
                setDeleteLoading(false);
                setErrorMessage(null);
              }}
            >
              <ModalHeader
                toggle={() => {
                  setSingleLead(null);
                  setDeleteOpen(false);
                  setDeleteLoading(false);
                  setErrorMessage(null);
                }}
              >
                Supprimer un lead
              </ModalHeader>
              <ModalBody>
                <div className="content-text p-lg-5">
                  <p className="msg-text">
                    Vous êtes sur de vouloir supprimer le lead{" "}
                    {singleLead?.firstName || singleLead?.email} ?
                  </p>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  outline
                  onClick={() => {
                    setSingleLead(null);
                    setDeleteOpen(false);
                    setDeleteLoading(false);
                    setErrorMessage(null);
                  }}
                >
                  Non
                </Button>
                <Button color="danger" onClick={async () => await deleteLead()}>
                  {deleteLoading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Oui"
                  )}
                </Button>
              </ModalFooter>
            </Modal>

            {/*choose new client or company*/}
            <Modal
              className="modal-warning modal-dialog-centered"
              isOpen={choiceOpen}
              size={"sm"}
              toggle={() => {
                setChoiceOpen(false);
              }}
            >
              <ModalHeader
                toggle={() => {
                  setChoiceOpen(false);
                }}
              >
                Sélectionner le type de création
              </ModalHeader>
              <ModalBody>
                <div className="sync-modal">
                  <div className="action-button-wrapper --warning">
                    <Button
                      color="warning"
                      outline
                      type="button"
                      onClick={() => {
                        setChoiceOpen(false);
                        setUserOpen(true);
                      }}
                    >
                      Nouveau client
                    </Button>
                  </div>
                  <div className="action-button-wrapper --warning">
                    <Button
                      color="warning"
                      outline
                      type="button"
                      onClick={() => {
                        setChoiceOpen(false);
                        setCompanyOpen(true);
                      }}
                    >
                      Nouvelle entreprise
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default GestionProspect;
