import React, { useState, FormEvent, useEffect } from "react";

import "./register.scss";
import loginImg from "../../../assets/images/logo.svg";

import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { RiCheckLine, RiCloseFill } from "react-icons/ri";
import RegisterStepOne from "./RegisterStepOne/RegisterStepOne";
import { Button } from "reactstrap";
import RegisterStepTwo from "./RegisterStepTwo/RegisterStepTwo";
import { Outlet, Router, useLocation } from "react-router-dom";
import RegisterStepThree from "./RegisterStepThree/RegisterStepThree";
import RegisterStepFour from "./RegisterStepFour/RegisterStepFour";
import RegisterStepFive from "./RegisterStepFive/RegisterStepFive";
import RegisterStepSix from "./RegisterStepSix/RegisterStepSix";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { useLocalStorage } from "../../../util/hooks/useLocalStorage";
import RegisterStepAsk from "./RegisterStepAsk/RegisterStepAsk";

const routes = [
  {
    path: "/register",
    step: 1,
  },
  {
    path: "/register/activite",
    step: 2,
  },
  {
    path: "/register/info-activite",
    step: 3,
  },
  {
    path: "/register/pack",
    step: 4,
  },
  {
    path: "/register/info-client",
    step: 5,
  },
  {
    path: "/register/nous-contacter",
    step: 6,
  },
  {
    path: "/register/erreur",
    step: 6,
  },
  {
    path: "/register/erreur-paiement",
    step: 6,
  },
  {
    path: "/register/succes-paiement",
    step: 6,
  },
];

const Register: React.FC = () => {
  const [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const { setValue, getValue, deleteValue } = useLocalStorage();
  const location = useLocation();

  const handleStep = () => {
    setStep(step + 1);
    setIsOpen(!isOpen);
  };
  const prevStep = () => {
    setStep(step - 1);
    setIsOpen(!isOpen);
  };

  const handleStepAsk = () => {
    setStep(6);
    setIsOpen(!isOpen);
  };
  const prevStepAsk = () => {
    setStep(1);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setStep(
      routes.findIndex((elt) => location.pathname === elt.path) > -1
        ? routes.find((elt) => location.pathname === elt.path)?.step!
        : 1
    );
  }, [location]);

  const contanerVariants = {
    start: {
      transition: {
        staggerChildren: 0.5,
      },
    },
    end: {
      transition: {
        staggerChildren: 0.6,
      },
    },
  };
  const barVariants = {
    start: {
      width: 0,
    },
    end: {
      width: 200,
    },
  };
  const cercleVariants = {
    start: {
      background: "#ccc",
      scale: 0,
    },
    end: {
      background: "#50b1dc",
      scale: 1,
    },
  };
  const cercleVariantsWarning = {
    start: {
      background: "#ccc",
      scale: 0,
    },
    end: {
      background: "#ffb254",
      scale: 1,
    },
  };
  const barTransition = {
    duration: 0.4,
    ease: "easeInOut",
  };
  const cercleTransition = {
    delay: 0.4,
    type: "spring",
    bounce: 0.3,
    ease: "easeInOut",
  };

  function submit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
  }

  return (
    // @ts-ignore
    <AnimateSharedLayout>
      <div className="main-register">
        <div className="mainInner">
          <div className="top-Login">
            <div className="login-top">
              <img src={loginImg} />
            </div>
          </div>

          <section className="stepperSection">
            <div className="container-md">
              {location.pathname !== "/register/contact-non-envoye" &&
                location.pathname !== "/register/contact-envoye" && (
                  <AnimatePresence>
                    <div className="steperWrap">
                      <motion.div className="stepper">
                        <span
                          className={` ${step === 1 ? "active" : ""} navStep `}
                        >
                          <motion.span
                            variants={cercleVariants}
                            transition={cercleTransition}
                            initial="start"
                            animate="end"
                            className="innerspan"
                          >
                            <RiCheckLine className="iconStep" />
                          </motion.span>
                        </span>
                        <>
                          <motion.span className="scrollBar">
                            {step >= 2 && (
                              <motion.span
                                className="porgress"
                                variants={barVariants}
                                initial="start"
                                animate="end"
                                transition={barTransition}
                              ></motion.span>
                            )}
                          </motion.span>
                        </>
                      </motion.div>
                      <motion.div
                        variants={contanerVariants}
                        initial="start"
                        animate="end"
                        className="stepper"
                      >
                        <motion.span
                          className={` ${step === 2 ? "active" : ""} navStep `}
                        >
                          {step >= 2 && (
                            <motion.span
                              variants={cercleVariants}
                              transition={cercleTransition}
                              initial="start"
                              animate="end"
                              className="innerspan"
                            >
                              <RiCheckLine className="iconStep" />
                            </motion.span>
                          )}
                        </motion.span>

                        <>
                          <motion.span className="scrollBar">
                            {step >= 3 && (
                              <motion.span
                                className="porgress"
                                variants={barVariants}
                                initial="start"
                                animate="end"
                                transition={barTransition}
                              ></motion.span>
                            )}
                          </motion.span>
                        </>
                      </motion.div>
                      <motion.div
                        variants={contanerVariants}
                        initial="start"
                        animate="end"
                        className="stepper"
                      >
                        <motion.span
                          className={` ${step === 3 ? "active" : ""} navStep `}
                        >
                          {step >= 3 && (
                            <motion.span
                              variants={cercleVariants}
                              transition={cercleTransition}
                              initial="start"
                              animate="end"
                              className="innerspan"
                            >
                              <RiCheckLine className="iconStep" />
                            </motion.span>
                          )}
                        </motion.span>

                        <>
                          <motion.span className="scrollBar">
                            {step >= 4 && (
                              <motion.span
                                className="porgress"
                                variants={barVariants}
                                initial="start"
                                animate="end"
                                transition={barTransition}
                              ></motion.span>
                            )}
                          </motion.span>
                        </>
                      </motion.div>
                      <motion.div
                        variants={contanerVariants}
                        initial="start"
                        animate="end"
                        className="stepper"
                      >
                        <motion.span
                          className={` ${step === 4 ? "active" : ""} navStep `}
                        >
                          {step >= 4 && (
                            <motion.span
                              variants={cercleVariants}
                              transition={cercleTransition}
                              initial="start"
                              animate="end"
                              className="innerspan"
                            >
                              <RiCheckLine className="iconStep" />
                            </motion.span>
                          )}
                        </motion.span>

                        <>
                          <motion.span className="scrollBar">
                            {step >= 5 && (
                              <motion.span
                                className="porgress"
                                variants={barVariants}
                                initial="start"
                                animate="end"
                                transition={barTransition}
                              ></motion.span>
                            )}
                          </motion.span>
                        </>
                      </motion.div>

                      <motion.div
                        variants={contanerVariants}
                        initial="start"
                        animate="end"
                        className="stepper"
                      >
                        <motion.span
                          className={` ${step === 5 ? "active" : ""} navStep `}
                        >
                          {step >= 5 && (
                            <motion.span
                              variants={cercleVariants}
                              transition={cercleTransition}
                              initial="start"
                              animate="end"
                              className="innerspan"
                            >
                              <RiCheckLine className="iconStep" />
                            </motion.span>
                          )}
                        </motion.span>

                        <>
                          <motion.span className="scrollBar">
                            {step >= 6 && (
                              <motion.span
                                className="porgress"
                                variants={barVariants}
                                initial="start"
                                animate="end"
                                transition={barTransition}
                              ></motion.span>
                            )}
                          </motion.span>
                        </>
                      </motion.div>
                      <motion.div
                        variants={contanerVariants}
                        initial="start"
                        animate="end"
                        className="stepper"
                      >
                        <motion.span
                          className={` ${step === 6 ? "active" : ""} navStep `}
                        >
                          {step >= 6 && (
                            <motion.span
                              variants={cercleVariants}
                              transition={cercleTransition}
                              initial="start"
                              animate="end"
                              className="innerspan"
                            >
                              <RiCheckLine className="iconStep" />
                            </motion.span>
                          )}
                        </motion.span>
                      </motion.div>
                    </div>
                  </AnimatePresence>
                )}
            </div>
          </section>
          <Outlet />
        </div>
      </div>
    </AnimateSharedLayout>
  );
};

export default Register;
