import { AnimatePresence } from "framer-motion";
import React from "react";
import { FiChevronsLeft, FiChevronsRight, FiRotateCcw } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import AskErrorStep from "./AskError";

const AskError = () => {
  

  return (
    <>
      {/* <AnimatePresence> */}
        <AskErrorStep />
      {/* </AnimatePresence> */}

    </>
  );
};

export default AskError;
