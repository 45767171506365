import React, { ReactElement, useCallback, useEffect, useState } from "react";
import "./MesClients.scss";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { SiPypy } from "react-icons/si";
import UserInfo from "../../components/user-info/UserInfo";
import { data } from "../../constants";
import {
  RiAddLine,
  RiCalendar2Line,
  RiSearchLine,
  RiUser3Line,
} from "react-icons/ri";
import BannerTop from "../../components/Banner/BannerTop";
import Select, { GroupBase, SingleValue } from "react-select";

import { BootyPagination } from "../../components/table/pagination";
import { BsTrashFill } from "react-icons/bs";
import DataTable from "react-data-table-component";
import CrownIcon from "../../assets/CrownIcon";
import EmailIcon from "../../assets/EmailIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  Company,
  IApplication,
  ICABINET,
  IPermission,
  User,
} from "../../interfaces";
import axios from "axios";
import config from "../../config";
import { ErrorLogger } from "../../util/errorLogger";
import {
  AppPacks,
  COMPANY_STATUT_OPTIONS,
  COMPANY_STATUT_OPTIONS_ID,
  CrudPermissions,
  JURID_FORMES,
  OptionType,
  PLAN_OPTIONS,
  TAX_SYST,
  TAX_SYST_ID,
  UserTypes,
  VAT_REGIME_OPTIONS,
  VAT_REGIME_OPTIONS_ID,
} from "../../util/context";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { usePermissions } from "../../util/hooks/usePermissions";
import {
  deleteCompany_List,
  setClientsList,
  setCompanUserStatus_List,
} from "../../store/reducers/clients-list/clientsSlice";
import {
  deleteCompany_Company,
  setCompanyUserStatus_Company,
} from "../../store/reducers/companies-list/companiesSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import useAxios from "../../util/hooks/useAxios";
import { Link } from "react-router-dom";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import BoolFilter from "@inovua/reactdatagrid-community/BoolFilter";
import BoolEditor from "@inovua/reactdatagrid-community/BoolEditor";
import "@inovua/reactdatagrid-community/index.css";

const { API_URL, APPLICATION_ID } = config[process.env.NODE_ENV];

type FormValues = {
  regimeTVA?: string | null;
  userStatus?: string | null;
  legalForm?: string | null;
  pack?: string | null;
  text?: string;
};

export const RenderDateTime = (value: any) => {
  if (!value) {
    return null;
  }
  return <span>{moment(value).format("DD/MM/YYYY")}</span>;
};

window.moment = moment;

const filterValue: any = [
  { name: "email", operator: "contains", type: "string", value: "" },
  // { name: "siret", operator: "contains", type: "string", value: "" },
  { name: "name", operator: "contains", type: "string", value: "" },
  { name: "clientPhone", operator: "contains", type: "string", value: "" },
  // { name: "companyPhone", operator: "contains", type: "string", value: "" },
  {
    name: "taxSystem",
    operator: "inlist",
    type: "select",
  },
  // {
  //   name: "regimeTVA",
  //   operator: "inlist",
  //   type: "select",
  // },
  {
    name: "dateOfExercise",
    operator: "inrange",
    type: "date",
  },
  {
    name: "isPermBookComplete",
    operator: "eq",
  },
  {
    name: "handler",
    operator: "contains",
    type: "string",
    value: "",
  },
  {
    name: "isPaymentUpToDate",
    operator: "eq",
  },
  {
    name: "isbankReconUpToDate",
    operator: "eq",
  },
  // {
  //   name: "isDelegationComplete",
  //   operator: "eq",
  // },
  // {
  //   name: "taxSystem",
  //   operator: "inlist",
  //   type: "select",
  // },
  {
    name: "userStatus",
    operator: "inlist",
    type: "select",
  },
];

type ClientLink = { value: string; label: string };

const ClientLinkCell = (value: ClientLink) => {
  return (
    <Link to={`/client/${value.value}`} style={{ cursor: "pointer" }}>
      {value.label.split("\n").map((elt: string, index: number) => (
        <>
          <span key={index}>{elt}</span>
          <br key={index} />
        </>
      ))}
    </Link>
  );
};

export const i18n: any = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
  pageText: "Page ",
  ofText: " de ",
  perPageText: "Résultats par page",
  showingText: "Afficher",
  clearAll: "Effacer tout",
  clear: "Effacer",
  showFilteringRow: "Afficher la ligne de filtrage",
  hideFilteringRow: "Masquer la ligne de filtrage",
  dragHeaderToGroup: "Faire glisser l'en-tête vers le groupe",
  disable: "Désactiver",
  enable: "Activer",
  sortAsc: "Trier en ordre croissant",
  sortDesc: "Trier par ordre décroissant",
  unsort: "Non-tri",
  group: "Groupe",
  ungroup: "Dégrouper",
  lockStart: "Début du verrouillage",
  lockEnd: "Fin du verrouillage",
  unlock: "Déverrouiller",
  columns: "Colonnes",
  contains: "Contient",
  startsWith: "Commence par",
  endsWith: "Se termine par",
  notContains: "Ne contient pas",
  neq: "N'est pas égal à",
  inrange: "dans l'intervalle",
  notinrange: "pas dans l'intervalle",
  eq: "Est égal à",
  notEmpty: "Non vide",
  empty: "Vide",
  lt: "Inférieur à",
  lte: "Inférieur ou égal",
  gt: "Supérieur à",
  gte: "Supérieur ou égal",
  "calendar.todayButtonText": "Aujourd'hui",
  "calendar.clearButtonText": "Effacer",
  "calendar.okButtonText": "OK",
  "calendar.cancelButtonText": "Annuler",
});

export const gridStyle = { minHeight: 500 };

type ColType = {
  name: string;
  selector?: (row: any) => any;
  sortable?: boolean;
  button?: boolean;
  cell?: any;
  omit?: (row: any) => any;
};

export interface ClientsProps {}
const MesClients: React.FC<ClientsProps> = ({}) => {
  const [open, setOpen] = useState(false);
  const [singleCompany, setSingleCompany] = useState<Company | null>();
  const [loading, setLoading] = useState(false);
  const [deleteCompanyLoading, setDeleteCompanyLoading] = useState(false);
  const [hideActions, setHideActions] = React.useState(false);
  const ontoggle = (company: Company) => {
    setSingleCompany(company);
    setOpen(true);
  };
  const { clientsList, cabinetLoading: clientsListLoading } = useSelector(
    (state: { clientsList: Company[] }) => {
      return state.clientsList;
    }
  ) as unknown as { clientsList: Company[]; cabinetLoading: string };

  const { companiesList, loading: companiesListLoading } = useSelector(
    (state: { companiesList: Company[] }) => state.companiesList
  ) as unknown as {
    companiesList: Company[];
    loading: string;
    currentRequestId: string;
  };

  const [localCompanies, setLocalCompanies] = useState<Company[]>([]);
  const [searched, setSearched] = useState<boolean>(false);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const [pending, setPending] = useState<boolean>(false);

  const { user, permissions } = useSelector(
    (state: { user: object; application: object; permissions: object }) =>
      state.user
  ) as { user: User; application: IApplication; permissions: IPermission[] };

  const { hasCrudPermission } = usePermissions();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const onMenuOpen = () => {
  //   setTimeout(() => {
  //     const selectedEl = document.getElementsByClassName(
  //       "select__option--is-selected"
  //     )[0];
  //     if (selectedEl) {
  //       selectedEl.scrollIntoView({
  //         behavior: "smooth",
  //         block: "nearest",
  //         inline: "end",
  //       });
  //     }
  //   }, 15);
  // };

  // const columns = React.useMemo(
  //   () => [
  //     {
  //       name: "Entreprise",
  //       cell: (row: any) => (
  //         <Link to={`/client/${row.id}`} style={{ cursor: "pointer" }}>
  //           {row.name}
  //         </Link>
  //       ),
  //       sortable: true,
  //     },
  //     {
  //       name: "E-mail",
  //       cell: (row: any) => (
  //         <Link to={`/client/${row.id}`} style={{ cursor: "pointer" }}>
  //           {row.email}
  //         </Link>
  //       ),
  //       sortable: true,
  //     },
  //     {
  //       name: "Téléphone",
  //       selector: (row: any) =>
  //         row.phone && row.phone !== ""
  //           ? row.phone
  //           : row.clients.length > 0
  //           ? row.clients[0].phone && row.clients[0].phone !== ""
  //             ? row.clients[0].phone
  //             : ""
  //           : "",
  //     },
  //     {
  //       name: "Régime de TVA",
  //       selector: (row: any) =>
  //         VAT_REGIME_OPTIONS.find((elt) => elt.value === row.regimeTVA)?.label,
  //     },
  //     {
  //       name: "Admin",
  //       selector: (row: any) => row.application.name,
  //       sortable: true,
  //     },
  //     {
  //       name: "Status",
  //       id: "status-cell",
  //       selector: (row: Company) => row.status,
  //       sortable: true,
  //       cell: (row: { id: string; userStatus: string }) => (
  //         <>
  //           <Select
  //             options={COMPANY_STATUT_OPTIONS}
  //             classNamePrefix="select"
  //             className="my-clients custom-select form-primary outline small basic-multi-select"
  //             maxMenuHeight={180}
  //             menuPlacement="auto"
  //             onChange={async (e) => await updateCompanyUserStatus(e, row)}
  //             defaultValue={
  //               row.userStatus &&
  //               COMPANY_STATUT_OPTIONS.find(
  //                 (elt) => elt.value === row.userStatus
  //               )
  //             }
  //             onMenuOpen={onMenuOpen}
  //           />
  //         </>
  //       ),
  //     },
  //     {
  //       name: "Action",
  //       button: true,
  //       omit:
  //         permissions.length > 0 &&
  //         !hasCrudPermission(permissions, CrudPermissions.DeleteAccounts),
  //       cell: (row: Company) => (
  //         <div className="table-action">
  //           <button className="btn btn-red" onClick={() => ontoggle(row)}>
  //             <BsTrashFill />
  //           </button>
  //         </div>
  //       ),
  //     },
  //   ],
  //   [permissions]
  // );

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({});
  let api = useAxios();

  const onSubmit: SubmitHandler<FormValues> = async (form: any) => {
    try {
      setPending(true);
      setLoading(true);

      let keys = Object.keys(form);
      let where: any = keys.reduce((acc, curr) => {
        let obj: { [prop: string]: string } = { ...acc };

        if (form[curr]) {
          obj[curr] = form[curr]["value"];
        }

        return obj;
      }, {});

      if (creds.role === UserTypes.Cabinet) {
        where.cabinets = {
          id: user.id,
        };
      }

      if (!form.text) {
        const { data } = await api.post(
          `/api/Company/All`,
          {
            where,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );

        setLocalCompanies(data.data);
      } else {
        const { data } = await api.post(
          `/api/helpers/Company/customAll`,
          {
            where: {
              ...where,
              text: [
                {
                  attr: "name",
                  op: "iLike",
                  val: `%${form.text}%`,
                },
                {
                  attr: "email",
                  op: "iLike",
                  val: `%${form.text}%`,
                },
                {
                  attr: "phone",
                  op: "iLike",
                  val: `%${form.text}%`,
                },
              ],
            },
            nested: [
              {
                model: "Application",
                as: "application",
              },
              {
                model: "Client",
                as: "clients",
              },
            ],
            paranoid: true,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );

        setLocalCompanies(data.data);
      }
      setLoading(false);
      setSearched(true);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("company filter form", error);
    }
  };

  const deleteCompany = async () => {
    try {
      setDeleteCompanyLoading(true);
      await api.post(
        `/api/auth/checkExternalCreds`,
        {
          company_id: singleCompany?.id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await api.post(
        `/api/Company/Update`,
        {
          id: singleCompany?.id,
          activated: false,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await api.post(
        `/api/Company/delete`,
        {
          id: [singleCompany?.id],
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const data = clientsList.filter(
        (company) => company.id !== singleCompany?.id
      );
      if (creds.role === UserTypes.Cabinet) {
        dispatch(
          deleteCompany_List({
            id: singleCompany?.id,
          })
        );
      } else {
        dispatch(
          deleteCompany_Company({
            id: singleCompany?.id,
          })
        );
      }
      setSingleCompany(null);
      setOpen(false);
      setDeleteCompanyLoading(false);
    } catch (error: any) {
      setDeleteCompanyLoading(false);
      ErrorLogger("deleting a company", error);
      setSingleCompany(null);
      setOpen(false);
      if (creds.role === UserTypes.Cabinet) {
        dispatch(
          deleteCompany_List({
            id: singleCompany?.id,
          })
        );
      } else {
        dispatch(
          deleteCompany_Company({
            id: singleCompany?.id,
          })
        );
      }
    }
  };

  const updateCompanyUserStatus = async (
    e: string | OptionType | null,
    company: Partial<Company>
  ) => {
    try {
      setPending(true);
      const { data } = await api.post(
        `/api/Company/Update`,
        {
          id: company.id,
          userStatus: (e as OptionType)?.value,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (data.updated.length) {
        if (creds.role === UserTypes.Cabinet) {
          dispatch(
            setCompanUserStatus_List({
              id: company.id,
              userStatus: (e as OptionType)?.value,
            })
          );
        } else {
          dispatch(
            setCompanyUserStatus_Company({
              id: company.id,
              userStatus: (e as OptionType)?.value,
            })
          );
        }
      }
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("updating company user status", error);
    }
  };

  const customCellStyle = (cellProps: { value: any }) => {
    const { value } = cellProps;
    return {
      background: value ? "#43b59c" : "#fe0000",
      color: "#fff",
      fontWeight: "bold",
    };
  };
  const { ref: textRef, ...text } = register("text");

  const columns = React.useMemo(() => {
    let cols = [
      {
        header: "E-mail",
        name: "email",
        render: ({ data }: { data: Company }) =>
          ClientLinkCell({
            value: data.id,
            label: data.email,
          }),
      },
      {
        header: "Entreprise",
        name: "name",
        minWidth: 150,
        render: ({ data }: { data: Company }) =>
          ClientLinkCell({
            value: data.id,
            label: data.name,
          }),
      },
      {
        header: "Téléphone",
        name: "clientPhone",
        render: ({ data }: { data: any }) => data.clientPhone,
        sortable: true,
        width: 120,
        maxWidth: 150,
        minWidth: 120,
      },
      {
        header: "Régime fiscal",
        name: "taxSystem",
        maxWidth: 150,
        render: ({ data }: { data: Company }) =>
          data.taxSystem
            ? TAX_SYST.find((elt) => elt.value === data.taxSystem)?.label
            : "Non noté",
        filterEditor: SelectFilter,
        filterEditorProps: {
          multiple: true,
          wrapMultiple: false,
          dataSource: [...TAX_SYST_ID, { label: "Non noté", id: null }],
        },
        sortable: false,
      },
      // {
      //   header: "Régime",
      //   name: "regimeTVA",
      //   maxWidth: 150,
      //   render: ({ data }: { data: Company }) =>
      //     data.regimeTVA
      //       ? VAT_REGIME_OPTIONS.find((elt) => elt.value === data.regimeTVA)
      //           ?.label
      //       : "Non noté",
      //   filterEditor: SelectFilter,
      //   filterEditorProps: {
      //     multiple: true,
      //     wrapMultiple: false,
      //     dataSource: [
      //       ...VAT_REGIME_OPTIONS_ID,
      //       { label: "Non noté", id: null },
      //     ],
      //   },
      //   sortable: false,
      // },
      {
        header: "Date clôture",
        name: "dateOfExercise",
        dateFormat: "DD/MM/YYYY",
        type: "date",
        render: ({ value }: { value: any }) => RenderDateTime(value),
        // editor: DateEditor as any,
        // renderGroupTitle: RenderDateTime,
        filterEditor: DateFilter,
        filterEditorProps: (props: any, { index }: any) => {
          return {
            dateFormat: "DD/MM/YYYY",
            cancelButton: false,
            highlightWeekends: false,
            placeholder: index == 1 ? "Avant" : "Après",
          };
        },
      },
      {
        header: "Cahier permanent",
        name: "isPermBookComplete",
        type: "boolean",
        filterEditor: BoolFilter,
        render: ({ data }: { data: any }) => {
          return data.isPermBookComplete ? "Complet" : "Incomplet";
        },
        sortable: true,
        style: customCellStyle,
        width: 100,
        maxWidth: 150,
        minWidth: 100,
      },
      {
        header: "Collaborateur",
        name: "handler",
        render: ({ data }: { data: any }) => data.handler,
        sortable: true,
        width: 120,
        maxWidth: 150,
        minWidth: 120,
      },
      {
        header: "Paiement",
        name: "isPaymentUpToDate",
        type: "boolean",
        filterEditor: BoolFilter,
        render: ({ data }: { data: any }) => {
          return data.isPaymentUpToDate ? "À jour" : "Défaut";
        },
        sortable: true,
        style: customCellStyle,
        width: 80,
        maxWidth: 120,
        minWidth: 80,
      },
      {
        header: "Rapprochement ",
        name: "isbankReconUpToDate",
        type: "boolean",
        filterEditor: BoolFilter,
        render: ({ data }: { data: any }) => {
          return data.isbankReconUpToDate ? "Oui" : "Non";
        },
        sortable: true,
        style: customCellStyle,
        width: 80,
        maxWidth: 120,
        minWidth: 80,
      },
      {
        header: "Statut",
        name: "userStatus",
        sortable: true,
        // editable: true,
        render: ({ data }: { data: Company }) => {
          let res = null;
          if (data.userStatus) {
            res = data.userStatus;
          }
          return res;
        },
        // editor: SelectEditor as any,
        // editorProps: {
        //   idProperty: "id",
        //   dataSource: COMPANY_STATUT_OPTIONS_ID,
        //   collapseOnSelect: true,
        //   clearIcon: null,
        // },
        filterEditor: SelectFilter,
        filterEditorProps: {
          multiple: true,
          wrapMultiple: false,
          dataSource: [
            { id: null, label: "Rien" },
            ...COMPANY_STATUT_OPTIONS_ID,
          ],
        },
      },
    ];

    if (
      permissions.length > 0 &&
      hasCrudPermission(permissions, CrudPermissions.DeleteAccounts)
    ) {
      cols = [
        ...cols,
        {
          header: "Action",
          name: "action",
          className: "my-clients-table_ColHeader",
          render: ({ data }: { data: Company }) => {
            return (
              <div className="my-clients-table_ActionButton">
                <button onClick={() => ontoggle(data)}>
                  <BsTrashFill />
                </button>
              </div>
            );
          },
        } as any,
      ];
    }
    return cols;
  }, [permissions]);

  const dataFormatter = (data: Company[]) =>
    data.map((elt) => {
      return ({
        ...elt,
        clientPhone:
          elt.client && elt.client.phone && elt.client.phone !== ""
            ? elt.client.phone.trim()
            : "",
        companyPhone: elt.phone && elt.phone !== "" ? elt.phone.trim() : "",
        handler:
          elt.cabinets && elt.cabinets.length > 0
            ? `${elt.cabinets[0].firstName} ${elt.cabinets[0].lastName}`
            : elt.application?.name.toUpperCase(),
        name: `${elt.name || ""}${
          elt.siret && elt.siret !== ""
            ? `${elt.name ? "\n" : ""}(${elt.siret})`
            : elt.siren && elt.siren !== ""
            ? `${elt.name ? "\n" : ""}(${elt.siren})`
            : ""
        }`,
      })
    });

  return (
    <div className="page">
      <div className="top-content">
        <Row>
          <Col lg={8} md={12}>
            <BannerTop banner={data.mesclients} />
          </Col>
          <Col lg={4} md={12}>
            <UserInfo user={user} />
          </Col>
        </Row>
      </div>

      {/* <Card className="card-filter">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="align-items-end">
            <Col lg={8} md={12}>
              <div className="filterInner">
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="exampleEmail">Régime de TVA</Label>
                      <Controller
                        name="regimeTVA"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={
                              VAT_REGIME_OPTIONS as unknown as readonly (
                                | string
                                | GroupBase<string>
                              )[]
                            }
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="exampleEmail">Forme juridique</Label>
                      <Controller
                        name="legalForm"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={
                              JURID_FORMES as unknown as readonly (
                                | string
                                | GroupBase<string>
                              )[]
                            }
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="exampleEmail">Pack</Label>
                      <Controller
                        name="pack"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={
                              PLAN_OPTIONS as unknown as readonly (
                                | string
                                | GroupBase<string>
                              )[]
                            }
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="exampleEmail">Statut</Label>
                      <Controller
                        name="userStatus"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={
                              COMPANY_STATUT_OPTIONS as unknown as readonly (
                                | string
                                | GroupBase<string>
                              )[]
                            }
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-icon icon-end">
                      <Label for="mc">Mot clé</Label>
                      <Input
                        {...text}
                        innerRef={textRef}
                        id="mc"
                        name="text"
                        placeholder="Mot clé"
                        type="text"
                        className="form-secondary"
                      />
                      <span className="icon icon-secondary ">
                        <RiSearchLine />
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="actionsFilter mb-3">
                <Button color="secondary">
                  <span>Filtrer</span>
                </Button>
                <Button
                  color="secondary"
                  type="button"
                  onClick={async () => {
                    reset({
                      regimeTVA: null,
                      userStatus: null,
                      legalForm: null,
                      pack: null,
                      text: "",
                    });
                    setLocalCompanies([]);
                    setSearched(false);
                  }}
                  outline
                >
                  <span>Réinitialiser</span>
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </Card> */}

      <ReactDataGrid
        idProperty="id"
        columns={columns}
        rowHeight={48}
        dataSource={
          creds.role === UserTypes.Cabinet
            ? dataFormatter(clientsList)
            : dataFormatter(companiesList)
        }
        style={gridStyle}
        pagination
        defaultLimit={100}
        i18n={i18n}
        loading={
          pending ||
          (creds.role === UserTypes.Cabinet &&
            clientsListLoading === "pending") ||
          (creds.role === UserTypes.Admin && companiesListLoading === "pending")
        }
        enableFiltering
        loadingText={"Récupération des données, veuillez patienter"}
        emptyText={"Pas de données disponibles"}
        defaultFilterValue={filterValue}
        showZebraRows={false}
        className="my-clients-table"
      />
      {/* <Card className="card-Table table-primary invoice-table my-clients-table-card">
        <DataTable
          columns={columns}
          data={
            searched
              ? localCompanies
              : creds.role === UserTypes.Cabinet
              ? clientsList
              : companiesList
          }
          noDataComponent={<p>Il n'y a aucun data à afficher</p>}
          pagination
          paginationPerPage={20}
          // onRowClicked={(row: Partial<Company>, e: any) => {
          //   if (!e.target.id.includes("status-cell")) {
          //     navigate(`/client/${row.id}`);
          //   }
          // }}
          paginationComponent={(props) => {
            const customProps = { ...props, color: "primary" };
            return <BootyPagination {...customProps} />;
          }}
          progressPending={
            pending ||
            (creds.role === UserTypes.Cabinet &&
              clientsListLoading === "pending") ||
            (creds.role === UserTypes.Admin &&
              companiesListLoading === "pending")
          }
          progressComponent={
            <>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
            </>
          }
        />
      </Card> */}
      <div className="openbtn text-center">
        <Modal
          className="modal-danger"
          isOpen={open}
          toggle={() => {
            setSingleCompany(null);
            setOpen(false);
            setDeleteCompanyLoading(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSingleCompany(null);
              setOpen(false);
              setDeleteCompanyLoading(false);
            }}
          >
            Supprimer un client
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer le client{" "}
                {singleCompany?.name || singleCompany?.email}?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={() => {
                setDeleteCompanyLoading(false);
                setSingleCompany(null);
                setOpen(false);
              }}
              disabled={deleteCompanyLoading}
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={async () => {
                await deleteCompany();
              }}
              disabled={deleteCompanyLoading}
            >
              {deleteCompanyLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Oui"
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default MesClients;
