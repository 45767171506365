import React, { useState, FormEvent, ReactNode, useEffect } from "react";

import "./RegisterStepAsk.scss";
import keyImg from "../../../../assets/images/svg/key.svg";
import sendImg from "../../../../assets/images/svg/send.svg";
import {
  Alert,
  Button,
  Card,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Switch from "../../../../components/RegisterSwitch/Switch";
import UserIcon from "../../../../assets/images/svg/user-lead.svg";
import PhoneIcon from "../../../../assets/images/svg/phone-blue.svg";
import CardRegister from "../../../../components/CardRegister/CardRegister";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorLogger } from "../../../../util/errorLogger";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import useAxios from "../../../../util/hooks/useAxios";
import config from "../../../../config";

type FormValues = {
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  email: string | null;
  contactClient: boolean;
};

const { APPLICATION_NAME } = config[process.env.NODE_ENV];

const RegisterStepAsk: React.FC = () => {
  const {
    control,
    setValue,
    getValues,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({});

  const [loading, setLoading] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);

  const [contactClient, setContactClient] = useState(false);

  const { deleteValue, getValue, clearAll } = useLocalStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const nextStep = () => {
    navigate("/register/activite");
  };

  const prevStep = () => {
    if (
      location &&
      location.search &&
      location.search.includes("existing_user")
    ) {
      clearAll(["persist:noly-root"]);
    } else {
      clearAll();
    }
    let url = "/register";
    if (location && location.search) {
      url += location.search;
    }
    navigate(url);
  };

  const { ref: firstNameRef, ...firstName } = register("firstName");
  const { ref: lastNameRef, ...lastName } = register("lastName");
  const { ref: emailRef, ...email } = register("email");
  const { ref: phoneRef, ...phone } = register("phone");

  let api = useAxios();

  const sendEmail: SubmitHandler<FormValues> = async (form: FormValues) => {
    try {
      setErrorMessage(null);
      if (!form.firstName) {
        setErrorMessage({
          type: "invalid_form",
          message: "Veuillez saisir votre prénom",
        });
        return;
      }
      if (!form.lastName) {
        setErrorMessage({
          type: "invalid_form",
          message: "Veuillez saisir votre nom",
        });
        return;
      }
      if (!form.email) {
        setErrorMessage({
          type: "invalid_form",
          message: "Veuillez saisir votre email",
        });
        return;
      }
      if (!form.phone) {
        setErrorMessage({
          type: "invalid_form",
          message: "Veuillez saisir votre numéro de téléphone",
        });
        return;
      }
      setLoading(true);
      const { data } = await api.post(`/api/auth/registerNoComp`, {
        ...form,
        contactClient: form.contactClient ? "Oui" : "Non",
        application: APPLICATION_NAME,
      });

      if (data && data.sent) {
        navigate("/register/contact-envoye");
      } else {
        navigate("/register/contact-non-envoye");
      }
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("creating sync org", error);
      setLoading(false);
    }
  };

  const flipBool = () => {
    setValue("contactClient", !contactClient);
    setContactClient(!contactClient);
  };

  return (
    <>
      <div className="register-stepFive col-lg-7 col-md-12 mx-auto">
        <Card className="card-blue ask-card">
          <div className="ask-form-title text-center">
            <h3>Création d'entreprise</h3>
          </div>

          <form className="connexion-form" onSubmit={handleSubmit(sendEmail)}>
            <FormGroup className="form-icon icon-start">
              <Label for="firstName">Nom</Label>
              <Input
                id="firstName"
                innerRef={lastNameRef}
                {...lastName}
                type="text"
                className="form-secondary"
                // onChange={()=>setErrorMessage(null)}
              />
              <span className="icon icon-secondary ">
                <img src={UserIcon} alt="icon" />
              </span>
            </FormGroup>
            <FormGroup className="form-icon icon-start">
              <Label for="lastName">Prénom</Label>
              <Input
                id="lastName"
                innerRef={firstNameRef}
                {...firstName}
                type="text"
                className="form-secondary"
                // onChange={()=>setErrorMessage(null)}
              />
              <span className="icon icon-secondary ">
                <img src={UserIcon} alt="icon" />
              </span>
            </FormGroup>
            <FormGroup className="form-icon icon-start">
              <Label for="phone">Téléphone</Label>
              <Input
                id="phone"
                innerRef={phoneRef}
                {...phone}
                type="text"
                className="form-secondary"
                // onChange={()=>setErrorMessage(null)}
              />
              <span className="icon icon-secondary ">
                <img src={PhoneIcon} alt="icon" />
              </span>
            </FormGroup>
            <FormGroup className="form-icon icon-start">
              <Label for="email">Email</Label>
              <Input
                id="email"
                innerRef={emailRef}
                {...email}
                type="text"
                className="form-secondary"
                // onChange={()=>setErrorMessage(null)}
              />
              <span className="icon icon-secondary ">
                <img src={sendImg} alt="icon" />
              </span>
            </FormGroup>
            <FormGroup className="form-icon icon-start row box-list">
              <div className="col d-flex text-start justify-content-start">
                <Label>Souhaitez vous être rappelé par un agent?</Label>
              </div>
              <div className="switch secondary col d-flex justify-content-end">
                <span className="label greenTxt">Non</span>
                <Switch id={`99`} onClick={flipBool} checked={contactClient} />

                <span className="label greenTxt">Oui</span>
              </div>
            </FormGroup>

            {errorMessage?.type === "invalid_form" && (
              <Alert color="danger">{errorMessage?.message}</Alert>
            )}
            <div className="bottomSteperCenter">
              <Button
                type="button"
                onClick={prevStep}
                color="primary"
                outline
                className="btn btn-prev "
              >
                <FiChevronsLeft />
                <span>Précédent</span>
              </Button>
              <Button type="submit" color="primary" className="btn btn-next ">
                <span>
                  {loading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Envoyer"
                  )}
                </span>
                <FiChevronsRight />
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </>
  );
};

export default RegisterStepAsk;
