import React, { useState, FormEvent, useEffect } from "react";

import "./PaymentErrorStep.scss";
import keyImg from "../../../../assets/images/svg/key.svg";
import sendImg from "../../../../assets/images/svg/send.svg";
import {
  Button,
  Card,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Switch from "../../../../components/Switch/Switch";
import CardRegister from "../../../../components/CardRegister/CardRegister";
import WarningIcon from "../../../../assets/images/icons-warning.png";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import { FiChevronsLeft, FiChevronsRight, FiRotateCcw } from "react-icons/fi";
import useAxios from "../../../../util/hooks/useAxios";
import { ErrorLogger } from "../../../../util/errorLogger";

const PaymentErrorStep: React.FC = () => {
  const { clearAll } = useLocalStorage();
  const [url, setUrl] = useState<String | null>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setUrl(searchParams.get("session_id"));
  }, [searchParams]);

  const nextStep = () => {
    if (
      location &&
      location.search &&
      location.search.includes("existing_user")
    ) {
      clearAll(["persist:noly-root"]);
    } else {
      clearAll();
    }

    navigate("/register");
  };

  const prevStep = () => {
    if (
      location &&
      location.search &&
      location.search.includes("existing_user")
    ) {
      clearAll(["persist:noly-root"]);
    } else {
      clearAll();
    }
    navigate("/login");
  };

  let api = useAxios();

  const redoPayment = async () => {
    try {
      setLoading(true);
      const { data: checkoutSession } = await api.post(
        `/api/payment/checkoutSession`,
        {
          sessionId: url,
        }
      );
      setLoading(false);

      window.location.href = checkoutSession.session.url;
    } catch (error: any) {
      setLoading(false);
      ErrorLogger("redoing payment", error);
    }
  };

  return (
    <>
      <div className="register-stepFive col-lg-7 col-md-12 mx-auto">
        <div className="card warning-message">
          <span className="iconMsg">
            <img src={WarningIcon} alt="img" />
          </span>
          <h4>Nous sommes désolés !</h4>
          <p>Votre session de paiement est invalide</p>
        </div>
      </div>
      <div className="bottomSteperCenter">
        {location && !location.search.includes("existing_user") && (
          <>
            <Button
              type="button"
              onClick={prevStep}
              color="primary"
              outline
              className="btn btn-prev "
              disabled={loading}
            >
              <FiChevronsLeft />
              <span>Connecter</span>
            </Button>
            <Button
              type="button"
              onClick={nextStep}
              color="primary"
              className="btn btn-next "
              disabled={loading}
            >
              <span>Inscrire</span>
              <FiChevronsRight />
            </Button>
          </>
        )}

        {url && (
          <Button
            type="button"
            onClick={async () => await redoPayment()}
            color="warning"
            className="btn btn-next "
            disabled={loading}
          >
            <span>
              {loading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Réessayer"
              )}
            </span>
            <FiRotateCcw />
          </Button>
        )}
      </div>
    </>
  );
};

export default PaymentErrorStep;
