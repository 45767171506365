import React, { ReactElement, useCallback, useEffect, useState } from "react";
import "./SuiviTVA.scss";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { SiPypy } from "react-icons/si";
import UserInfo from "../../components/user-info/UserInfo";
import { data } from "../../constants";
import {
  RiAddLine,
  RiAlertFill,
  RiCalendar2Line,
  RiSearchLine,
  RiUser3Line,
} from "react-icons/ri";
import BannerTop from "../../components/Banner/BannerTop";
import Select, { GroupBase, SingleValue } from "react-select";

import { BootyPagination } from "../../components/table/pagination";
import { BsTrashFill } from "react-icons/bs";
import DataTable from "react-data-table-component";
import CrownIcon from "../../assets/CrownIcon";
import EmailIcon from "../../assets/EmailIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  Company,
  IApplication,
  ICABINET,
  IPermission,
  User,
} from "../../interfaces";
import axios from "axios";
import config from "../../config";
import { ErrorLogger } from "../../util/errorLogger";
import {
  AppPacks,
  COMPANY_STATUT_OPTIONS,
  COMPANY_STATUT_OPTIONS_ID,
  CrudPermissions,
  JURID_FORMES,
  OptionType,
  PLAN_OPTIONS,
  TAX_SYST_ID,
  UserTypes,
  VAT_REGIME_OPTIONS,
  VAT_REGIME_OPTIONS_ID,
} from "../../util/context";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { usePermissions } from "../../util/hooks/usePermissions";
import {
  deleteCompany_List,
  fetchAllClients,
  setClientsList,
  setCompanUserStatus_List,
} from "../../store/reducers/clients-list/clientsSlice";
import {
  deleteCompany_Company,
  fetchAllCompanies,
  setCompanyUserStatus_Company,
} from "../../store/reducers/companies-list/companiesSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import useAxios from "../../util/hooks/useAxios";
import { Link } from "react-router-dom";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import BoolFilter from "@inovua/reactdatagrid-community/BoolFilter";
import BoolEditor from "@inovua/reactdatagrid-community/BoolEditor";
import DateEditor from "@inovua/reactdatagrid-community/DateEditor";

import "@inovua/reactdatagrid-community/index.css";
import { toast } from "react-toastify";

type FormValues = {
  regimeTVA?: string | null;
  userStatus?: string | null;
  legalForm?: string | null;
  pack?: string | null;
  text?: string;
};

const RenderDateTime = (value: any) => {
  if (!value) {
    return null;
  }
  return <span>{moment(value).format("DD/MM/YYYY")}</span>;
};

window.moment = moment;

const filterValue: any = [
  { name: "name", operator: "contains", type: "string", value: "" },
  {
    name: "regimeTVA",
    operator: "inlist",
    type: "select",
  },
  // {
  //   name: "firstDeleclaration",
  //   operator: "inrange",
  //   type: "date",
  // },
  {
    name: "nextDeleclaration",
    operator: "inrange",
    type: "date",
  },
  {
    name: "isTvaStatusComplete",
    operator: "eq",
  },
];

type ClientLink = {
  value: string;
  label: string;
  isTvaStatusComplete: boolean | null;
};

const ClientLinkCell = (value: ClientLink) => {
  return (
    <Link
      to={`/client/${value.value}`}
      style={{ cursor: "pointer" }}
      state={{ action: "suivi-tva" }}
      className="d-flex align-items-center"
    >
      {!value.isTvaStatusComplete && (
        <RiAlertFill color="red" style={{ marginRight: "5px" }} size={15} />
      )}
      {value.label.split("\n").map((elt: string, index: number) => (
        <>
          <span style={{ marginRight: "5px" }} key={index}>
            {elt}
          </span>
        </>
      ))}
    </Link>
  );
};

const i18n: any = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
  pageText: "Page ",
  ofText: " de ",
  perPageText: "Résultats par page",
  showingText: "Afficher",
  clearAll: "Effacer tout",
  clear: "Effacer",
  showFilteringRow: "Afficher la ligne de filtrage",
  hideFilteringRow: "Masquer la ligne de filtrage",
  dragHeaderToGroup: "Faire glisser l'en-tête vers le groupe",
  disable: "Désactiver",
  enable: "Activer",
  sortAsc: "Trier en ordre croissant",
  sortDesc: "Trier par ordre décroissant",
  unsort: "Non-tri",
  group: "Groupe",
  ungroup: "Dégrouper",
  lockStart: "Début du verrouillage",
  lockEnd: "Fin du verrouillage",
  unlock: "Déverrouiller",
  columns: "Colonnes",
  contains: "Contient",
  startsWith: "Commence par",
  endsWith: "Se termine par",
  notContains: "Ne contient pas",
  neq: "N'est pas égal à",
  inrange: "dans l'intervalle",
  notinrange: "pas dans l'intervalle",
  eq: "Est égal à",
  notEmpty: "Non vide",
  empty: "Vide",
  lt: "Inférieur à",
  lte: "Inférieur ou égal",
  gt: "Supérieur à",
  gte: "Supérieur ou égal",
  "calendar.todayButtonText": "Aujourd'hui",
  "calendar.clearButtonText": "Effacer",
  "calendar.okButtonText": "OK",
  "calendar.cancelButtonText": "Annuler",
});

const gridStyle = { minHeight: 500 };

export interface ClientsProps {}
const SuiviTVA: React.FC<ClientsProps> = ({}) => {
  const [open, setOpen] = useState(false);
  const [singleCompany, setSingleCompany] = useState<Company | null>();
  const [deleteCompanyLoading, setDeleteCompanyLoading] = useState(false);
  const ontoggle = (company: Company) => {
    setSingleCompany(company);
    setOpen(true);
  };
  const { clientsList, cabinetLoading: clientsListLoading } = useSelector(
    (state: { clientsList: Company[] }) => {
      return state.clientsList;
    }
  ) as unknown as { clientsList: Company[]; cabinetLoading: string };

  const { companiesList, loading: companiesListLoading } = useSelector(
    (state: { companiesList: Company[] }) => state.companiesList
  ) as unknown as {
    companiesList: Company[];
    loading: string;
    currentRequestId: string;
  };
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const [pending, setPending] = useState<boolean>(false);

  const { user, permissions } = useSelector(
    (state: { user: object; application: object; permissions: object }) =>
      state.user
  ) as { user: User; application: IApplication; permissions: IPermission[] };

  const { hasCrudPermission } = usePermissions();
  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({});

  const { ref: textRef, ...text } = register("text");

  let api = useAxios();

  const columns = React.useMemo(() => {
    let cols = [
      {
        header: "Entreprise",
        name: "name",
        minWidth: 400,
        editable: false,
        render: ({ data }: { data: Company }) =>
          ClientLinkCell({
            value: data.id,
            label: data.name,
            isTvaStatusComplete: data.isTvaStatusComplete,
          }),
      },
      {
        header: "Régime TVA",
        name: "regimeTVA",
        minWidth: 300,
        editable: false,
        render: ({ data }: { data: Company }) => {
          let newArr = VAT_REGIME_OPTIONS.filter(
            (option) => option.value !== "Non"
          );
          let hasRegime = newArr.find((elt) => elt.value === data.regimeTVA);

          return data.regimeTVA && hasRegime ? hasRegime.label : "";
        },
        filterEditor: SelectFilter,
        filterEditorProps: {
          multiple: true,
          wrapMultiple: false,
          dataSource: [...VAT_REGIME_OPTIONS_ID],
        },
        sortable: false,
      },
      {
        header: "Prochaine déclaration",
        name: "nextDeleclaration",
        dateFormat: "DD/MM/YYYY",
        type: "date",
        minWidth: 300,
        render: ({ value }: { value: any }) => RenderDateTime(value),
        filterEditor: DateFilter,
        filterEditorProps: (props: any, { index }: any) => {
          return {
            dateFormat: "DD/MM/YYYY",
            cancelButton: false,
            highlightWeekends: false,
            placeholder: index == 1 ? "Avant" : "Après",
          };
        },
        editor: DateEditor,
        style: {
          cursor: "pointer",
        },
      },
      {
        header: "Statut à jour",
        name: "isTvaStatusComplete",
        type: "boolean",
        minWidth: 200,
        filterEditor: BoolFilter,
        render: ({ data }: { data: Company }) => {
          return data.isTvaStatusComplete ? "Traitée" : "En attente ";
        },
        editor: BoolEditor,
        sortable: true,
        style: {
          cursor: "pointer",
        },
      },
    ];
    return cols;
  }, [permissions]);

  const dataFormatter = (data: Company[]) =>
    data
      .filter((company) => {
        let newArr = VAT_REGIME_OPTIONS.filter(
          (option) => option.value !== "Non"
        );
        let hasRegime = newArr.find((elt) => elt.value === company.regimeTVA);

        return (
          company.subjectToVAT &&
          company.userStatus &&
          ["En cours","Client"].includes(company.userStatus) &&
          hasRegime &&
          hasRegime.label
        );
      })
      .map((elt) => ({
        ...elt,
        regimeTVA: !elt.regimeTVA ? "Non" : elt.regimeTVA,
        isTvaStatusComplete: elt.isTvaStatusComplete || false,
        name: `${elt.name || elt.email}${
          elt.siret && elt.siret !== ""
            ? `${elt.name ? "\n" : ""}(${elt.siret})`
            : elt.siren && elt.siren !== ""
            ? `${elt.name ? "\n" : ""}(${elt.siren})`
            : ""
        }`,
      }));

  const onEditComplete = useCallback(
    async (data: any) => {
      await updateCompany(data);
    },
    [clientsList, companiesList]
  );

  const updateCompany = async (data: any) => {
    const options: any = {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    toast.dismiss();
    try {
      setPending(true);
      toast.warning(
        "Cela peut prendre quelques secondes,s'il vous plaît, ne faites pas d'autre action ...",
        options
      );
      let value = data.value;
      let payload: any = {
        id: data.rowId,
        [data.columnId]: value,
      };

      if (
        data.columnId === "nextDeleclaration" &&
        moment(data.data.nextDeleclaration).format("DD/MM/YYYY") !== value
      ) {
        let dateValue = moment(value, "DD/MM/YYYY").format(
          "YYYY-MM-DD hh:mm"
        ) as string;
        payload = {
          ...payload,
          nextDeleclaration: dateValue,
        };
      }

      await api.post(`/api/Company/Update`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (creds.role === UserTypes.Admin) {
        dispatch(fetchAllCompanies());
      }
      if (creds.role === UserTypes.Cabinet) {
        dispatch(fetchAllClients(creds.user_id));
      }
      toast.success("Votre mise à jour a été effectuée avec succès", options);
      setSingleCompany(null);
      setOpen(false);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("updateCompany", error);
      setSingleCompany(null);
      setOpen(false);
      toast.error(
        "Quelque chose d'inattendu s'est produit, veuillez réessayer plus tard.",
        options
      );
    }
  };

  return (
    <div className="page">
      <div className="top-content">
        <Row>
          <Col lg={8} md={12}>
            <BannerTop banner={data.suivitva} />
          </Col>
          <Col lg={4} md={12}>
            <UserInfo user={user} />
          </Col>
        </Row>
      </div>

      <ReactDataGrid
        idProperty="id"
        columns={columns as any}
        rowHeight={48}
        dataSource={
          creds.role === UserTypes.Cabinet
            ? dataFormatter(clientsList)
            : dataFormatter(companiesList)
        }
        style={gridStyle}
        pagination
        defaultLimit={100}
        i18n={i18n}
        loading={
          pending ||
          (creds.role === UserTypes.Cabinet &&
            clientsListLoading === "pending") ||
          (creds.role === UserTypes.Admin && companiesListLoading === "pending")
        }
        enableFiltering
        loadingText={"Récupération des données, veuillez patienter"}
        emptyText={"Pas de données disponibles"}
        defaultFilterValue={filterValue}
        showZebraRows={false}
        className="my-clients-table"
        onEditComplete={onEditComplete}
        editable={true}
      />
      <div className="openbtn text-center"></div>
    </div>
  );
};

export default SuiviTVA;
