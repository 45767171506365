import React, { useEffect, useState } from "react";
import "./ApercuAdmin.scss";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import UserInfo from "../../components/user-info/UserInfo";
import { data, images } from "../../constants";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiCalendar2Line,
  RiCheckFill,
  RiDownload2Fill,
  RiUser3Line,
  RiUserAddFill,
  RiUserFollowFill,
} from "react-icons/ri";
import BannerTop from "../../components/Banner/BannerTop";
import AttacheIcon from "../../assets/AttacheIcon";
import Select from "react-select";
import { lighten, darken } from "polished";
import Icon1 from "../../assets/images/svg/icon-nav-1.svg";
import Icon2 from "../../assets/images/svg/icon-nav-2.svg";
import Icon3 from "../../assets/images/svg/icon-nav-3.svg";
import Icon4 from "../../assets/images/svg/icon-nav-4.svg";
import IconLogOut from "../../assets/images/svg/icon-nav-5.svg";
import ImgBanner from "../../assets/images/banner/b1.png";
import previewPrsopect from "../../assets/images/previewPrsopect.png";
import profilePhoto from "../../assets/images/avatar_empty.png";
import emptyLogo from "../../assets/images/EmptyLogo.jpg";

import TopNav from "../../components/topnav/TopNav";
import { Link } from "react-router-dom";
import { SiPypy } from "react-icons/si";
import EmailIcon from "../../assets/EmailIcon";
import CrownIcon from "../../assets/CrownIcon";
import { IoMdCreate } from "react-icons/io";
import { BsTrashFill } from "react-icons/bs";
import UserOrangeIcon from "../../assets/UserOrangeIcon";
import userAddIcon from "../../assets/images/svg/user-t1.svg";
import AddIconLead from "../../assets/images/svg/userLead-icon.svg";
import colors from "../../constants/colors";
import styled from "styled-components";
import TicketIcon from "./TicketIcon";
import EmailPrevIcon from "./EmailIcon";
import CerleUserBg from "../../assets/CerleUserBg";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const ApercuAdmin = ({
  scale,
  transformOrigin = "top left",
  logo,
  color1 = colors.primaryColor,
  color2 = colors.secondaryColor,
  color3 = colors.orange,
}: {
  scale: number | number[];
  transformOrigin?: string;
  logo?: any;
  color1?: string;
  color2?: string;
  color3?: string;
}) => {
  const ActiveLi = styled.li`
    a {
      background: ${color2};
      box-shadow: inset 0px 4px 6px 0px ${color2};
    }
    :before {
      background-color: ${color2};
    }
    :after {
      box-shadow: inset 2px 2px 7px 3px ${lighten(0.3, color2)};
    }
  `;

  const BannerApercu = styled.div`
    box-shadow: 0 5px 20px ${lighten(0.24, color1)};
  `;

  const TableApercuHead = styled.thead`
    color: ${color1};
    background: ${lighten(0.6, color1)};
  `;

  const ButtonSec = styled.button`
    background: ${color2} !important;
    box-shadow: inset 0px 4px 5px 0px ${color2} !important;
    border-color: ${color2} !important;
  `;

  const EditIcon = styled.button`
    box-shadow: -2px 4px 10px ${lighten(0.2, color2)} !important;
    svg {
      fill: ${color2};
    }
  `;

  const ButtonPrim = styled.button`
    background: ${color1} !important;
    box-shadow: inset 0px 4px 5px 0px ${color1} !important;
    border-color: ${color1} !important;
  `;

  const ButtonOrange = styled.button`
    background: ${color3} !important;
    box-shadow: inset 0px 4px 5px 0px ${color3} !important;
    border-color: ${color3} !important;
  `;

  const CalenderIcon = styled.span`
    box-shadow: inset 0px 4px 6px 0px ${lighten(0.3, color2)} !important;
    svg {
      fill: ${color2};
    }
  `;

  return (
    <div
      className="main-apercu"
      style={{
        transform: `scale${
          Array.isArray(scale) ? `(${scale[0]}, ${scale[1]})` : `(${scale})`
        }`,
        transformOrigin: transformOrigin,
      }}
    >
      <div className="preview-wrapper">
        <div className="sidebar-apercu">
          <div className="logo-apercu">
            <img
              src={
                logo
                  ? logo.key
                    ? logo.url
                    : URL.createObjectURL(logo)
                  : emptyLogo
              }
              alt="app-logo"
            />
          </div>
          <ul>
            <li>
              <Link to="/" className="nav-item ">
                <span className="icon-nav">
                  <img src={Icon1} alt="icon" />
                </span>
                <span className="txt-nav">Mes Clients</span>
              </Link>
            </li>
            <li>
              <Link to="/" className="nav-item">
                <span className="icon-nav  icon-1">
                  <img src={Icon2} alt="icon" />
                </span>
                <span className="txt-nav">Gestion admin</span>
              </Link>
            </li>
            <li>
              <Link to="/" className="nav-item">
                <span className="icon-nav">
                  <img src={Icon3} alt="icon" />
                </span>
                <span className="txt-nav">Gestion Comptable</span>
              </Link>
            </li>
            <ActiveLi className="active">
              <Link to="/" className="nav-item">
                <span className="icon-nav">
                  <img src={Icon4} alt="icon" />
                </span>
                <span className="txt-nav">Gestion prospects</span>
              </Link>
            </ActiveLi>
          </ul>

          <div className="logout-sidbar">
            <ul>
              <li>
                <Link to="/" className="nav-item">
                  <span className="icon-nav">
                    <img src={IconLogOut} alt="icon" />
                  </span>
                  <span className="txt-nav">Déconnexion</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="main-content-apercu">
          <div className="top-navbtn">
            <Button
              className="btn-creat-lead btn-creat-apercu mx-2"
              style={{
                boxShadow: `inset 0px 4px 5px 0px ${color2}`,
                borderColor: color2,
                background: color2,
                backgroundColor: color2,
              }}
            >
              <img src={AddIconLead} alt="icon" />
              <span>Créer un lead</span>
            </Button>
            <Button
              className="btn-creat-user btn-creat-apercu"
              style={{
                boxShadow: `inset 0px 4px 5px 0px ${color3}`,
                borderColor: color3,
                background: color3,
                backgroundColor: color3,
              }}
            >
              <img src={userAddIcon} alt="icon" />
              <span>Créer un client</span>
            </Button>
          </div>

          <Row className="mb-4">
            <Col md={8}>
              <BannerApercu
                className="banner-apercu"
                style={{
                  background: `${color1}`,
                }}
              >
                <div className="Banner-text">
                  <h2>Mes prospects</h2>
                </div>
                <div className="Banner-img">
                  <img src={previewPrsopect} alt="Gestion Admin" />
                </div>
              </BannerApercu>
            </Col>
            <Col md={4}>
              <Card className="user-apercu">
                <TicketIcon color1={color2} color2={color2} />

                <div className="user-info">
                  <CerleUserBg className="bgCercle" fillColor={color2} />
                  <div className="user-info__img">
                    <img src={profilePhoto} alt="img" />
                  </div>
                </div>

                <EmailPrevIcon color1={color2} color2={color2} />
              </Card>
            </Col>
          </Row>
          <Card className="card-filter">
            <Form>
              <Row className="align-items-end">
                <Col lg={6} md={12}>
                  <div className="filterInner">
                    <Row>
                      <Col lg={6} md={6}>
                        <FormGroup className="form-icon icon-end">
                          <Label for="dated">Date de debut</Label>
                          <Input
                            id="dated"
                            name="date"
                            placeholder="Date de debut"
                            type="date"
                            className="form-secondary"
                            style={{
                              color: color2,
                              background: `${lighten(0.35, color2)}`,
                              boxShadow: `inset 0px 4px 6px 0px ${lighten(
                                0.2,
                                color2
                              )}`,
                              borderColor: color2,
                            }}
                          />
                          <CalenderIcon className="icon icon-secondary ">
                            <RiCalendar2Line />
                          </CalenderIcon>
                        </FormGroup>
                      </Col>
                      <Col lg={6} md={6}>
                        <FormGroup className="form-icon icon-end">
                          <Label for="datef">Date de fin</Label>
                          <Input
                            id="datef"
                            name="date"
                            placeholder="Date de fin"
                            type="date"
                            className="form-secondary"
                            style={{
                              color: color2,
                              background: `${lighten(0.35, color2)}`,
                              boxShadow: `inset 0px 4px 6px 0px ${lighten(
                                0.2,
                                color2
                              )}`,
                              borderColor: color2,
                            }}
                          />
                          <CalenderIcon className="icon icon-secondary ">
                            <RiCalendar2Line />
                          </CalenderIcon>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <div className="actionsFilter mb-3">
                    <Button
                      style={{
                        boxShadow: `inset 0px 4px 5px 0px ${color2}`,
                        borderColor: color2,
                        background: color2,
                        backgroundColor: color2,
                      }}
                    >
                      <span>Filtrer</span>
                    </Button>
                    <Button
                      style={{
                        borderColor: color2,
                        color: color2,
                      }}
                      outline
                    >
                      <span>Réinitialiser</span>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
          <div className=" table-primary card">
            <Table className="table-apercu">
              <TableApercuHead>
                <tr>
                  <th>Prénom</th>
                  <th>Nom</th>
                  <th>Email</th>
                  <th>Création</th>
                  <th>Statut</th>
                  <th>Action</th>
                </tr>
              </TableApercuHead>
              <tbody>
                <tr>
                  <td scope="row">Mark</td>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>
                    <ButtonPrim className="btn-status btn btn-small btn-primary">
                      <RiCheckFill />
                      Lead
                    </ButtonPrim>
                  </td>
                  <td>
                    <div className="table-action">
                      <EditIcon className="btn btn-blue">
                        <IoMdCreate />
                      </EditIcon>
                      <button className="btn btn-red">
                        <BsTrashFill />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td scope="row">Mark</td>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@mdo</td>
                  <td>
                    <ButtonSec className="btn-status btn btn-small btn-secondary">
                      <RiUserAddFill />
                      Intéressé
                    </ButtonSec>
                  </td>
                  <td>
                    <div className="table-action">
                      <EditIcon className="btn btn-blue">
                        <IoMdCreate />
                      </EditIcon>
                      <button className="btn btn-red">
                        <BsTrashFill />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td scope="row">Mark</td>
                  <td>Larry</td>
                  <td>the Bird</td>
                  <td>@mdo</td>
                  <td>
                    <ButtonOrange className="btn-status btn btn-small btn-warning">
                      <RiUserFollowFill />A Valider
                    </ButtonOrange>
                  </td>
                  <td>
                    <div className="table-action">
                      <EditIcon className="btn btn-blue">
                        <IoMdCreate />
                      </EditIcon>
                      <button className="btn btn-red">
                        <BsTrashFill />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApercuAdmin;

const hexToHSL = (H: any, a: any) => {
  // Convert hex to RGB first
  let r: any = 0;
  let g: any = 0;
  let b: any = 0;

  if (H.length == 5) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
    a = "0x" + H[4] + H[4];
  }
  if (H.length == 9) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
    a = "0x" + H[7] + H[8];
  }
  if (H.length == 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  }
  if (H.length == 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);
  a = (a / 255).toFixed(3);

  return { h, s, l, a };
};

const HSLAToHEX = (h: any, s: any, l: any, a: any) => {
  s /= 100;
  l /= 100;

  let c: any = (1 - Math.abs(2 * l - 1)) * s;
  let x: any = c * (1 - Math.abs(((h / 60) % 2) - 1));
  let m: any = l - c / 2;
  let r: any = 0;
  let g: any = 0;
  let b: any = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  r = Math.round((r + m) * 255).toString(16);
  g = Math.round((g + m) * 255).toString(16);
  b = Math.round((b + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;

  a = Math.round(a * 255).toString(16);

  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;
  if (a.length == 1) a = "0" + a;

  return "#" + r + g + b + a;
};

export const adjustColor = (color: string, amount: number) => {
  const hsla = hexToHSL(color, amount);
  const hex = HSLAToHEX(hsla.h, hsla.s, hsla.l, hsla.a);
  return hex;
};
