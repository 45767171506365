import { AnimatePresence } from "framer-motion";
import React from "react";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import RegisterStepTwo from "./RegisterStepTwo";

const StepTwo = () => {
  const navigate = useNavigate();
  const { deleteValue, getValue } = useLocalStorage();
  const location = useLocation();
  const nextStep = () => {
    let url = "/register/info-activite";
    if (location && location.search) {
      url += location.search;
    }
    if (getValue("ActivityType")) {
      navigate(url);
    }
  };

  const prevStep = () => {
    deleteValue("ActivityType");
    deleteValue("CompanyType");
    let url = "/register";
    if (location && location.search) {
      url += location.search;
    }
    navigate(url);
  };

  return (
    <>
      <AnimatePresence>
        <RegisterStepTwo onClick={nextStep} />
      </AnimatePresence>

      <div className="bottomSteperCenter">
        <Button
          type="button"
          onClick={prevStep}
          color="primary"
          outline
          className="btn btn-prev "
        >
          <FiChevronsLeft />
          <span>Précédent</span>
        </Button>
        <Button
          type="button"
          onClick={nextStep}
          color="primary"
          className="btn btn-next "
        >
          <span>Suivant</span>
          <FiChevronsRight />
        </Button>
      </div>
    </>
  );
};

export default StepTwo;
