import React, { useState, FormEvent, useEffect } from "react";

import "./RegisterStepFour.scss";
import loginImg from "../../../../assets/images/logo.svg";
import CardPack from "../../../../components/CardPack/CardPack";
import { Button, Col, Row } from "reactstrap";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
// import { Registerkeys } from "../../../../util/context";
import { useLocation, useNavigate } from "react-router-dom";
import { AppPacks } from "../../../../util/context";

const RegisterStepFour = ({
  clicked,
  setClikced,
}: {
  clicked: boolean;
  setClikced: any;
}) => {
  const [renderer, setRenderer] = useState<String>("ERROR");
  const [activityType, setActivityType] = useState<String>("");
  const [answersObject, setAnswersObject] = useState<any>();
  const { setValue, getValue, deleteValue } = useLocalStorage();

  const navigate = useNavigate();
  const location = useLocation()

  const chooseRender = () => {
    const ActivityType = getValue("ActivityType");
    const ANSWERS_OBJ = getValue("AnswersObject");
    setActivityType(ActivityType);
    setAnswersObject(ANSWERS_OBJ);

    switch (ActivityType) {
      case "commercial":
      case "eirl":
        if (
          !ANSWERS_OBJ ||
          (ActivityType === "eirl" && ANSWERS_OBJ.eirlQ1 === "BIC")
        ) {
          setRenderer("ERROR");
        } else if (
          (ActivityType === "commercial" &&
            ANSWERS_OBJ.commercialQ1 &&
            ANSWERS_OBJ.commercialQ2) ||
          (ActivityType === "commercial" &&
            ANSWERS_OBJ.commercialQ1 &&
            ANSWERS_OBJ.commercialQ3)
        ) {
          setRenderer("BIC");
          setPack(AppPacks.BIC);
        } else if (ActivityType === "eirl" && ANSWERS_OBJ.eirlQ1 === "BNC") {
          setRenderer("BNC");
          setPack(AppPacks.BNCAssoc);
        }
        return;

      /* Bilan  eirl + commercial*/

      case "bilancommercial":
      case "bilaneirl":
        if (
          !ANSWERS_OBJ ||
          (ActivityType === "bilaneirl" && ANSWERS_OBJ.eirlQ1 === "BIC")
        ) {
          setRenderer("ERROR");
        } else if (
          (ActivityType === "bilancommercial" &&
            ANSWERS_OBJ.bilancommercialQ1 &&
            ANSWERS_OBJ.bilancommercialQ2) ||
          (ActivityType === "bilancommercial" &&
            ANSWERS_OBJ.bilancommercialQ1 &&
            ANSWERS_OBJ.bilancommercialQ3)
        ) {
          setRenderer("BilanBIC");
          setPack(AppPacks.BilanBIC);
        } else if (
          ActivityType === "bilaneirl" &&
          ANSWERS_OBJ.bilaneirlQ1 === "BNC"
        ) {
          setRenderer("BilanBNC");
          setPack(AppPacks.BilanBNCAssoc);
        }
        return;

      case "civilSociety":
        if (!ANSWERS_OBJ) {
          setRenderer("ImmoPlusTVA");
          setPack(AppPacks.ImmoPlusTVA);
        } else if (
          ActivityType === "civilSociety" &&
          !ANSWERS_OBJ.civilSocietyQ1 &&
          !ANSWERS_OBJ.civilSocietyQ2
        ) {
          setRenderer("ImmoPlusTVA");
          setPack(AppPacks.ImmoPlusTVA);
        } else if (
          ActivityType === "civilSociety" &&
          !ANSWERS_OBJ.civilSocietyQ1 &&
          ANSWERS_OBJ.civilSocietyQ2
        ) {
          setRenderer("ImmoPlus");
          setPack(AppPacks.ImmoPlus);
        } else if (
          ActivityType === "civilSociety" &&
          ANSWERS_OBJ.civilSocietyQ1 &&
          !ANSWERS_OBJ.civilSocietyQ2
        ) {
          setRenderer("ImmoTVA");
          setPack(AppPacks.ImmoTVA);
        } else if (
          ActivityType === "civilSociety" &&
          ANSWERS_OBJ.civilSocietyQ1 &&
          ANSWERS_OBJ.civilSocietyQ2
        ) {
          setRenderer("Immo");
          setPack(AppPacks.Immo);
        } else if (
          ActivityType === "civilSociety" &&
          ANSWERS_OBJ.civilSocietyQ1 &&
          ANSWERS_OBJ.civilSocietyQ2
        ) {
          setRenderer("Immo");
          setPack(AppPacks.Immo);
        } else {
          setRenderer("ERROR");
        }
        return;

      /* bilan civilSociety */
      case "bilancivilSociety":
        if (!ANSWERS_OBJ) {
          console.log("case 1 ")
          // setRenderer("ERROR");
          setRenderer("BilanImmoPlusTVA");
          setPack(AppPacks.BilanImmoPlusTVA);
        } else if (
          ActivityType === "bilancivilSociety" &&
          !ANSWERS_OBJ.bilancivilSocietyQ1 &&
          !ANSWERS_OBJ.bilancivilSocietyQ2
        ) {
          console.log("case 2 ")
          setRenderer("BilanImmoPlusTVA");
          setPack(AppPacks.BilanImmoPlusTVA);
        } else if (
          ActivityType === "bilancivilSociety" &&
          !ANSWERS_OBJ.bilancivilSocietyQ1 &&
          ANSWERS_OBJ.bilancivilSocietyQ2
        ) {
          console.log("case 3 ")
          setRenderer("BilanImmoPlus");
          setPack(AppPacks.BilanImmoPlus);
        } else if (
          ActivityType === "bilancivilSociety" &&
          ANSWERS_OBJ.bilancivilSocietyQ1 &&
          !ANSWERS_OBJ.bilancivilSocietyQ2
        ) {
          console.log("case 4 ")
          // setRenderer("BilanImmoTVA");
          // setPack(AppPacks.BilanImmoTVA);
          setRenderer("BilanImmoPlus");
          setPack(AppPacks.BilanImmoPlus);
        } else if (
          ActivityType === "bilancivilSociety" &&
          ANSWERS_OBJ.bilancivilSocietyQ1 &&
          ANSWERS_OBJ.bilancivilSocietyQ2
        ) {
          console.log("case 5 ")
          setRenderer("BilanImmo");
          setPack(AppPacks.BilanImmo);
        } else {
          setRenderer("ERROR");
        }
        return;

      case "association":
        if (!ANSWERS_OBJ) {
          setRenderer("BNC");
          setPack(AppPacks.BNCAssoc);
        } else {
          setRenderer("ERROR");
        }
        return;

        {
          /* Bilan Association */
        }

      case "bilanassociation":
        if (!ANSWERS_OBJ) {
          setRenderer("BilanBNC");
          setPack(AppPacks.BilanBNCAssoc);
        } else {
          setRenderer("ERROR");
        }
        return;

      case "worksCouncil":
        if (!ANSWERS_OBJ) {
          setRenderer("BIC");
          setPack(AppPacks.BIC);
        } else {
          setRenderer("ERROR");
        }
        return;

        {
          /* Bilan worksCouncil */
        }

      case "bilanworksCouncil":
        if (!ANSWERS_OBJ) {
          setRenderer("BilanBIC");
          setPack(AppPacks.BilanBIC);
        } else {
          setRenderer("ERROR");
        }
        return;

      case "marchendise":
      case "prestation":
      case "autresPrestations":
      case "profession":
      case "location":
        setRenderer("ENTREP");
        setPack(AppPacks.Entrepreneur);
        return;

        {
          /* Bilan Rest Pakcs */
        }

      case "bilanmarchendise":
      case "bilanprestation":
      case "bilanautresPrestations":
      case "bilanprofession":
      case "bilanlocation":
        setRenderer("BilanENTREP");
        setPack(AppPacks.BilanEntrepreneur);
        return;

      default:
        setRenderer("ERROR");
        return;
    }
  };

  const setPack = (pack: string) => {
    setValue(pack, "pack");
  };

  useEffect(() => {
    chooseRender();
  }, []);

  return (
    <div className="register-stepFour col-lg-8 col-md-12 mx-auto">
      {/* <form> */}
      <Row
        className={
          renderer !== "BNC" ? "d-flex px-3 justify-content-center" : ""
        }
      >
        {renderer === "ERROR" && (
          <div className="card box-message">
            <p>
              Votre activité implique un inventaire de stocks en fin d'année ?
            </p>

            <div className="actions-message">
              <Button
                type="button"
                color="secondary"
                outline
                className="btn "
                onClick={() => {
                  if (getValue("CompanyType") === "company") {
                    setRenderer("CHECK_TVA");
                  } else {
                    setRenderer("BilanBICPlus");
                    setPack(AppPacks.BilanBICPlus);
                  }
                }}
              >
                <span>Non</span>
              </Button>
              <Button
                type="button"
                color="secondary"
                className="btn "
                onClick={() => {
                  let url  = "/register/erreur"
                  if(location && location.search){
                    url += location.search
                  }
                  navigate(url);
                }}
              >
                <span>Oui</span>
              </Button>
            </div>
          </div>
        )}

        {renderer === "CHECK_TVA" && (
          <div className="card box-message">
            <p>
              Souhaitez-vous que l’on se charge de vos déclarations de TVA{" "}
              <br />
              <u>( fortement recommandé )</u> ? à 5.90 €/mois
            </p>

            <div className="actions-message">
              <Button
                type="button"
                color="secondary"
                outline
                className="btn "
                onClick={() => {
                  setRenderer("BICPlus");
                  setPack(AppPacks.BICPlus);
                }}
              >
                <span>Non</span>
              </Button>
              <Button
                type="button"
                color="secondary"
                className="btn "
                onClick={() => {
                  setRenderer("BICPlusTVA");
                  setPack(AppPacks.BICPlusTVA);
                }}
              >
                <span>Oui</span>
              </Button>
            </div>
          </div>
        )}

        {/* ENTREP */}
        {renderer === "ENTREP" && (
          <Col md={6} sm={12}>
            <CardPack
              title="Pack micro-entreprise"
              price="14.90 € HT"
              className="primary"
            >
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.Entrepreneur)}
                  defaultChecked={getValue("pack") === AppPacks.Entrepreneur}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Récupération des données bancaires</li>
                  <li>Encaissement en espèce</li>
                  <li>Tableau de bord/gestion de trésorerie</li>
                  <li>Déclaration chiffre d’affaires à l’Urssaf</li>
                  <li>Support et conseil</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* BIC */}
        {renderer === "BIC" && (
          <Col md={6} sm={12}>
            <CardPack title="PACK BIC" price="49,90 € HT" className="primary">
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.BIC)}
                  defaultChecked={getValue("pack") === AppPacks.BIC}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de calcul de TVA</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Réconciliation des créances et dettes</li>
                  <li>Déclaration de TVA</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* BIC + */}
        {renderer === "BICPlus" && (
          <Col md={6} sm={12}>
            <CardPack title="PACK BIC +" price="64.9 € HT" className="primary">
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.BICPlus)}
                  defaultChecked={getValue("pack") === AppPacks.BICPlus}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de calcul de TVA</li>
                  <li>Système de récupération de facture</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation/emprunt/ paiement différés</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Calcul de l'IS</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* BIC + / TVA */}
        {renderer === "BICPlusTVA" && (
          <Col md={6} sm={12}>
            <CardPack
              title="PACK BIC +"
              price="70.8 € HT"
              className="primary"
              tva
            >
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.BICPlus)}
                  defaultChecked={getValue("pack") === AppPacks.BICPlusTVA}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de calcul de TVA</li>
                  <li>Système de récupération de facture</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation/emprunt/ paiement différés</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Calcul de l'IS</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* BNC ASSOC + */}
        {/* BNC ASSOC */}
        {renderer === "BNC" && (
          <>
            <Col md={6} sm={12}>
              <CardPack
                title="PACK BNC ASSO"
                price="34,90 € HT"
                className="secondary"
              >
                <div className="check-pack check-secondary">
                  <input
                    type="radio"
                    id="pack-1"
                    name="radio-group"
                    onClick={() => setPack(AppPacks.BNCAssoc)}
                    defaultChecked={getValue("pack") === AppPacks.BNCAssoc}
                  />
                  <label htmlFor="pack-1"> </label>
                </div>

                <div className="text-pack">
                  <ul className="list-text">
                    <li>Accès application</li>
                    <li>Récupération des données bancaires</li>
                    <li>Catégorisation des dépenses automatiques</li>
                    <li>Accès page Tableau de bord</li>
                    <li>Note de frais / Encaissement espèces</li>
                    <li>Gestion immobilisation</li>
                    <li>Support & Conseil *</li>
                    <li>Réalisation de votre Bilan **</li>
                    <li>Etablissement de la liasse fiscale</li>
                    <li>
                      Réalisation de la déclaration sociale des indépendants
                      (DSI)
                    </li>
                    <li>
                      Gestion et transmission des données comptables à l'AGA
                    </li>
                  </ul>
                </div>
              </CardPack>
            </Col>
            <Col md={6} sm={12}>
              <CardPack
                title="PACK BNC ASSO +"
                price="44,90 € HT"
                className="primary"
                tva
              >
                <div className="check-pack check-primary">
                  <input
                    type="radio"
                    id="pack-2"
                    name="radio-group"
                    onClick={() => setPack(AppPacks.BNCAssocPlus)}
                    defaultChecked={getValue("pack") === AppPacks.BNCAssocPlus}
                  />
                  <label htmlFor="pack-2"> </label>
                </div>

                <div className="text-pack">
                  <ul className="list-text">
                    <li>Accès application</li>
                    <li>Récupération des données bancaires</li>
                    <li>Catégorisation des dépenses automatiques</li>
                    <li>Système de calcul de TVA</li>
                    <li>Accès page Tableau de bord</li>
                    <li>Note de frais / Encaissement espèces</li>
                    <li>Gestion immobilisation</li>
                    <li>Support & Conseil *</li>
                    <li>Réalisation de votre Bilan **</li>
                    <li>Etablissement de la liasse fiscale</li>
                    <li>Déclaration de TVA</li>
                    <li>
                      Gestion et transmission des données comptables à l'AGA
                    </li>
                  </ul>
                </div>
              </CardPack>
            </Col>
          </>
        )}

        {/* ImmoPlusTVA */}
        {renderer === "ImmoPlusTVA" && (
          <Col md={6} sm={12}>
            <CardPack
              title="PACK IMMO +"
              price="29,90 € HT"
              className="primary"
              tva
            >
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.ImmoPlusTVA)}
                  defaultChecked={getValue("pack") === AppPacks.ImmoPlusTVA}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de calcul de TVA</li>
                  <li>Système de récupération de quittance</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation/emprunt/ paiement différés</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Calcul de l'IS</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* ImmoPlus */}
        {renderer === "ImmoPlus" && (
          <Col md={6} sm={12}>
            <CardPack
              title="PACK IMMO +"
              price="24,90 € HT"
              className="primary"
            >
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.ImmoPlus)}
                  defaultChecked={getValue("pack") === AppPacks.ImmoPlus}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de calcul de TVA</li>
                  <li>Système de récupération de quittance</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation/emprunt/ paiement différés</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Calcul de l'IS</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* ImmoTVA */}
        {renderer === "ImmoTVA" && (
          <Col md={6} sm={12}>
            <CardPack
              title="PACK IMMO"
              price="24,90 € HT"
              className="primary"
              tva
            >
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.ImmoTVA)}
                  defaultChecked={getValue("pack") === AppPacks.ImmoTVA}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de récupération de facture</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation/emprunt/ paiement différés</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Calcul de l'IS</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* Immo */}
        {renderer === "Immo" && (
          <Col md={6} sm={12}>
            <CardPack title="PACK IMMO" price="19,90 € HT" className="primary">
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.Immo)}
                  defaultChecked={getValue("pack") === AppPacks.Immo}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de récupération de facture</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation/emprunt/ paiement différés</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Calcul de l'IS</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* Bilan PACKS */}

        {renderer === "BilanCHECK_TVA" && (
          <div className="card box-message">
            <p>
              Souhaitez-vous que l’on se charge de vos déclarations de TVA{" "}
              <br />
              <u>( fortement recommandé )</u> ? à 59 €/mois
            </p>

            <div className="actions-message">
              <Button
                type="button"
                color="secondary"
                outline
                className="btn "
                onClick={() => {
                  setRenderer("BilanBICPlus");
                  setPack(AppPacks.BilanBICPlus);
                }}
              >
                <span>Non</span>
              </Button>
              <Button
                type="button"
                color="secondary"
                className="btn "
                onClick={() => {
                  setRenderer("BilanBICPlusTVA");
                  setPack(AppPacks.BilanBICPlusTVA);
                }}
              >
                <span>Oui</span>
              </Button>
            </div>
          </div>
        )}

        {/* Bilan BIC + */}
        {renderer === "BilanBICPlus" && (
          <Col md={6} sm={12}>
            <CardPack title="PACK BIC +" price="699 € HT" className="primary">
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.BilanBICPlus)}
                  defaultChecked={getValue("pack") === AppPacks.BilanBICPlus}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de calcul de TVA</li>
                  <li>Système de récupération de facture</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation/emprunt/ paiement différés</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Calcul de l'IS</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* Bilan BIC + / TVA */}
        {renderer === "BilanBICPlusTVA" && (
          <Col md={6} sm={12}>
            <CardPack
              title="PACK BIC +"
              price="708 € HT"
              className="primary"
              tva
            >
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.BilanBICPlusTVA)}
                  defaultChecked={getValue("pack") === AppPacks.BilanBICPlusTVA}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de calcul de TVA</li>
                  <li>Système de récupération de facture</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation/emprunt/ paiement différés</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Calcul de l'IS</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* BilanImmoPlusTVA */}
        {renderer === "BilanImmoPlusTVA" && (
          <Col md={6} sm={12}>
            {/* <CardPack
              title="PACK IMMO +"
              price="299 € HT"
              className="primary"
              tva
            > */}
            <CardPack
              title="PACK IMMO +"
              price="360 € HT"
              className="primary"
              tva
            >
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.BilanImmoPlusTVA)}
                  defaultChecked={
                    getValue("pack") === AppPacks.BilanImmoPlusTVA
                  }
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de calcul de TVA</li>
                  <li>Système de récupération de quittance</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation/emprunt/ paiement différés</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Calcul de l'IS</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* BilanImmoPlus */}
        {renderer === "BilanImmoPlus" && (
          <Col md={6} sm={12}>
            {/* <CardPack title="PACK IMMO +" price="249 € HT" className="primary"> */}
            <CardPack title="PACK IMMO +" price="300 € HT" className="primary">
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.BilanImmoPlus)}
                  defaultChecked={getValue("pack") === AppPacks.BilanImmoPlus}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de calcul de TVA</li>
                  <li>Système de récupération de quittance</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation/emprunt/ paiement différés</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Calcul de l'IS</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* BilanImmoTVA */}
        {renderer === "BilanImmoTVA" && (
          <Col md={6} sm={12}>
            <CardPack
              title="PACK IMMO"
              price="249 € HT"
              className="primary"
              tva
            >
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.BilanImmoTVA)}
                  defaultChecked={getValue("pack") === AppPacks.BilanImmoTVA}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de calcul de TVA</li>
                  <li>Système de récupération de quittance</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation/emprunt/ paiement différés</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Calcul de l'IS</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* BilanImmo */}
        {renderer === "BilanImmo" && (
          <Col md={6} sm={12}>
            {/* <CardPack title="PACK IMMO" price="199 € HT" className="primary"> */}
            <CardPack title="PACK IMMO" price="250 € HT" className="primary">
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.BilanImmo)}
                  defaultChecked={getValue("pack") === AppPacks.BilanImmo}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de calcul de TVA</li>
                  <li>Système de récupération de quittance</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation/emprunt/ paiement différés</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Calcul de l'IS</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* Bilan BNC ASSOC + */}
        {/* Bilan BNC ASSOC */}
        {renderer === "BilanBNC" && (
          <>
            <Col md={6} sm={12}>
              <CardPack
                title="PACK BNC ASSO"
                price="349 € HT"
                className="secondary"
              >
                <div className="check-pack check-secondary">
                  <input
                    type="radio"
                    id="pack-1"
                    name="radio-group"
                    onClick={() => setPack(AppPacks.BilanBNCAssoc)}
                    defaultChecked={getValue("pack") === AppPacks.BilanBNCAssoc}
                  />
                  <label htmlFor="pack-1"> </label>
                </div>

                <div className="text-pack">
                  <ul className="list-text">
                    <li>Accès application</li>
                    <li>Récupération des données bancaires</li>
                    <li>Catégorisation des dépenses automatiques</li>
                    <li>Accès page Tableau de bord</li>
                    <li>Note de frais / Encaissement espèces</li>
                    <li>Gestion immobilisation</li>
                    <li>Support & Conseil *</li>
                    <li>Réalisation de votre Bilan **</li>
                    <li>Etablissement de la liasse fiscale</li>
                    <li>
                      Réalisation de la déclaration sociale des indépendants
                      (DSI)
                    </li>
                    <li>
                      Gestion et transmission des données comptables à l'AGA
                    </li>
                  </ul>
                </div>
              </CardPack>
            </Col>
            <Col md={6} sm={12}>
              <CardPack
                title="PACK BNC ASSO +"
                price="449 € HT"
                className="primary"
                tva
              >
                <div className="check-pack check-primary">
                  <input
                    type="radio"
                    id="pack-2"
                    name="radio-group"
                    onClick={() => setPack(AppPacks.BilanBNCAssocPlus)}
                    defaultChecked={
                      getValue("pack") === AppPacks.BilanBNCAssocPlus
                    }
                  />
                  <label htmlFor="pack-2"> </label>
                </div>

                <div className="text-pack">
                  <ul className="list-text">
                    <li>Accès application</li>
                    <li>Récupération des données bancaires</li>
                    <li>Catégorisation des dépenses automatiques</li>
                    <li>Système de calcul de TVA</li>
                    <li>Accès page Tableau de bord</li>
                    <li>Note de frais / Encaissement espèces</li>
                    <li>Gestion immobilisation</li>
                    <li>Support & Conseil *</li>
                    <li>Réalisation de votre Bilan **</li>
                    <li>Etablissement de la liasse fiscale</li>
                    <li>Déclaration de TVA</li>
                    <li>
                      Gestion et transmission des données comptables à l'AGA
                    </li>
                  </ul>
                </div>
              </CardPack>
            </Col>
          </>
        )}

        {/* Bilan BIC */}
        {renderer === "BilanBIC" && (
          <Col md={6} sm={12}>
            <CardPack title="PACK BIC" price="549 € HT" className="primary">
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.BilanBIC)}
                  defaultChecked={getValue("pack") === AppPacks.BilanBIC}
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Accès application</li>
                  <li>Récupération des données bancaires</li>
                  <li>Catégorisation des dépenses automatiques</li>
                  <li>Système de calcul de TVA</li>
                  <li>Accès page Tableau de bord</li>
                  <li>Note de frais / Encaissement espèces</li>
                  <li>Gestion immobilisation</li>
                  <li>Support & Conseil *</li>
                  <li>Réalisation de votre Bilan **</li>
                  <li>Etablissement de la liasse fiscale</li>
                  <li>Réconciliation des créances et dettes</li>
                  <li>Déclaration de TVA</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}

        {/* Bilan ENTREP */}
        {renderer === "BilanENTREP" && (
          <Col md={6} sm={12}>
            <CardPack
              title="Pack micro-entreprise"
              price="149 € HT"
              className="primary"
            >
              <div className="check-pack check-primary">
                <input
                  type="radio"
                  id="pack-1"
                  name="radio-group"
                  onClick={() => setPack(AppPacks.BilanEntrepreneur)}
                  defaultChecked={
                    getValue("pack") === AppPacks.BilanEntrepreneur
                  }
                />
                <label htmlFor="pack-1"> </label>
              </div>

              <div className="text-pack">
                <ul className="list-text">
                  <li>Récupération des données bancaires</li>
                  <li>Encaissement en espèce</li>
                  <li>Tableau de bord/gestion de trésorerie</li>
                  <li>Déclaration chiffre d’affaires à l’Urssaf</li>
                  <li>Support et conseil</li>
                </ul>
              </div>
            </CardPack>
          </Col>
        )}
      </Row>
      {renderer !== "ERROR" &&
        renderer !== "CHECK_TVA" &&
        renderer !== "BilanCHECK_TVA" && (
          <div
            className={`checkform-box check-secondary ${
              renderer !== "BNC" ? "text-center" : ""
            }`}
          >
            <input
              className="styled-checkbox"
              id="checkbox-5"
              type="checkbox"
              value="value2"
              onClick={() => {
                setClikced(false);
                if (getValue("packOk")) {
                  deleteValue("packOk");
                }
                setValue("OK", "packOk");
              }}
            />
            <label htmlFor="checkbox-5">
              Je reconnais avoir lu les conditions générales de vente.
            </label>
            {clicked && (
              <p className="text-danger">
                Veuillez confirmer avoir pris connaissance des CGV
              </p>
            )}
          </div>
        )}
      {/* </form> */}
    </div>
  );
};

export default RegisterStepFour;
