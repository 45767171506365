import React, { useState, FormEvent } from "react";

import "./RegisterStepFive.scss";
import keyImg from "../../../../assets/images/svg/key.svg";
import sendImg from "../../../../assets/images/svg/send.svg";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import useAxios from "../../../../util/hooks/useAxios";
import { ErrorLogger } from "../../../../util/errorLogger";
import config from "../../../../config";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import juridiqueIcons from "../../../../assets/images/svg/juridique-icon.svg";

import * as yup from "yup";
import { useSelector } from "react-redux";
import { User } from "../../../../interfaces";

type FormValues = {
  email: string;
  password: string;
  password_repeat: string;
};

type NewCompanyFormValues = {
  email: string;
  name: string;
  siren: string;
};

const { APPLICATION_ID, BaseURL } = config[process.env.NODE_ENV];

const emailSchema = yup.string().email();

const passwordSchema = yup
  .string()
  .matches(
    new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
  );

const RegisterStepFive: React.FC = () => {
  const { getValue, setValue, deleteValue } = useLocalStorage();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const nextStep = () => {};
  const prevStep = () => {
    deleteValue("pack");
    deleteValue("packOk");
    deleteValue("email");
    deleteValue("password");
    let url = "/register/pack";
    if (location && location.search) {
      url += location.search;
    }
    navigate(url);
  };

  const { user: connectedUser } = useSelector(
    (state: { root: object; user: object }) => state.user
  ) as { user: User };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const {
    register: _register,
    handleSubmit: _handleSubmit,
    formState: { errors: _errors },
  } = useForm<NewCompanyFormValues>();

  const { ref: emailRef, ...email } = register("email");
  const { ref: passwordRef, ...password } = register("password");
  const { ref: passwordRepeatRef, ...password_repeat } =
    register("password_repeat");
  let api = useAxios();

  const { ref: companyEmailRef, ...companyEmail } = _register("email");
  const { ref: sirenRef, ...siren } = _register("siren");
  const { ref: nameRef, ...name } = _register("name");

  const onSubmit: SubmitHandler<FormValues> = async (payload) => {
    try {
      setErrorMessage(null);
      if (!payload.password || !payload.password_repeat || !payload.email) {
        setErrorMessage("Veuillez insérer toutes les données nécessaires");
        return;
      }
      if (!emailSchema.isValidSync(payload.email)) {
        return setErrorMessage("Vous devez fournir une adresse email valide");
      }
      if (!passwordSchema.isValidSync(payload.password)) {
        return setErrorMessage(
          "Le mot de passe doit contenir au moins 8 caractères, au moins un majuscule, un minuscule, un chiffre et un caractère spécial de '! @ # $% ^ & *'"
        );
      }
      if (
        payload.password.toLowerCase() !== payload.password_repeat.toLowerCase()
      ) {
        setErrorMessage("Les mots de passe ne correspondent pas");
        return;
      }
      setLoading(true);
      const { data } = await api.post(`/api/auth/verifyUserExists`, {
        email: payload.email,
      });
      if (data.exists) {
        setLoading(false);
        setErrorMessage("Cet email existe déjà, essayez-en un autre.");
        return;
      }
      const { data: checkoutSession } = await api.post(
        `/api/payment/createCheckoutSession`,
        {
          success_url: `${BaseURL}/register/succes-paiement`,
          cancel_url: `${BaseURL}/register/erreur-paiement`,
          customer_email: payload.email,
          metadata: {
            email: payload.email,
            password: payload.password,
            pack: getValue("pack") || "pack-BIC",
            activity: getValue("ActivityType"),
            declaration: getValue("declaration") || null,
            application_id: APPLICATION_ID,
            dischargeTax: getValue("dischargeTax") || null,
          },
        }
      );
      if (!checkoutSession.session || !checkoutSession.session.url) {
        setLoading(false);
        setErrorMessage("Problème de paiement, veuillez réessayer plus tard");
        return;
      }

      setTimeout(() => {
        setSuccessMessage(
          "Vous allez être redirigé vers une page de paiement, veuillez patienter."
        );
      }, 3000);

      setValue(payload.email, "email");
      setValue(payload.password, "password");

      setLoading(false);

      window.location.href = checkoutSession.session.url;
    } catch (error: any) {
      setLoading(false);
      ErrorLogger("register form", error);
      setErrorMessage("Problème de paiement, veuillez réessayer plus tard");
    }
  };

  const onNewCompanySubmit: SubmitHandler<NewCompanyFormValues> = async (
    payload
  ) => {
    try {
      setErrorMessage(null);
      if (!payload.siren || !payload.name || !payload.email) {
        setErrorMessage("Veuillez insérer toutes les données nécessaires");
        return;
      }
      setLoading(true);

      const { data: checkoutSession } = await api.post(
        `/api/payment/createCheckoutSession`,
        {
          success_url: `${BaseURL}/register/succes-paiement${location.search}`,
          cancel_url: `${BaseURL}/register/erreur-paiement${location.search}`,
          customer_email: connectedUser.email,
          metadata: {
            email: payload.email,
            name: payload.name,
            siren: payload.siren,
            pack: getValue("pack") || "pack-BIC",
            activity: getValue("ActivityType"),
            declaration: getValue("declaration") || null,
            application_id: APPLICATION_ID,
            dischargeTax: getValue("dischargeTax") || null,
            existing_user : connectedUser.id
          },
        }
      );
      if (!checkoutSession.session || !checkoutSession.session.url) {
        setLoading(false);
        setErrorMessage("Problème de paiement, veuillez réessayer plus tard");
        return;
      }

      setTimeout(() => {
        setSuccessMessage(
          "Vous allez être redirigé vers une page de paiement, veuillez patienter."
        );
      }, 3000);

      setValue(payload.email, "email");
      setValue(payload.siren, "siren");
      setValue(payload.name, "name");

      setLoading(false);

      window.location.href = checkoutSession.session.url;
    } catch (error: any) {
      setLoading(false);
      ErrorLogger("register form", error);
      setErrorMessage("Problème de paiement, veuillez réessayer plus tard");
    }
  };

  return (
    <div className="register-stepFive col-lg-7 col-md-12 mx-auto">
      <div className="top-Login">
        <h1>Bienvenue !</h1>
        <p>Veuillez créer vos identifiants</p>
      </div>
      {location && location.search.includes("existing_user") ? (
        <form
          className="connexion-form"
          onSubmit={_handleSubmit(onNewCompanySubmit)}
        >
          <FormGroup className="form-icon icon-start">
            <Input
              id="email"
              {...companyEmail}
              onChange={() => {
                setErrorMessage(null);
              }}
              innerRef={companyEmailRef}
              placeholder="Email de l'entreprise"
              type="text"
              className="form-secondary"
            />
            <span className="icon icon-secondary ">
              <img src={sendImg} alt="icon" />
            </span>
          </FormGroup>
          <FormGroup className="form-icon icon-start">
            <Input
              id="email"
              {...name}
              onChange={() => {
                setErrorMessage(null);
              }}
              innerRef={nameRef}
              placeholder="Nom commercial"
              type="text"
              className="form-secondary"
            />
            <span className="icon icon-secondary ">
              <img src={juridiqueIcons} alt="icon" />
            </span>
          </FormGroup>
          <FormGroup className="form-icon icon-start">
            <Input
              id="email"
              {...siren}
              onChange={() => {
                setErrorMessage(null);
              }}
              innerRef={sirenRef}
              placeholder="Numéro de SIREN/SIRET"
              type="text"
              className="form-secondary"
            />
            <span className="icon icon-secondary ">
              <img src={juridiqueIcons} alt="icon" />
            </span>
          </FormGroup>
          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          {successMessage && <Alert color="info">{successMessage}</Alert>}
          <div className="bottomSteperCenter">
            <Button
              type="button"
              onClick={prevStep}
              color="primary"
              outline
              className="btn btn-prev "
              disabled={loading}
            >
              <FiChevronsLeft />
              <span>Précédent</span>
            </Button>
            <Button
              type="submit"
              onClick={nextStep}
              color="primary"
              className="btn btn-next "
              disabled={loading}
            >
              <span>
                {loading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Suivant"
                )}
              </span>
              <FiChevronsRight />
            </Button>
          </div>
        </form>
      ) : (
        <form className="connexion-form" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup className="form-icon icon-start">
            <Input
              id="email"
              {...email}
              onChange={() => {
                setErrorMessage(null);
              }}
              innerRef={emailRef}
              placeholder="Email"
              type="text"
              className="form-secondary"
            />
            <span className="icon icon-secondary ">
              <img src={sendImg} alt="icon" />
            </span>
          </FormGroup>
          <FormGroup className="form-icon icon-start">
            <Input
              id="password"
              {...password}
              onChange={() => {
                setErrorMessage(null);
              }}
              innerRef={passwordRef}
              placeholder="Mot de passe"
              type="password"
              className="form-secondary"
            />

            <span className="icon icon-secondary ">
              <img src={keyImg} alt="icon" />
            </span>
          </FormGroup>
          <FormGroup className="form-icon icon-start">
            <Input
              id="password_repeat"
              {...password_repeat}
              onChange={() => {
                setErrorMessage(null);
              }}
              innerRef={passwordRepeatRef}
              placeholder="Confirmez votre mot de passe"
              type="password"
              className="form-secondary"
            />

            <span className="icon icon-secondary ">
              <img src={keyImg} alt="icon" />
            </span>
          </FormGroup>
          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          {successMessage && <Alert color="info">{successMessage}</Alert>}
          <div className="bottomSteperCenter">
            <Button
              type="button"
              onClick={prevStep}
              color="primary"
              outline
              className="btn btn-prev "
              disabled={loading}
            >
              <FiChevronsLeft />
              <span>Précédent</span>
            </Button>
            <Button
              type="submit"
              onClick={nextStep}
              color="primary"
              className="btn btn-next "
              disabled={loading}
            >
              <span>
                {loading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Suivant"
                )}
              </span>
              <FiChevronsRight />
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default RegisterStepFive;
