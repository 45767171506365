import { useState } from "react";

const useLocalStorage = () => {
  const setValue = (value: any, key: string) => {
    try {
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const clearAll = (exceptions: string[] = []) => {
    try {
      let items: string | any[] = [];
      if (exceptions.length > 0) {
        items = exceptions.map((elt) => ({ key: elt, value: getValue(elt) }));
      }
      if (typeof window !== "undefined") {
        window.localStorage.clear();
      }

      if (items.length > 0) {
        for (let item of items) {
          setValue(item.value, item.key);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getValue = (key: string) => {
    try {
      if (typeof window !== "undefined") {
        return JSON.parse(window.localStorage.getItem(key) as string);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteValue = (key: string) => {
    try {
      if (typeof window !== "undefined") {
        window.localStorage.removeItem(key);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateValue = (value: any, key: string, attribute: string = "") => {
    try {
      if (typeof window !== "undefined") {
        let storedItem = getValue(key);
        if (attribute !== "") {
          storedItem = { ...storedItem, attribute: value };
          setValue(storedItem, key);
        } else {
          deleteValue(key);
          setValue(value, key);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return {
    setValue,
    getValue,
    updateValue,
    deleteValue,
    clearAll,
  };
};

export { useLocalStorage };
