import React, { useState, FormEvent, useEffect } from "react";

import "./RegisterStepOne.scss";
import homeIcon from "../../../../assets/images/icons/home-icon.png";
import QuestionIcon from "../../../../assets/images/icons/question.png";
import FuseIcon from "../../../../assets/images/icons/fuse.png";
import BilanIcon from "../../../../assets/images/Bilan.png";
import { Col, Form, Row } from "reactstrap";
import { RiArrowRightLine } from "react-icons/ri";
import CardImage from "../../../../components/CardImage/CardImage";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import { useLocation, useNavigate } from "react-router-dom";

const VALUE = "CompanyType";
const STEP_VALUE = "STEP";

const RegisterStepOne = ({ onClick }: { onClick: any }) => {
  function submit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
  }
  const navigate = useNavigate();
  const { setValue, deleteValue } = useLocalStorage();
  const location = useLocation()

  const nextStep = () => {
    deleteValue("CompanyType");
    let url  = "/register/nous-contacter"
    if(location && location.search){
      url += location.search
    }
    navigate(url);
  };

  return (
    <div className="register-stepOne col-lg-10 col-md-12 mx-auto">
      <Form>
        <Row>
          <Col md={3} sm={12}>
            <CardImage
              icon={homeIcon}
              title="Ma structure existe"
              onClick={() => {
                setValue("company", VALUE);
                onClick();
              }}
            >
              <label className="btnBottom">
                <RiArrowRightLine />
                <input type="radio" name="rado-company" />
              </label>
            </CardImage>
          </Col>
          <Col md={3} sm={12}>
            <CardImage
              icon={QuestionIcon}
              title="Je n'ai pas de structure existante"
              onClick={() => {
                nextStep();
              }}
            >
              <label className="btnBottom">
                <RiArrowRightLine />
                <input type="radio" name="rado-company" />
              </label>
            </CardImage>
          </Col>
          <Col md={3} sm={12}>
            <CardImage
              icon={FuseIcon}
              title="Je suis une micro-entreprise"
              onClick={() => {
                setValue("entrep", VALUE);
                onClick();
              }}
            >
              <label className="btnBottom">
                <RiArrowRightLine />
                <input type="radio" name="rado-company" />
              </label>
            </CardImage>
          </Col>
          <Col md={3} sm={12}>
            <CardImage
              icon={BilanIcon}
              title="Bilan express"
              onClick={() => {
                setValue("bilan", VALUE);
                onClick();
              }}
            >
              <label className="btnBottom">
                <RiArrowRightLine />
                <input type="radio" name="rado-company" />
              </label>
            </CardImage>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default RegisterStepOne;
