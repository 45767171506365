import React from "react";

interface PhoneProps {
  className?: string;
}

const PhoneIcon = ({ className }: PhoneProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="33.292"
    height="36.739"
    viewBox="0 0 33.292 36.739"
  >
    <defs>
      <filter
        id="Tracé_81"
        x="0"
        y="0"
        width="33.292"
        height="36.739"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="1.5" result="blur" />
        <feFlood floodColor="#50b2dc" strokeWidth="0.541" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="Tracé_82"
        x="12.988"
        y="0.832"
        width="19.493"
        height="22.985"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="1.5" result="blur-2" />
        <feFlood floodColor="#50b2dc" strokeWidth="0.541" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="Tracé_83"
        x="12.983"
        y="4.883"
        width="15.452"
        height="18.945"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="1.5" result="blur-3" />
        <feFlood floodColor="#50b2dc" strokeWidth="0.541" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Groupe_45"
      data-name="Groupe 45"
      transform="translate(426.336 -625.523)"
    >
      <g
        transform="matrix(1, 0, 0, 1, -426.34, 625.52)"
        filter="url(#Tracé_81)"
      >
        <path
          id="Tracé_81-2"
          data-name="Tracé 81"
          d="M-421.836,631.614a5.14,5.14,0,0,1,.25-.607,17.174,17.174,0,0,1,2.5-3.385,22.047,22.047,0,0,1,1.818-1.712,1.416,1.416,0,0,1,2.18.314,28.834,28.834,0,0,1,3.008,4.93,6.587,6.587,0,0,1,.287.807,1.206,1.206,0,0,1-.247,1.208c-.294.333-.631.629-.958.932-.222.206-.452.406-.692.591a.27.27,0,0,0-.091.366,11.035,11.035,0,0,0,4.095,5.353,10.9,10.9,0,0,0,2.616,1.354.275.275,0,0,0,.365-.1q.421-.521.872-1.017a6.407,6.407,0,0,1,.648-.635,1.252,1.252,0,0,1,1.187-.245,9.863,9.863,0,0,1,2.505,1.179c1.041.637,2.057,1.316,3.079,1.982a1.917,1.917,0,0,1,.782.994,1.052,1.052,0,0,1-.086.991,8.293,8.293,0,0,1-.738.99,21.946,21.946,0,0,1-4.281,3.523,2.4,2.4,0,0,1-1.909.246,17.416,17.416,0,0,1-6.114-2.619,27.761,27.761,0,0,1-9.111-9.645,15.727,15.727,0,0,1-1.821-4.794C-421.745,632.313-421.779,632-421.836,631.614Zm5.486-4.418c-.064.045-.087.057-.1.073a21.393,21.393,0,0,0-3.647,4.309.522.522,0,0,0-.059.327,12.317,12.317,0,0,0,1.367,4.072,25.7,25.7,0,0,0,10.156,10.461,13.124,13.124,0,0,0,4.695,1.7.665.665,0,0,0,.415-.095,20.618,20.618,0,0,0,3.26-2.588c.361-.349.7-.717,1.067-1.09-.115-.089-.2-.159-.286-.223a29.368,29.368,0,0,0-4.534-2.689.32.32,0,0,0-.461.1c-.457.54-.935,1.061-1.4,1.6a1.045,1.045,0,0,1-1.207.3,12.107,12.107,0,0,1-4.786-2.727,12.317,12.317,0,0,1-3.588-5.641,1.06,1.06,0,0,1,.312-1.228c.279-.243.545-.5.822-.748.246-.218.49-.439.751-.638a.33.33,0,0,0,.088-.5c-.651-1.139-1.278-2.292-1.939-3.425C-415.7,628.076-416.035,627.652-416.35,627.2Z"
          transform="translate(426.34 -625.52)"
          fill="#50b2dc"
        />
      </g>
      <g
        transform="matrix(1, 0, 0, 1, -426.34, 625.52)"
        filter="url(#Tracé_82)"
      >
        <path
          id="Tracé_82-2"
          data-name="Tracé 82"
          d="M-218.759,648.352h-1.6a8.839,8.839,0,0,0-2.615-6.268,8.827,8.827,0,0,0-6.279-2.631V637.87A10.483,10.483,0,0,1-218.759,648.352Z"
          transform="translate(246.74 -637.03)"
          fill="#50b2dc"
        />
      </g>
      <g
        transform="matrix(1, 0, 0, 1, -426.34, 625.52)"
        filter="url(#Tracé_83)"
      >
        <path
          id="Tracé_83-2"
          data-name="Tracé 83"
          d="M-229.315,699.514c0-.534,0-1.053,0-1.573a6.415,6.415,0,0,1,6.452,6.436h-1.6a4.878,4.878,0,0,0-1.44-3.434A4.855,4.855,0,0,0-229.315,699.514Z"
          transform="translate(246.8 -693.05)"
          fill="#50b2dc"
        />
      </g>
    </g>
  </svg>
);

export default PhoneIcon;
