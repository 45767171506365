import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import config from "../../config";
import {
  resetUtil,
  setCounter,
  setReloadTickets,
} from "../../store/reducers/utils/utilSlice";
import { UserTypes } from "../context";
import { deleteWhere } from "../helpers/utilities";
import { persistor } from "../../store";
import { resetRoot } from "../../store/reducers/root/rootSlice";
import { resetApplications } from "../../store/reducers/applications/applicationsSlice";
import { resetCategories } from "../../store/reducers/categories/categoriesSlice";
import { resetClientsList } from "../../store/reducers/clients-list/clientsSlice";
import { resetCompaniesList } from "../../store/reducers/companies-list/companiesSlice";
import { resetPermissionsList } from "../../store/reducers/permissions-list/permissionsListSlice";
import { resetUser } from "../../store/reducers/user/userSlice";
import { resetNotifs } from "../../store/reducers/messages-notifs/messagesNotifsSlice";
import { useNavigate } from "react-router-dom";

const { API_URL } = config[process.env.NODE_ENV];

const useAxios = () => {
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: { "x-access-token": creds.token },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const leave = async () => {
    try {
      persistor.purge();
      dispatch(resetRoot());
      dispatch(resetApplications());
      dispatch(resetCategories());
      dispatch(resetClientsList());
      dispatch(resetCompaniesList());
      dispatch(resetPermissionsList());
      dispatch(resetUser());
      dispatch(resetNotifs());
      dispatch(resetUtil());
      dispatch(setReloadTickets(false));
      navigate("/login");
    } catch (error: any) {
      console.log("token invalid : ", error);
    }
  };

  axiosInstance.interceptors.response.use(
    async (res: any) => {
      return res;
    },
    (error: any) => {
      if (
        (creds.token &&
          creds.token !== "" &&
          !error.response.data.auth &&
          error.response.data.message &&
          error.response.data.message
            .toLowerCase()
            .includes("token rejected")) ||
        (error.response.data.message &&
          error.response.data.message
            .toLowerCase()
            .includes("failed to authenticate token."))
      ) {
        leave();
        toast.warn("Votre session a expiré, veuillez vous connecter ", {
          closeButton: false,
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          toastId: "toast-2022",
          autoClose: 4000,
        });
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxios;
