import { AnimatePresence } from "framer-motion";
import React from "react";
import { FiChevronsRight } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import RegisterStepOne from "./RegisterStepOne";

const StepOne = () => {
  const { getValue } = useLocalStorage();
  const navigate = useNavigate();
  const location = useLocation()
  const nextStep = () => {
    if (getValue("CompanyType")) {
      let url  = "/register/activite"
      if(location && location.search){
        url += location.search
      }
      navigate(url);
    }
  };
  return (
    <>
      <AnimatePresence>
        <RegisterStepOne onClick={nextStep} />
      </AnimatePresence>
      <div className="bottomSteperCenter">
        <Button
          type="button"
          onClick={nextStep}
          color="primary"
          className="btn btn-next "
          // disabled={!getValue("CompanyType")}
        >
          <span>Suivant</span>
          <FiChevronsRight />
        </Button>
      </div>
    </>
  );
};

export default StepOne;
