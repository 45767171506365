import React from "react";
import { persistStore } from "redux-persist";
import ReactDOMClient from "react-dom/client";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { pdfjs } from "react-pdf";
import { Worker } from "@react-pdf-viewer/core";
import { ToastContainer } from "react-toastify";

const container: any = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

let persistor = persistStore(store);

// worker version @2.14.305 must match the 'pdfjs-dist' version in package.json

root.render(
  <>
    <Worker
      workerUrl={`https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js`}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ToastContainer autoClose={4000} />
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </Worker>
  </>
);
