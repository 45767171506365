const images = {
  logo: require("../assets/images/logo.svg").default,
  avt: require("../assets/images/avatar.png"),
  imgDashboard: require("../assets/images/img-dashboard.png"),
  imgInvoice: require("../assets/images/img-invoice.png"),
  imgBilan: require("../assets/images/img-bilan.png"),
  imgImmobilisation: require("../assets/images/img-immob.png"),
  imgIndemnity: require("../assets/images/img-indem.png"),
  imgBanner: require("../assets/images/man-sitting.png"),
  imgBannerB1: require("../assets/images/banner/b1.png"),
  imgBannerProspects: require("../assets/images/banner/imgBannerProspects.png"),
  imgBannerClients: require("../assets/images/banner/imgBannerClients.png"),
  imgBannerAdmin: require("../assets/images/banner/imgBannerAdmin.png"),
  imgBannerAccounting: require("../assets/images/banner/imgBannerAccounting.png"),
  imgSuiviTva: require("../assets/images/img-immob.png"),
  imgBannerTickets: require("../assets/images/banner/imgBannerTickets.png"),
  imgBannerMailing: require("../assets/images/banner/imgBannerMailing.png"),
  imgBannerQuittance: require("../assets/images/quittance-img.png"),
  car: require("../assets/images/banner/voiture.png"),
  motorcycle: require("../assets/images/banner/motocyclettes.png"),
  moped: require("../assets/images/banner/cyclomoteurs.png"),
};

export default images;
