import React, { useState, FormEvent, useEffect, useReducer } from "react";

import "./RegisterStepThree.scss";
import AssocIcon from "../../../../assets/images/svg/asso-icon.svg";
import CardRegister from "../../../../components/CardRegister/CardRegister";
import { RiArrowRightLine, RiInformationFill } from "react-icons/ri";
import Switch from "../../../../components/RegisterSwitch/Switch";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import { JsxElement } from "typescript";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import { stat } from "fs/promises";
import { useLocation, useNavigate } from "react-router-dom";

const VALUE = "ActivityType";
const SET_PACK_VALUE = "set_pack";
const STEP_VALUE = "STEP";
const DISCHARGE_TAX_VALUE = "dischargeTax";
const DECLERATION_VALUE = "declaration";
const ANSWERS_OBJ = "AnswersObject";

type Question = {
  question: string | JSX.Element;
  options: string[];
  popOver?: string;
  optionsPopOvers?: string[];
  onClick: () => void;
};

type ActivityQuestions = {
  activityType: string;
  questions: Question[];
};

type ReducerAction = {
  type: string;
  payload: {
    ref: number;
    value?: string | boolean;
  };
};

const RegisterStepThree: React.FC = () => {
  function submit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
  }
  const navigate = useNavigate();
  const location = useLocation()
  const { setValue, getValue, deleteValue } = useLocalStorage();

  const [questionsType, setQuestionsType] = useState<ActivityQuestions[]>([]);

  const CompanyQuestions: ActivityQuestions[] = [
    {
      activityType: "commercial",
      questions: [
        {
          question: "Vous avez opté pour :",
          options: ["l'impôt sur les sociétés", "l'impôt sur le revenu"],
          onClick: () => {
            dispatch({
              type: "commercial",
              payload: {
                ref: 1,
              },
            });
          },
        },

        {
          question: "Exercez-vous une activité d'achats reventes?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "commercial",
              payload: {
                ref: 2,
              },
            });
          },
        },
        {
          question: "Exercez-vous une activité de prestation de service?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "commercial",
              payload: {
                ref: 3,
              },
            });
          },
        },
        {
          question: "Votre chiffre d'affaires est inférieur à 818 k par an?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "commercial",
              payload: {
                ref: 4,
              },
            });
          },
        },
        {
          question: "Votre chiffre d'affaires est inférieur à 247 k par an?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "commercial",
              payload: {
                ref: 5,
              },
            });
          },
        },
      ],
    },

    /* Bilan commercial questions */
    {
      activityType: "bilancommercial",
      questions: [
        {
          question: "Vous avez opté pour :",
          options: ["l'impôt sur les sociétés", "l'impôt sur le revenu"],
          onClick: () => {
            dispatch({
              type: "bilancommercial",
              payload: {
                ref: 1,
              },
            });
          },
        },

        {
          question: "Exercez-vous une activité d'achats reventes?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "bilancommercial",
              payload: {
                ref: 2,
              },
            });
          },
        },
        {
          question: "Exercez-vous une activité de prestation de service?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "bilancommercial",
              payload: {
                ref: 3,
              },
            });
          },
        },
        {
          question: "Votre chiffre d'affaires est inférieur à 818 k par an?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "bilancommercial",
              payload: {
                ref: 4,
              },
            });
          },
        },
        {
          question: "Votre chiffre d'affaires est inférieur à 247 k par an?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "bilancommercial",
              payload: {
                ref: 5,
              },
            });
          },
        },
      ],
    },

    {
      activityType: "association",
      questions: [
        {
          question: "L'association est-elle reconnue d'utilité publique?",
          options: ["Non", "Oui"],
          popOver:
            "Pour en savoir plus sur la notion d’intérêt public : https://www.service-public.fr/associations/vosdroits/F1131",
          onClick: () => {
            dispatch({
              type: "association",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question:
            "L'association émet-elle des valeurs mobilières de placement ?",
          options: ["Non", "Oui"],
          popOver:
            "Titres négociables interchangeables et fongibles, pouvant être cotées en bourse",
          onClick: () => {
            dispatch({
              type: "association",
              payload: {
                ref: 2,
              },
            });
          },
        },
        {
          question:
            "L'association reçoit-elle plus de 153 000 € de subventions par an ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "association",
              payload: {
                ref: 3,
              },
            });
          },
        },
        {
          question: "L'association exerce-t-elle une activité économique?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "association",
              payload: {
                ref: 4,
              },
            });
          },
        },
      ],
    },

    /* Bilan association */

    {
      activityType: "bilanassociation",
      questions: [
        {
          question: "L'association est-elle reconnue d'utilité publique?",
          options: ["Non", "Oui"],
          popOver:
            "Pour en savoir plus sur la notion d’intérêt public : https://www.service-public.fr/associations/vosdroits/F1131",
          onClick: () => {
            dispatch({
              type: "bilanassociation",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question:
            "L'association émet-elle des valeurs mobilières de placement ?",
          options: ["Non", "Oui"],
          popOver:
            "Titres négociables interchangeables et fongibles, pouvant être cotées en bourse",
          onClick: () => {
            dispatch({
              type: "bilanassociation",
              payload: {
                ref: 2,
              },
            });
          },
        },
        {
          question:
            "L'association reçoit-elle plus de 153 000 € de subventions par an ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "bilanassociation",
              payload: {
                ref: 3,
              },
            });
          },
        },
        {
          question: "L'association exerce-t-elle une activité économique?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "bilanassociation",
              payload: {
                ref: 4,
              },
            });
          },
        },
      ],
    },

    {
      activityType: "eirl",
      questions: [
        {
          question: "Quel régime fiscal?",
          options: ["BIC", "BNC"],
          optionsPopOvers: [
            "Bénéfices industriels et commerciaux. Cette information se trouve sur le formulaire M0",
            "Bénéfices non commerciaux. Cette information se trouve sur le formulaire M0",
          ],
          onClick: () => {
            dispatch({
              type: "eirl",
              payload: {
                ref: 1,
              },
            });
          },
        },
      ],
    },
    /* Bilan eirl */

    {
      activityType: "bilaneirl",
      questions: [
        {
          question: "Quel régime fiscal?",
          options: ["BIC", "BNC"],
          optionsPopOvers: [
            "Bénéfices industriels et commerciaux. Cette information se trouve sur le formulaire M0",
            "Bénéfices non commerciaux. Cette information se trouve sur le formulaire M0",
          ],
          onClick: () => {
            dispatch({
              type: "bilaneirl",
              payload: {
                ref: 1,
              },
            });
          },
        },
      ],
    },

    {
      activityType: "civilSociety",
      questions: [
        {
          question: <p>Vous avez opté pour :</p>,
          options: ["l’impôt sur les sociétés", "l’impôt sur le revenu"],
          onClick: () => {
            dispatch({
              type: "civilSociety",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question: <p>Assujettis à la TVA ? </p>,
          options: ["Oui", "Non"],
          onClick: () => {
            dispatch({
              type: "civilSociety",
              payload: {
                ref: 2,
              },
            });
          },
        },
      ],
    },

    /* Bilan civilSociety */
    {
      activityType: "bilancivilSociety",
      questions: [
        {
          question: <p>Vous avez opté pour :</p>,
          options: ["l’impôt sur les sociétés", "l’impôt sur le revenu"],
          onClick: () => {
            dispatch({
              type: "bilancivilSociety",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question: <p>Assujettis à la TVA ? </p>,
          options: ["Oui", "Non"],
          onClick: () => {
            dispatch({
              type: "bilancivilSociety",
              payload: {
                ref: 2,
              },
            });
          },
        },
      ],
    },

    {
      activityType: "bilanworksCouncil",
      questions: [
        {
          question:
            "Le comité d'entreprise dispose-t-il de ressources supérieures à 153 000 € ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "worksCouncil",
              payload: {
                ref: 1,
              },
            });
          },
        },
      ],
    },

    /* Bilan workscouncil */

    {
      activityType: "bilanworksCouncil",
      questions: [
        {
          question:
            "Le comité d'entreprise dispose-t-il de ressources supérieures à 153 000 € ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "worksCouncil",
              payload: {
                ref: 1,
              },
            });
          },
        },
      ],
    },
  ];

  const EntrepQuestions: ActivityQuestions[] = [
    {
      activityType: "marchendise",
      questions: [
        {
          question: "Avez-vous opté pour le prélèvement libératoire ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "marchendise",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question:
            "Quelle est la fréquence de votre  déclaration de votre chiffre d’affaires à l’Urssaff ?",
          options: ["Trimestrielle", "Mensuelle"],
          onClick: () => {
            dispatch({
              type: "marchendise",
              payload: {
                ref: 2,
              },
            });
          },
        },
      ],
    },

    /* Bilan marchendise */

    {
      activityType: "bilanmarchendise",
      questions: [
        {
          question: "Avez-vous opté pour le prélèvement libératoire ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "bilanmarchendise",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question:
            "Quelle est la fréquence de votre  déclaration de votre chiffre d’affaires à l’Urssaff ?",
          options: ["Trimestrielle", "Mensuelle"],
          onClick: () => {
            dispatch({
              type: "bilanmarchendise",
              payload: {
                ref: 2,
              },
            });
          },
        },
      ],
    },
    {
      activityType: "prestation",
      questions: [
        {
          question: "Avez-vous opté pour le prélèvement libératoire ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "prestation",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question:
            "Quelle est la fréquence de votre  déclaration de votre chiffre d’affaires à l’Urssaff ?",
          options: ["Trimestrielle", "Mensuelle"],
          onClick: () => {
            dispatch({
              type: "prestation",
              payload: {
                ref: 2,
              },
            });
          },
        },
      ],
    },

    /* Bilan prestation */

    {
      activityType: "bilanprestation",
      questions: [
        {
          question: "Avez-vous opté pour le prélèvement libératoire ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "bilanprestation",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question:
            "Quelle est la fréquence de votre  déclaration de votre chiffre d’affaires à l’Urssaff ?",
          options: ["Trimestrielle", "Mensuelle"],
          onClick: () => {
            dispatch({
              type: "bilanprestation",
              payload: {
                ref: 2,
              },
            });
          },
        },
      ],
    },
    {
      activityType: "autresPrestations",
      questions: [
        {
          question: "Avez-vous opté pour le prélèvement libératoire ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "autresPrestations",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question:
            "Quelle est la fréquence de votre  déclaration de votre chiffre d’affaires à l’Urssaff ?",
          options: ["Trimestrielle", "Mensuelle"],
          onClick: () => {
            dispatch({
              type: "autresPrestations",
              payload: {
                ref: 2,
              },
            });
          },
        },
      ],
    },

    /* Bilan autres prestations */

    {
      activityType: "bilanautresPrestations",
      questions: [
        {
          question: "Avez-vous opté pour le prélèvement libératoire ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "bilanautresPrestations",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question:
            "Quelle est la fréquence de votre  déclaration de votre chiffre d’affaires à l’Urssaff ?",
          options: ["Trimestrielle", "Mensuelle"],
          onClick: () => {
            dispatch({
              type: "bilanautresPrestations",
              payload: {
                ref: 2,
              },
            });
          },
        },
      ],
    },

    {
      activityType: "profession",
      questions: [
        {
          question: "Avez-vous opté pour le prélèvement libératoire ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "profession",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question:
            "Quelle est la fréquence de votre  déclaration de votre chiffre d’affaires à l’Urssaff ?",
          options: ["Trimestrielle", "Mensuelle"],
          onClick: () => {
            dispatch({
              type: "profession",
              payload: {
                ref: 2,
              },
            });
          },
        },
      ],
    },

    /* Bilan profession */

    {
      activityType: "bilanprofession",
      questions: [
        {
          question: "Avez-vous opté pour le prélèvement libératoire ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "bilanprofession",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question:
            "Quelle est la fréquence de votre  déclaration de votre chiffre d’affaires à l’Urssaff ?",
          options: ["Trimestrielle", "Mensuelle"],
          onClick: () => {
            dispatch({
              type: "bilanprofession",
              payload: {
                ref: 2,
              },
            });
          },
        },
      ],
    },
    {
      activityType: "location",
      questions: [
        {
          question: "Avez-vous opté pour le prélèvement libératoire ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "location",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question:
            "Quelle est la fréquence de votre  déclaration de votre chiffre d’affaires à l’Urssaff ?",
          options: ["Trimestrielle", "Mensuelle"],
          onClick: () => {
            dispatch({
              type: "location",
              payload: {
                ref: 2,
              },
            });
          },
        },
      ],
    },

    /* Bilan location */

    {
      activityType: "bilanlocation",
      questions: [
        {
          question: "Avez-vous opté pour le prélèvement libératoire ?",
          options: ["Non", "Oui"],
          onClick: () => {
            dispatch({
              type: "bilanlocation",
              payload: {
                ref: 1,
              },
            });
          },
        },
        {
          question:
            "Quelle est la fréquence de votre  déclaration de votre chiffre d’affaires à l’Urssaff ?",
          options: ["Trimestrielle", "Mensuelle"],
          onClick: () => {
            dispatch({
              type: "bilanlocation",
              payload: {
                ref: 2,
              },
            });
          },
        },
      ],
    },
  ];

  const [viewModal, setViewModal] = useState<boolean>(false);
  const [singleQuestion, setSingleQuestion] = useState<{
    parentRef: number;
    question: Question;
  } | null>();

  const initialState = {
    commercialQ1: false,
    commercialQ1View: true,
    commercialQ1ViewType: "inline",

    commercialQ2: false,
    commercialQ2View: false,
    commercialQ2ViewType: "inline",

    commercialQ3: false,
    commercialQ3View: false,
    commercialQ3ViewType: "inline",

    commercialQ4: false,
    commercialQ4View: false,
    commercialQ4ViewType: "modal",

    commercialQ5: false,
    commercialQ5View: false,
    commercialQ5ViewType: "modal",

    associationQ1: false,
    associationQ1View: true,
    associationQ1ViewType: "inline",

    associationQ2: false,
    associationQ2View: true,
    associationQ2ViewType: "inline",

    associationQ3: false,
    associationQ3View: true,
    associationQ3ViewType: "inline",

    associationQ4: false,
    associationQ4View: true,
    associationQ4ViewType: "inline",

    eirlQ1: "BIC",
    eirlQ1View: true,
    eirlQ1ViewType: "inline",

    civilSocietyQ1: false,
    civilSocietyQ1View: true,
    civilSocietyQ1ViewType: "inline",

    civilSocietyQ2: false,
    civilSocietyQ2View: true,
    civilSocietyQ2ViewType: "inline",

    // civilSocietyQ3: false,
    // civilSocietyQ3View: false,
    // civilSocietyQ3ViewType: "inline",

    // civilSocietyQ4: false,
    // civilSocietyQ4View: false,
    // civilSocietyQ4ViewType: "modal",

    // civilSocietyQ5: false,
    // civilSocietyQ5View: false,
    // civilSocietyQ5ViewType: "modal",

    worksCouncilQ1: false,
    worksCouncilQ1View: true,
    worksCouncilQ1ViewType: "inline",

    marchendiseQ1: false,
    marchendiseQ1View: true,
    marchendiseQ1ViewType: "inline",

    marchendiseQ2: "trimestrielle",
    marchendiseQ2View: true,
    marchendiseQ2ViewType: "inline",

    prestationQ1: false,
    prestationQ1View: true,
    prestationQ1ViewType: "inline",

    prestationQ2: "trimestrielle",
    prestationQ2View: true,
    prestationQ2ViewType: "inline",

    autresPrestationsQ1: false,
    autresPrestationsQ1View: true,
    autresPrestationsQ1ViewType: "inline",

    autresPrestationsQ2: "trimestrielle",
    autresPrestationsQ2View: true,
    autresPrestationsQ2ViewType: "inline",

    professionQ1: false,
    professionQ1View: true,
    professionQ1ViewType: "inline",

    professionQ2: "trimestrielle",
    professionQ2View: true,
    professionQ2ViewType: "inline",

    locationQ1: false,
    locationQ1View: true,
    locationQ1ViewType: "inline",

    locationQ2: "trimestrielle",
    locationQ2View: true,
    locationQ2ViewType: "inline",

    declaration: "Mensuelle",
    dischargeTax: false,

    // Bilan express Initial states

    bilancommercialQ1: false,
    bilancommercialQ1View: true,
    bilancommercialQ1ViewType: "inline",

    bilancommercialQ2: false,
    bilancommercialQ2View: false,
    bilancommercialQ2ViewType: "inline",

    bilancommercialQ3: false,
    bilancommercialQ3View: false,
    bilancommercialQ3ViewType: "inline",

    bilancommercialQ4: false,
    bilancommercialQ4View: false,
    bilancommercialQ4ViewType: "modal",

    bilancommercialQ5: false,
    bilancommercialQ5View: false,
    bilancommercialQ5ViewType: "modal",

    bilanassociationQ1: false,
    bilanassociationQ1View: true,
    bilanassociationQ1ViewType: "inline",

    bilanassociationQ2: false,
    bilanassociationQ2View: true,
    bilanassociationQ2ViewType: "inline",

    bilanassociationQ3: false,
    bilanassociationQ3View: true,
    bilanassociationQ3ViewType: "inline",

    bilanassociationQ4: false,
    bilanassociationQ4View: true,
    bilanassociationQ4ViewType: "inline",

    bilaneirlQ1: "BIC",
    bilaneirlQ1View: true,
    bilaneirlQ1ViewType: "inline",

    bilancivilSocietyQ1: false,
    bilancivilSocietyQ1View: true,
    bilancivilSocietyQ1ViewType: "inline",

    bilancivilSocietyQ2: false,
    bilancivilSocietyQ2View: true,
    bilancivilSocietyQ2ViewType: "inline",

    // civilSocietyQ3: false,
    // civilSocietyQ3View: false,
    // civilSocietyQ3ViewType: "inline",

    // civilSocietyQ4: false,
    // civilSocietyQ4View: false,
    // civilSocietyQ4ViewType: "modal",

    // civilSocietyQ5: false,
    // civilSocietyQ5View: false,
    // civilSocietyQ5ViewType: "modal",

    bilanworksCouncilQ1: false,
    bilanworksCouncilQ1View: true,
    bilanworksCouncilQ1ViewType: "inline",

    bilanmarchendiseQ1: false,
    bilanmarchendiseQ1View: true,
    bilanmarchendiseQ1ViewType: "inline",

    bilanmarchendiseQ2: "trimestrielle",
    bilanmarchendiseQ2View: true,
    bilanmarchendiseQ2ViewType: "inline",

    bilanprestationQ1: false,
    bilanprestationQ1View: true,
    bilanprestationQ1ViewType: "inline",

    bilanprestationQ2: "trimestrielle",
    bilanprestationQ2View: true,
    bilanprestationQ2ViewType: "inline",

    bilanautresPrestationsQ1: false,
    bilanautresPrestationsQ1View: true,
    bilanautresPrestationsQ1ViewType: "inline",

    bilanautresPrestationsQ2: "trimestrielle",
    bilanautresPrestationsQ2View: true,
    bilanautresPrestationsQ2ViewType: "inline",

    bilanprofessionQ1: false,
    bilanprofessionQ1View: true,
    bilanprofessionQ1ViewType: "inline",

    bilanprofessionQ2: "trimestrielle",
    bilanprofessionQ2View: true,
    bilanprofessionQ2ViewType: "inline",

    bilanlocationQ1: false,
    bilanlocationQ1View: true,
    bilanlocationQ1ViewType: "inline",

    bilanlocationQ2: "trimestrielle",
    bilanlocationQ2View: true,
    bilanlocationQ2ViewType: "inline",

    bilandeclaration: "Mensuelle",
    bilandischargeTax: false,
  };

  const reducer = (state = initialState as any, action: ReducerAction) => {
    let obj: any = getValue(ANSWERS_OBJ) || {};

    switch (action.type) {
      case "commercial":
        const newCommercialState: any = {};

        newCommercialState[`commercialQ${action.payload.ref}`] = action.payload
          .value
          ? action.payload.value
          : !state[`commercialQ${action.payload.ref}`];

        obj[`commercialQ${action.payload.ref}`] =
          newCommercialState[`commercialQ${action.payload.ref}`];

        if (action.payload.ref === 1) {
          newCommercialState[`commercialQ2View`] =
            !state[`commercialQ${action.payload.ref}`];
          newCommercialState[`commercialQ3View`] =
            !state[`commercialQ${action.payload.ref}`];
        }

        if (
          action.payload.ref === 2 &&
          String(!state[`commercialQ${action.payload.ref}`]) === "true"
        ) {
          newCommercialState[`commercialQ4View`] = true;
          setSingleQuestion({
            parentRef: 2,
            question: CompanyQuestions[0].questions[3],
          });
          setViewModal(true);
        }

        if (
          action.payload.ref === 3 &&
          String(!state[`commercialQ${action.payload.ref}`]) === "true"
        ) {
          newCommercialState[`commercialQ5View`] = true;
          setSingleQuestion({
            parentRef: 3,
            question: CompanyQuestions[0].questions[4],
          });
          setViewModal(true);
        }

        setValue(obj, ANSWERS_OBJ);

        return { ...state, ...newCommercialState };

      case "association":
        const newAssociationState: any = {};
        newAssociationState[`associationQ${action.payload.ref}`] = action
          .payload.value
          ? action.payload.value
          : !state[`associationQ${action.payload.ref}`];

        obj[`associationQ${action.payload.ref}`] =
          newAssociationState[`associationQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);

        return { ...state, ...newAssociationState };

      case "eirl":
        const newEirlState: any = {};
        newEirlState[`eirlQ${action.payload.ref}`] =
          typeof state[`eirlQ${action.payload.ref}`] === "string"
            ? state[`eirlQ${action.payload.ref}`] === "BIC"
              ? "BNC"
              : "BIC"
            : !state[`eirlQ${action.payload.ref}`];

        obj[`eirlQ${action.payload.ref}`] =
          newEirlState[`eirlQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);

        return { ...state, ...newEirlState };

      case "civilSociety":
        const newCivilSocietyState: any = {};

        newCivilSocietyState[`civilSocietyQ${action.payload.ref}`] = action
          .payload.value
          ? action.payload.value
          : !state[`civilSocietyQ${action.payload.ref}`];

        obj[`civilSocietyQ${action.payload.ref}`] =
          newCivilSocietyState[`civilSocietyQ${action.payload.ref}`];

        if (action.payload.ref === 1) {
          newCivilSocietyState[`civilSocietyQ3View`] =
            !state[`civilSocietyQ${action.payload.ref}`];
        }
        setValue(obj, ANSWERS_OBJ);
        return { ...state, ...newCivilSocietyState };

      case "worksCouncil":
        const newWorksCouncilState: any = {};
        newWorksCouncilState[`worksCouncilQ${action.payload.ref}`] = action
          .payload.value
          ? action.payload.value
          : !state[`worksCouncilQ${action.payload.ref}`];

        obj[`worksCouncilQ${action.payload.ref}`] =
          newWorksCouncilState[`worksCouncilQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);

        return { ...state, ...newWorksCouncilState };

      case "marchendise":
        let newMarchendiseState: any = {};

        newMarchendiseState[`marchendiseQ${action.payload.ref}`] =
          typeof state[`marchendiseQ${action.payload.ref}`] === "string"
            ? state[`marchendiseQ${action.payload.ref}`] === "trimestrielle"
              ? "mensuelle"
              : "trimestrielle"
            : !state[`marchendiseQ${action.payload.ref}`];

        setValue(
          newMarchendiseState[`marchendiseQ${action.payload.ref}`],
          action.payload.ref === 2 ? DECLERATION_VALUE : DISCHARGE_TAX_VALUE
        );

        obj[`marchendiseQ${action.payload.ref}`] =
          newMarchendiseState[`marchendiseQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);

        return {
          ...state,
          ...newMarchendiseState,
        };

      case "prestation":
        let newPrestationState: any = {};

        newPrestationState[`prestationQ${action.payload.ref}`] =
          typeof state[`prestationQ${action.payload.ref}`] === "string"
            ? state[`prestationQ${action.payload.ref}`] === "trimestrielle"
              ? "mensuelle"
              : "trimestrielle"
            : !state[`prestationQ${action.payload.ref}`];

        setValue(
          newPrestationState[`prestationQ${action.payload.ref}`],
          action.payload.ref === 2 ? DECLERATION_VALUE : DISCHARGE_TAX_VALUE
        );

        obj[`prestationQ${action.payload.ref}`] =
          newPrestationState[`prestationQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);

        return {
          ...state,
          ...newPrestationState,
        };

      case "autresPrestations":
        let newAutresPrestationsState: any = {};

        newAutresPrestationsState[`autresPrestationsQ${action.payload.ref}`] =
          typeof state[`autresPrestationsQ${action.payload.ref}`] === "string"
            ? state[`autresPrestationsQ${action.payload.ref}`] ===
              "trimestrielle"
              ? "mensuelle"
              : "trimestrielle"
            : !state[`autresPrestationsQ${action.payload.ref}`];

        setValue(
          newAutresPrestationsState[`autresPrestationsQ${action.payload.ref}`],
          action.payload.ref === 2 ? DECLERATION_VALUE : DISCHARGE_TAX_VALUE
        );

        obj[`autresPrestationsQ${action.payload.ref}`] =
          newAutresPrestationsState[`autresPrestationsQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);

        return {
          ...state,
          ...newAutresPrestationsState,
        };

      case "profession":
        let newProfessionState: any = {};

        newProfessionState[`professionQ${action.payload.ref}`] =
          typeof state[`professionQ${action.payload.ref}`] === "string"
            ? state[`professionQ${action.payload.ref}`] === "trimestrielle"
              ? "mensuelle"
              : "trimestrielle"
            : !state[`professionQ${action.payload.ref}`];

        setValue(
          newProfessionState[`marchendiseQ${action.payload.ref}`],
          action.payload.ref === 2 ? DECLERATION_VALUE : DISCHARGE_TAX_VALUE
        );

        obj[`professionQ${action.payload.ref}`] =
          newProfessionState[`professionQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);

        return {
          ...state,
          ...newProfessionState,
        };

      case "location":
        let newLocationState: any = {};

        newLocationState[`locationQ${action.payload.ref}`] =
          typeof state[`locationQ${action.payload.ref}`] === "string"
            ? state[`locationQ${action.payload.ref}`] === "trimestrielle"
              ? "mensuelle"
              : "trimestrielle"
            : !state[`locationQ${action.payload.ref}`];

        setValue(
          newLocationState[`locationQ${action.payload.ref}`],
          action.payload.ref === 2 ? DECLERATION_VALUE : DISCHARGE_TAX_VALUE
        );

        obj[`locationQ${action.payload.ref}`] =
          newLocationState[`locationQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);
        return {
          ...state,
          ...newLocationState,
        };

      // Bilan cases
      case "bilancommercial":
        const newBilanCommercialState: any = {};

        newBilanCommercialState[`bilancommercialQ${action.payload.ref}`] =
          action.payload.value
            ? action.payload.value
            : !state[`bilancommercialQ${action.payload.ref}`];

        obj[`bilancommercialQ${action.payload.ref}`] =
          newBilanCommercialState[`bilancommercialQ${action.payload.ref}`];

        if (action.payload.ref === 1) {
          newBilanCommercialState[`bilancommercialQ2View`] =
            !state[`bilancommercialQ${action.payload.ref}`];
          newBilanCommercialState[`bilancommercialQ3View`] =
            !state[`bilancommercialQ${action.payload.ref}`];
        }

        if (
          action.payload.ref === 2 &&
          String(!state[`bilancommercialQ${action.payload.ref}`]) === "true"
        ) {
          newBilanCommercialState[`bilancommercialQ4View`] = true;
          setSingleQuestion({
            parentRef: 2,
            question: CompanyQuestions[0].questions[3],
          });
          setViewModal(true);
        }

        if (
          action.payload.ref === 3 &&
          String(!state[`bilancommercialQ${action.payload.ref}`]) === "true"
        ) {
          newBilanCommercialState[`bilancommercialQ5View`] = true;
          setSingleQuestion({
            parentRef: 3,
            question: CompanyQuestions[0].questions[4],
          });
          setViewModal(true);
        }

        setValue(obj, ANSWERS_OBJ);

        return { ...state, ...newBilanCommercialState };

      case "bilanassociation":
        const newBilanAssociationState: any = {};
        newBilanAssociationState[`bilanassociationQ${action.payload.ref}`] =
          action.payload.value
            ? action.payload.value
            : !state[`bilanassociationQ${action.payload.ref}`];

        obj[`bilanassociationQ${action.payload.ref}`] =
          newBilanAssociationState[`bilanassociationQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);

        return { ...state, ...newBilanAssociationState };

      case "bilaneirl":
        const newBilanEirlState: any = {};
        newBilanEirlState[`bilaneirlQ${action.payload.ref}`] =
          typeof state[`bilaneirlQ${action.payload.ref}`] === "string"
            ? state[`bilaneirlQ${action.payload.ref}`] === "BIC"
              ? "BNC"
              : "BIC"
            : !state[`bilaneirlQ${action.payload.ref}`];

        obj[`bilaneirlQ${action.payload.ref}`] =
          newBilanEirlState[`bilaneirlQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);
        console.log(obj, "answer bilaneirl");

        return { ...state, ...newBilanEirlState };

      case "bilancivilSociety":
        const newBilanCivilSocietyState: any = {};

        newBilanCivilSocietyState[`bilancivilSocietyQ${action.payload.ref}`] =
          action.payload.value
            ? action.payload.value
            : !state[`bilancivilSocietyQ${action.payload.ref}`];

        obj[`bilancivilSocietyQ${action.payload.ref}`] =
          newBilanCivilSocietyState[`bilancivilSocietyQ${action.payload.ref}`];

        if (action.payload.ref === 1) {
          // newBilanCivilSocietyState[`bilancivilSocietyQ2View`] =
          //   !state[`bilancivilSocietyQ${action.payload.ref}`];
          newBilanCivilSocietyState[`bilancivilSocietyQ3View`] =
            !state[`bilancivilSocietyQ${action.payload.ref}`];
        }

        // if (
        //   action.payload.ref === 2 &&
        //   String(!state[`bilancivilSocietyQ${action.payload.ref}`]) === "true"
        // ) {
        //   newBilanCivilSocietyState[`bilancivilSocietyQ4View`] = true;
        //   setSingleQuestion({
        //     parentRef: 2,
        //     question: CompanyQuestions[0].questions[3],
        //   });
        //   setViewModal(true);
        // }

        // if (
        //   action.payload.ref === 3 &&
        //   String(!state[`bilancivilSocietyQ${action.payload.ref}`]) === "true"
        // ) {
        //   newBilanCivilSocietyState[`bilancivilSocietyQ5View`] = true;
        //   setSingleQuestion({
        //     parentRef: 3,
        //     question: CompanyQuestions[0].questions[4],
        //   });
        //   setViewModal(true);
        // }

        setValue(obj, ANSWERS_OBJ);

        return { ...state, ...newBilanCivilSocietyState };

      case "bilanworksCouncil":
        const newBilanWorksCouncilState: any = {};
        newBilanWorksCouncilState[`bilanworksCouncilQ${action.payload.ref}`] =
          action.payload.value
            ? action.payload.value
            : !state[`bilanworksCouncilQ${action.payload.ref}`];

        obj[`bilanworksCouncilQ${action.payload.ref}`] =
          newBilanWorksCouncilState[`bilanworksCouncilQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);

        return { ...state, ...newBilanWorksCouncilState };

      case "bilanmarchendise":
        let newBilanMarchendiseState: any = {};

        newBilanMarchendiseState[`bilanmarchendiseQ${action.payload.ref}`] =
          typeof state[`bilanmarchendiseQ${action.payload.ref}`] === "string"
            ? state[`bilanmarchendiseQ${action.payload.ref}`] ===
              "trimestrielle"
              ? "mensuelle"
              : "trimestrielle"
            : !state[`bilanmarchendiseQ${action.payload.ref}`];

        setValue(
          newBilanMarchendiseState[`bilanmarchendiseQ${action.payload.ref}`],
          action.payload.ref === 2 ? DECLERATION_VALUE : DISCHARGE_TAX_VALUE
        );

        obj[`bilanmarchendiseQ${action.payload.ref}`] =
          newBilanMarchendiseState[`bilanmarchendiseQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);

        return {
          ...state,
          ...newBilanMarchendiseState,
        };

      case "bilanprestation":
        let newBilanPrestationState: any = {};

        newBilanPrestationState[`bilanprestationQ${action.payload.ref}`] =
          typeof state[`bilanprestationQ${action.payload.ref}`] === "string"
            ? state[`bilanprestationQ${action.payload.ref}`] === "trimestrielle"
              ? "mensuelle"
              : "trimestrielle"
            : !state[`bilanprestationQ${action.payload.ref}`];

        setValue(
          newBilanPrestationState[`bilanprestationQ${action.payload.ref}`],
          action.payload.ref === 2 ? DECLERATION_VALUE : DISCHARGE_TAX_VALUE
        );

        obj[`bilanprestationQ${action.payload.ref}`] =
          newBilanPrestationState[`bilanprestationQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);

        return {
          ...state,
          ...newBilanPrestationState,
        };

      case "bilanautresPrestations":
        let newBilanAutresPrestationsState: any = {};

        newBilanAutresPrestationsState[
          `bilanautresPrestationsQ${action.payload.ref}`
        ] =
          typeof state[`bilanautresPrestationsQ${action.payload.ref}`] ===
          "string"
            ? state[`bilanautresPrestationsQ${action.payload.ref}`] ===
              "trimestrielle"
              ? "mensuelle"
              : "trimestrielle"
            : !state[`bilanautresPrestationsQ${action.payload.ref}`];

        setValue(
          newBilanAutresPrestationsState[
            `bilanautresPrestationsQ${action.payload.ref}`
          ],
          action.payload.ref === 2 ? DECLERATION_VALUE : DISCHARGE_TAX_VALUE
        );

        obj[`bilanautresPrestationsQ${action.payload.ref}`] =
          newBilanAutresPrestationsState[
            `bilanautresPrestationsQ${action.payload.ref}`
          ];

        setValue(obj, ANSWERS_OBJ);

        return {
          ...state,
          ...newBilanAutresPrestationsState,
        };

      case "bilanprofession":
        let newBilanProfessionState: any = {};

        newBilanProfessionState[`bilanprofessionQ${action.payload.ref}`] =
          typeof state[`bilanprofessionQ${action.payload.ref}`] === "string"
            ? state[`bilanprofessionQ${action.payload.ref}`] === "trimestrielle"
              ? "mensuelle"
              : "trimestrielle"
            : !state[`bilanprofessionQ${action.payload.ref}`];

        setValue(
          newBilanProfessionState[`bilanmarchendiseQ${action.payload.ref}`],
          action.payload.ref === 2 ? DECLERATION_VALUE : DISCHARGE_TAX_VALUE
        );

        obj[`bilanprofessionQ${action.payload.ref}`] =
          newBilanProfessionState[`bilanprofessionQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);

        return {
          ...state,
          ...newBilanProfessionState,
        };

      case "bilanlocation":
        let newBilanLocationState: any = {};

        newBilanLocationState[`bilanlocationQ${action.payload.ref}`] =
          typeof state[`bilanlocationQ${action.payload.ref}`] === "string"
            ? state[`bilanlocationQ${action.payload.ref}`] === "trimestrielle"
              ? "mensuelle"
              : "trimestrielle"
            : !state[`bilanlocationQ${action.payload.ref}`];

        setValue(
          newBilanLocationState[`bilanlocationQ${action.payload.ref}`],
          action.payload.ref === 2 ? DECLERATION_VALUE : DISCHARGE_TAX_VALUE
        );

        obj[`bilanlocationQ${action.payload.ref}`] =
          newBilanLocationState[`bilanlocationQ${action.payload.ref}`];

        setValue(obj, ANSWERS_OBJ);
        return {
          ...state,
          ...newBilanLocationState,
        };

      case "DISCHARGE_TAX_VALUE":
        setValue(obj, ANSWERS_OBJ);
        return {
          ...state,
          dischargeTax: action.payload.value,
        };

      case "DECLERATION_VALUE":
        setValue(obj, ANSWERS_OBJ);
        return {
          ...state,
          declaration: action.payload.value,
        };

      default:
        setValue(obj, ANSWERS_OBJ);
        return state;
    }
  };

  const [answers, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setQuestionsType(
      getValue("CompanyType") === "company" ||
        getValue("CompanyType") === "bilan"
        ? CompanyQuestions
        : EntrepQuestions
    );
  }, []);

  return (
    <div className="register-stepThree col-lg-11 col-md-12 mx-auto">
      <div>
        <CardRegister className="card-blue" icon={AssocIcon}>
          <div className="card-list">
            {questionsType.length > 0 &&
              questionsType
                .find((elt) => elt.activityType === getValue("ActivityType"))
                ?.questions.map((elt, index) => {
                  return (
                    <React.Fragment key={index}>
                      {answers[
                        `${getValue("ActivityType")}Q${index + 1}View`
                      ] &&
                      answers[
                        `${getValue("ActivityType")}Q${index + 1}ViewType`
                      ] === "inline" ? (
                        <div className="row box-list">
                          <div className="col question-wrapper">
                            {typeof elt.question === "string" ? (
                              <h3>{elt.question}</h3>
                            ) : (
                              elt.question
                            )}
                            {elt.popOver ? (
                              <>
                                <p id={`rectif-${index}`}>
                                  <RiInformationFill />
                                </p>
                                <UncontrolledTooltip
                                  target={`rectif-${index}`}
                                  // className="rectifi-popver-body"
                                  placement="bottom"
                                >
                                  {elt.popOver}
                                </UncontrolledTooltip>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className="switch primary col answers-wrapper">
                            {elt.optionsPopOvers && elt.optionsPopOvers[0] ? (
                              <>
                                <p id={`rectif-optionPopover-0`}>
                                  <RiInformationFill />
                                </p>
                                <UncontrolledTooltip
                                  target={`rectif-optionPopover-0`}
                                  // className="rectifi-popver-body"
                                  placement="bottom"
                                >
                                  {elt.optionsPopOvers[0]}
                                </UncontrolledTooltip>
                              </>
                            ) : (
                              <></>
                            )}

                            <span
                              className={`label  ${
                                (typeof answers[
                                  `${getValue("ActivityType")}Q${index + 1}`
                                ] === "boolean" &&
                                  !answers[
                                    `${getValue("ActivityType")}Q${index + 1}`
                                  ]) ||
                                answers[
                                  `${getValue("ActivityType")}Q${index + 1}`
                                ] === ("BIC" || "trimestrielle")
                                  ? "greenTxt"
                                  : ""
                              }`}
                            >
                              {elt.options[0]}
                            </span>

                            <Switch
                              id={`${index}`}
                              onClick={elt.onClick}
                              checked={
                                (typeof answers[
                                  `${getValue("ActivityType")}Q${index + 1}`
                                ] === "boolean" &&
                                  answers[
                                    `${getValue("ActivityType")}Q${index + 1}`
                                  ]) ||
                                answers[
                                  `${getValue("ActivityType")}Q${index + 1}`
                                ] === "BNC" ||
                                answers[
                                  `${getValue("ActivityType")}Q${index + 1}`
                                ] === "mensuelle"
                              }
                              disabled={
                                (getValue("ActivityType") === "commercial" &&
                                  answers.commercialQ2 &&
                                  index === 2) ||
                                (getValue("ActivityType") === "commercial" &&
                                  answers.commercialQ3 &&
                                  index === 1) ||
                                (getValue("ActivityType") === "civilSociety" &&
                                  answers.civilSocietyQ3 &&
                                  index === 1) ||
                                (getValue("ActivityType") === "civilSociety" &&
                                  answers.civilSocietyQ2 &&
                                  index === 2)
                              }
                            />

                            <span
                              className={`label  ${
                                (typeof answers[
                                  `${getValue("ActivityType")}Q${index + 1}`
                                ] === "boolean" &&
                                  answers[
                                    `${getValue("ActivityType")}Q${index + 1}`
                                  ]) ||
                                answers[
                                  `${getValue("ActivityType")}Q${index + 1}`
                                ] === ("BNC" || "mensuelle")
                                  ? "greenTxt"
                                  : ""
                              }`}
                            >
                              {elt.options[1]}
                            </span>

                            {elt.optionsPopOvers && elt.optionsPopOvers[1] ? (
                              <>
                                <p id={`rectif-optionPopover-1`}>
                                  <RiInformationFill />
                                </p>
                                <UncontrolledTooltip
                                  target={`rectif-optionPopover-1`}
                                  // className="rectifi-popver-body"
                                  placement="bottom"
                                >
                                  {elt.optionsPopOvers[1]}
                                </UncontrolledTooltip>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  );
                })}
          </div>
        </CardRegister>
      </div>
      <div className="openbtn text-center">
        <Modal
          isOpen={viewModal}
          toggle={() => {
            setSingleQuestion(null);
            setViewModal(false);
            dispatch({
              type: getValue("ActivityType"),
              payload: {
                ref: singleQuestion?.parentRef!,
                value: false,
              },
            });
          }}
          className="modal-secondary modal-dialog-centered"
        >
          <ModalHeader
            toggle={() => {
              setSingleQuestion(null);
              setViewModal(false);
              dispatch({
                type: getValue("ActivityType"),
                payload: {
                  ref: singleQuestion?.parentRef!,
                  value: false,
                },
              });
            }}
          >
            Confirmer
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">{singleQuestion?.question.question}</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              outline
              onClick={() => {
                setViewModal(false);
                deleteValue("AnswersObject");
                let url  = "/register/pack"
                if(location && location.search){
                  url += location.search
                }
                navigate(url);
                setSingleQuestion(null);
              }}
            >
              Non
            </Button>
            <Button
              color="secondary"
              onClick={async () => {
                setSingleQuestion(null);
                setViewModal(false);
                dispatch({
                  type: getValue("ActivityType"),
                  payload: {
                    ref: singleQuestion?.parentRef!,
                    value: true,
                  },
                });
              }}
            >
              Oui
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default RegisterStepThree;
