import React, { useEffect, useState } from "react";
import "./TvaFollow.scss";
import {
  Col,
  Row,
} from "reactstrap";
import TopCard from "../../components/TopCard/TopCard";
import collectIcon from "../../assets/images/svg/collect-percent-icon.svg";
import DeductibleIcon from "../../assets/images/svg/money-percent-icon.svg";
import TVAIcon from "../../assets/images/svg/calculator-icon.svg";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import { ErrorLogger } from "../../util/errorLogger";
import { useFormatter } from "../../util/hooks/useFormatter";
import useAxios from "../../util/hooks/useAxios";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Select from "react-select";
import Tabs from "../../components/Tabs";
import { TabsType } from "../Facturation";
import Export from "./Export";
import { toast, ToastContainer } from "react-toastify";
import { IoMdCreate } from "react-icons/io";
registerLocale("fr", fr);
moment.updateLocale("fr", {});

const Cards = ({ id, pack }: { id?: string; pack?: string }) => {
  const [currentDate, setCurrentDate] = useState<string>(
    moment().format("YYYY-MM-DD hh:mm")
  );
  const [collectedTVA, setCollectedTVA] = useState<number | null>(0);
  const [detuctibleTVA, setDeductibleTVA] = useState<number | null>(0);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };
  let api = useAxios();

  const getNonDecutibleTVA = async () => {
    try {
      const { data } = await api.post(
        `/api/stats/collectedTVA`,
        {
          dateFrom: moment(currentDate)
            .startOf("month")
            .format("YYYY-MM-DD hh:mm"),
          dateTo: moment(currentDate).endOf("month").format("YYYY-MM-DD hh:mm"),
          companyId: id,
          pack: pack,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setCollectedTVA(data.tva.length > 0 ? data.tva[0].result : 0);
    } catch (error: any) {
      ErrorLogger("getting od tva data", error);
    }
  };

  const getDecutibleTVA = async () => {
    try {
      const { data } = await api.post(
        `/api/stats/decutibleTVA`,
        {
          dateFrom: moment(currentDate)
            .startOf("month")
            .format("YYYY-MM-DD hh:mm"),
          dateTo: moment(currentDate).endOf("month").format("YYYY-MM-DD hh:mm"),
          companyId: id,
          pack: pack,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setDeductibleTVA(data.tva.length > 0 ? data.tva[0].result : 0);
    } catch (error: any) {
      ErrorLogger("getting od tva data", error);
    }
  };

  useEffect(() => {
    getNonDecutibleTVA();
    getDecutibleTVA();
  }, []);

  const { setDecimalDigits } = useFormatter();

  useEffect(() => {
    getNonDecutibleTVA();
    getDecutibleTVA();
  }, [currentDate]);

  return (
    <>
      <div className="top-odt-tva">
        <div className="action-top-odt">
          <span className="month-label">
            {moment(currentDate).format("MMMM/YYYY")}
          </span>
          <div className="od-tva-wrapper">
            <button
              type="button"
              className="btn btn-outline-secondary "
              onClick={() =>
                setCurrentDate(
                  moment(currentDate)
                    .subtract(1, "month")
                    .format("YYYY-MM-DD hh:mm")
                )
              }
            >
              Mois précédent
            </button>
            <button
              type="button"
              className="btn btn-secondary "
              onClick={() =>
                setCurrentDate(
                  moment(currentDate).add(1, "month").format("YYYY-MM-DD hh:mm")
                )
              }
            >
              Mois suivant
            </button>
          </div>
        </div>
        <Row>
          <Col lg={4} md={12}>
            <TopCard
              title="TVA collectée"
              text={setDecimalDigits(Math.abs(collectedTVA!)) + " €"}
              icon={collectIcon}
              className="card-secondary"
            />
          </Col>
          <Col lg={4} md={12}>
            <TopCard
              title="TVA déductible"
              text={setDecimalDigits(Math.abs(detuctibleTVA!)) + " €"}
              icon={DeductibleIcon}
              className="card-purple"
            />
          </Col>
          <Col lg={4} md={12}>
            <TopCard
              title={
                Math.abs(collectedTVA!) === 0 && Math.abs(detuctibleTVA!) === 0
                  ? "TVA"
                  : Math.abs(collectedTVA!) > Math.abs(detuctibleTVA!)
                  ? "TVA DUE"
                  : "Crédit de TVA "
              }
              text={
                setDecimalDigits(
                  Math.abs(Math.abs(detuctibleTVA!) - Math.abs(collectedTVA!))
                ) + " €"
              }
              icon={TVAIcon}
              className={
                Math.abs(collectedTVA!) === 0 && Math.abs(detuctibleTVA!) === 0
                  ? "card-warning"
                  : Math.abs(collectedTVA!) > Math.abs(detuctibleTVA!)
                  ? "card-danger"
                  : "card-primary"
              }
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Cards;
