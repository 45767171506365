import React, {useEffect, useId, useMemo, useState} from "react";
import "./CreateFacture.scss";
import LogoNoly from "../../../assets/images/logo.svg";

import IconPlusYellow from "../../../assets/images/svg/puls-icon-yellow.svg";
import Select from "react-select";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  RiAddFill,
  RiCameraLine,
  RiCloseCircleFill,
  RiLinkM,
  RiSave3Fill,
} from "react-icons/ri";
import {IoMdCreate} from "react-icons/io";
import {BsTrashFill} from "react-icons/bs";
import SaveIconWhite from "../../../assets/SaveIconWhite";
import EmailIcon from "../../../assets/images/svg/mail-green.svg";
import moment from "moment";
import "moment/locale/fr";
import useAxios from "../../../util/hooks/useAxios";
import {useSelector} from "react-redux";
import {ErrorLogger} from "../../../util/errorLogger";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {Company, ISubCategory, User} from "../../../interfaces";
import {useFormatter} from "../../../util/hooks/useFormatter";
import {ClientTypes, OptionType} from "../../../util/context";
import CerleUserBg from "../../../assets/CerleUserBg";
import {colors} from "../../../constants";
import {FaFileInvoice} from "react-icons/fa";
import ReactQuill from "react-quill";

const options = [
  {
    value: "Prestations de ser-vices a 20%",
    label: "Prestations de ser-vices a 20% de ser-vices a 20%",
  },
];
export interface FactureCreatProps {}

export type GeneralFormValues = {
  id: number;
  category: OptionType | null;
  description: string | null;
  unitPrice: number | null;
  quantity: number | null;
  tva: number | null;
  totalTVA: number | null;
  totalHT: number | null;
  totalTTC: number | null;
  otherCategory: Record<string, string> | null;
};

const CreateFacture = ({
  model,
  invoiceSubCategs,
  formData,
  setFormData,
  needEmail,
  setNeedEmail,
  needModelRef,
  setNeedModelRef,
  clientType,
}: {
  model: string;
  invoiceSubCategs: ISubCategory[];
  formData: any;
  setFormData: any;
  needEmail: boolean;
  setNeedEmail: any;
  needModelRef: boolean;
  setNeedModelRef: any;
  clientType: OptionType;
}) => {
  const [addNewRow, setAddNewRow] = useState<boolean>(false);
  const [nextID, setNextID] = useState("");
  const [paymentLink, setPaymentLink] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [modelRef, setModelRef] = useState("");

  const [companyAddress, setcompanyAddress] = useState("");
  const [companyShareCapital, setcompanyShareCapital] = useState("");
  const [companyPhone, setcompanyPhone] = useState("");
  const [companySiren, setcompanySiren] = useState("");
  const [companyTVA, setcompanyTVA] = useState("");
  const [clientName, setclientName] = useState("");
  const [clientAddress, setclientAddress] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientSiren, setclientSiren] = useState("");
  const [clientTVA, setclientTVA] = useState("");
  const [additionalInfo, setadditionalInfo] = useState("");

  const [legalFooter, setlegalFooter] = useState(
    "Application de l'article L441-6 du Code de commerce et du décret n°2012-1115 du 2 octobre 2012\nPénalités pour retard de paiement : 3 fois le taux de l'intérêt légal + indemnité forfaitaire de recouvrement : 40 € - TVA acquittée sur les encaissements - Aucun escompte ne sera accordé en cas de paiement anticipé."
  );

  const [invoiceTotalHT, setInvoiceTotalHT] = useState(0);
  const [invoiceTotalTVA, setInvoiceTotalTVA] = useState(0);
  const [invoiceTotalTTC, setInvoiceTotalTTC] = useState(0);
  const {setDecimalDigits} = useFormatter();

  const [firstRowId, setFirstRowId] = useState(0);
  const [updatingFirst, setUpdatingFirst] = useState(false);

  const [companyLogo, setcompanyLogo] = useState<any>();

  const [rows, setRows] = useState<GeneralFormValues[]>([]);

  let api = useAxios();
  const creds = useSelector(
    (state: {root: object; user: object}) => state.root
  ) as {user_id: string; company_id: string; token: string; role: string};

  const {user, company} = useSelector(
    (state: {root: object; user: object; company: object}) => state.user
  ) as {user: User; company: Company};

  const getNextInvoiceID = async () => {
    try {
      const {data} = await api.post(
        `/api/invoice/getNextInvoiceID`,
        {
          model,
          company_id: creds.company_id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (data.id) {
        setNextID(data.id);
      }
    } catch (error: any) {
      ErrorLogger("getting invoice nex id", error);
    }
  };

  const totHTClaculator = (qty: number, unitPrice: number) =>
    parseFloat(setDecimalDigits(qty * unitPrice));
  const totTVACalculator = (tva: number, qty: number, unitPrice: number) =>
    parseFloat(setDecimalDigits((totHTClaculator(qty, unitPrice) * tva) / 100));
  const totTTCCalculator = (tva: number, qty: number, unitPrice: number) =>
    parseFloat(
      setDecimalDigits(
        totTVACalculator(tva, qty, unitPrice) + totHTClaculator(qty, unitPrice)
      )
    );

  const updateInvoiceTotal = (rows: GeneralFormValues[]) => {
    const ht = rows.reduce((acc, curr) => acc + (curr.totalHT || 0), 0);
    setInvoiceTotalHT(ht);
    const tva = rows.reduce((acc, curr) => acc + (curr.totalTVA || 0), 0);
    setInvoiceTotalTVA(tva);
    const ttc = rows.reduce((acc, curr) => acc + (curr.totalTTC || 0), 0);
    setInvoiceTotalTTC(ttc);
  };

  const {
    control,
    setValue,
    getValues,
    watch,
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<GeneralFormValues>({});

  useEffect(() => {
    setcompanyAddress(company.headOffice);
    setcompanyPhone(company.phone);
    setcompanySiren(company.siren);
    setcompanyTVA(company.intraCommunityVATNumber);
    setcompanyShareCapital(company.shareCapital);
  }, [company]);

  useEffect(() => {
    updateInvoiceTotal(rows);
  }, [rows]);

  useEffect(() => {
    let obj: any = {
      rows,
      companyAddress,
      companyPhone,
      companySiren,
      companyShareCapital,
      companyTVA,
      clientName,
      clientAddress,
      clientPhone,
      clientSiren,
      clientTVA,
      additionalInfo,
      invoiceTotalHT: parseFloat(setDecimalDigits(invoiceTotalHT)),
      invoiceTotalTVA: parseFloat(setDecimalDigits(invoiceTotalTVA)),
      invoiceTotalTTC: parseFloat(setDecimalDigits(invoiceTotalTTC)),
      companyLogo,
      paymentLink,
      receiverEmail,
      modelRef,
    };
    if (model && model !== "" && model.toLowerCase() === "facture") {
      obj = {
        ...obj,
        legalFooter,
      };
    }
    setFormData(obj);
  }, [
    rows,
    companyAddress,
    companyShareCapital,
    companyPhone,
    companySiren,
    companyTVA,
    clientName,
    clientAddress,
    clientPhone,
    clientSiren,
    clientTVA,
    additionalInfo,
    invoiceTotalHT,
    invoiceTotalTVA,
    invoiceTotalTTC,
    companyLogo,
    paymentLink,
    receiverEmail,
    modelRef,
    legalFooter,
  ]);

  const deleteRow = (id: number) => {
    setRows((prevState) => {
      const oldState = prevState.filter((row) => row.id !== id);
      return oldState;
    });
    reset();
  };

  const resetRow = (row: GeneralFormValues) => {
    if (row.id === firstRowId) {
      setUpdatingFirst(true);
    }

    reset({
      ...row,
    });
  };

  const {ref: categoryRef, ...category} = register("category");
  const {ref: descriptionRef, ...description} = register("description");
  const {ref: unitPriceRef, ...unitPrice} = register("unitPrice");
  const {ref: quantityRef, ...quantity} = register("quantity");
  const {ref: tvaRef, ...tva} = register("tva");
  const {ref: totalTVARef, ...totalTVA} = register("totalTVA");
  const {ref: totalHTRef, ...totalHT} = register("totalHT");
  const {ref: totalTTCRef, ...totalTTC} = register("totalTTC");
  const {ref: otherCategoryRef, ...otherCategory} = register("otherCategory");

  const closeRow = () => {
    reset({
      category: null,
      description: null,
      unitPrice: null,
      quantity: null,
      // tva: form.tva,
      tva: null,
      totalTVA: null,
      totalHT: null,
      totalTTC: null,
    });
    setAddNewRow(false);
  };

  const addRow: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      let row: any = {};

      if (!form.unitPrice || !form.quantity) {
        return;
      }

      row.category = form.category;

      if (form.description) {
        row.description = form.description;
      }
      if (form.unitPrice) {
        row.unitPrice = form.unitPrice;
      }
      if (form.quantity) {
        row.quantity = form.quantity;
      }

      if (form.tva) {
        row.tva = form.tva;
      }

      if (form.totalTVA) {
        row.totalTVA = parseFloat(form.totalTVA.toFixed(2));
      }
      if (form.totalHT) {
        row.totalHT = parseFloat(form.totalHT.toFixed(2));
      }
      if (form.totalTTC) {
        row.totalTTC = parseFloat(form.totalTTC.toFixed(2));
      }
      if (form.otherCategory) {
        if (typeof form.otherCategory === "string") {
          row.otherCategory = {title: form.otherCategory};
        } else {
          row.otherCategory = form.otherCategory;
        }
      }

      if (!form.id) {
        row.id = Date.now();
      } else {
        row.id = form.id;
      }

      setRows((prevState) => {
        let oldState = [...prevState];

        return [...oldState, row];
      });
      closeRow();
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  useEffect(() => {
    if (model) {
      getNextInvoiceID();
    }
  }, [model]);

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event: any) => {
    (hiddenFileInput.current as any).click();
  };

  const handleChange = (event: {target: {files: any[]}}) => {
    const fileUploaded = event.target.files[0];
    setcompanyLogo(fileUploaded);
  };

  const subCategorieList = useMemo(() => {
    const list = invoiceSubCategs.reduce(
      (acc, curr) => [
        ...acc,
        {
          label: curr.name as string,
          value: curr.id as string,
        },
      ],
      [] as {value: string; label: string}[]
    );

    if (model !== "Facture") {
      return list.filter((el) => el.label !== "Autres");
    }
    return list;
  }, [invoiceSubCategs, model]);

  // useEffect(() => {
  //   console.log("rows", rows);
  // }, [rows]);

  useEffect(() => {
    if (
      watch("category")?.value !== "nt_-mrdk5f58" &&
      watch("otherCategory")?.title !== ""
    ) {
      setValue("otherCategory", null);
    }
  }, [watch("category")]);

  // Facture
  return (
    <>
      <div className="facture-Wrrap">
        <div className="Header-fact">
          <div className="left-head-fact">
            <div className="titleNUm-fact">
              <span className="titlenum-fact">{model} n° :</span>
              <span className="num-fact">{nextID.toUpperCase()}</span>
              <span className="date-fact">
                Date: {moment().format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
          <div className="right-head-fact">
            {/* <img src={LogoNoly} alt="logo" /> */}
            <div className="invoice-logo user-info mb-4">
              {companyLogo && (
                <>
                  <CerleUserBg
                    className={"bgCercle"}
                    fillColor={colors.primaryColor}
                  />
                  <img
                    src={URL.createObjectURL(companyLogo as any)}
                    alt="logo"
                  />
                </>
              )}
            </div>
            <Button
              type="button"
              className="form__button"
              color="secondary"
              onClick={handleClick}
            >
              Ajouter un logo
            </Button>

            <input
              ref={hiddenFileInput}
              onChange={handleChange as any}
              type="file"
              className="file-input"
            />
          </div>
        </div>

        <div className="Header-fact-infos">
          <div className="left-infos-fact">
            <div className="box-fact-edit ">
              <h3>{company?.name || ""}</h3>
              <FormGroup className="form-icon">
                <Label for="adress">Adresse :</Label>
                <Input
                  id="adress"
                  type="text"
                  className="form-default"
                  value={companyAddress}
                  onChange={(e) => setcompanyAddress(e.target.value)}
                />
              </FormGroup>

              <FormGroup className="form-icon">
                <Label for="phone">Teléphone :</Label>
                <Input
                  type="text"
                  className="form-default"
                  value={companyPhone}
                  onChange={(e) => setcompanyPhone(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="form-icon">
                <Label for="siren">Siren :</Label>
                <Input
                  id="siren"
                  type="text"
                  className="form-default"
                  value={companySiren}
                  onChange={(e) => setcompanySiren(e.target.value)}
                  disabled
                />
              </FormGroup>
              <FormGroup className="form-icon">
                <Label for="numtva">Numéro TVA :</Label>
                <Input
                  id="numtva"
                  type="text"
                  className="form-default"
                  value={companyTVA}
                  onChange={(e) => setcompanyTVA(e.target.value)}
                  disabled
                />
              </FormGroup>
              <FormGroup className="form-icon">
                <Label for="capital">Capital social :</Label>
                <Input
                  id="capital"
                  type="text"
                  className="form-default"
                  value={companyShareCapital}
                  onChange={(e) => setcompanyShareCapital(e.target.value)}
                  disabled
                />
              </FormGroup>
            </div>
          </div>
          <div className="right-infos-fact">
            <div className="box-fact-edit ">
              <input
                className="form-fact clientName-input"
                placeholder="..."
                value={clientName}
                onChange={(e) => setclientName(e.target.value)}
              />

              <FormGroup className="form-icon">
                <Label for="adress">Adresse :</Label>
                <Input
                  id="adress"
                  type="text"
                  className="form-primary"
                  value={clientAddress}
                  onChange={(e) => setclientAddress(e.target.value)}
                />
              </FormGroup>

              <FormGroup className="form-icon">
                <Label for="phone">Teléphone :</Label>
                <Input
                  id="phone"
                  type="text"
                  className="form-primary"
                  value={clientPhone}
                  onChange={(e) => setclientPhone(e.target.value)}
                />
              </FormGroup>
              {clientType && clientType.value === ClientTypes.B2B ? (
                <>
                  <FormGroup className="form-icon">
                    <Label for="siren">Siren :</Label>
                    <Input
                      id="siren"
                      type="text"
                      className="form-primary"
                      value={clientSiren}
                      onChange={(e) => setclientSiren(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="form-icon">
                    <Label for="numtva">Numéro TVA :</Label>
                    <Input
                      id="numtva"
                      type="text"
                      className="form-primary"
                      value={clientTVA}
                      onChange={(e) => setclientTVA(e.target.value)}
                    />
                  </FormGroup>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="infos-additionnels">
          <div className="text-added">
            <span className="label-text-added">
              Informations additionnelles:
            </span>
            <ReactQuill
              className="form-secondary"
              onChange={(value) => setadditionalInfo(value)}
              defaultValue={additionalInfo}
              theme={"snow"}
            />
            {/* <textarea
              className="form-fact "
              placeholder="..."
              value={additionalInfo}
              onChange={(e) => setadditionalInfo(e.target.value)}
            ></textarea> */}
          </div>
        </div>

        <div className="table-fact table-responsive">
          <form onSubmit={handleSubmit(addRow)}>
            <table className="table">
              <thead>
                <tr>
                  <th>Catégorie</th>
                  <th>Description</th>
                  <th>Prix unit.HT</th>
                  <th>Quantité</th>
                  <th>%TVA</th>
                  <th>Tot.TVA</th>
                  <th>Tot.HT</th>
                  <th>Tot.TTC</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((elt, index) => (
                  <tr key={index}>
                    <td style={{position: "relative"}}>
                      {elt.category?.label}
                      <span
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          left: "5px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "100%",
                        }}
                      >
                        {elt.otherCategory && elt.otherCategory.title
                          ? elt.otherCategory.title
                          : null}
                      </span>
                    </td>
                    <td>{elt.description}</td>
                    <td>
                      <div className="input-device">{elt.unitPrice} €</div>
                    </td>
                    <td>{elt.quantity}</td>
                    <td>{elt.tva}</td>
                    <td>
                      <div className="input-device">{elt.totalTVA} €</div>
                    </td>
                    <td>
                      <div className="input-device">{elt.totalHT} €</div>
                    </td>
                    <td>
                      <div className="input-device">{elt.totalTTC} €</div>
                    </td>
                    <td>
                      <div className="table-action flex-end">
                        <button
                          type="button"
                          className="btn btn-blue"
                          onClick={() => {
                            resetRow(elt);
                            deleteRow(elt.id);
                            setAddNewRow(true);
                          }}
                        >
                          <IoMdCreate />
                        </button>
                        <button
                          className="btn btn-red"
                          type="button"
                          onClick={() => {
                            deleteRow(elt.id);
                          }}
                        >
                          <BsTrashFill />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}

                {/* add new row */}
                {addNewRow && (
                  <tr>
                    {/* {rows.length === 0 || updatingFirst ? ( */}
                    <td>
                      <Controller
                        name="category"
                        control={control}
                        render={({field}) => (
                          <Select
                            {...field}
                            options={subCategorieList}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-secondary outline small categ-select"
                            onChange={(e) => {
                              setValue("category", e);
                              const unitPrice = getValues("unitPrice") || 0;
                              const qty = getValues("quantity") || 0;
                              const subCateg = invoiceSubCategs.find(
                                (elt) => elt.id === e?.value
                              );

                              if (
                                subCateg &&
                                subCateg?.TVA &&
                                !isNaN(parseFloat(subCateg?.TVA)) &&
                                parseFloat(subCateg?.TVA) !== 0
                              ) {
                                setValue("tva", parseFloat(subCateg?.TVA));
                                setValue(
                                  "totalTVA",
                                  totTVACalculator(
                                    parseFloat(subCateg?.TVA),
                                    qty,
                                    unitPrice
                                  )
                                );
                                setValue(
                                  "totalTTC",
                                  totTTCCalculator(
                                    parseFloat(subCateg?.TVA),
                                    qty,
                                    unitPrice
                                  )
                                );
                              }
                            }}
                          />
                        )}
                      />
                      {watch("category")?.value === "nt_-mrdk5f58" ? (
                        <Input
                          id="otherCategory"
                          placeholder="autre ..."
                          type="text"
                          className="form-secondary small"
                          {...otherCategory}
                          innerRef={otherCategoryRef}
                          value={watch("otherCategory")?.title}
                          onChange={(e) =>
                            setValue("otherCategory", {title: e.target.value})
                          }
                        />
                      ) : null}
                    </td>
                    <td>
                      <Input
                        id="desc"
                        placeholder="Desc."
                        type="text"
                        className="form-secondary small"
                        innerRef={descriptionRef}
                        {...description}
                      />
                    </td>
                    <td>
                      <div className="input-device-left">
                        <Input
                          id="unitPrice"
                          type="number"
                          className="form-secondary small outline"
                          onWheel={(e) => (e.target as any).blur()}
                          innerRef={unitPriceRef}
                          {...unitPrice}
                          min={0}
                          step={0.01}
                          onChange={(e) => {
                            const unitPrice = parseFloat(e.target.value);
                            const qty = getValues("quantity") || 0;
                            const tva = getValues("tva") || 0;

                            if (unitPrice) {
                              setValue(
                                "totalHT",
                                totHTClaculator(qty, unitPrice)
                              );
                              setValue(
                                "totalTVA",
                                totTVACalculator(tva, qty, unitPrice)
                              );
                              setValue(
                                "totalTTC",
                                totTTCCalculator(tva, qty, unitPrice)
                              );
                            }
                          }}
                        />
                        <span className="devise">€</span>
                      </div>
                    </td>
                    <td>
                      <Input
                        id="quantity"
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        className="form-secondary small outline"
                        innerRef={quantityRef}
                        {...quantity}
                        min={0}
                        step={0.01}
                        onChange={(e) => {
                          const unitPrice = getValues("unitPrice") || 0;
                          const qty = parseFloat(e.target.value);
                          const tva = getValues("tva") || 0;
                          if (qty) {
                            setValue(
                              "totalHT",
                              totHTClaculator(qty, unitPrice)
                            );
                            setValue(
                              "totalTVA",
                              totTVACalculator(tva, qty, unitPrice)
                            );
                            setValue(
                              "totalTTC",
                              totTTCCalculator(tva, qty, unitPrice)
                            );
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Input
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        className="form-secondary small outline"
                        innerRef={tvaRef}
                        {...tva}
                        min={0}
                        step={0.01}
                        defaultValue={rows.length > 0 ? rows[0].tva || 0 : 0}
                        onChange={(e) => {
                          const unitPrice = getValues("unitPrice") || 0;
                          const qty = getValues("quantity") || 0;
                          const tva = parseFloat(e.target.value);
                          setValue("totalHT", totHTClaculator(qty, unitPrice));
                          if (tva) {
                            setValue(
                              "totalTVA",
                              totTVACalculator(tva, qty, unitPrice)
                            );
                            setValue(
                              "totalTTC",
                              totTTCCalculator(tva, qty, unitPrice)
                            );
                          }
                        }}
                      />
                    </td>
                    <td>
                      <div className="input-device-left">
                        <Input
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          className="form-secondary small outline"
                          innerRef={totalTVARef}
                          {...totalTVA}
                          min={0}
                          step={"any"}
                        />
                        <span className="devise">€</span>
                      </div>
                    </td>
                    <td>
                      <div className="input-device-left">
                        <Input
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          className="form-secondary small outline"
                          innerRef={totalHTRef}
                          {...totalHT}
                          min={0}
                          step={"any"}
                        />
                        <span className="devise">€</span>
                      </div>
                    </td>
                    <td>
                      <div className="input-device-left">
                        <Input
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          className="form-secondary small outline"
                          innerRef={totalTTCRef}
                          {...totalTTC}
                          min={0}
                          step={"any"}
                        />

                        <span className="devise">€</span>
                      </div>
                    </td>
                    <td>
                      <div className="table-action flex-end">
                        <button className="btn btn-blue btn-save" type="submit">
                          <SaveIconWhite />
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className="table-action flex-end">
                        <button
                          type="button"
                          className="btn btn-red"
                          onClick={() => closeRow()}
                        >
                          <RiCloseCircleFill />
                        </button>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </form>
          {!addNewRow && (
            <div className="action-add-row">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setAddNewRow(true)}
              >
                <img src={IconPlusYellow} alt="icon" />
              </button>
            </div>
          )}
        </div>
        <div className="resultat-table">
          <div className="inner-total">
            <div className="item-totat">
              <span className="label-total">Total HT : </span>
              <span className="num-total">
                {setDecimalDigits(invoiceTotalHT)} €
              </span>
            </div>
            <div className="item-totat">
              <span className="label-total">Total TVA : </span>
              <span className="num-total">
                {setDecimalDigits(invoiceTotalTVA)} €
              </span>
            </div>
            <div className="item-totat total-ttc">
              <span className="label-total">Total TTC :</span>
              <span className="num-total">
                {setDecimalDigits(invoiceTotalTTC)} €
              </span>
            </div>
          </div>
        </div>
        {model && model !== "" && model.toLowerCase() === "facture" && (
          <div className="leaglFooter">
            <textarea
              placeholder="..."
              value={legalFooter}
              onChange={(e) => setlegalFooter(e.target.value)}
              rows={3}
            ></textarea>
          </div>
        )}

        <div className="footer-fact">
          <span>Noly Compta</span>
        </div>
      </div>

      <div className="outer-wrap-bottom">
        <FormGroup className="form-icon icon-start">
          <Label for="lab">Ajouter un lien de paiement</Label>
          <Input
            id="lab"
            name="lab"
            placeholder="Lien"
            type="text"
            className="form-primary outline"
            value={paymentLink}
            onChange={(e) => setPaymentLink(e.target.value)}
          />
          <span className="icon icon-primary">
            <RiLinkM />
          </span>
        </FormGroup>
        <FormGroup className="form-icon icon-start">
          <Label for="lab">Ajouter l'email du destinataire</Label>
          <Input
            id="lab"
            name="lab"
            placeholder="Email"
            type="text"
            className={`form-primary ${
              needEmail ? "invalid-custom-input" : ""
            }`}
            value={receiverEmail}
            onChange={(e) => {
              setReceiverEmail(e.target.value);
              if (needEmail) {
                setNeedEmail(false);
              }
            }}
          />

          <span className="icon icon-primary ">
            <img src={EmailIcon} alt="icon" />
          </span>
        </FormGroup>
        {needEmail && (
          <span className="text-center text-danger">
            Une adresse email valide est requise
          </span>
        )}
        {needModelRef && (
          <FormGroup className="form-icon icon-start">
            <Label for="lab">Ajouter le nom du modèle</Label>
            <Input
              id="lab"
              name="lab"
              placeholder="Nom du modèle"
              type="text"
              className={`form-primary ${
                modelRef === "" ? "invalid-custom-input" : ""
              }`}
              value={modelRef}
              onChange={(e) => {
                setModelRef(e.target.value);
              }}
              invalid={modelRef === ""}
            />

            <span className="icon icon-primary ">
              <FaFileInvoice />
            </span>
          </FormGroup>
        )}
      </div>
    </>
  );
};

export default CreateFacture;
