import { AnimatePresence } from "framer-motion";
import React from "react";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import RegisterStepError from "./RegisterStepError";

const StepError = () => {
  const { deleteValue, getValue } = useLocalStorage();
  const navigate = useNavigate();
  const location = useLocation()
  const nextStep = () => {
    navigate("/register/activite");
  };

  const prevStep = () => {
    let url  = "/register/pack"
    if(location && location.search){
      url += location.search
    }
    navigate(url);
  };

  return (
    <>
      <AnimatePresence>
        <RegisterStepError />
      </AnimatePresence>
      <div className="bottomSteperCenter">
        <Button
          type="button"
          onClick={prevStep}
          color="primary"
          outline
          className="btn btn-prev "
        >
          <FiChevronsLeft />
          <span>Précédent</span>
        </Button>
      </div>
    </>
  );
};

export default StepError;
