import {Modal, ModalBody, ModalHeader} from "reactstrap";
import GestionBridgeAccount from "../../../AccountsChoice/GestionBridgeAccount/GestionBridgeAccount";
import {IBridgeAccount} from "../../../../interfaces";
import {useSelector} from "react-redux";

type Props = {
  openIbanModal: boolean;
  handelModal: () => void;
  getBridgeAccounts: () => Promise<void>;
  ibans: IBridgeAccount[];
  clientId: string;
};

const CompteGestionIbanModal = ({
  openIbanModal,
  handelModal,
  getBridgeAccounts,
  ibans,
  clientId,
}: Props) => {
  const companiesList = useSelector(
    (state: {root: object; user: Record<string, unknown>}) =>
      state.user.userCompanies
  ) as Array<Record<string, string>>;
  const company_id = useSelector(
    (state: {root: object; user: Record<string, unknown>}) =>
      state.user.company_id
  ) as string;

  return (
    <Modal
      className="modal-secondary modal-dialog-centered"
      isOpen={openIbanModal}
      toggle={handelModal}
    >
      <ModalHeader toggle={handelModal}>Gestion Iban</ModalHeader>
      <ModalBody>
        <div className="container">
          <GestionBridgeAccount
            getBridgeAccounts={getBridgeAccounts}
            ibans={ibans}
            handelModal={handelModal}
            clientId={clientId}
            companiesList={companiesList}
            companyId={company_id}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CompteGestionIbanModal;
