import {AxiosError, AxiosInstance} from "axios";

export const deleteBridgeItem = async ({
  api,
  itemId,
  clientId,
}: {
  api: AxiosInstance;
  itemId: number;
  clientId: string;
}) => {
  try {
    const body = {
      itemId: Number(itemId),
      clientId,
    };

    const res = await api.post("/api/v2/accountSync/deleteItem", body);
    return res;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const fixBridgeItem = async ({
  api,
  itemId,
  status,
  from,
  clientId,
}: {
  api: AxiosInstance;
  itemId: number;
  status: number;
  from: string;
  clientId: string;
}) => {
  try {
    const resp = await api.post("/api/v2/accountSync/fixItemStatus", {
      itemId,
      status,
      from,
      clientId,
    });

    return resp;
  } catch (error: any) {
    return Promise.reject(error);
  }
};
