import React from "react";
import "./Switch.scss";
interface SwitchProps {
  id?: string;
  onClick?: any;
  checked?: any;
  disabled?: boolean;
}

const Switch: React.FC<SwitchProps> = ({ id, onClick, checked, disabled }) => {
  return (
    <div className="switchbox">
      <input
        className="react-switch-checkbox"
        id={id}
        type="checkbox"
        onClick={onClick}
        checked={checked}
        readOnly
        disabled={disabled}
      />
      <label className="react-switch-label" htmlFor={id}>
        <span className={`react-switch-button`} />
      </label>
    </div>
  );
};

export default Switch;
