import React, { useEffect, useState } from "react";
import "./Categories.scss";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { data } from "../../../constants";
import { RiCloseLine, RiSearchLine } from "react-icons/ri";

import Select, { GroupBase } from "react-select";

import { BootyPagination } from "../../../components/table/pagination";
import { BsTrashFill } from "react-icons/bs";
import DataTable from "react-data-table-component";
import { IoMdCreate } from "react-icons/io";
import GridIcon from "../../../assets/GridIcon";
import GridBlueIcon from "../../../assets/images/svg/grid-icon-blue.svg";
import ZoomIcon from "../../../assets/ZoomIcon";
import config from "../../../config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ICategory } from "../../../interfaces";
import { ErrorLogger } from "../../../util/errorLogger";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { setCategories } from "../../../store/reducers/categories/categoriesSlice";
import useAxios from "../../../util/hooks/useAxios";

const options = [
  { value: "Décaissement", label: "Décaissement" },
  { value: "Encaissement", label: "Encaissement" },
];

const { API_URL, APPLICATION_ID } = config[process.env.NODE_ENV];

type EditFormValues = {
  name?: string | null;
  type?: { value: string; label: string } | null;
};

type CreateFormValues = {
  categ_name?: string | null;
  categ_type?: { value: string; label: string } | null;
};

type SearchFormValues = {
  text?: string | null;
};

export interface ClientsProps {}
const Categories: React.FC<ClientsProps> = ({}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [createLoading, setCreateLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [closeCategSearch, setCloseCategSearch] = useState<boolean>(false);
  const [singleCategory, setSingleCategory] = useState<ICategory | null>();
  const [localCategories, setLocalCategories] = useState<ICategory[]>([]);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);
  const ontoggle = () => {
    setOpen(true);
  };

  const [pending, setPending] = useState<boolean>(true);

  const dispatch = useDispatch();

  const onEdittoggle = (category: ICategory) => {
    setSingleCategory(category);
    setEditOpen(true);
  };

  const onDeletetoggle = (category: ICategory) => {
    setSingleCategory(category);
    setDeleteOpen(true);
  };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };

  const columns: any = [
    {
      name: "Nom de la catégorie",
      selector: (row: any) => row.name,
      sortable: true,
    },
    {
      name: "Type de la catégorie",
      selector: (row: any) => row.type,
      sortable: true,
    },

    {
      name: "Actions",
      button: true,
      cell: (row: ICategory) => (
        <div className="table-action">
          <button className="btn btn-blue" onClick={() => onEdittoggle(row)}>
            <IoMdCreate />
          </button>
          <button className="btn btn-red" onClick={() => onDeletetoggle(row)}>
            <BsTrashFill />
          </button>
        </div>
      ),
    },
  ];
  let api = useAxios();

  const getCategories = async () => {
    try {
      setLoading(true);

      const { data } = await api.post(
        `/api/Category/all`,
        {},
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setLocalCategories(data.data);
      setLoading(false);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting categories", error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (singleCategory) {
      reset({
        name: singleCategory.name,
        type: { value: singleCategory.type, label: singleCategory.type },
      });
    }
  }, [singleCategory]);

  useEffect(() => {
    if (!open) {
      createReset({
        categ_name: null,
        categ_type: null,
      });
    }
  }, [open]);

  //edit form hook
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: {},
  } = useForm<EditFormValues>({});

  //create form hook
  const {
    reset: createReset,
    control: createControl,
    register: createRegister,
    handleSubmit: createHandleSubmit,
    formState: {},
  } = useForm<CreateFormValues>({});

  //search form hook
  const {
    reset: searchReset,
    register: searchRegister,
    handleSubmit: searchHandleSubmit,
    formState: {},
  } = useForm<SearchFormValues>({});

  const searchCategory: SubmitHandler<SearchFormValues> = async (
    form: SearchFormValues
  ) => {
    try {
      setLoading(true);
      setPending(true);
      if (closeCategSearch) {
        searchReset();
        await getCategories();
        setCloseCategSearch(false);
      } else {
        setCloseCategSearch(true);
        const { data } = await api.post(
          `/api/Category/all`,
          {
            where: {
              name: {
                iLike: `%${form.text}%`,
              },
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );

        setLocalCategories(data.data);
        setLoading(false);
      }
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("category filter form", error);
    }
  };

  const editCategory: SubmitHandler<EditFormValues> = async (
    form: EditFormValues
  ) => {
    try {
      if (!form.name && !form.type) {
        setErrorMessage({
          type: "edit_empty_form",
          message: "Vous devez mettre à jour au moins une valeur",
        });
        return;
      }
      setEditLoading(true);
      let payload: Partial<EditFormValues> = {};

      if (form.name) payload.name = form.name;
      if (form.type) (payload.type as unknown as string) = form.type.value;

      await api.patch(
        `/api/Category`,
        {
          id: singleCategory?.id,
          ...payload,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setEditOpen(false);
      setSingleCategory(null);
      getCategories();
      setEditLoading(false);
      reset({
        name: null,
        type: null,
      });
    } catch (error: any) {
      ErrorLogger("updating category form", error);
      setEditLoading(false);
    }
  };

  const deleteCategory = async () => {
    try {
      setDeleteLoading(true);
      await api.post(
        `/api/Category/deleteWhere`,
        {
          where: {
            id: [singleCategory?.id],
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setSingleCategory(null);
      setDeleteOpen(false);
      getCategories();
      setDeleteLoading(false);
    } catch (error: any) {
      ErrorLogger("deleting a category", error);
      setDeleteLoading(false);
    }
  };

  const createCategory: SubmitHandler<CreateFormValues> = async (
    form: CreateFormValues
  ) => {
    try {
      if (!form.categ_name || !form.categ_type) {
        setErrorMessage({
          type: "creation_empty_form",
          message: "Vous devez fournir une catégorie valide",
        });
        return;
      }
      setCreateLoading(true);

      await api.post(
        `/api/Category/Create`,
        {
          name: form.categ_name,
          type: form.categ_type.value,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setOpen(false);
      getCategories();
      setCreateLoading(false);
    } catch (error: any) {
      ErrorLogger("creating category form", error);
      setCreateLoading(false);
    }
  };

  const { ref: textRef, ...text } = searchRegister("text");
  // edit register
  const { ref: nameRef, ...name } = register("name");
  const { ref: categ_nameRef, ...categ_name } = createRegister("categ_name");

  return (
    <div className="page page-categorie">
      <Card className="card-Table table-primary">
        <div className="search-top">
          <Row>
            <Col md={8}>
              <form
                onSubmit={searchHandleSubmit(searchCategory)}
                className="form-icon  icon-end"
              >
                <Input
                  id="mc"
                  {...text}
                  innerRef={textRef}
                  placeholder="Chercher une catégorie"
                  type="text"
                  className="form-secondary"
                />
                <button type="submit" className="icon icon-secondary ">
                  {closeCategSearch ? <RiCloseLine /> : <ZoomIcon />}
                </button>
              </form>
            </Col>
            <Col md={4} className="btn-addCat">
              <button className="btn btn-warning" onClick={() => ontoggle()}>
                <GridIcon /> <span>Ajouter une catégorie</span>
              </button>
            </Col>
          </Row>
        </div>
        <DataTable
          columns={columns}
          data={localCategories}
          noDataComponent={<p>Il n'y a aucun data à afficher</p>}
          pagination
          paginationComponent={(props) => {
            const customProps = { ...props, color: "primary" };
            return <BootyPagination {...customProps} />;
          }}
          progressPending={pending}
          progressComponent={
            <>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
            </>
          }
        />

        <div className="openbtn text-center">
          {/*delete category*/}
          <Modal
            className="modal-danger"
            isOpen={deleteOpen}
            toggle={() => {
              setSingleCategory(null);
              setDeleteOpen(false);
              setDeleteLoading(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setSingleCategory(null);
                setDeleteOpen(false);
                setDeleteLoading(false);
              }}
            >
              Supprimer une catégorie
            </ModalHeader>
            <ModalBody>
              <div className="content-text p-lg-5">
                <p className="msg-text">
                  Vous êtes sur de vouloir supprimer la catégorie{" "}
                  {singleCategory?.name}?
                </p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                outline
                onClick={() => {
                  setSingleCategory(null);
                  setDeleteOpen(false);
                  setDeleteLoading(false);
                }}
                disabled={deleteLoading}
              >
                Non
              </Button>
              <Button
                color="danger"
                onClick={async () => await deleteCategory()}
              >
                {deleteLoading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Oui"
                )}
              </Button>
            </ModalFooter>
          </Modal>

          {/*create category*/}
          <Modal
            className="modal-warning"
            isOpen={open}
            toggle={() => {
              setOpen(false);
              setCreateLoading(false);
              createReset({
                categ_name: null,
                categ_type: null,
              });
            }}
          >
            <ModalHeader
              toggle={() => {
                setOpen(false);
                setCreateLoading(false);
                createReset({
                  categ_name: null,
                  categ_type: null,
                });
              }}
            >
              Créer une nouvelle catégorie
            </ModalHeader>
            <form onSubmit={createHandleSubmit(createCategory)}>
              <ModalBody>
                <div className="content-creat-cat">
                  <FormGroup className="form-icon icon-start">
                    <Label for="name">Nom de la catégorie</Label>
                    <Input
                      id="name"
                      innerRef={categ_nameRef}
                      {...categ_name}
                      placeholder="Nom"
                      type="text"
                      className="form-warning"
                      onChange={() => {
                        setErrorMessage(null);
                      }}
                    />
                    <span className="icon icon-warning ">
                      <GridIcon />
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">Type de la catégorie</Label>
                    <Controller
                      name="categ_type"
                      control={createControl}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={options}
                          classNamePrefix="select"
                          className="custom-select form-warning"
                        />
                      )}
                    />
                  </FormGroup>
                  {errorMessage?.type === "creation_empty_form" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="warning"
                  outline
                  disabled={createLoading}
                  onClick={() => {
                    setOpen(false);
                    setCreateLoading(false);
                    createReset({
                      categ_name: null,
                      categ_type: null,
                    });
                  }}
                >
                  Annuler
                </Button>
                <Button color="warning" type="submit" disabled={createLoading}>
                  {createLoading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Enregistrer"
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/*edit category*/}
          <Modal
            className="modal-secondary"
            isOpen={editOpen}
            toggle={() => {
              setSingleCategory(null);
              setEditOpen(false);
              setEditLoading(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setSingleCategory(null);
                setEditOpen(false);
                setEditLoading(false);
              }}
            >
              Editer la catégorie
            </ModalHeader>
            <form onSubmit={handleSubmit(editCategory)}>
              <ModalBody>
                <div className="content-creat-cat">
                  <FormGroup className="form-icon icon-start">
                    <Label for="name">Nom de la catégorie</Label>
                    <Input
                      id="name"
                      innerRef={nameRef}
                      {...name}
                      placeholder="Nom"
                      type="text"
                      className="form-secondary"
                      onChange={() => {
                        setErrorMessage(null);
                      }}
                    />
                    <span className="icon icon-secondary ">
                      <img src={GridBlueIcon} alt="icon" />
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">Type de la catégorie</Label>
                    <Controller
                      name="type"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={options}
                          classNamePrefix="select"
                          className="custom-select form-secondary"
                        />
                      )}
                    />
                  </FormGroup>
                  {errorMessage?.type === "edit_empty_form" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  outline
                  onClick={() => {
                    setSingleCategory(null);
                    setEditOpen(false);
                    setEditLoading(false);
                  }}
                  disabled={editLoading}
                >
                  Annuler
                </Button>
                <Button color="secondary" type="submit" disabled={editLoading}>
                  {editLoading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Enregistrer"
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>
        </div>
      </Card>
    </div>
  );
};

export default Categories;
