import axios from "axios";
import { toast } from "react-toastify";
import config from "../../config";
import { saveAs } from "file-saver";
import { persistor } from "../../store";
import { resetRoot } from "../../store/reducers/root/rootSlice";
import { resetApplications } from "../../store/reducers/applications/applicationsSlice";
import { resetCategories } from "../../store/reducers/categories/categoriesSlice";
import { resetClientsList } from "../../store/reducers/clients-list/clientsSlice";
import { resetCompaniesList } from "../../store/reducers/companies-list/companiesSlice";
import { resetPermissionsList } from "../../store/reducers/permissions-list/permissionsListSlice";
import { resetUser } from "../../store/reducers/user/userSlice";
import { resetNotifs } from "../../store/reducers/messages-notifs/messagesNotifsSlice";
import { resetUtil, setReloadTickets } from "../../store/reducers/utils/utilSlice";

const { API_URL } = config[process.env.NODE_ENV];

export const createAxiosInsatance = (
  token: string,
  dispatch: any,
  action: any
) => {
  try {
    const axiosInstance = axios.create({
      baseURL: API_URL,
      headers: { "x-access-token": token },
    });

    axiosInstance.interceptors.response.use(
      async (res: any) => {
        return res;
      },
      (error: any) => {
        if (
          (token &&
            !error.response.data.auth &&
            error.response.data.message
              .toLowerCase()
              .includes("token rejected")) ||
          error.response.data.message
            .toLowerCase()
            .includes("failed to authenticate token.")
        ) {
          toast.warn("Votre session a expiré, veuillez vous connecter ", {
            closeButton: false,
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            toastId: "toast-2022",
            autoClose: 4000,
          });
          return Promise.reject(error);
        }
      }
    );
    return axiosInstance;
  } catch (error) {
    console.error("createAxiosInsatance : ", error);
  }
};

export const fileSaver = async (payload: any, fileName: string) => {
  try {
    let blob: any = null;
    if (typeof payload === "string") {
      const data = await fetch(payload);
      blob = await data.blob();
    } else {
      blob = payload;
    }
    saveAs(blob, fileName);
  } catch (error: any) {
    console.error("file saver : ", error);
  }
};
