import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import moment, { Moment } from "moment";
import { Company, IBilan, IS3, User } from "../../interfaces";
import { useSelector } from "react-redux";
import { ErrorLogger } from "../../util/errorLogger";
import { RiDownload2Fill } from "react-icons/ri";
import AttacheIcon from "../../assets/AttacheIcon";
import axios from "axios";
import config from "../../config";
import { BsTrashFill } from "react-icons/bs";
import FileViewer from "../../components/FileViewer";
import useAxios from "../../util/hooks/useAxios";
import FilePicker from "../../components/FilePicker";
import { UserTypes } from "../../util/context";
import { toast } from "react-toastify";
import { deleteFileFromList } from "../ClientInfo/InformationClient";

moment.updateLocale("fr", {});

type GeneralFormValues = {
  etat: number | null;
  faitMarquant: string | null;
  relevesBancaires: IS3[] | null;
  emprunt: IS3[] | null;
  cotisationsObligatoires: IS3[] | null;
  cotisationsFacultatives: IS3[] | null;
  documentsFiscaux: IS3[] | null;
  documentsSupplementaires: IS3[] | null;
  year: number | null;
  liasseFiscale: IS3[] | null;
  FEC: IS3[] | null;
  recapIR: IS3[] | null;
  fakeLiasseFiscale: IS3[] | null;
  fakeFEC: IS3[] | null;
  fakeRecapIR: IS3[] | null;
  zipURL: IS3[] | null;
  fullRecap: IS3[] | null;
};

const { API_URL } = config[process.env.NODE_ENV];

export default function EmptyBilan({
  context,
  company,
  callback,
}: {
  context?: string;
  company?: Company;
  callback: () => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentBilan, setCurrentBilan] = useState<IBilan | null>(null);
  const [relevesBancaires, setrelevesBancaires] = useState<any>([]);
  const [emprunt, setemprunt] = useState<any>([]);
  const [cotisationsObligatoires, setcotisationsObligatoires] = useState<any>(
    []
  );
  const [cotisationsFacultatives, setcotisationsFacultatives] = useState<any>(
    []
  );
  const [documentsFiscaux, setdocumentsFiscaux] = useState<any>([]);
  const [documentsSupplementaires, setdocumentsSupplementaires] = useState<any>(
    []
  );

  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user: connectedUser } = useSelector(
    (state: { root: object; user: Object }) => state.user
  ) as { user: User };

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormValues>({});

  const { ref: faitMarquantRef, ...faitMarquant } = register("faitMarquant");

  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  let api = useAxios();

  const handleBilan: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (!currentBilan) {
        formData.append("year", String(new Date().getFullYear() - 1));
        formData.append("company_id", company?.id!);
      }

      if (currentBilan) {
        formData.append("id", currentBilan.id);
      }

      if (creds.role !== UserTypes.Client) {
        formData.append("etat", "2");
        await api.post(
          `/api/Company/Update`,
          {
            id: company?.id!,
            Bilan: true,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      } else {
        formData.append("etat", "1");
      }

      if (currentBilan) {
        filesUploadLogic(formData);
      } else {
        if (relevesBancaires.length > 0) {
          for (let elt of relevesBancaires) {
            formData.append("relevesBancaires", elt);
          }
        }
        if (emprunt.length > 0) {
          for (let elt of emprunt) {
            formData.append("emprunt", elt);
          }
        }
        if (cotisationsObligatoires.length > 0) {
          for (let elt of cotisationsObligatoires) {
            formData.append("cotisationsObligatoires", elt);
          }
        }
        if (cotisationsFacultatives.length > 0) {
          for (let elt of cotisationsFacultatives) {
            formData.append("cotisationsFacultatives", elt);
          }
        }
        if (documentsFiscaux.length > 0) {
          for (let elt of documentsFiscaux) {
            formData.append("documentsFiscaux", elt);
          }
        }
        if (documentsSupplementaires.length > 0) {
          for (let elt of documentsSupplementaires) {
            formData.append("documentsSupplementaires", elt);
          }
        }
      }

      if (form.faitMarquant) formData.append("faitMarquant", form.faitMarquant);

      await api.post(
        `/api/Bilan/${currentBilan ? "Update" : "Create"}`,
        formData,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setLoading(false);
      await callback()!;
    } catch (error: any) {
      ErrorLogger("creating bilan", error);
    }
  };

  const filesUploadLogic = (formData: any) => {
    if (
      currentBilan?.relevesBancaires &&
      relevesBancaires.length > currentBilan?.relevesBancaires.length
    ) {
      for (let elt of relevesBancaires) {
        if (!elt.key) {
          formData.append("relevesBancaires", elt as any);
        }
      }
    } else {
      for (let elt of relevesBancaires) {
        formData.append("relevesBancaires", elt as any);
      }
    }

    if (
      currentBilan?.emprunt &&
      emprunt.length > currentBilan?.emprunt.length
    ) {
      for (let elt of emprunt) {
        if (!elt.key) {
          formData.append("emprunt", elt as any);
        }
      }
    } else {
      for (let elt of emprunt) {
        formData.append("emprunt", elt as any);
      }
    }

    if (
      currentBilan?.cotisationsObligatoires &&
      cotisationsObligatoires.length >
        currentBilan?.cotisationsObligatoires.length
    ) {
      for (let elt of cotisationsObligatoires) {
        if (!elt.key) {
          formData.append("cotisationsObligatoires", elt as any);
        }
      }
    } else {
      for (let elt of cotisationsObligatoires) {
        formData.append("cotisationsObligatoires", elt as any);
      }
    }

    if (
      currentBilan?.cotisationsFacultatives &&
      cotisationsFacultatives.length >
        currentBilan?.cotisationsFacultatives.length
    ) {
      for (let elt of cotisationsFacultatives) {
        if (!elt.key) {
          formData.append("cotisationsFacultatives", elt as any);
        }
      }
    } else {
      for (let elt of cotisationsFacultatives) {
        formData.append("cotisationsFacultatives", elt as any);
      }
    }

    if (
      currentBilan?.documentsFiscaux &&
      documentsFiscaux.length > currentBilan?.documentsFiscaux.length
    ) {
      for (let elt of documentsFiscaux) {
        if (!elt.key) {
          formData.append("documentsFiscaux", elt as any);
        }
      }
    } else {
      for (let elt of documentsFiscaux) {
        formData.append("documentsFiscaux", elt as any);
      }
    }

    if (
      currentBilan?.documentsSupplementaires &&
      documentsSupplementaires.length >
        currentBilan?.documentsSupplementaires.length
    ) {
      for (let elt of documentsSupplementaires) {
        if (!elt.key) {
          formData.append("documentsSupplementaires", elt as any);
        }
      }
    } else {
      for (let elt of documentsSupplementaires) {
        formData.append("documentsSupplementaires", elt as any);
      }
    }
  };

  const saveBilanFiles = async () => {
    try {
      setLoading(true);
      if (
        relevesBancaires.length === 0 &&
        emprunt.length === 0 &&
        cotisationsObligatoires.length === 0 &&
        cotisationsFacultatives.length === 0 &&
        documentsFiscaux.length === 0 &&
        documentsSupplementaires.length === 0
      ) {
        setLoading(false);
        toast.dismiss();
        toast.warning("Vous devez sauvegarder au moins un fichier", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const formData = new FormData();

      if (currentBilan) {
        formData.append("id", currentBilan?.id);
        filesUploadLogic(formData);
      } else {
        formData.append("year", String(new Date().getFullYear() - 1));
        formData.append("company_id", company?.id!);
        if (relevesBancaires.length > 0) {
          for (let elt of relevesBancaires) {
            formData.append("relevesBancaires", elt);
          }
        }

        if (emprunt.length > 0) {
          for (let elt of emprunt) {
            formData.append("emprunt", elt);
          }
        }

        if (cotisationsObligatoires.length > 0) {
          for (let elt of cotisationsObligatoires) {
            formData.append("cotisationsObligatoires", elt);
          }
        }

        if (cotisationsFacultatives.length > 0) {
          for (let elt of cotisationsFacultatives) {
            formData.append("cotisationsFacultatives", elt);
          }
        }

        if (documentsFiscaux.length > 0) {
          for (let elt of documentsFiscaux) {
            formData.append("documentsFiscaux", elt);
          }
        }

        if (documentsSupplementaires.length > 0) {
          for (let elt of documentsSupplementaires) {
            formData.append("documentsSupplementaires", elt);
          }
        }
      }

      await api.post(
        `/api/Bilan/${currentBilan ? "Update" : "Create"}`,
        formData,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setLoading(false);
      await getCurrentBilan()!;
      toast.dismiss();
      toast.success("Votre modification a été effectuée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("creating bilan", error);
    }
  };

  const getCurrentBilan = async () => {
    try {
      const { data } = await api.post(
        `/api/Bilan/all`,
        {
          where: {
            companyId: company && company.id ? company?.id : creds.company_id,
            year: new Date().getFullYear() - 1,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      console.log("bilan : ", data.data.bilan);
      if (data.data && data.data[0]) {
        const bilan = data.data[0] as IBilan;
        setCurrentBilan(bilan);
        setrelevesBancaires(bilan.relevesBancaires || []);
        setemprunt(bilan.emprunt || []);
        setcotisationsObligatoires(bilan.cotisationsObligatoires || []);
        setcotisationsFacultatives(bilan.cotisationsFacultatives || []);
        setdocumentsFiscaux(bilan.documentsFiscaux || []);
        setdocumentsSupplementaires(bilan.documentsSupplementaires || []);
      }
    } catch (error: any) {
      ErrorLogger("getting bilan data", error);
    }
  };

  useEffect(() => {
    getCurrentBilan();
  }, []);

  useEffect(() => {
    if (creds.company_id) {
      getCurrentBilan();
    }
  }, [creds.company_id]);

  const handleRelevChange = (event: { target: { files: any } }) => {
    setrelevesBancaires((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleEmpruntChange = (event: { target: { files: any } }) => {
    setemprunt((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleCotisationsObligatoiresChange = (event: {
    target: { files: any };
  }) => {
    setcotisationsObligatoires((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleCotisationsFacultativesChange = (event: {
    target: { files: any };
  }) => {
    setcotisationsFacultatives((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleDocumentsFiscauxChange = (event: { target: { files: any } }) => {
    setdocumentsFiscaux((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleDocumentsSupplementairesChange = (event: {
    target: { files: any };
  }) => {
    setdocumentsSupplementaires((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleBilan)}>
        <div className="form-bilan">
          <FormGroup className="form-icon icon-start">
            <Label for="cpwd">Fait(s) marquant(s)</Label>
            <Input
              className="form-secondary text-area-custom"
              type="textarea"
              rows="15"
              innerRef={faitMarquantRef}
              {...faitMarquant}
            />
          </FormGroup>
          <div className="box-bilan-item mt-5">
            <h3>
              Relevés bancaires{" "}
              <span className="required-file">(Obligatoire)</span>
            </h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Veuillez-nous transmettre les relevés bancaires de l’année
                  fiscale précédente (1er janvier au 31 décembre). Si vous
                  détenez plusieurs comptes professionnels (pour l’activité
                  renseignée), veuillez télécharger les relevés bancaires pour
                  chacun d’entre eux.
                </p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleRelevChange}
                  state={relevesBancaires}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    setLoading(true);
                    await deleteFileFromList(
                      [elt.key],
                      "relevesBancaires",
                      "Bilan",
                      currentBilan?.id!,
                      creds.token,
                      api
                    );
                    await callback()!;
                    setLoading(false);
                    toast.dismiss();
                    toast.success(
                      "Votre supression a été effectuée avec succès!",
                      {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setrelevesBancaires((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>Emprunt</h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Si vous avez un emprunt en cours (ou qui s’est terminé
                  durant l’exercice), veuillez télécharger le tableau
                  d’amortissement de celui-ci
                </p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleEmpruntChange}
                  state={emprunt}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    setLoading(true);
                    await deleteFileFromList(
                      [elt.key],
                      "emprunt",
                      "Bilan",
                      currentBilan?.id!,
                      creds.token,
                      api
                    );
                    await callback()!;
                    setLoading(false);
                    toast.dismiss();
                    toast.success(
                      "Votre supression a été effectuée avec succès!",
                      {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setemprunt((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>
              Cotisations sociales obligatoires{" "}
              <span className="required-file">(Obligatoire)</span>
            </h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Appel de cotisation et régularisation des différents
                  organismes
                </p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleCotisationsObligatoiresChange}
                  state={cotisationsObligatoires}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    setLoading(true);
                    await deleteFileFromList(
                      [elt.key],
                      "cotisationsObligatoires",
                      "Bilan",
                      currentBilan?.id!,
                      creds.token,
                      api
                    );
                    await callback()!;
                    setLoading(false);
                    toast.dismiss();
                    toast.success(
                      "Votre supression a été effectuée avec succès!",
                      {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setcotisationsObligatoires((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>Cotisations sociales facultatives</h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Tout document relatif à la souscription d’un contrat de
                  prévoyance ou de retraite facultatif
                </p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleCotisationsFacultativesChange}
                  state={cotisationsFacultatives}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    setLoading(true);
                    await deleteFileFromList(
                      [elt.key],
                      "cotisationsFacultatives",
                      "Bilan",
                      currentBilan?.id!,
                      creds.token,
                      api
                    );
                    await callback()!;
                    setLoading(false);
                    toast.dismiss();
                    toast.success(
                      "Votre supression a été effectuée avec succès!",
                      {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setcotisationsFacultatives((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>Documents fiscaux</h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>* On s’en charge 😉</p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleDocumentsFiscauxChange}
                  state={documentsFiscaux}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    setLoading(true);
                    await deleteFileFromList(
                      [elt.key],
                      "documentsFiscaux",
                      "Bilan",
                      currentBilan?.id!,
                      creds.token,
                      api
                    );
                    await callback()!;
                    setLoading(false);
                    toast.dismiss();
                    toast.success(
                      "Votre supression a été effectuée avec succès!",
                      {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setdocumentsFiscaux((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>Documents supplémentaires</h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Avez-vous d’autres éléments que vous souhaiteriez que nous
                  prenions en compte ?
                </p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleDocumentsSupplementairesChange}
                  state={documentsSupplementaires}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    setLoading(true);
                    await deleteFileFromList(
                      [elt.key],
                      "documentsSupplementaires",
                      "Bilan",
                      currentBilan?.id!,
                      creds.token,
                      api
                    );
                    await callback()!;
                    setLoading(false);
                    toast.dismiss();
                    toast.success(
                      "Votre supression a été effectuée avec succès!",
                      {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setdocumentsSupplementaires((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                />
              </Col>
            </Row>
          </div>
        </div>
        {errorMessage?.type === "missing_files" && (
          <Alert color="danger" className="mt-2">
            {errorMessage?.message}
          </Alert>
        )}
        <div className="text-center py-3 bottom-actions">
          {creds.role === UserTypes.Client && (
            <Button
              color="secondary"
              outline
              onClick={async () => {
                await saveBilanFiles();
              }}
              disabled={loading}
            >
              {loading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Sauvegarder les fichiers"
              )}
            </Button>
          )}
          <button
            type="submit"
            className="btn btn-secondary"
            disabled={
              loading ||
              (creds.role === UserTypes.Client &&
                (relevesBancaires.length === 0 ||
                  cotisationsObligatoires.length === 0))
            }
          >
            {loading ? (
              <Spinner color="light" type="border" size={"sm"}>
                Loading...
              </Spinner>
            ) : (
              "Valider"
            )}
          </button>
        </div>
      </form>
      <div className="openbtn text-center">
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </>
  );
}
