import React, { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import moment, { Moment } from "moment";
import { Company, IBilan, IS3, User } from "../../interfaces";
import { useDispatch, useSelector } from "react-redux";
import { ErrorLogger } from "../../util/errorLogger";
import { RiDownload2Fill } from "react-icons/ri";
import AttacheIcon from "../../assets/AttacheIcon";
import config from "../../config";
import axios from "axios";
import FileViewer from "../../components/FileViewer";
import { FaEye } from "react-icons/fa";
import { BsTrashFill } from "react-icons/bs";
import { deleteFileFromList } from "../ClientInfo/InformationClient";
import { toast, ToastContainer } from "react-toastify";
import useAxios from "../../util/hooks/useAxios";
import FilePicker from "../../components/FilePicker";
import { UserTypes } from "../../util/context";
import { fileSaver } from "../../util/helpers/createAxiosInsatance";
import { useNavigate } from "react-router-dom";
import { rehydrateUser } from "../../store/reducers/user/userSlice";

moment.updateLocale("fr", {});

type GeneralFormValues = {
  etat: number | null;
  faitMarquant: string | null;
  relevesBancaires: IS3[] | null;
  emprunt: IS3[] | null;
  cotisationsObligatoires: IS3[] | null;
  cotisationsFacultatives: IS3[] | null;
  documentsFiscaux: IS3[] | null;
  documentsSupplementaires: IS3[] | null;
  year: number | null;
  liasseFiscale: IS3[] | null;
  FEC: IS3[] | null;
  recapIR: IS3[] | null;
  fakeLiasseFiscale: IS3[] | null;
  fakeFEC: IS3[] | null;
  fakeRecapIR: IS3[] | null;
  zipURL: IS3[] | null;
  fullRecap: IS3[] | null;
};

const { API_URL } = config[process.env.NODE_ENV];

export default function FullBilan({
  company,
  callback,
  bilan,
}: {
  bilan: IBilan;
  company?: Company;
  callback: () => void;
}) {
  const [file, setFile] = useState("");

  const [closeBilanModal, setCloseBilanModal] = useState<boolean>(false);

  const [currentBilan, setCurrentBilan] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [closeLoading, setCloseLoading] = useState<boolean>(false);
  const [needPrevYear, setNeedPrevYear] = useState<boolean>(false);
  const [firstFileloadError, setFirstFileloadError] = useState<boolean>(false);
  const [secondFileloadError, setSecondFileloadError] =
    useState<boolean>(false);
  const [recapLoading, setRecapLoading] = useState<boolean>(false);
  const [prevRecapLoading, setPrevRecapLoading] = useState<boolean>(false);
  const [FECLoading, setFECLoading] = useState<boolean>(false);
  const [recapIRLoading, setRecapIRLoading] = useState<boolean>(false);
  const [liasseFiscaleLoading, setLiasseFiscaleLoading] =
    useState<boolean>(false);
  const [relevesBancaires, setrelevesBancaires] = useState<IS3[]>([]);
  const [emprunt, setemprunt] = useState<IS3[]>([]);
  const [cotisationsObligatoires, setcotisationsObligatoires] = useState<IS3[]>(
    []
  );
  const [cotisationsFacultatives, setcotisationsFacultatives] = useState<IS3[]>(
    []
  );
  const [documentsFiscaux, setdocumentsFiscaux] = useState<IS3[]>([]);
  const [documentsSupplementaires, setdocumentsSupplementaires] = useState<
    IS3[]
  >([]);
  const [liasseFiscale, setliasseFiscale] = useState<IS3 | any>();
  const [liasseFiscaleDouble, setliasseFiscaleDouble] = useState<IS3 | any>();
  const [prevYearLiasseFiscale, setPrevYearliasseFiscale] = useState<
    IS3 | any
  >();
  const [FEC, setFEC] = useState<IS3 | any>();
  const [recapIR, setrecapIR] = useState<IS3 | any>();

  const [fakeLiasseFiscale, setfakeLiasseFiscale] = useState<IS3 | any>();
  const [fakeFEC, setfakeFEC] = useState<IS3 | any>();
  const [fakeRecapIR, setfakeRecapIR] = useState<IS3 | any>();

  const [draftLiasseFiscale, setDraftLiasseFiscale] = useState<boolean | null>(
    false
  );
  const [draftFEC, setDraftFEC] = useState<boolean | null>(false);
  const [draftRecapIR, setDraftRecapIR] = useState<boolean | null>(false);

  const [zipURL, setzipURL] = useState<IS3 | any>();
  const [fullRecap, setfullRecap] = useState<IS3 | any>();

  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user: connectedUser } = useSelector(
    (state: { root: object; user: Object }) => state.user
  ) as { user: User };

  let api = useAxios();

  useEffect(() => {
    if (bilan.id || creds.company_id) {
      reset({
        faitMarquant: bilan.faitMarquant || null,
      });
      setrelevesBancaires(bilan.relevesBancaires || []);
      setemprunt(bilan.emprunt || []);
      setcotisationsObligatoires(bilan.cotisationsObligatoires || []);
      setcotisationsFacultatives(bilan.cotisationsFacultatives || []);
      setdocumentsFiscaux(bilan.documentsFiscaux || []);
      setdocumentsSupplementaires(bilan.documentsSupplementaires || []);

      setliasseFiscale(bilan.liasseFiscale);
      setFEC(bilan.FEC);
      setrecapIR(bilan.recapIR);

      setfakeLiasseFiscale(bilan.fakeLiasseFiscale || null);
      setfakeFEC(bilan.fakeFEC || null);
      setfakeRecapIR(bilan.fakeRecapIR || null);
      setzipURL(bilan.zipURL || null);
      setfullRecap(bilan.fullRecap);

      setDraftLiasseFiscale(bilan.draft_liasseFiscale || null);
      setDraftFEC(bilan.draft_FEC || null);
      setDraftRecapIR(bilan.draft_recapIR || null);
    }
  }, [bilan,creds.company_id]);

  const navigate = useNavigate();

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormValues>({});

  const { ref: faitMarquantRef, ...faitMarquant } = register("faitMarquant");
  const { ref: etatRef, ...etat } = register("etat");

  const handleBilan: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("id", bilan?.id);

      if (form.faitMarquant !== bilan.faitMarquant)
        formData.append("faitMarquant", form.faitMarquant!);

      // if (
      //   form.etat == 2 &&
      //   connectedUser &&
      //   creds.role !== UserTypes.Client &&
      //   bilan &&
      //   bilan.etat === 1
      // ) {
      //   formData.append("etat", "2");
      // }

      if (
        bilan.relevesBancaires &&
        relevesBancaires.length > bilan.relevesBancaires.length
      ) {
        for (let elt of relevesBancaires) {
          if (!elt.key) {
            formData.append("relevesBancaires", elt as any);
          }
        }
      } else {
        for (let elt of relevesBancaires) {
          formData.append("relevesBancaires", elt as any);
        }
      }

      if (bilan.emprunt && emprunt.length > bilan.emprunt.length) {
        for (let elt of emprunt) {
          if (!elt.key) {
            formData.append("emprunt", elt as any);
          }
        }
      } else {
        for (let elt of emprunt) {
          formData.append("emprunt", elt as any);
        }
      }

      if (
        bilan.cotisationsObligatoires &&
        cotisationsObligatoires.length > bilan.cotisationsObligatoires.length
      ) {
        for (let elt of cotisationsObligatoires) {
          if (!elt.key) {
            formData.append("cotisationsObligatoires", elt as any);
          }
        }
      } else {
        for (let elt of cotisationsObligatoires) {
          formData.append("cotisationsObligatoires", elt as any);
        }
      }

      if (
        bilan.cotisationsFacultatives &&
        cotisationsFacultatives.length > bilan.cotisationsFacultatives.length
      ) {
        for (let elt of cotisationsFacultatives) {
          if (!elt.key) {
            formData.append("cotisationsFacultatives", elt as any);
          }
        }
      } else {
        for (let elt of cotisationsFacultatives) {
          formData.append("cotisationsFacultatives", elt as any);
        }
      }

      if (
        bilan.documentsFiscaux &&
        documentsFiscaux.length > bilan.documentsFiscaux.length
      ) {
        for (let elt of documentsFiscaux) {
          if (!elt.key) {
            formData.append("documentsFiscaux", elt as any);
          }
        }
      } else {
        for (let elt of documentsFiscaux) {
          formData.append("documentsFiscaux", elt as any);
        }
      }

      if (
        bilan.documentsSupplementaires &&
        documentsSupplementaires.length > bilan.documentsSupplementaires.length
      ) {
        for (let elt of documentsSupplementaires) {
          if (!elt.key) {
            formData.append("documentsSupplementaires", elt as any);
          }
        }
      } else {
        for (let elt of documentsSupplementaires) {
          formData.append("documentsSupplementaires", elt as any);
        }
      }

      if (liasseFiscale && !bilan.liasseFiscale.key) {
        formData.append(`draft_liasseFiscale`, "false");
      }

      if (FEC && !bilan.FEC.key) {
        formData.append(`draft_FEC`, "false");
      }

      if (recapIR && !bilan.recapIR.key) {
        formData.append(`draft_recapIR`, "false");
      }

      await api.post(`/api/Bilan/Update`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });
      await callback()!;
      setNeedPrevYear(false);
      setPrevYearliasseFiscale(null);
      setLoading(false);
      toast.dismiss();
      toast.success("Votre modification a été effectuée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("updating bilan", error);
      setLoading(false);
    }
  };

  const handleBilanState = async () => {
    try {
      setLoading(true);

      await api.post(
        `/api/Bilan/Update`,
        {
          id: bilan.id,
          etat: bilan.etat === 1 ? 2 : 1,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await callback()!;
      setLoading(false);
      toast.dismiss();
      toast.success("Votre modification a été effectuée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("updating bilan", error);
      setLoading(false);
    }
  };

  const createDraftFile = async (
    file: any,
    attribute: string,
    year: number = 0,
    notNeedCallback: boolean = false
  ) => {
    try {
      setLoading(true);
      switch (attribute) {
        case "liasseFiscale":
          setLiasseFiscaleLoading(true);
          break;
        case "FEC":
          setFECLoading(true);
          break;
        case "recapIR":
          setRecapIRLoading(true);
          break;

        default:
          break;
      }
      const formData = new FormData();
      if (year !== 0) {
        formData.append("year", String(year));
      } else {
        formData.append("id", bilan?.id);
      }

      formData.append(attribute, file);
      formData.append(`draft_${attribute}`, true as any);

      const { data: updatedBilan } = await api.post(
        `/api/Bilan/Update`,
        formData,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (!notNeedCallback) {
        await callback()!;
      }
      setLoading(false);
      switch (attribute) {
        case "liasseFiscale":
          setLiasseFiscaleLoading(false);
          break;
        case "FEC":
          setFECLoading(false);
          break;
        case "recapIR":
          setRecapIRLoading(false);
          break;

        default:
          break;
      }

      if (
        updatedBilan &&
        updatedBilan.updated.length > 0 &&
        updatedBilan.updated[0].id &&
        updatedBilan.updated[0].view
      ) {
        return updatedBilan.updated[0].view;
      } else {
        return null;
      }
    } catch (error: any) {
      ErrorLogger("updating bilan", error);
      setLoading(false);
      switch (attribute) {
        case "liasseFiscale":
          setLiasseFiscaleLoading(false);
          break;
        case "FEC":
          setFECLoading(false);
          break;
        case "recapIR":
          setRecapIRLoading(false);
          break;

        default:
          break;
      }
    }
  };

  const deleteDraftFile = async (attribute: string, year: number = 0) => {
    try {
      setLoading(true);

      const formData = new FormData();
      let id: string = "";

      if (year !== 0) {
        const { data } = await api.post(
          `/api/Bilan/all`,
          {
            where: {
              companyId: company?.id,
              year: new Date().getFullYear() - 2,
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        if (data.data.length > 0) {
          id = data.data[0].id;
        }
      } else {
        id = bilan?.id;
      }

      formData.append("id", id);
      formData.append(attribute, "");
      formData.append(
        `fake${attribute.charAt(0).toUpperCase() + attribute.slice(1)}`,
        ""
      );
      await api.patch(`/api/Bilan/files`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      await api.post(
        `/api/Bilan/Update`,
        {
          id: id,
          [`draft_${attribute}`]: false,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await callback()!;
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("updating bilan", error);
      setLoading(false);
    }
  };

  const dispatch = useDispatch();

  const closeCurrentBilan = async () => {
    try {
      setCloseLoading(true);

      const { data } = await api.post(
        `/api/Bilan/Update`,
        {
          id: bilan?.id,
          etat: 3,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (data.updated.length > 0) {
        const hasZip = data.updated[0].view.zipURL;
        if (hasZip && hasZip.url) {
          await fileSaver(
            hasZip.url,
            `bilan_${moment().get("year") - 1}_${
              company?.name.replace(/[^a-zA-Z0-9 ]/g, "") ||
              company?.email.replace(/[^a-zA-Z0-9 ]/g, "")
            }.zip`
          );
        }
      }
      await callback()!;
      setCloseLoading(false);
      setCloseBilanModal(false);
      dispatch(rehydrateUser());
      navigate("/");
    } catch (error: any) {
      ErrorLogger("updating bilan", error);
      setCloseLoading(false);
      setCloseBilanModal(false);
      await callback()!;
    }
  };

  const checkHasPrevLiasse = async () => {
    try {
      const { data } = await api.post(
        `/api/Bilan/all`,
        {
          where: {
            companyId: company?.id,
            year: new Date().getFullYear() - 2,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (data.data && data.data.length > 0 && data.data[0].liasseFiscale) {
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      ErrorLogger("checking has prevLisse bilan", error);
    }
  };

  const createRecap = async (currentFile: any) => {
    try {
      setFirstFileloadError(false);
      setSecondFileloadError(false);
      setRecapLoading(true);

      const formData = new FormData();
      formData.append("bilanId", bilan?.id);
      formData.append("companyId", company?.id!);
      formData.append("thisYearliasse", currentFile);

      // first year client bilan
      if (String(company?.beginningOfFiscalYear) === "true") {
        formData.append("year", String(new Date().getFullYear() - 1));
        const { data } = await api.post(
          `/api/documents/create-recap-one`,
          formData,
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        if (data.create === 0) {
          setRecapLoading(false);
          setFirstFileloadError(true);
          return 0;
        }

        if (data.create === 1) {
          setRecapLoading(false);
          return 1;
        }
      } else {
        const checkHasPrevLiasseValue = await checkHasPrevLiasse();
        // doesn't have previous bilan
        if (!checkHasPrevLiasseValue) {
          setRecapLoading(false);
          setNeedPrevYear(true);
          return 2;
        } else {
          formData.append("year", String(new Date().getFullYear() - 1));
          const { data } = await api.post(
            `/api/documents/create-recap-two`,
            formData,
            {
              headers: {
                "x-access-token": creds.token,
              },
            }
          );

          if (data.create === 0 || data.create === -1) {
            setRecapLoading(false);
            setFirstFileloadError(true);
            return 0;
          }

          if (data.create === 1) {
            setRecapLoading(false);
            return 1;
          }
        }
      }
    } catch (error: any) {
      ErrorLogger("creating recap bilan", error);
      setRecapLoading(false);
      setFirstFileloadError(true);
      return 0;
    }
  };

  const createDoubleFileRecap = async (currentFile: any, prevFile: any) => {
    try {
      setSecondFileloadError(false);
      setPrevRecapLoading(true);

      const formData = new FormData();
      formData.append("bilanId", bilan?.id);
      formData.append("companyId", company?.id!);
      formData.append("thisYearliasse", currentFile);
      formData.append("prevYearLiasse", prevFile);

      formData.append("year", String(new Date().getFullYear() - 1));
      const { data } = await api.post(
        `/api/documents/create-recap-three`,
        formData,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (data.create === 0) {
        setPrevRecapLoading(false);
        setSecondFileloadError(true);
        return 0;
      }

      if (data.create === 1) {
        setPrevRecapLoading(false);
        return 1;
      }
    } catch (error: any) {
      ErrorLogger("creating recap bilan", error);
      setPrevRecapLoading(false);
      setSecondFileloadError(true);
      return 0;
    }
  };

  const resetBilan = async () => {
    try {
      const formData = new FormData();
      setFirstFileloadError(false);
      setLoading(false);
      setNeedPrevYear(false);
      setRecapLoading(false);
      setPrevRecapLoading(false);
      setPrevYearliasseFiscale(null);
      setliasseFiscaleDouble(null);
      formData.append("id", bilan.id);
      formData.append("liasseFiscale", "");
      formData.append("FEC", "");
      formData.append("recapIR", "");
      formData.append("fakeLiasseFiscale", "");
      formData.append("fakeFEC", "");
      formData.append("fakeRecapIR", "");
      formData.append("zipURL", "");
      formData.append("fullRecap", "");

      await api.patch(`/api/Bilan/files`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      await api.post(
        `/api/Bilan/Update`,
        {
          id: bilan?.id,
          draft_recapIR: false,
          draft_FEC: false,
          draft_liasseFiscale: false,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await callback()!;
    } catch (error: any) {
      ErrorLogger("resetting bilan", error);
      setRecapLoading(false);
      setPrevRecapLoading(false);
    }
  };

  const handleRelevChange = (event: { target: { files: any } }) => {
    setrelevesBancaires((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleEmpruntChange = (event: { target: { files: any } }) => {
    setemprunt((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleCotisationsObligatoiresChange = (event: {
    target: { files: any };
  }) => {
    setcotisationsObligatoires((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleCotisationsFacultativesChange = (event: {
    target: { files: any };
  }) => {
    setcotisationsFacultatives((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleDocumentsFiscauxChange = (event: { target: { files: any } }) => {
    setdocumentsFiscaux((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleDocumentsSupplementairesChange = (event: {
    target: { files: any };
  }) => {
    setdocumentsSupplementaires((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const checkHasDocs = () => {
    return (
      (bilan.cotisationsFacultatives &&
        bilan.cotisationsFacultatives.length > 0) ||
      (bilan.cotisationsObligatoires &&
        bilan.cotisationsObligatoires.length > 0) ||
      (bilan.documentsFiscaux && bilan.documentsFiscaux.length > 0) ||
      (bilan.documentsSupplementaires &&
        bilan.documentsSupplementaires.length > 0) ||
      (bilan.emprunt && bilan.emprunt.length > 0) ||
      (bilan.relevesBancaires && bilan.relevesBancaires.length > 0) ||
      bilan.etat
    );
  };

  return (
    <React.Fragment>
      <div className="progress-bilan">
        <h3>Etat d'avancement</h3>
        {bilan ? (
          <Progress
            value={
              !checkHasDocs()
                ? "10"
                : bilan.etat === 1
                ? "33"
                : bilan.etat === 2
                ? "66"
                : "100"
            }
          >
            <span className="value-bilan">
              <span>
                {!checkHasDocs()
                  ? "0"
                  : bilan.etat === 1
                  ? "33"
                  : bilan.etat === 2
                  ? "66"
                  : "100"}
                %
              </span>
            </span>
          </Progress>
        ) : (
          <Spinner color="info" type="border" size={"sm"}>
            Loading...
          </Spinner>
        )}
      </div>
      <form onSubmit={handleSubmit(handleBilan)}>
        <div className="form-bilan">
          <FormGroup className="form-icon icon-start">
            <Label for="cpwd">Fait(s) marquant(s)</Label>
            <Input
              className="form-secondary text-area-custom"
              type="textarea"
              rows="15"
              innerRef={faitMarquantRef}
              {...faitMarquant}
              disabled={creds.role === UserTypes.Client}
            />
          </FormGroup>
          <div className="box-bilan-item mt-5">
            <h3>
              Relevés bancaires{" "}
              <span className="required-file">(Obligatoire)</span>
            </h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Veuillez-nous transmettre les relevés bancaires de l’année
                  fiscale précédente (1er janvier au 31 décembre). Si vous
                  détenez plusieurs comptes professionnels (pour l’activité
                  renseignée), veuillez télécharger les relevés bancaires pour
                  chacun d’entre eux.
                </p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleRelevChange}
                  state={relevesBancaires}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    setLoading(true);
                    await deleteFileFromList(
                      [elt.key],
                      "relevesBancaires",
                      "Bilan",
                      bilan?.id!,
                      creds.token,
                      api
                    );
                    await callback()!;
                    setLoading(false);
                    toast.dismiss();
                    toast.success(
                      "Votre supression a été effectuée avec succès!",
                      {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setrelevesBancaires((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                  disabled={creds.role === UserTypes.Client}
                  disableDelete={creds.role === UserTypes.Client}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>Emprunt</h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Si vous avez un emprunt en cours (ou qui s’est terminé
                  durant l’exercice), veuillez télécharger le tableau
                  d’amortissement de celui-ci
                </p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleEmpruntChange}
                  state={emprunt}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    setLoading(true);
                    await deleteFileFromList(
                      [elt.key],
                      "emprunt",
                      "Bilan",
                      bilan?.id!,
                      creds.token,
                      api
                    );
                    await callback()!;
                    setLoading(false);
                    toast.dismiss();
                    toast.success(
                      "Votre supression a été effectuée avec succès!",
                      {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setemprunt((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                  disabled={creds.role === UserTypes.Client}
                  disableDelete={creds.role === UserTypes.Client}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>
              Cotisations sociales obligatoires{" "}
              <span className="required-file">(Obligatoire)</span>
            </h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Appel de cotisation et régularisation des différents
                  organismes
                </p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleCotisationsObligatoiresChange}
                  state={cotisationsObligatoires}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    setLoading(true);
                    await deleteFileFromList(
                      [elt.key],
                      "cotisationsObligatoires",
                      "Bilan",
                      bilan?.id!,
                      creds.token,
                      api
                    );
                    await callback()!;
                    setLoading(false);
                    toast.dismiss();
                    toast.success(
                      "Votre supression a été effectuée avec succès!",
                      {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setcotisationsObligatoires((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                  disabled={creds.role === UserTypes.Client}
                  disableDelete={creds.role === UserTypes.Client}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>Cotisations sociales facultatives</h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Tout document relatif à la souscription d’un contrat de
                  prévoyance ou de retraite facultatif
                </p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleCotisationsFacultativesChange}
                  state={cotisationsFacultatives}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    setLoading(true);
                    await deleteFileFromList(
                      [elt.key],
                      "cotisationsFacultatives",
                      "Bilan",
                      bilan?.id!,
                      creds.token,
                      api
                    );
                    await callback()!;
                    setLoading(false);
                    toast.dismiss();
                    toast.success(
                      "Votre supression a été effectuée avec succès!",
                      {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setcotisationsFacultatives((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                  disabled={creds.role === UserTypes.Client}
                  disableDelete={creds.role === UserTypes.Client}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>Documents fiscaux</h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>* On s’en charge 😉</p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleDocumentsFiscauxChange}
                  state={documentsFiscaux}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    setLoading(true);
                    await deleteFileFromList(
                      [elt.key],
                      "documentsFiscaux",
                      "Bilan",
                      bilan?.id!,
                      creds.token,
                      api
                    );
                    await callback()!;
                    setLoading(false);
                    toast.dismiss();
                    toast.success(
                      "Votre supression a été effectuée avec succès!",
                      {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setdocumentsFiscaux((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                  disabled={creds.role === UserTypes.Client}
                  disableDelete={creds.role === UserTypes.Client}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>Documents supplémentaires</h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Avez-vous d’autres éléments que vous souhaiteriez que nous
                  prenions en compte ?
                </p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleDocumentsSupplementairesChange}
                  state={documentsSupplementaires}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    setLoading(true);
                    await deleteFileFromList(
                      [elt.key],
                      "documentsSupplementaires",
                      "Bilan",
                      bilan?.id!,
                      creds.token,
                      api
                    );
                    await callback()!;
                    setLoading(false);
                    toast.dismiss();
                    toast.success(
                      "Votre supression a été effectuée avec succès!",
                      {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setdocumentsSupplementaires((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                  disabled={creds.role === UserTypes.Client}
                  disableDelete={creds.role === UserTypes.Client}
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className="noly-compta-dilan">
          <div className="check-box check-secondary mb-4">
            <input
              className="styled-checkbox"
              id="checkbox-1"
              type="checkbox"
              value="2"
              ref={etatRef}
              {...etat}
              disabled={creds.role === UserTypes.Client}
              defaultChecked={bilan && bilan.etat > 1}
              onChange={async () => await handleBilanState()}
            />
            <label htmlFor="checkbox-1">
              Le comptable a effectué la révision des comptes ?
            </label>
          </div>
          <div className="box-bilan-item">
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <h3>Liasse fiscale</h3>
                <>
                  {creds.role !== UserTypes.Client && (
                    <>
                      {recapLoading && (
                        <span className="recap-creation">
                          Création du fichier récapitulatif Bilan, veuillez
                          patienter ...
                        </span>
                      )}
                      {firstFileloadError && (
                        <span className="recap-error">
                          Une erreur s'est produite lors du traitement de votre
                          fichier.
                        </span>
                      )}
                    </>
                  )}
                </>
              </Col>

              {creds.role !== UserTypes.Client ? (
                <>
                  {String(company?.beginningOfFiscalYear) === "null" &&
                  creds.role !== UserTypes.Client ? (
                    <span>
                      Veuillez mettre à jour le statut de{" "}
                      <strong>premier exercice</strong> de la société dans
                      l'onglet{" "}
                      <strong>
                        {" "}
                        Informations société / Exercice Précédent{" "}
                      </strong>
                    </span>
                  ) : (
                    <Col md={4} sm={5} className="right-bilan-file">
                      <FormGroup className="form-file file-secondary ">
                        <span className="label-file">
                          {recapLoading || liasseFiscaleLoading ? (
                            <React.Fragment></React.Fragment>
                          ) : (
                            "Déposez les fichiers ici"
                          )}
                        </span>
                        <label className="file-box ">
                          {recapLoading || liasseFiscaleLoading ? (
                            <React.Fragment>
                              <Spinner color="info" type="border" size={"sm"}>
                                Loading...
                              </Spinner>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <RiDownload2Fill />
                              <Input
                                type="file"
                                placeholder="Nom white label"
                                className="form-default"
                                onChange={async (event) => {
                                  try {
                                    const data = await createRecap(
                                      event.target.files![0]
                                    );

                                    if (data === 1 || data === 2) {
                                      const bilan = await createDraftFile(
                                        event.target.files![0],
                                        "liasseFiscale",
                                        0,
                                        data === 2
                                      );
                                      if (
                                        data === 2 &&
                                        bilan &&
                                        bilan.liasseFiscale
                                      ) {
                                        setliasseFiscale(bilan.liasseFiscale);
                                      }
                                      setliasseFiscaleDouble(
                                        event.target.files![0]
                                      );
                                    }
                                  } catch (error) {
                                    console.error(error);
                                  }
                                }}
                                disabled={recapLoading || prevRecapLoading}
                              />
                              <span> Télécharger d'ici</span>
                            </React.Fragment>
                          )}
                        </label>
                      </FormGroup>
                      <div className="list-Files">
                        {liasseFiscale && (
                          <React.Fragment>
                            {!liasseFiscale.key ? (
                              <React.Fragment>
                                <span className={`file-box-item-with-clickers`}>
                                  <span className="file-viewer-click">
                                    <AttacheIcon />
                                    <span>{(liasseFiscale as any).name}</span>
                                  </span>
                                  <span
                                    className="file-delete-click"
                                    onClick={async () => {
                                      await deleteDraftFile("liasseFiscale");
                                      setliasseFiscale(null);
                                    }}
                                  >
                                    <BsTrashFill />
                                  </span>
                                </span>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <span className={`file-box-item-with-clickers`}>
                                  <span
                                    className="file-viewer-click"
                                    onClick={() => {
                                      setUrl(liasseFiscale.url);
                                      setViewModal(true);
                                    }}
                                  >
                                    <AttacheIcon />
                                    <span>{liasseFiscale.key}</span>
                                  </span>
                                  <span
                                    className="file-delete-click"
                                    onClick={async () => {
                                      await deleteDraftFile("liasseFiscale");
                                      setliasseFiscale(null);
                                      if (fullRecap) {
                                        await deleteDraftFile("fullRecap");
                                        setfullRecap(null);
                                      }
                                    }}
                                  >
                                    <BsTrashFill />
                                  </span>
                                </span>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    </Col>
                  )}
                </>
              ) : (
                <Col md={4} sm={5} className="right-bilan-file">
                  {draftLiasseFiscale && fakeLiasseFiscale ? (
                    <h5
                      className="bilan-admin-file-ready"
                      onClick={() => {
                        setUrl(fakeLiasseFiscale.url);
                        setViewModal(true);
                      }}
                    >
                      Voir le fichier
                    </h5>
                  ) : (
                    <h5 className="text-danger">Liasse fiscale en cours</h5>
                  )}
                </Col>
              )}
            </Row>
          </div>
          {creds.role !== UserTypes.Client &&
            needPrevYear &&
            !recapLoading &&
            !liasseFiscaleLoading && (
              <div className="box-bilan-item">
                <Row className="inner-box-bilan">
                  <Col md={8} sm={7} className="box-text-bilan">
                    <h3>
                      Liasse fiscale de l'année {new Date().getFullYear() - 2}{" "}
                    </h3>
                    <span>
                      Vous avez besoin de la liasse fiscale de l'année{" "}
                      {new Date().getFullYear() - 2}, vous devez la télécharger
                    </span>
                    <br />
                    {prevRecapLoading && (
                      <span className="recap-creation">
                        Création du fichier de récapitulation du bilan, veuillez
                        patienter ...
                      </span>
                    )}
                    {secondFileloadError && (
                      <span className="recap-error">
                        Une erreur s'est produite lors du traitement de votre
                        fichier.
                      </span>
                    )}
                  </Col>
                  <Col md={4} sm={5} className="right-bilan-file">
                    <FormGroup className="form-file file-secondary ">
                      <span className="label-file">
                        {prevRecapLoading ? (
                          <React.Fragment></React.Fragment>
                        ) : (
                          "Déposez les fichiers ici"
                        )}
                      </span>
                      <label className="file-box ">
                        {prevRecapLoading ? (
                          <React.Fragment>
                            {" "}
                            <Spinner color="info" type="border" size={"sm"}>
                              Loading...
                            </Spinner>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <RiDownload2Fill />
                            <Input
                              type="file"
                              placeholder="Nom white label"
                              className="form-default"
                              onChange={async (event) => {
                                try {
                                  const res = await createDoubleFileRecap(
                                    liasseFiscaleDouble,
                                    event.target.files![0]
                                  );
                                  if (res === 1) {
                                    setPrevYearliasseFiscale(
                                      event.target.files![0]
                                    );
                                    await createDraftFile(
                                      event.target.files![0],
                                      "liasseFiscale",
                                      new Date().getFullYear() - 2
                                    );
                                  }
                                  setliasseFiscaleDouble(null);
                                } catch (error) {
                                  console.error(error);
                                }
                              }}
                              disabled={recapLoading || prevRecapLoading}
                            />
                            <span> Télécharger d'ici</span>
                          </React.Fragment>
                        )}
                      </label>
                    </FormGroup>
                    <div className="list-Files">
                      {prevYearLiasseFiscale && (
                        <React.Fragment>
                          {!prevYearLiasseFiscale.key ? (
                            <React.Fragment>
                              <span className={`file-box-item-with-clickers`}>
                                <span
                                  className="file-viewer-click"
                                  onClick={() => {
                                    setUrl(
                                      URL.createObjectURL(
                                        prevYearLiasseFiscale as any
                                      )
                                    );
                                    setViewModal(true);
                                  }}
                                >
                                  <AttacheIcon />
                                  <span>
                                    {(prevYearLiasseFiscale as any).name}
                                  </span>
                                </span>
                                <span
                                  className="file-delete-click"
                                  onClick={async () => {
                                    await deleteDraftFile(
                                      "liasseFiscale",
                                      new Date().getFullYear() - 2
                                    );
                                    setPrevYearliasseFiscale(null);
                                  }}
                                >
                                  <BsTrashFill />
                                </span>
                              </span>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <span className={`file-box-item-with-clickers`}>
                                <span
                                  className="file-viewer-click"
                                  onClick={() => {
                                    setUrl(prevYearLiasseFiscale.url);
                                    setViewModal(true);
                                  }}
                                >
                                  <AttacheIcon />
                                  <span>{prevYearLiasseFiscale.key}</span>
                                </span>
                                <span
                                  className="file-delete-click"
                                  onClick={async () => {
                                    await deleteDraftFile(
                                      "liasseFiscale",
                                      new Date().getFullYear() - 2
                                    );
                                    setPrevYearliasseFiscale(null);
                                  }}
                                >
                                  <BsTrashFill />
                                </span>
                              </span>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          <div className="box-bilan-item">
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <h3>FEC</h3>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                {creds.role !== UserTypes.Client ? (
                  <>
                    <FormGroup className="form-file file-secondary ">
                      <span className="label-file">
                        {FECLoading ? (
                          <React.Fragment></React.Fragment>
                        ) : (
                          "Déposez les fichiers ici"
                        )}
                      </span>
                      <label className="file-box ">
                        {FECLoading ? (
                          <React.Fragment>
                            <Spinner color="info" type="border" size={"sm"}>
                              Loading...
                            </Spinner>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <RiDownload2Fill />
                            <Input
                              type="file"
                              placeholder="Nom white label"
                              className="form-default"
                              onChange={async (event) => {
                                await createDraftFile(
                                  event.target.files![0],
                                  "FEC"
                                );
                                setFEC(event.target.files![0]);
                              }}
                            />
                            <span> Télécharger d'ici</span>
                          </React.Fragment>
                        )}
                      </label>
                    </FormGroup>
                  </>
                ) : (
                  <></>
                )}
                {creds.role !== UserTypes.Client ? (
                  <div className="list-Files">
                    {FEC && (
                      <React.Fragment>
                        {!FEC.key ? (
                          <React.Fragment>
                            <span className={`file-box-item-with-clickers`}>
                              <span
                                className="file-viewer-click"
                                onClick={() => {
                                  setUrl(URL.createObjectURL(FEC as any));
                                  setViewModal(true);
                                }}
                              >
                                <AttacheIcon />
                                <span>{(FEC as any).name}</span>
                              </span>
                              <span
                                className="file-delete-click"
                                onClick={async () => {
                                  await deleteDraftFile("FEC");
                                  setFEC(null);
                                }}
                              >
                                <BsTrashFill />
                              </span>
                            </span>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <span className={`file-box-item-with-clickers`}>
                              <span
                                className="file-viewer-click"
                                onClick={() => {
                                  setUrl(FEC.url);
                                  setViewModal(true);
                                }}
                              >
                                <AttacheIcon />
                                <span>{FEC.key}</span>
                              </span>
                              <span
                                className="file-delete-click"
                                onClick={async () => {
                                  await deleteDraftFile("FEC");
                                  setFEC(null);
                                }}
                              >
                                <BsTrashFill />
                              </span>
                            </span>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                ) : (
                  <>
                    {draftFEC && fakeFEC ? (
                      <h5
                        className="bilan-admin-file-ready"
                        onClick={() => {
                          setUrl(fakeFEC.url);
                          setViewModal(true);
                        }}
                      >
                        Voir le fichier
                      </h5>
                    ) : (
                      <h5 className="text-danger">FEC en cours</h5>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <h3>Aide pour impôt sur le revenu</h3>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                {creds.role !== UserTypes.Client ? (
                  <>
                    <FormGroup className="form-file file-secondary ">
                      <span className="label-file">
                        {recapIRLoading ? (
                          <React.Fragment></React.Fragment>
                        ) : (
                          "Déposez les fichiers ici"
                        )}
                      </span>
                      <label className="file-box ">
                        {recapIRLoading ? (
                          <React.Fragment>
                            <Spinner color="info" type="border" size={"sm"}>
                              Loading...
                            </Spinner>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <RiDownload2Fill />
                            <Input
                              type="file"
                              placeholder="Nom white label"
                              className="form-default"
                              onChange={async (event) => {
                                await createDraftFile(
                                  event.target.files![0],
                                  "recapIR"
                                );
                                setrecapIR(event.target.files![0]);
                              }}
                            />
                            <span> Télécharger d'ici</span>
                          </React.Fragment>
                        )}
                      </label>
                    </FormGroup>
                  </>
                ) : (
                  <></>
                )}
                <div className="list-Files">
                  {creds.role !== UserTypes.Client ? (
                    <>
                      {recapIR && (
                        <React.Fragment>
                          {!recapIR.key ? (
                            <React.Fragment>
                              <span className={`file-box-item-with-clickers`}>
                                <span
                                  className="file-viewer-click"
                                  onClick={() => {
                                    setUrl(URL.createObjectURL(recapIR as any));
                                    setViewModal(true);
                                  }}
                                >
                                  <AttacheIcon />
                                  <span>{(recapIR as any).name}</span>
                                </span>
                                <span
                                  className="file-delete-click"
                                  onClick={async () => {
                                    await deleteDraftFile("recapIR");
                                    setrecapIR(null);
                                  }}
                                >
                                  <BsTrashFill />
                                </span>
                              </span>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <span className={`file-box-item-with-clickers`}>
                                <span
                                  className="file-viewer-click"
                                  onClick={() => {
                                    setUrl(recapIR.url);
                                    setViewModal(true);
                                  }}
                                >
                                  <AttacheIcon />
                                  <span>{recapIR.key}</span>
                                </span>
                                <span
                                  className="file-delete-click"
                                  onClick={async () => {
                                    await deleteDraftFile("recapIR");
                                    setrecapIR(null);
                                  }}
                                >
                                  <BsTrashFill />
                                </span>
                              </span>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </>
                  ) : (
                    <>
                      {draftRecapIR && fakeRecapIR ? (
                        <h5
                          className="bilan-admin-file-ready"
                          onClick={() => {
                            setUrl(fakeRecapIR.url);
                            setViewModal(true);
                          }}
                        >
                          Voir le fichier
                        </h5>
                      ) : (
                        <h5 className="text-danger">
                          Aide pour impôt sur le revenu en cours
                        </h5>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          {creds.role !== UserTypes.Client && fullRecap && (
            <div className="box-bilan-item">
              <Row className="inner-box-bilan">
                <Col md={8} sm={7} className="box-text-bilan">
                  <h3>Récapitulatif Bilan</h3>
                </Col>
                <Col md={4} sm={5} className="right-bilan-file">
                  <FormGroup className="form-file file-secondary ">
                    <div className="list-Files">
                      {fullRecap && (
                        <span className={`file-box-item-with-clickers`}>
                          <span
                            className="file-viewer-click"
                            onClick={() => {
                              setUrl(fullRecap.url);
                              setViewModal(true);
                            }}
                          >
                            <AttacheIcon />
                            <span>
                              {!fullRecap.key
                                ? (fullRecap as any).name
                                : fullRecap.key}
                            </span>
                          </span>
                          <span
                            className="file-delete-click"
                            onClick={async () => {
                              await deleteDraftFile("fullRecap");
                              setfullRecap(null);
                            }}
                          >
                            <BsTrashFill />
                          </span>
                        </span>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <div className="text-center py-3 bottom-actions">
          {creds.role !== UserTypes.Client ? (
            <>
              <Button
                color="secondary"
                outline
                onClick={async () => await resetBilan()}
                disabled={loading}
              >
                {loading ? (
                  <Spinner color="info" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Reinitialiser"
                )}
              </Button>
              <button
                type="submit"
                className="btn btn-secondary"
                disabled={loading}
              >
                {loading ? (
                  <Spinner color="info" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Valider"
                )}
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-secondary"
                disabled={!liasseFiscale || !FEC || !recapIR}
                onClick={() => setCloseBilanModal(true)}
              >
                Fermer
              </button>
            </>
          )}
        </div>
      </form>
      <div className="openbtn text-center">
        <Modal
          className="modal-warning modal-dialog-centered "
          isOpen={closeBilanModal}
          toggle={() => {
            setCloseBilanModal(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setCloseBilanModal(false);
            }}
          >
            Fermeture Bilan {moment().get("year") - 1}
          </ModalHeader>

          <ModalBody>
            <div className="content-form-block">
              <div className="container">
                <div className="section-information-societe">
                  <h5>
                    Vous êtes sur le point de fermer ce bilan, un fichier{" "}
                    <strong>.zip</strong> va être téléchargé automatiquement
                    avec les documents relatifs à ce bilan, voulez-vous
                    continuer ?
                  </h5>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              outline
              onClick={async () => {
                setCloseBilanModal(false);
              }}
              type="button"
              disabled={closeLoading}
            >
              Annuler
            </Button>
            <Button
              color="warning"
              type="button"
              disabled={closeLoading}
              onClick={async () => {
                await closeCurrentBilan();
              }}
            >
              {closeLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "J'accepte"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </React.Fragment>
  );
}
