import React, { Ref, useEffect, useRef, useState } from "react";
import "./WhiteLabelAdmins.scss";
import {
  Alert,
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { data } from "../../../constants";

import { BootyPagination } from "../../../components/table/pagination";
import { BsTrashFill } from "react-icons/bs";
import DataTable from "react-data-table-component";
import { IoMdClose, IoMdCreate } from "react-icons/io";
import UserIcon from "../../../assets/images/svg/user-blue.svg";
import EmailIcon from "../../../assets/images/svg/mail-blue.svg";
import KeyIcon from "../../../assets/KeyIcon";
import PhoneIcon from "../../../assets/PhoneIcon";
import ZoomIcon from "../../../assets/ZoomIcon";
import { ErrorLogger } from "../../../util/errorLogger";
import { useSelector } from "react-redux";
import {
  Company,
  IADMIN,
  IApplication,
  ICABINET,
  IPermission,
  User,
} from "../../../interfaces";
import config from "../../../config";
import axios from "axios";
import { usePermissions } from "../../../util/hooks/usePermissions";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { OptionType, UserTypes } from "../../../util/context";
import CustomSwitch from "../../../components/CustomSwitch/Switch";
import { RiCloseLine } from "react-icons/ri";
import useAxios from "../../../util/hooks/useAxios";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export type ColType = {
  name: string;
  selector?: (row: any) => any;
  sortable?: boolean;
  button?: boolean;
  cell?: any;
};

export interface WhiteLabelAdminsProps {}

export type FormValues = {
  text?: string;
};

export type GeneralFormValues = {
  lastName: string | null;
  firstName: string | null;
  adminApp: string | null;
  adminPerms: string | null;
  email: string | null;
  phone: string | null;
  password?: string | null;
  password_repeat?: string | null;
  adminCompanies: OptionType[] | null;
};

const { API_URL, APPLICATION_ID } = config[process.env.NODE_ENV];

const WhiteLabelAdmins: React.FC<WhiteLabelAdminsProps> = ({}) => {
  const [editOpen, setEditOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [singleAdmin, setSingleAdmin] = useState<IADMIN | ICABINET | null>();
  const [closeAdminSearch, setCloseAdminSearch] = useState<boolean>(false);

  const onEditToggle = (admin: ICABINET | IADMIN) => {
    setSingleAdmin(admin);
    setEditOpen(true);
  };

  const onDeleteToggle = (admin: ICABINET | IADMIN) => {
    setSingleAdmin(admin);
    setDeleteOpen(true);
  };

  const columnsArr: ColType[] = [
    {
      name: "Nom",
      selector: (row: any) => row.username,
      sortable: true,
    },
    {
      name: "E-mail",
      selector: (row: any) => row.email,
      sortable: true,
    },
    {
      name: "Nombre de dossiers",
      // selector: (row: any) => 2,
      selector: (row: ICABINET | IADMIN) => {
        if (row.role === UserTypes.Admin) {
          return "Tous";
        } else {
          return (row as ICABINET).companies.length;
        }
      },
    },
    {
      name: "Actions",
      button: true,
      cell: (row: ICABINET | IADMIN) => {
        // const deletePermission = hasCrudPermission(permissions, {
        //   module: "accounts",
        //   label: "Suppression des comptes",
        // });

        // const updatePermission = hasCrudPermission(permissions, {
        //   module: "accounts",
        //   label: "Mise à jour des comptes",
        // });

        return (
          <div className="table-action">
            {/* {updatePermission && ( */}
            <button className="btn btn-blue" onClick={() => onEditToggle(row)}>
              <IoMdCreate />
            </button>
            {/* )} */}
            {/* {deletePermission && ( */}
            <button className="btn btn-red" onClick={() => onDeleteToggle(row)}>
              <BsTrashFill />
            </button>
            {/* )} */}
          </div>
        );
      },
    },
  ];

  const { user, application, permissions } = useSelector(
    (state: { root: object; user: Object }) => state.user
  ) as { user: User; application: IApplication; permissions: IPermission[] };

  const { applications } = useSelector(
    (state: { applications: Object }) => state.applications
  ) as { applications: IApplication[] };

  const { clientsList } = useSelector(
    (state: { clientsList: Company[] }) => state.clientsList
  ) as unknown as { clientsList: Company[] };

  const { companiesList } = useSelector(
    (state: { companiesList: Company[] }) => state.companiesList
  ) as unknown as { companiesList: Company[] };

  const { permissionsList } = useSelector(
    (state: { permissionsList: IPermission[] }) => state.permissionsList
  ) as unknown as { permissionsList: IPermission[] };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };

  const [admins, setAdmins] = useState<IADMIN[] | ICABINET[]>([]);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);
  let api = useAxios();

  const getApplicationAdmins = async () => {
    try {
      setLoading(true);
      const { data } = await api.post(
        `/api/User/all`,
        {
          where: {
            role: [UserTypes.Admin, UserTypes.Cabinet],
            application: {
              id: user.application_id,
            },
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const { data: adminsData } = data;
      setAdmins(adminsData);
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("getting admins accounts", error);
    }
  };

  useEffect(() => {
    if (application.id) {
      getApplicationAdmins();
    }
  }, []);

  useEffect(() => {
    getApplicationAdmins();
  }, [clientsList]);

  useEffect(() => {
    if (singleAdmin) {
      editReset({
        lastName: singleAdmin.lastName,
        firstName: singleAdmin.firstName,
        adminApp: singleAdmin.applicationId,
        adminPerms: null,
        email: singleAdmin.email,
        phone: singleAdmin.phone,
        password: null,
        password_repeat: null,
        adminCompanies: null,
      });
    }
  }, [singleAdmin]);

  const {
    control: searchControl,
    register: searchRegister,
    handleSubmit: searchHandleSubmit,
    reset: searchReset,
    formState: { errors: searchErrors },
  } = useForm<FormValues>({});

  const {
    watch,
    control: editControl,
    register: editRegister,
    handleSubmit: editHandleSubmit,
    reset: editReset,
    formState: { errors: editErrors },
  } = useForm<GeneralFormValues>({});

  const { adminApp: chosenAdminAppID } = watch();

  const searchAdmins: SubmitHandler<FormValues> = async (form: any) => {
    try {
      setLoading(true);
      if (closeAdminSearch) {
        searchReset();
        await getApplicationAdmins();
        setCloseAdminSearch(false);
      } else {
        setCloseAdminSearch(true);
        const { data } = await api.post(
          `/api/User/all`,
          {
            where: {
              application: {
                id: user.application_id,
              },
              role: [UserTypes.Admin, UserTypes.Cabinet],
              username: {
                iLike: `%${form.text}%`,
              },
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        setAdmins(data.data);
        setLoading(false);
      }
    } catch (error: any) {
      ErrorLogger("admin filter form", error);
    }
  };

  const editAdmin: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      if (
        form.password?.toLowerCase() !== form.password_repeat?.toLowerCase()
      ) {
        setErrorMessage({
          type: "password_repeat",
          message: "Les mots de passe ne correspondent pas",
        });
        return;
      }

      if (
        form.password !== "" &&
        form.password !== null &&
        form.password_repeat !== "" &&
        form.password_repeat !== null &&
        form.password?.toLowerCase() === form.password_repeat?.toLowerCase()
      ) {
        await api.patch(
          `/api/User`,
          {
            id: singleAdmin?.id,
            password: form.password,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }

      let payload: any = { id: singleAdmin?.id };

      if (singleAdmin?.applicationId !== form.adminApp) {
        payload["applicationId"] = form.adminApp;
      }
      if (singleAdmin?.firstName !== form.firstName) {
        payload["firstName"] = form.firstName;
      }
      if (singleAdmin?.phone !== form.phone) {
        payload["phone"] = form.phone;
      }
      if (singleAdmin?.lastName !== form.lastName) {
        payload["lastName"] = form.lastName;
      }
      if (singleAdmin?.email !== form.email) {
        const { data } = await api.post(
          `/api/User/All`,
          {
            where: {
              email: form.email,
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        if (data.data && Array.isArray(data.data) && data.data.length) {
          return setErrorMessage({
            type: "email",
            message: "Cet email existe déjà",
          });
        }
        payload["email"] = form.email;
      }
      if (singleAdmin?.phone !== form.phone) {
        payload["phone"] = form.phone;
      }

      if (Object.keys(payload).length > 1) {
        await api.patch(`/api/User`, payload, {
          headers: {
            "x-access-token": creds.token,
          },
        });
      }

      // if admin has no permissions
      if (
        form.adminPerms &&
        Array.isArray(form.adminPerms) &&
        singleAdmin?.permissions &&
        !singleAdmin.permissions.length
      ) {
        await api.patch(
          `/api/${
            singleAdmin?.role === UserTypes.Cabinet
              ? UserTypes.Cabinet
              : "Admin"
          }`,
          {
            id: singleAdmin?.id,
            permissionsid: [...form.adminPerms],
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }

      //if admin has permissions
      if (
        form.adminPerms &&
        Array.isArray(form.adminPerms) &&
        singleAdmin?.permissions &&
        singleAdmin.permissions.length
      ) {
        await api.patch(
          `/api/${
            singleAdmin?.role === UserTypes.Cabinet
              ? UserTypes.Cabinet
              : "Admin"
          }`,
          {
            id: singleAdmin?.id,
            permissionsid: [
              ...form.adminPerms,
              ...(singleAdmin.permissions
                .filter((elt) => elt.showFront === false)
                .reduce(
                  (acc, curr) => [...acc, curr.id as string],
                  [] as string[]
                ) as string[]),
            ],
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }

      const selectedAdmin = admins.find((elt) => elt.id === singleAdmin?.id);
      if (selectedAdmin?.role === UserTypes.Cabinet) {
        const selectedAdminComps = (selectedAdmin as ICABINET).companies.reduce(
          (acc, curr) => [...acc, curr.id],
          [] as string[]
        );

        const singleAdminComps = (singleAdmin as ICABINET).companies.reduce(
          (acc, curr) => [...acc, curr.id],
          [] as string[]
        );

        let checkCompaniesEqual =
          selectedAdminComps.length === singleAdminComps.length &&
          singleAdminComps.every((a1) => selectedAdminComps.includes(a1));
        let companiesid = [];

        if (!checkCompaniesEqual) {
          companiesid = singleAdminComps;
          await api.patch(
            `/api/Cabinet`,
            {
              id: singleAdmin?.id,
              companiesid,
            },
            {
              headers: {
                "x-access-token": creds.token,
              },
            }
          );
        }
      }
      setEditOpen(false);
      setSingleAdmin(null);
      getApplicationAdmins();
    } catch (error: any) {
      ErrorLogger("admin filter form", error);
    }
  };

  const deleteAdmin = async () => {
    try {
      await api.post(
        `/api/User/Update`,
        {
          id: singleAdmin?.id,
          activated: false,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await api.post(
        `/api/User/delete`,
        {
          id: singleAdmin?.id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setAdmins((prevState) =>
        prevState.filter((admin) => admin.id !== singleAdmin?.id)
      );
      setSingleAdmin(null);
      getApplicationAdmins();
      setDeleteOpen(false);
    } catch (error: any) {
      ErrorLogger("deleting an admin", error);
    }
  };

  const filterCompanies = (
    cabinetCompanies: Company[],
    applicationId: string | undefined | null = singleAdmin?.applicationId
  ) => {
    const companies = companiesList.filter(
      (comp) => comp.applicationId === applicationId
    );

    const otherClients = companies.filter((comp) => {
      const cabinetCompanyIndex = cabinetCompanies.findIndex(
        (cc) => cc.id === comp.id
      );
      if (cabinetCompanyIndex < 0) {
        return comp;
      }
    });

    const options = otherClients.reduce(
      (acc: { value: string; label: string }[], curr: Company) => [
        ...acc,
        { value: curr.id, label: curr.name || curr.email },
      ],
      []
    );

    return options;
  };

  const { ref: textRef, ...text } = searchRegister("text");
  const { ref: lastNameRef, ...lastName } = editRegister("lastName");
  const { ref: firstNameRef, ...firstName } = editRegister("firstName");
  const { ref: adminAppRef, ...adminApp } = editRegister("adminApp");
  const { ref: adminPermsRef, ...adminPerms } = editRegister("adminPerms");
  const { ref: emailRef, ...email } = editRegister("email");
  const { ref: phoneRef, ...phone } = editRegister("phone");
  const { ref: passwordRef, ...password } = editRegister("password");
  const { ref: passwordRepeatRef, ...password_repeat } =
    editRegister("password_repeat");

  return (
    <Card className="card-Table table-primary">
      <div className="search-top">
        <form onSubmit={searchHandleSubmit(searchAdmins)}>
          <FormGroup className="form-icon icon-end">
            <Input
              {...text}
              innerRef={textRef}
              id="mc"
              name="text"
              placeholder="Chercher un utilisateur"
              type="text"
              className="form-secondary"
            />
            <button type="submit" className="icon icon-secondary ">
              {closeAdminSearch ? <RiCloseLine /> : <ZoomIcon />}
            </button>
          </FormGroup>
        </form>
      </div>

      <DataTable
        columns={columnsArr}
        data={admins}
        noDataComponent={<p>Il n'y a aucun data à afficher</p>}
        pagination
        progressPending={loading}
        progressComponent={
          <>
            <Spinner color="light" type="grow">
              Loading...
            </Spinner>
            <Spinner color="light" type="grow">
              Loading...
            </Spinner>
            <Spinner color="light" type="grow">
              Loading...
            </Spinner>
          </>
        }
        paginationComponent={(props) => {
          const customProps = { ...props, color: "primary" };
          return <BootyPagination {...customProps} />;
        }}
      />

      <div className="openbtn text-center">
        <Modal
          className="modal-danger modal-dialog-centered"
          isOpen={deleteOpen}
          toggle={() => {
            setSingleAdmin(null);
            setDeleteOpen(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSingleAdmin(null);
              setDeleteOpen(false);
            }}
          >
            Supprimer un utilisateur
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer l'utilisateur{" "}
                {singleAdmin?.username || singleAdmin?.email}?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={() => {
                setSingleAdmin(null);
                setDeleteOpen(false);
              }}
            >
              Non
            </Button>
            <Button color="danger" onClick={async () => await deleteAdmin()}>
              Oui
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div className="openbtn text-center">
        <Modal
          className="modal-secondary modal-dialog-centered modal-lg"
          isOpen={editOpen}
          toggle={() => {
            setEditOpen(false);
            setSingleAdmin(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setEditOpen(false);
              setSingleAdmin(null);
            }}
          >
            Editer{" "}
            {singleAdmin?.role !== UserTypes.Cabinet
              ? "l'administrateur"
              : "le sous-compte"}
          </ModalHeader>
          <form onSubmit={editHandleSubmit(editAdmin)}>
            <ModalBody>
              <div className="content-form-block">
                <h4>Informations générales</h4>
                <Row>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="name">Nom</Label>
                      <Input
                        id="name"
                        innerRef={lastNameRef}
                        {...lastName}
                        placeholder="Nom"
                        type="text"
                        className="form-secondary"
                        // defaultValue={singleAdmin?.lastName}
                      />
                      <span className="icon icon-secondary ">
                        <img src={UserIcon} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="prenom">Prénom</Label>
                      <Input
                        id="prenom"
                        innerRef={firstNameRef}
                        {...firstName}
                        placeholder="Prénom"
                        type="text"
                        className="form-secondary"
                        // defaultValue={singleAdmin?.firstName}
                      />
                      <span className="icon icon-secondary ">
                        <img src={UserIcon} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="email">Email</Label>
                      <Input
                        id="email"
                        innerRef={emailRef}
                        {...email}
                        placeholder="Email"
                        type="text"
                        className="form-secondary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      />
                      <span className="icon icon-secondary ">
                        <img src={EmailIcon} alt="icon" />
                      </span>
                    </FormGroup>
                    {errorMessage?.type === "email" && (
                      <Alert color="danger">{errorMessage?.message}</Alert>
                    )}
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="phone">Téléphone</Label>
                      <Input
                        id="phone"
                        innerRef={phoneRef}
                        {...phone}
                        placeholder="Téléphone"
                        type="text"
                        className="form-secondary"
                        // defaultValue={singleAdmin?.phone}
                      />
                      <span className="icon icon-secondary ">
                        <PhoneIcon />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="pwd">Mot de passe</Label>
                      <Input
                        id="pwd"
                        innerRef={passwordRef}
                        {...password}
                        placeholder="Mot de passe"
                        type="password"
                        className="form-secondary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      />
                      <span className="icon icon-secondary ">
                        <KeyIcon />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="cpwd">Confirmer le mot de passe</Label>
                      <Input
                        id="cpwd"
                        innerRef={passwordRepeatRef}
                        {...password_repeat}
                        placeholder="Confirmer le mot de passe"
                        type="password"
                        className="form-secondary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      />
                      <span className="icon icon-secondary ">
                        <KeyIcon />
                      </span>
                    </FormGroup>
                    {errorMessage?.type === "password_repeat" && (
                      <Alert color="danger">{errorMessage?.message}</Alert>
                    )}
                  </Col>
                  <div className="radio-form">
                    <div
                      className={`inner-radio-form ${
                        singleAdmin?.role === UserTypes.Cabinet
                          ? "has__sibling"
                          : ""
                      }`}
                    >
                      <h4>Application associée</h4>
                      <div className="form-line">
                        {Array.isArray(applications) &&
                          applications.map((app, index) => (
                            <div
                              className="check-box check-secondary mb-1"
                              key={index}
                            >
                              <input
                                type="radio"
                                id={`radio-${index}`}
                                ref={adminAppRef}
                                value={app.id}
                                {...adminApp}
                                defaultChecked={
                                  singleAdmin?.applicationId === app.id
                                }
                              />
                              <label htmlFor={`radio-${index}`}>
                                {app.name}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  {singleAdmin?.role === UserTypes.Cabinet && (
                    <div className="lists-clients-block">
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="exampleEmail">Liste des clients</Label>
                            <Select
                              options={filterCompanies(
                                (singleAdmin as ICABINET).companies,
                                chosenAdminAppID
                              )}
                              classNamePrefix="select"
                              className="custom-select form-secondary"
                              value={null}
                              onChange={(item) => {
                                const selectedComp = companiesList.find(
                                  (elt) => elt.id === item?.value
                                );
                                setSingleAdmin((prevState) => {
                                  return {
                                    ...(prevState as ICABINET),
                                    companies: [
                                      ...(prevState as ICABINET).companies,
                                      selectedComp,
                                    ],
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <div className="liste-tags">
                            {(singleAdmin as ICABINET).companies.map(
                              (comp, index) => (
                                <span
                                  className="tags-item tags-secondary"
                                  key={index}
                                >
                                  <span>{comp.email || comp.name}</span>{" "}
                                  <IoMdClose
                                    onClick={() => {
                                      setSingleAdmin((prevState) => {
                                        const companies = (
                                          prevState as ICABINET
                                        ).companies.filter(
                                          (elt) => elt.id !== comp.id
                                        );
                                        return {
                                          ...(prevState as ICABINET),
                                          companies,
                                        };
                                      });
                                    }}
                                  />
                                </span>
                              )
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}

                  <div className="checkbox-form">
                    <div className="inner-checkbox-form">
                      <label className="form-label">
                        Type d'administrateur :
                      </label>
                      <div className="custom-switch">
                        {singleAdmin?.role === UserTypes.Cabinet ? (
                          <span className="label">Sous-compte</span>
                        ) : (
                          <span className="label orangeTxt">Admin</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="checkbox-form">
                    <div className="inner-checkbox-form">
                      <h4>Droits d'accès</h4>
                      <Row>
                        {permissionsList.length > 0 &&
                          permissionsList.map((perm, index) => (
                            <Col md={6} key={index}>
                              <div className="check-box check-secondary">
                                <input
                                  className="styled-checkbox"
                                  id={`checkbox-${index}`}
                                  type="checkbox"
                                  ref={adminPermsRef}
                                  {...adminPerms}
                                  value={perm.id}
                                  defaultChecked={
                                    singleAdmin?.permissions &&
                                    singleAdmin?.permissions.findIndex(
                                      (elt) => elt.id === perm.id
                                    ) > -1
                                  }
                                  disabled={
                                    singleAdmin?.role !== UserTypes.Cabinet
                                  }
                                />
                                <label htmlFor={`checkbox-${index}`}>
                                  {perm.label}
                                </label>
                              </div>
                            </Col>
                          ))}
                      </Row>
                    </div>
                  </div>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                outline
                type="button"
                onClick={() => {
                  setSingleAdmin(null);
                  setEditOpen(false);
                }}
              >
                Annuler
              </Button>
              <Button color="secondary">Editer</Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </Card>
  );
};

export default WhiteLabelAdmins;
