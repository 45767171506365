import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import RegisterStepFour from "./RegisterStepFour";

const StepFour = () => {
  const navigate = useNavigate();
  const { deleteValue, getValue } = useLocalStorage();
  const location = useLocation()
  const nextStep = () => {
    setClikced(false)
    if (!getValue("packOk") || getValue("packOk") !== "OK") {
      setClikced(true)
      return;
    }
    if (getValue("pack") && getValue("packOk")) {
      let url  = "/register/info-client"
      if(location && location.search){
        url += location.search
      }
      navigate(url);
    }
  };
  const [clicked, setClikced] = useState(false);

  const prevStep = () => {
    deleteValue("pack");
    deleteValue("packOk");
    deleteValue("AnswersObject");
    let url  = "/register/info-activite"
    if(location && location.search){
      url += location.search
    }
    navigate(url);
  };

  return (
    <>
      <AnimatePresence>
        <RegisterStepFour clicked={clicked} setClikced={setClikced} />
      </AnimatePresence>

      <div className="bottomSteperCenter">
        <Button
          type="button"
          onClick={prevStep}
          color="primary"
          outline
          className="btn btn-prev "
        >
          <FiChevronsLeft />
          <span>Précédent</span>
        </Button>
        <Button
          type="button"
          onClick={nextStep}
          color="primary"
          className="btn btn-next "
        >
          <span>Suivant</span>
          <FiChevronsRight />
        </Button>
      </div>
    </>
  );
};

export default StepFour;
