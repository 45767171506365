import React from "react";
import "./style.scss";
import { Banners } from "../../../interfaces";
type BannerProps = {
  banner: Banners;
  children?: string;
  className?: string;
};
const ModalBanner: React.FC<BannerProps> = ({
  banner,
  children,
  className,
}) => {
  return (
    <div className={`modal-banner primary`}>
      <div className="modal-banner__text">
        <h2 className="modal-banner__title">{banner.title}</h2>
      </div>
      <div className="modal-banner__img">
        <img src={banner.image} alt={banner.title} />
      </div>

      {children}
    </div>
  );
};

export default ModalBanner;
