import { AnimatePresence } from "framer-motion";
import React from "react";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router";
import { Button } from "reactstrap";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import RegisterStepThree from "./RegisterStepThree";

const StepThree = () => {
  const { deleteValue, getValue } = useLocalStorage();
  const navigate = useNavigate();
  const location = useLocation()
  const nextStep = () => {
    const answersObject = getValue("AnswersObject");
    if (
      answersObject &&
      answersObject.commercialQ1 &&
      !answersObject.commercialQ2 &&
      !answersObject.commercialQ3
    ) {
      return;
    }
    let url = "/register/pack";
    if (location && location.search) {
      url += location.search;
    }
    navigate(url);
  };

  const prevStep = () => {
    deleteValue("AnswersObject");
    deleteValue("ActivityType");
    let url = "/register/activite";
    if (location && location.search) {
      url += location.search;
    }
    navigate(url);
  };

  return (
    <>
      <AnimatePresence>
        <RegisterStepThree />
      </AnimatePresence>

      <div className="bottomSteperCenter">
        <Button
          type="button"
          onClick={prevStep}
          color="primary"
          outline
          className="btn btn-prev "
        >
          <FiChevronsLeft />
          <span>Précédent</span>
        </Button>
        <Button
          type="button"
          onClick={nextStep}
          color="primary"
          className="btn btn-next "
        >
          <span>Suivant</span>
          <FiChevronsRight />
        </Button>
      </div>
    </>
  );
};

export default StepThree;
