import React from "react";

import "./AskSuccess.scss";
import SuccessIcon from "../../../../assets/images/check-valid.png";

const AskSuccessStep: React.FC = () => {
  return (
    <div className="register-stepFive col-lg-7 col-md-12 mx-auto">
      <div className="card success-message">
        <span className="iconMsg">
          <img src={SuccessIcon} alt="img" />
        </span>
        <h4>Merci,</h4>
        <p>
          Votre e-mail a été envoyé, <br /> nous vous répondrons dans les plus
          brefs délais.
        </p>
        <p>Vous pouvez également nous contacter ici</p>
        <address className="text-white">
          Email : <a href="mailto:info@noly-compta.fr">info@noly-compta.fr</a>
          <br />
          Numéro de téléphone : <a href="tel:0188610474">0188610474</a>
        </address>
      </div>
    </div>
  );
};

export default AskSuccessStep;
