import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAxiosInsatance } from "../../../util/helpers/createAxiosInsatance";
import { setCounter } from "../utils/utilSlice";

const initialState = {
  user: {},
  company: {},
  userCompanies  : [],
  count: {},
  application: {},
  permissions: {},
  loading: "idle",
  currentRequestId: undefined as any,
  error: null as any,
};

export const rehydrateUser = createAsyncThunk(
  "applications/rehydrateUser",
  async (_, { getState, requestId, dispatch }) => {
    const thunkState = getState() as any;
    let api = createAxiosInsatance(thunkState.root.token, dispatch, setCounter);
    const { data } = await api!.post(
      `/api/User/All`,
      {
        where: {
          id: thunkState.root.user_id,
        },
      },
      {
        headers: {
          "x-access-token": thunkState.root.token,
        },
      }
    );
    return data.data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: { user: any }, action: { payload: any }) => {
      state.user = action.payload;
    },

    setCompany: (state: { company: any }, action: { payload: any }) => {
      state.company = action.payload;
    },

    setUserCompanies: (state: { userCompanies: any }, action: { payload: any }) => {
      state.userCompanies = action.payload;
    },

    setCountNotifs: (state: { count: any }, action: { payload: any }) => {
      state.count = action.payload;
    },

    setApplication: (state: { application: any }, action: { payload: any }) => {
      state.application = action.payload;
    },

    setPermissions: (state: { permissions: any }, action: { payload: any }) => {
      state.permissions = action.payload;
    },

    resetUser: (state: any) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(rehydrateUser.pending, (state: any, action: any) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(rehydrateUser.fulfilled, (state: any, action: any) => {
        const { requestId } = action.meta;
        const { company, ...user } = action.payload[0];
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          if (company) {
            state.company = company;
          }
          state.user = user;
          state.currentRequestId = undefined;
        }
      })
      .addCase(rehydrateUser.rejected, (state: any, action: any) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const {
  setUser,
  setCompany,
  setApplication,
  setPermissions,
  resetUser,
  setCountNotifs,
  setUserCompanies
} = userSlice.actions;
export default userSlice.reducer;
