import React, { PropsWithChildren, ReactElement } from "react";
import "./CardRegister.scss";
import { ICardRegister } from "../../interfaces";
import { Card } from "reactstrap";

function CardRegister(props: PropsWithChildren<ICardRegister>): ReactElement {
  return (
    <Card className={`card card-register ${props.className} `} onClick={props.onClick}>
      <div className="top-card-icon">
        <img src={props.icon} alt="icon" />
      </div>
      <div className="card-body">
        <h3>{props.title}</h3>

        {props.children}
      </div>
    </Card>
  );
}

export default CardRegister;
