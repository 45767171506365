import { AnimatePresence } from "framer-motion";
import React from "react";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import RegisterStepFive from "./RegisterStepFive";

const StepFive = () => {
  return (
    <>
      <AnimatePresence>
        <RegisterStepFive />
      </AnimatePresence>
    </>
  );
};

export default StepFive;
