const colors = {
    green: '#4CAF50',
    red: '#DB190C',
    purple: '#8624DB',
    white: '#fff',
    orange: '#FF9066',
    primaryColor:' #5ac7b0',
    secondaryColor:'#50b1dc',
    gradienGreen: 'linear-gradient(to left, #46b59d, #3da790, #349984, #2c8b78, #237e6c)' ,
    gradienBlue: 'linear-gradient(to left, #53bae5, #44abd8, #359dcb, #248fbe, #0b81b1)',
    tableBorder : "#4e4d4f66"
}

export default colors;