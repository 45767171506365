import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import InformationSociete from "../../CompanyInfo/InformationSociete/InformationSociete";

function CompanyInfoWrapper() {
  const { company, callback } = useOutletContext() as any;
  return <InformationSociete company={company} callback={callback} />;
}

export default CompanyInfoWrapper;
