import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import {
  setApplication,
  setCompany,
  setPermissions,
  setUser,
} from "../../../../store/reducers/user/userSlice";
import { ErrorLogger } from "../../../../util/errorLogger";
import useAxios from "../../../../util/hooks/useAxios";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import PaymentSuccessStep from "./AskSuccess";

const AskSuccess = () => {

  return (
    <>
      {/* <AnimatePresence> */}
        <PaymentSuccessStep />
      {/* </AnimatePresence> */}
    </>
  );
};

export default AskSuccess;
