import React from "react";

interface KeyProps {
  className?: string;
}

const KeyIcon = ({ className }: KeyProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="33.333"
    height="36.833"
    viewBox="0 0 33.333 36.833"
  >
    <defs>
      <filter
        id="Tracé_84"
        x="0"
        y="0"
        width="33.333"
        height="36.833"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="1.5" result="blur" />
        <feFlood floodColor="#50b2dc" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="Tracé_85"
        x="16.379"
        y="3.691"
        width="13.264"
        height="16.764"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="1.5" result="blur-2" />
        <feFlood floodColor="#50b2dc" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Groupe_58" data-name="Groupe 58" transform="translate(-647.461 -44)">
      <g transform="matrix(1, 0, 0, 1, 647.46, 44)" filter="url(#Tracé_84)">
        <path
          id="Tracé_84-2"
          data-name="Tracé 84"
          d="M676.294,52.364c-.088.489-.143.987-.271,1.466a7.812,7.812,0,0,1-9.746,5.526.358.358,0,0,0-.414.1c-.5.511-1.013,1-1.514,1.509a.9.9,0,0,1-.7.286c-.386-.01-.772,0-1.186,0,0,.412,0,.805,0,1.2a.74.74,0,0,1-.94.82c-.3-.041-.61-.069-.937-.106-.04.417-.086.816-.115,1.216-.05.679-.255.894-.93.952-.4.035-.8.073-1.237.112.034.307.055.6.1.9a.909.909,0,0,1-.288.879c-.29.27-.564.555-.841.838a.866.866,0,0,1-.656.276c-1.291,0-2.581,0-3.872,0a.717.717,0,0,1-.788-.779c0-1.3,0-2.6,0-3.9a.926.926,0,0,1,.319-.686q3.194-3.188,6.384-6.381c.728-.728,1.453-1.458,2.186-2.181a.327.327,0,0,0,.095-.371,7.661,7.661,0,0,1,1.916-7.678,7.431,7.431,0,0,1,4.463-2.268c.2-.029.4-.061.606-.092h1.046a.59.59,0,0,0,.112.032,7.707,7.707,0,0,1,4.806,2.167,7.467,7.467,0,0,1,2.115,3.587c.129.5.193,1.021.286,1.533Zm-15.256,9.409c0-.383,0-.753,0-1.123a.732.732,0,0,1,.836-.826c.443,0,.887-.009,1.33,0a.559.559,0,0,0,.457-.183c.509-.521,1.042-1.019,1.547-1.544a.9.9,0,0,1,1.071-.241,6.025,6.025,0,0,0,2.777.354,6.215,6.215,0,0,0,5.078-3.412,6.365,6.365,0,0,0-1.4-7.738,6.4,6.4,0,0,0-10.3,6.923.966.966,0,0,1-.263,1.129q-4.313,4.289-8.609,8.595a.524.524,0,0,0-.169.414c.008.839,0,1.679,0,2.518v.263c.856,0,1.68-.02,2.5.009a1.007,1.007,0,0,0,.9-.376.545.545,0,0,0,.149-.463c-.056-.4-.109-.8-.137-1.2a.686.686,0,0,1,.657-.776q.708-.078,1.418-.135c.141-.011.206-.045.217-.2.032-.458.086-.914.13-1.371a.708.708,0,0,1,.839-.717C660.383,61.7,660.7,61.736,661.038,61.773Z"
          transform="translate(-647.46 -44)"
          fill="#50b2dc"
        />
      </g>
      <g transform="matrix(1, 0, 0, 1, 647.46, 44)" filter="url(#Tracé_85)">
        <path
          id="Tracé_85-2"
          data-name="Tracé 85"
          d="M929.825,110.4a2.132,2.132,0,1,1,2.132-2.126A2.138,2.138,0,0,1,929.825,110.4Zm.709-2.139a.708.708,0,1,0-.7.714A.7.7,0,0,0,930.534,108.257Z"
          transform="translate(-906.81 -102.44)"
          fill="#50b2dc"
        />
      </g>
    </g>
  </svg>
);

export default KeyIcon;
