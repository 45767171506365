import React, { useEffect } from "react";
import "./LineChartsMu.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { CAChartTypes } from "../Dashboard";
import { Spinner } from "reactstrap";
import { deepComparison } from "../../../util/helpers/utilities";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  {
    id: "ChartMu",
    beforeDraw: (chart: { ctx: any }, args: any, options: any) => {
      const { ctx } = chart;
      ctx.shadowColor = "rgba(0, 0, 0, 0.25)";
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 10;
      ctx.shadowOffsetY = 10;
    },
  }
);

export const ShadowPlugin = {
  // id: "1",
  beforeDraw: (chart: { ctx: any }, args: any, options: any) => {
    const { ctx } = chart;
    console.log("chart : ", chart);
    ctx.shadowColor = "rgba(0, 0, 0, 0.25)";
    ctx.shadowBlur = 10;
    ctx.shadowOffsetX = 10;
    ctx.shadowOffsetY = 10;
  },
};

export const options = {
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "",
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.dataset.label || "";
          if (label) {
            label += ": ";
          }
          if (context.raw !== null) {
            label += parseFloat(context.raw).toFixed(2) + " €";
          }

          return label;
        },
      },
    },
  },
  responsive: true,
  scales: {
    x: {},
    y: {
      grid: {
        display: false,
      },
      ticks: {
        callback: function (value: any) {
          return `${value} €`;
        },
      },
    },
  },
};

// const labels = [
//   "01/2021",
//   "02/2021",
//   "03/2021",
//   "03/2021",
//   "04/2021",
//   "06/2021",
//   "07/2021",
//   "08/2021",
//   "09/2021",
//   "10/2021",
//   "11/2021",
//   "12/2021",
// ];

// export const data = {
//   labels: [] as string[],
//   datasets: [
//     {
//       label: "Charges",
//       data: [],
//       backgroundColor: "#fff",
//       borderColor: "#fd6c80",
//       color: "#fff",
//       lineTension: 0.5,
//       borderWidth: 8,
//       pointBorderWidth: 2,
//     },
//     {
//       label: "Chiffre d'affaires",
//       data: [],
//       backgroundColor: "#fff",
//       borderColor: "#00acbe",
//       lineTension: 0.5,
//       borderWidth: 8,
//       pointBorderWidth: 2,
//     },
//   ] as {
//     label: string;
//     data: (number | null | undefined)[];
//     backgroundColor: string;
//     borderColor: string;
//     lineTension: number;
//     borderWidth: number;
//     pointBorderWidth: number;
//   }[],
// };

export const renderChart = (chartData1: any, chartData2: any, labels: any) => {
  return {
    labels: labels as string[],
    datasets: [
      {
        label: "Charges",
        data: chartData1,
        backgroundColor: "#fff",
        borderColor: "#fd6c80",
        color: "#fff",
        lineTension: 0.5,
        borderWidth: 8,
        pointBorderWidth: 2,
      },
      {
        label: "Chiffre d'affaires",
        data: chartData2,
        backgroundColor: "#fff",
        borderColor: "#00acbe",
        lineTension: 0.5,
        borderWidth: 8,
        pointBorderWidth: 2,
      },
    ] as {
      label: string;
      data: (number | null | undefined)[];
      backgroundColor: string;
      borderColor: string;
      lineTension: number;
      borderWidth: number;
      pointBorderWidth: number;
    }[],
  };
};

const LineChartsMu = ({
  caCahrtData,
  loading,
}: {
  caCahrtData: CAChartTypes[];
  loading: boolean;
}) => {
  return (
    <>
      <div>
        {loading || caCahrtData.length === 0 ? (
          <div className="loading-chart">
            <Spinner type="border" size={"md"} className="chart-spinner">
              Loading...
            </Spinner>
            <Line
              options={options}
              data={{
                labels: [] as string[],
                datasets: [],
              }}
            />
          </div>
        ) : (
          <Line
            options={options}
            data={renderChart(
              caCahrtData.map((elt) => elt.result.Spending),
              caCahrtData.map((elt) => elt.result.IT),
              caCahrtData.map((elt) => `${elt.shortMonth}/${elt.year}`)
            )}
            redraw
          />
        )}
      </div>
    </>
  );
};

export default React.memo(LineChartsMu, (prevProps, nextProps) => {
  return deepComparison(
    nextProps.caCahrtData,
    prevProps.caCahrtData,
    "result",
    "Differential"
  );
});
