import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import PaymentSuccessStep from "./PaymentSuccessStep";

const PaymentError = () => {

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  return (
    <>
      <AnimatePresence>
        <PaymentSuccessStep />
      </AnimatePresence>
      {/* <div className="bottomSteperCenter">
        <Button
          type="button"
          onClick={async () => await prevStep()}
          color="primary"
          outline
          className="btn btn-prev "
        >
          <FiChevronsLeft />
          <span>
            {prevLoading ? (
              <Spinner color="light" type="border" size={"sm"}>
                Loading...
              </Spinner>
            ) : (
              "Plus tard"
            )}
          </span>
        </Button>
        <Button
          type="button"
          onClick={async () => await nextStep()}
          color="primary"
          className="btn btn-next "
        >
          <span>
            {nextLoading ? (
              <Spinner color="light" type="border" size={"sm"}>
                Loading...
              </Spinner>
            ) : (
              "Valider"
            )}
          </span>
          <FiChevronsRight />
        </Button>
      </div> */}
    </>
  );
};

export default PaymentError;
