import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { User } from "../../../interfaces";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import { deleteFile, GeneralFormValues } from "../../ClientInfo/InformationClient";
import moment from "moment";
import "moment/locale/fr";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import {
  RiBriefcase4Line,
  RiCameraLine,
  RiCommunityLine,
  RiFlagLine,
  RiMapPinLine,
} from "react-icons/ri";
import { GENDER_OPTIONS, UserTypes } from "../../../util/context";
import Select from "react-select";
import FilePicker from "../../../components/FilePicker";
import { BsMailbox } from "react-icons/bs";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import UserProsp from "../../../assets/images/svg/user-blue.svg";
import UserChat from "../../../assets/images/avatar_empty.png";
import EmailIcon from "../../../assets/images/svg/mail-blue.svg";
import PhoneIcon from "../../../assets/images/svg/phone-blue.svg";
import CalenderIconBlue from "../../../assets/images/svg/calender-icon-blue.svg";
import FileViewer from "../../../components/FileViewer";
import CerleUserBg from "../../../assets/CerleUserBg";
import { colors } from "../../../constants";

const AdminComp = ({ user, setReload }: { user: User; setReload: any }) => {
  const [currentUser, setCurrentUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const [userProfilePhoto, setUserProfilePhoto] = useState<any>();
  const [userCIN, setUserCIN] = useState<any>();
  const [cinLoading, setCinLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);
  let api = useAxios();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };

  const { id } = useParams();

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormValues>({});

  const { proofOfIdentity: _proofOfIdentity } = watch();

  const getUser = async () => {
    try {
      setCurrentUser(user);
      reset({
        username: user?.username,
        nationality: user?.nationality,
        city: user?.city,
        address: user?.address,
        companyPosition: user?.companyPosition,
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        dateOfBirth: user?.dateOfBirth,
        phone: user?.phone,
        gender: GENDER_OPTIONS.find((elt) => elt.value === user?.gender),
        postcodeOfBirth: user?.postcodeOfBirth,
        countryOfBirth: user?.countryOfBirth,
      });
      setUserProfilePhoto(user?.profilePhoto);
      setUserCIN(user?.proofOfIdentity);
    } catch (error: any) {
      ErrorLogger("getting client info", error);
    }
  };

  useEffect(() => {
    getUser();
  }, [user]);

  const { ref: lastNameRef, ...lastName } = register("lastName");
  const { ref: firstNameRef, ...firstName } = register("firstName");
  const { ref: usernameRef, ...username } = register("username");
  const { ref: proofOfIdentityRef, ...proofOfIdentity } =
    register("proofOfIdentity");
  const { ref: nationalityRef, ...nationality } = register("nationality");
  const { ref: cityRef, ...city } = register("city");
  const { ref: addressRef, ...address } = register("address");
  const { ref: companyPositionRef, ...companyPosition } =
    register("companyPosition");
  const { ref: emailRef, ...email } = register("email");
  const { ref: dateOfBirthRef, ...dateOfBirth } = register("dateOfBirth");
  const { ref: profilePhotoRef, ...profilePhoto } = register("profilePhoto");
  const { ref: phoneRef, ...phone } = register("phone");
  const { ref: genderRef, ...gender } = register("gender");
  const { ref: postcodeOfBirthRef, ...postcodeOfBirth } =
    register("postcodeOfBirth");

  const updateClient: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("id", currentUser?.id as string);

      if (userProfilePhoto && !userProfilePhoto.key) {
        formData.append("profilePhoto", userProfilePhoto);
      }

      if (userCIN && !userCIN.key) {
        formData.append("proofOfIdentity", userCIN);
      }

      if (form.nationality && form.nationality !== currentUser?.nationality) {
        formData.append("nationality", form.nationality);
      }

      if (form.firstName && form.firstName !== currentUser?.firstName) {
        formData.append("firstName", form.firstName);
      }

      if (form.city && form.city !== currentUser?.city) {
        formData.append("city", form.city);
      }

      if (form.address && form.address !== currentUser?.address) {
        formData.append("address", form.address);
      }

      if (
        form.companyPosition &&
        form.companyPosition !== currentUser?.companyPosition
      ) {
        formData.append("companyPosition", form.companyPosition);
      }

      if (
        form.countryOfBirth &&
        form.countryOfBirth !== currentUser?.countryOfBirth
      ) {
        formData.append("countryOfBirth", form.countryOfBirth);
      }

      if (
        form.dateOfBirth &&
        moment(form.dateOfBirth).format("dd/MM/yyyy") !==
          moment(currentUser?.dateOfBirth).format("dd/MM/yyyy")
      ) {
        formData.append(
          "dateOfBirth",
          moment(form.dateOfBirth).format("YYYY-MM-DD hh:mm")
        );
      }

      if (form.gender && form.gender.value !== currentUser?.gender) {
        formData.append("gender", form.gender.value as string);
      }

      if (form.lastName && form.lastName !== currentUser?.lastName) {
        formData.append("lastName", form.lastName);
      }

      if (form.phone && form.phone !== currentUser?.phone) {
        formData.append("phone", form.phone);
      }

      if (form.email && form.email !== currentUser?.email) {
        formData.append("email", form.email);
      }

      if (
        form.postcodeOfBirth &&
        form.postcodeOfBirth !== currentUser?.postcodeOfBirth
      ) {
        formData.append("postcodeOfBirth", form.postcodeOfBirth);
      }

      await api.post(`/api/User/Update`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      setReload(true);
      setLoading(false);
      toast.dismiss()
      toast.success("Votre modification a été effectuée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event: any) => {
    (hiddenFileInput.current as any).click();
  };

  const handleChange = (event: { target: { files: any[] } }) => {
    const fileUploaded = event.target.files[0];
    setUserProfilePhoto(fileUploaded);
  };

  const addDefaultSrc = (event: any) => {
    event.target.src = UserChat;
  };

  const handleCinChange = async (event: { target: { files: any } }) => {
    try {
      setUserCIN(event.target.files![0]);
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  return (
    <>
      <div className="form__container">
        
        <form onSubmit={handleSubmit(updateClient)}>
          <Row>
            <Col md={3} className="profile__photo">
              <Card className="admin-profile-photo">
                <div className="user-info mb-4">
                  <CerleUserBg
                    className={"bgCercle"}
                    fillColor={
                      user.role === UserTypes.Client
                        ? colors.primaryColor
                        : colors.secondaryColor
                    }
                  />
                  <div className="user-info__img">
                    {userProfilePhoto ? (
                      <img
                        src={
                          (userProfilePhoto as any).url
                            ? (userProfilePhoto as any).url
                            : URL.createObjectURL(userProfilePhoto as any)
                        }
                        alt="img"
                        onError={addDefaultSrc}
                      />
                    ) : (
                      <img src={UserChat} alt="img" onError={addDefaultSrc} />
                    )}
                  </div>
                </div>

                <Button
                  type="button"
                  className="form__button"
                  color="secondary"
                  onClick={handleClick}
                >
                  <RiCameraLine className="icon-btn" />
                  Changer photo
                </Button>

                <input
                  ref={hiddenFileInput}
                  onChange={handleChange as any}
                  type="file"
                  className="file-input"
                />
              </Card>
            </Col>

            <Col md={9}>
              <Card className="admin-profile-form">
                <Row>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="gender">Civilité</Label>
                      <Controller
                        name="gender"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={GENDER_OPTIONS}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="firstName">Prènom</Label>
                      <Input
                        id="firstName"
                        innerRef={firstNameRef}
                        {...firstName}
                        type="text"
                        className="form-secondary"
                      />
                      <span className="icon icon-secondary ">
                        <img src={UserProsp} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="lastName">Nom</Label>
                      <Input
                        id="lastName"
                        innerRef={lastNameRef}
                        {...lastName}
                        type="text"
                        className="form-secondary"
                      />
                      <span className="icon icon-secondary ">
                        <img src={UserProsp} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="email">Email</Label>
                      <Input
                        id="email"
                        innerRef={emailRef}
                        {...email}
                        type="text"
                        className="form-secondary"
                      />
                      <span className="icon icon-secondary ">
                        <img src={EmailIcon} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="phone">Téléphone</Label>
                      <Input
                        id="phone"
                        innerRef={phoneRef}
                        {...phone}
                        type="text"
                        className="form-secondary"
                      />
                      <span className="icon icon-secondary ">
                        <img src={PhoneIcon} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="dateOfBirth">Date de naissance</Label>
                      <Controller
                        control={control}
                        name="dateOfBirth"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de début"
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown={true}
                            showYearDropdown
                            showMonthDropdown
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />

                      <span className="icon icon-secondary ">
                        <img src={CalenderIconBlue} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="nationality">Pays</Label>
                      <Input
                        id="nationality"
                        innerRef={nationalityRef}
                        {...nationality}
                        type="text"
                        className="form-secondary"
                      />
                      <span className="icon icon-secondary ">
                        <RiFlagLine />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="city">Ville</Label>
                      <Input
                        id="city"
                        innerRef={cityRef}
                        {...city}
                        type="text"
                        className="form-secondary"
                      />
                      <span className="icon icon-secondary ">
                        <RiCommunityLine />
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="postcodeOfBirth">
                        Code postal de naissance
                      </Label>
                      <Input
                        id="postcodeOfBirth"
                        innerRef={postcodeOfBirthRef}
                        {...postcodeOfBirth}
                        type="text"
                        className="form-secondary"
                      />
                      <span className="icon icon-secondary ">
                        <BsMailbox />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="address">Adresse</Label>
                      <Input
                        id="address"
                        innerRef={addressRef}
                        {...address}
                        type="text"
                        className="form-secondary"
                      />
                      <span className="icon icon-secondary ">
                        <RiMapPinLine />
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="companyPosition">
                        Poste au sein de l'entreprise
                      </Label>
                      <Input
                        id="companyPosition"
                        innerRef={companyPositionRef}
                        {...companyPosition}
                        type="text"
                        className="form-secondary"
                      />
                      <span className="icon icon-secondary ">
                        <RiBriefcase4Line />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="proofOfIdentity">Carte d'identité</Label>
                      <FilePicker
                        className={
                          "form-icon icon-start form-file file-secondary"
                        }
                        onChange={handleCinChange}
                        state={userCIN}
                        fileReadyDelete={async () => {
                          try {
                            await deleteFile(
                              "User",
                              user?.id!,
                              "proofOfIdentity",
                              creds.token,
                              api
                            );
                            setReload(true);
                            setLoading(false);
                            toast.dismiss()
                            toast.success(
                              "Votre modification a été effectuée avec succès!",
                              {
                                position: "bottom-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                          } catch (error: any) {
                            console.table("deleting file : ", error);
                          }
                        }}
                        fileStagedDelete={() => setUserCIN(null)}
                        loader={cinLoading}
                        setUrl={setUrl}
                        setViewModal={setViewModal}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="form__footer">
            <Button
              className="form__button"
              color="secondary"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Enregistrer"
              )}
            </Button>
          </Row>
        </form>
      </div>
      <div className="openbtn text-center">
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </>
  );
};

export default AdminComp;
