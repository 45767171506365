import React from "react";

interface GridIconProps {
  className?: string;
}

const GridIcon = ({ className }: GridIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="36.333"
    height="36.334"
    viewBox="0 0 36.333 36.334"
  >
    <g
      id="Groupe_95"
      data-name="Groupe 95"
      transform="translate(-144.875 -439.624)"
    >
      <path
        id="Tracé_110"
        data-name="Tracé 110"
        d="M161.442,447.909c0,1.416,0,2.832,0,4.248a3.957,3.957,0,0,1-4.034,4.034q-4.248.008-8.5,0a3.954,3.954,0,0,1-4.033-4.034q-.007-4.248,0-8.5a3.952,3.952,0,0,1,4.033-4.032q4.248-.007,8.5,0a3.955,3.955,0,0,1,4.034,4.033C161.446,445.077,161.442,446.493,161.442,447.909Zm-15.021-.05c0,1.449-.005,2.9,0,4.347a2.367,2.367,0,0,0,2.427,2.44q4.3.011,8.595,0a2.368,2.368,0,0,0,2.453-2.464q.008-4.273,0-8.546a2.364,2.364,0,0,0-2.452-2.464q-4.273-.008-8.546,0a2.373,2.373,0,0,0-2.477,2.489C146.418,445.06,146.421,446.459,146.421,447.859Z"
        fill="#fff"
        opacity="0.71"
      />
      <path
        id="Tracé_111"
        data-name="Tracé 111"
        d="M311.442,447.909c0,1.416,0,2.832,0,4.248a3.957,3.957,0,0,1-4.034,4.033q-4.248.008-8.5,0a3.954,3.954,0,0,1-4.032-4.034q-.007-4.248,0-8.5a3.952,3.952,0,0,1,4.033-4.032q4.248-.007,8.5,0a3.955,3.955,0,0,1,4.033,4.033C311.446,445.077,311.442,446.493,311.442,447.909Zm-1.544.05c0-1.449.006-2.9,0-4.347a2.363,2.363,0,0,0-2.427-2.439q-4.3-.01-8.6,0a2.366,2.366,0,0,0-2.453,2.463q-.008,4.273,0,8.546a2.368,2.368,0,0,0,2.451,2.465q4.273.009,8.545,0a2.373,2.373,0,0,0,2.478-2.489C309.9,450.758,309.9,449.358,309.9,447.959Z"
        transform="translate(-130.235)"
        fill="#fff"
        opacity="0.71"
      />
      <path
        id="Tracé_112"
        data-name="Tracé 112"
        d="M161.452,597.93c0,1.416,0,2.832,0,4.248a3.957,3.957,0,0,1-4.042,4.026q-4.223.007-8.447,0a3.959,3.959,0,0,1-4.075-4.091q0-4.149,0-8.3a3.963,3.963,0,0,1,4.19-4.174h8.2a3.965,3.965,0,0,1,4.175,4.19Q161.453,595.88,161.452,597.93Zm-1.544.042c0-1.449.006-2.9,0-4.347a2.363,2.363,0,0,0-2.427-2.439q-4.3-.01-8.595,0a2.366,2.366,0,0,0-2.453,2.463q-.008,4.273,0,8.546a2.368,2.368,0,0,0,2.451,2.465q4.273.009,8.546,0a2.373,2.373,0,0,0,2.478-2.489C159.912,600.771,159.909,599.372,159.909,597.972Z"
        transform="translate(-0.01 -130.249)"
        fill="#fff"
        opacity="0.71"
      />
      <path
        id="Tracé_113"
        data-name="Tracé 113"
        d="M294.886,597.935c0-1.416,0-2.832,0-4.248a3.956,3.956,0,0,1,4.065-4.051q4.174,0,8.348,0a3.962,3.962,0,0,1,4.151,4.164q0,4.1,0,8.2a3.968,3.968,0,0,1-4.214,4.2h-8.15a3.968,3.968,0,0,1-4.2-4.214Q294.886,599.96,294.886,597.935Zm15.021-.018c0-1.433.006-2.865,0-4.3a2.363,2.363,0,0,0-2.427-2.439q-4.3-.01-8.6,0a2.366,2.366,0,0,0-2.452,2.463q-.008,4.273,0,8.545a2.368,2.368,0,0,0,2.451,2.465q4.273.009,8.546,0a2.373,2.373,0,0,0,2.478-2.489C309.911,600.75,309.907,599.334,309.907,597.918Z"
        transform="translate(-130.244 -130.244)"
        fill="#fff"
      />
      <path
        id="Tracé_114"
        data-name="Tracé 114"
        d="M220.9,485.481c0,.159,0,.318,0,.477q.008,2.859.016,5.719c0,1.335-.58,1.908-1.932,1.9-.917,0-1.833,0-2.75,0l-.113-.133,4.621-8.01Z"
        transform="translate(-61.856 -39.777)"
        fill="#fff"
        opacity="0.71"
      />
      <path
        id="Tracé_115"
        data-name="Tracé 115"
        d="M370.92,485.433q0,1.45,0,2.9c0,1.167.013,2.334.006,3.5a1.589,1.589,0,0,1-1.7,1.691c-1,.007-1.994,0-2.99,0l-.1-.14,4.625-8.012Z"
        transform="translate(-192.106 -39.721)"
        fill="#fff"
        opacity="0.71"
      />
      <path
        id="Tracé_116"
        data-name="Tracé 116"
        d="M220.876,635.476c.007.143.021.286.021.429q.01,2.933.017,5.866a1.607,1.607,0,0,1-1.785,1.8q-1.449,0-2.9,0l-.112-.134,4.621-8.01Z"
        transform="translate(-61.856 -170.008)"
        fill="#fff"
        opacity="0.71"
      />
      <path
        id="Tracé_117"
        data-name="Tracé 117"
        d="M328.49,624.78c-.924,0-1.762,0-2.6,0-.585,0-.952-.308-.952-.772s.367-.765.954-.769c.838-.005,1.676,0,2.6,0v-1.23c0-.411,0-.822,0-1.234,0-.712.28-1.1.784-1.089s.759.39.76,1.113c0,.788,0,1.575,0,2.439h2.08c.214,0,.428-.008.641,0a.8.8,0,0,1,.83.743.786.786,0,0,1-.83.792c-.756.016-1.513,0-2.27.006-.129,0-.257.012-.452.021v1.627c0,.28,0,.559,0,.839,0,.678-.273,1.055-.76,1.064s-.78-.374-.783-1.041,0-1.316,0-1.974Z"
        transform="translate(-156.337 -156.335)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default GridIcon;
