import React, { useState, FormEvent } from "react";

import "./RegisterStepError.scss";
import keyImg from "../../../../assets/images/svg/key.svg";
import sendImg from "../../../../assets/images/svg/send.svg";
import { Card, Form, FormGroup, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import Switch from "../../../../components/Switch/Switch";
import CardRegister from "../../../../components/CardRegister/CardRegister";
import WarningIcon from "../../../../assets/images/icons-warning.png";

const RegisterStepError: React.FC = () => {
  function submit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
  }

  return (
    <div className="register-stepFive col-lg-7 col-md-12 mx-auto">
      <div className="card warning-message">
        <span className="iconMsg">
          <img src={WarningIcon} alt="img" />
        </span>
        <h4>Nous sommes désolés !</h4>
        <p>
          nous ne sommes pas en mesure de vous offrir nos services comptables.
        </p>
      </div>
    </div>
  );
};

export default RegisterStepError;
