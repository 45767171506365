import React, {memo} from "react";
import ReactQuill from "react-quill";

type Props = {
  onChange: (value: React.SetStateAction<string>) => void;
  text: string;
};

const Editor = ({onChange, text}: Props) => {
  return (
    <ReactQuill
      className="form-secondary"
      onChange={(value) => onChange(value)}
      theme={"snow"}
      id="5548"
      value={text}
    />
  );
};

export default memo(Editor);
