import React, { PropsWithChildren, ReactElement } from "react";
import "./CardPack.scss";
import { ICardPack } from "../../interfaces";
import { Card } from "reactstrap";

function CardPack(props: PropsWithChildren<ICardPack>): ReactElement {
  return (
    <Card className={` card-packs ${props.className} `}>
      <div className="card-pack-head">
        <h3>{props.title}</h3>
        <span className="price-pack">{props.price}</span>
        <span className="par-span">par mois</span>
        {props.tva && <span className="tva-span">* Déclaration de TVA <strong>incluse</strong></span>}
      </div>
      {props.children}
    </Card>
  );
}

export default CardPack;
