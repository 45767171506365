import React from "react";
import "./user-info.scss";
import { ITicketNotif, User } from "../../interfaces";
import { Badge, Button, Card } from "reactstrap";
import EmailIcon from "../../assets/EmailIcon";
import CrownIcon from "../../assets/CrownIcon";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IMessageNotifs } from "../../store/reducers/messages-notifs/messagesNotifsSlice";
import CerleUserBg from "../../assets/CerleUserBg";
import { colors } from "../../constants";
import { UserTypes } from "../../util/context";
import UserChat from "../../assets/images/avatar_empty.png";

type UserProps = {
  user: User;
};

const UserInfo: React.FC<UserProps> = ({ user }) => {
  const navigate = useNavigate();

  const { newNotif } = useSelector(
    (state: { messagesNotifs: object }) => state.messagesNotifs
  ) as { newNotif: boolean };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const addDefaultSrc = (event: any) => {
    event.target.src = UserChat;
  };

  return (
    <>
      <div
        className={`${creds.role !== UserTypes.Client ? "card blockUser" : " blockUserInfos"} `}
      >
        {creds.role !== UserTypes.Client && (
          <Button
            className="btn-icon btn-icon-secondary"
            color="light"
            onClick={() => navigate("/tickets")}
          >
            {newNotif && <Badge color="primary"> </Badge>}
            <CrownIcon />
          </Button>
        )}

        <div
          className={`user-info ${
            creds.role !== UserTypes.Client && "clickable-profile"
          } ${
            creds.role === UserTypes.Client && "client-profile-pic"
          }`}
          onClick={() => {
            if (creds.role !== UserTypes.Client) {
              return navigate("/admin");
            } else {
              return null;
            }
          }}
        >
          <CerleUserBg
            className={"bgCercle"}
            fillColor={
              creds.role === UserTypes.Client
                ? colors.primaryColor
                : colors.secondaryColor
            }
          />
          <div className="user-info__img">
          {user.profilePhoto ? (
              <img
                src={(user.profilePhoto as any).url}
                alt="img"
                onError={addDefaultSrc}
              />
            ) : (
              <img src={UserChat} alt="img" onError={addDefaultSrc} />
            )}

          </div>
        </div>

        {creds.role !== UserTypes.Client && (
          <Button
            className="btn-icon btn-icon-secondary btn-mailing"
            color="light"
            onClick={() => navigate("/mailing")}
          >
            <EmailIcon />
          </Button>
        )}
      </div>
    </>
  );
};

export default UserInfo;
