import React, { useEffect, useState } from "react";
import "./WhiteLabel.scss";
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Select from "react-select";

import { IoMdClose } from "react-icons/io";
import UserIcon from "../../../assets/images/svg/user-blue.svg";
import EmailIcon from "../../../assets/images/svg/mail-blue.svg";
import logoLabel from "../../../assets/images/logo-dw.png";
import KeyIcon from "../../../assets/KeyIcon";
import PhoneIcon from "../../../assets/PhoneIcon";
import LabelItem from "../LabelItem/LabelItem";
import Tabs from "../WhiteLabelsTab";
import { useDispatch, useSelector } from "react-redux";
import { IApplication, IS3, User } from "../../../interfaces";
import config from "../../../config";
import { SubmitHandler, useForm } from "react-hook-form";
import useAxios from "../../../util/hooks/useAxios";
import { ErrorLogger } from "../../../util/errorLogger";
import { fetchAllApplications } from "../../../store/reducers/applications/applicationsSlice";
import MeubleIconBlue from "../../../assets/images/svg/meuble-blue-icon.svg";
import IconPepColors from "../../../assets/images/svg/colors-icons.svg";
import ApercuImage from "../../../assets/images/web-apercu.png";
import ApercuIcons from "../../../assets/images/svg/apercu-icons.svg";
import { setErrorMessage } from "../../../store/reducers/utils/utilSlice";
import FileViewer from "../../../components/FileViewer";
import FilePicker from "../../../components/FilePicker";
import { deleteFile } from "../../ClientInfo/InformationClient";
import { toast, ToastContainer } from "react-toastify";
import ApercuAdmin from "../../ApercuAdmin/ApercuAdmin";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

type CustomAppType = {
  primColor: string | null;
  secColor: string | null;
  thirdColor: string | null;
  logo: any;
  name: string | null;
  baseUrl: string | null;
  activated: string | boolean;
};

export interface WhiteLabelProps {}

const { APPLICATION_ID } = config[process.env.NODE_ENV];

const WhiteLabel: React.FC<WhiteLabelProps> = ({}) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [color, setColor] = useState("#000000");
  const [color2, setColor2] = useState("#000000");
  const [color3, setColor3] = useState("#000000");
  const [updateLabelModal, setUpdateLabelModal] = useState<boolean>(false);
  const [singleApp, setSingleApp] = useState<IApplication | null>();
  const [appLogo, setAppLogo] = useState<any>();
  const [appFavicon, setAppFavicon] = useState<any>();
  const [stateErrorMessage, setStateErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };

  const { applications } = useSelector(
    (state: { applications: Object }) => state.applications
  ) as { applications: IApplication[] };

  const [crudLoading, setCrudLoading] = useState<boolean>(false);

  let api = useAxios();
  const dispatch = useDispatch();

  const resteForm = () =>
    reset({
      primColor: null,
      secColor: null,
      thirdColor: null,
      baseUrl: null,
      logo: null,
      name: null,
    });

  const fillForm = () => {
    reset({
      primColor: singleApp?.primColor || null,
      secColor: singleApp?.secColor || null,
      thirdColor: singleApp?.thirdColor || null,
      baseUrl: singleApp?.baseUrl || null,
      name: singleApp?.name || null,
    });
    setAppLogo(singleApp?.logo || null);
    setAppFavicon(singleApp?.favicon || null);
  };

  const {
    watch,
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<CustomAppType>({});

  const {
    primColor: _primColor,
    secColor: _secColor,
    thirdColor: _thirdColor,
  } = watch();

  const { ref: primColorRef, ...primColor } = register("primColor");
  const { ref: secColorRef, ...secColor } = register("secColor");
  const { ref: thirdColorRef, ...thirdColor } = register("thirdColor");
  const { ref: nameRef, ...name } = register("name");
  const { ref: baseUrlRef, ...baseUrl } = register("baseUrl");

  const editWhiteLabel: SubmitHandler<CustomAppType> = async (
    form: CustomAppType
  ) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("id", singleApp?.id as string);

      if (form.primColor !== singleApp?.primColor) {
        formData.append("primColor", form.primColor as string);
      }
      if (form.secColor !== singleApp?.secColor) {
        formData.append("secColor", form.secColor as string);
      }
      if (form.thirdColor !== singleApp?.thirdColor) {
        formData.append("thirdColor", form.thirdColor as string);
      }
      if (form.name !== singleApp?.name) {
        formData.append("name", form.name as string);
      }

      if (form.baseUrl !== singleApp?.baseUrl) {
        formData.append(
          "baseUrl",
          (form.baseUrl!.includes("https")
            ? form.baseUrl
            : `https://${form.baseUrl}`) as string
        );
      }

      if (appLogo && !appLogo.key) {
        formData.append("logo", appLogo);
      }

      if (appFavicon && !appFavicon.key) {
        formData.append("favicon", appFavicon);
      }

      const { data } = await api.post(`/api/Application/Update`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (data.updated.length > 0) {
        toast.dismiss()
        toast.success("Votre modification a été effectuée avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoading(false);
      setCrudLoading(false);
      dispatch(fetchAllApplications());
      setUpdateLabelModal(false);
      setSingleApp(null);
      setErrorMessage(null);
    } catch (error: any) {
      ErrorLogger("creating admin", error);
      setLoading(false);
      setCrudLoading(false);
      dispatch(fetchAllApplications());
      setUpdateLabelModal(false);
      setSingleApp(null);
      setErrorMessage(null);
    }
  };

  const editCallback = (index: number) => {
    setSingleApp(
      applications.filter((elt) => elt.id !== APPLICATION_ID)[index]
    );
    setUpdateLabelModal(true);
  };

  useEffect(() => {
    if (updateLabelModal) {
      fillForm();
    } else {
      resteForm();
    }
  }, [updateLabelModal]);

  const handleAppLogoEditChange = (event: { target: { files: any } }) => {
    setAppLogo(event.target.files![0]);
  };

  const handleAppFaviconEditChange = (event: { target: { files: any } }) => {
    setAppFavicon(event.target.files![0]);
  };

  const handleStatusApp = async (app: IApplication) => {
    try {
      setErrorMessage(null);
      setLoading(true);
      const { data } = await api.post(
        `/api/Application/Update`,
        {
          id: app.id,
          activated: app.activated ? false : true,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (data.updated.length > 0) {
        toast.dismiss()
        toast.success("Votre modification a été effectuée avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      dispatch(fetchAllApplications());
      setLoading(false);
      setCrudLoading(false);
      setUpdateLabelModal(false);
      setSingleApp(null);
      setAppLogo(null);
      setAppFavicon(null);
    } catch (error: any) {
      ErrorLogger("disactivating app", error);
      setLoading(false);
      setUpdateLabelModal(false);
      setSingleApp(null);
      setAppLogo(null);
      setAppFavicon(null);
      setCrudLoading(false);
    }
  };

  return (
    <>
      
      <Card className="white-label-section">
        <Tabs
          selectedTab={selectedTab}
          onClick={(tab) => {
            setSelectedTab(tab);
          }}
          orientation="vertical"
          tabs={applications
            .filter((elt) => elt.id !== APPLICATION_ID)
            .map((elt, index) => {
              return {
                // label: elt.name,
                image: elt.logo ? elt.logo.url : null,
                index,
                Component: LabelItem,
                activated: elt.activated,
              };
            })}
          context={
            applications.filter((elt) => elt.id !== APPLICATION_ID)[selectedTab]
              .id
          }
          callback={editCallback}
        />
        <div className="openbtn text-center">
          <Modal
            className="modal-secondary modal-dialog-centered modal-lg"
            isOpen={updateLabelModal}
            toggle={() => {
              setUpdateLabelModal(false);
              setSingleApp(null);
              setErrorMessage(null);
              setAppLogo(null);
              setAppFavicon(null);
              setLoading(false);
              setCrudLoading(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setUpdateLabelModal(false);
                setSingleApp(null);
                setErrorMessage(null);
                setAppLogo(null);
                setAppFavicon(null);
                setLoading(false);
                setCrudLoading(false);
              }}
            >
              {singleApp?.name || singleApp?.baseUrl}
            </ModalHeader>
            <form onSubmit={handleSubmit(editWhiteLabel)}>
              <ModalBody>
                <div className="content-form-block content-admin">
                  <Row>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="lab">Nom du white label</Label>
                        <Input
                          id="lab"
                          placeholder="Nom white label"
                          type="text"
                          className="form-secondary"
                          innerRef={nameRef}
                          {...name}
                          disabled={true}
                        />
                        <span className="icon icon-secondary ">
                          <img src={MeubleIconBlue} alt="icon" />
                        </span>
                      </FormGroup>

                      <div>
                        <Row>
                          <Col lg={4} md={6} sm={4} className="color-box">
                            <Label className="color-label" for="primColor">
                              Couleur primaire
                            </Label>
                            <FormGroup className="form-color">
                              <Input
                                type="color"
                                id="primColor"
                                placeholder="Nom white label"
                                className="form-default"
                                innerRef={primColorRef}
                                {...primColor}
                              />
                              <span>{_primColor}</span>

                              <span className="icon-pep">
                                <img src={IconPepColors} alt="icon" />
                              </span>
                            </FormGroup>
                          </Col>
                          <Col lg={4} md={6} sm={4} className="color-box">
                            <Label className="color-label" for="secColor">
                              Couleur secondaire
                            </Label>
                            <FormGroup className="form-color">
                              <Input
                                id="secColor"
                                type="color"
                                placeholder="Nom white label"
                                className="form-default"
                                innerRef={secColorRef}
                                {...secColor}
                              />
                              <span>{_secColor}</span>
                              <span className="icon-pep">
                                <img src={IconPepColors} alt="icon" />
                              </span>
                            </FormGroup>
                          </Col>
                          <Col lg={4} md={12} sm={4} className="color-box">
                            <Label className="color-label" for="thirdColor">
                              Couleur tertiaire
                            </Label>
                            <FormGroup className="form-color">
                              <Input
                                id="thirdColor"
                                type="color"
                                placeholder="Nom white label"
                                className="form-default"
                                innerRef={thirdColorRef}
                                {...thirdColor}
                              />
                              <span>{_thirdColor}</span>
                              <span className="icon-pep">
                                <img src={IconPepColors} alt="icon" />
                              </span>
                            </FormGroup>
                          </Col>
                          <Col sm={12} className="pt-2">
                            <Label for="logo">Ajouter le nouveau logo</Label>
                            <FilePicker
                              className="form-icon icon-start form-file file-secondary"
                              onChange={handleAppLogoEditChange}
                              state={appLogo}
                              fileReadyDelete={async () => {
                                try {
                                  setCrudLoading(true);
                                  await deleteFile(
                                    "Application",
                                    singleApp?.id!,
                                    "logo",
                                    creds.token,
                                    api
                                  );
                                  setAppLogo(null);
                                  dispatch(fetchAllApplications());
                                  setCrudLoading(false);
                                  toast.dismiss()
                                  toast.success(
                                    "Votre ajout a été effectué avec succès!",
                                    {
                                      position: "bottom-right",
                                      autoClose: 2000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                } catch (error: any) {
                                  console.table("deleting file : ", error);
                                }
                              }}
                              fileStagedDelete={() => setAppLogo(null)}
                              setUrl={setUrl}
                              setViewModal={setViewModal}
                            />
                            <p className="txt-orange">
                              *Le meilleur format pour le logo png
                            </p>
                            <p className="txt-orange">
                              *Le logo doit mesurer au moins 275 x 82 pixels
                            </p>
                          </Col>
                          <Col sm={12} className="pt-2">
                            <Label for="logo">Ajouter le favicon</Label>
                            <FilePicker
                              className="form-icon icon-start form-file file-secondary"
                              onChange={handleAppFaviconEditChange}
                              state={appFavicon}
                              fileReadyDelete={async () => {
                                try {
                                  setCrudLoading(true);
                                  await deleteFile(
                                    "Application",
                                    singleApp?.id!,
                                    "favicon",
                                    creds.token,
                                    api
                                  );
                                  setAppFavicon(null);
                                  dispatch(fetchAllApplications());
                                  setCrudLoading(false);
                                  toast.dismiss()
                                  toast.success(
                                    "Votre ajout a été effectué avec succès!",
                                    {
                                      position: "bottom-right",
                                      autoClose: 2000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                } catch (error: any) {
                                  console.table("deleting file : ", error);
                                }
                              }}
                              fileStagedDelete={() => setAppFavicon(null)}
                              setUrl={setUrl}
                              setViewModal={setViewModal}
                            />
                            <p className="txt-orange">
                              *Le favicon doit être carré et mesurer au moins 32
                              x 32 pixels
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="lab">Url du white label</Label>
                        <Input
                          id="lab"
                          placeholder="Nom white label"
                          type="text"
                          className={`form-secondary ${!getValues("baseUrl")?.includes("https") && "url-input"}`}
                          innerRef={baseUrlRef}
                          {...baseUrl}
                          disabled={true}
                        />
                        <span className="icon icon-secondary ">
                          <img src={MeubleIconBlue} alt="icon" />
                          {!getValues("baseUrl")?.includes("https") && (
                            <span className="url-prefix url-prefix-secondary">https://</span>
                          )}
                        </span>
                      </FormGroup>
                      <div className="block-apercu">
                        <Label for="logo">Aperçu</Label>
                        <div className="block-apercu">
                          <ApercuAdmin
                            scale={[0.51, 0.45]}
                            transformOrigin="top left"
                            logo={appLogo}
                            color1={_primColor || "#000000"}
                            color2={_secColor || "#000000"}
                            color3={_thirdColor || "#000000"}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  color="secondary"
                  outline
                  onClick={() => {
                    setUpdateLabelModal(false);
                    setSingleApp(null);
                    setErrorMessage(null);
                    setAppLogo(null);
                    setAppFavicon(null);
                    setLoading(false);
                    setCrudLoading(false);
                  }}
                  type="button"
                  disabled={loading || crudLoading}
                >
                  {loading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Annuler"
                  )}
                </Button>
                <Button
                  color="secondary"
                  type="submit"
                  disabled={loading || crudLoading}
                >
                  {loading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Modifier"
                  )}
                </Button>

                <Button
                  color={singleApp?.activated ? "danger" : "primary"}
                  type="button"
                  onClick={async () => {
                    await handleStatusApp(singleApp!);
                  }}
                  disabled={loading || crudLoading}
                >
                  {loading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    <>{singleApp?.activated ? "Désactiver" : "Activer"}</>
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>
          <FileViewer
            url={url!}
            setUrl={setUrl}
            viewModal={viewModal}
            setViewModal={setViewModal}
          />
        </div>
      </Card>
    </>
  );
};

export default WhiteLabel;
