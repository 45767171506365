import { AnimatePresence } from "framer-motion";
import React from "react";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import RegisterStepAsk from "./RegisterStepAsk";

const StepAsk = () => {

  return (
    <>
      <AnimatePresence>
        <RegisterStepAsk />
      </AnimatePresence>
      {/* <div className="bottomSteperCenter">
        <Button
          type="button"
          onClick={prevStep}
          color="primary"
          outline
          className="btn btn-prev "
        >
          <FiChevronsLeft />
          <span>Précédent</span>
        </Button>
        <Button
          type="button"
          onClick={() => {}}
          color="primary"
          className="btn btn-next "
        >
          <span>Envoyer</span>
          <FiChevronsRight />
        </Button>
      </div> */}
    </>
  );
};

export default StepAsk;
