import React, { useState, FormEvent, useEffect } from "react";

import "./PaymentSuccessStep.scss";
import keyImg from "../../../../assets/images/svg/key.svg";
import sendImg from "../../../../assets/images/svg/send.svg";
import { Card, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Switch from "../../../../components/Switch/Switch";
import CardRegister from "../../../../components/CardRegister/CardRegister";
import WarningIcon from "../../../../assets/images/icons-warning.png";
import SuccessIcon from "../../../../assets/images/check-valid.png";
import { RiDownload2Fill } from "react-icons/ri";
import AttacheIcon from "../../../../assets/AttacheIcon";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";
import useAxios from "../../../../util/hooks/useAxios";
import config from "../../../../config";
import { useDispatch, useSelector } from "react-redux";
import {
  setApplicationId,
  setCompanyId,
  setCompanyPack,
  setToken,
  setUserId,
  setUserRole,
} from "../../../../store/reducers/root/rootSlice";
import {
  setApplication,
  setCompany,
  setPermissions,
  setUser,
  setUserCompanies,
} from "../../../../store/reducers/user/userSlice";
import { UserTypes } from "../../../../util/context";
import { fetchAllApplications } from "../../../../store/reducers/applications/applicationsSlice";
import { fetchAllPermissions } from "../../../../store/reducers/permissions-list/permissionsListSlice";
import { ErrorLogger } from "../../../../util/errorLogger";
import FilePicker from "../../../../components/FilePicker";
import { toast, ToastContainer } from "react-toastify";
import { deleteFile } from "../../../ClientInfo/InformationClient";
import FileViewer from "../../../../components/FileViewer";
import moment from "moment";
import { Company } from "../../../../interfaces";

const { APPLICATION_ID } = config[process.env.NODE_ENV];

const PaymentSuccessStep: React.FC = () => {
  const [view, setView] = useState("LOGIN");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { deleteValue, getValue } = useLocalStorage();
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const [searchParams] = useSearchParams();
  let api = useAxios();
  const dispatch = useDispatch();
  const location = useLocation();

  const login = async () => {
    try {
      setLoading(true);
      const response = await api.post(`/api/auth/login`, {
        email: getValue("email"),
        password: getValue("password"),
      });

      if (!response) {
        setLoading(false);
        setView("ERROR");
        return;
      }

      const { data } = response;

      if (!data.auth) {
        setLoading(false);
        setView("ERROR");
        return;
      }
      const { token } = data;
      const { companies, ...user } = data.user;
      const { application, permissions } = user;
      if (application.id !== APPLICATION_ID) {
        return setErrorMessage({
          type: "email",
          message: "Pas d'utilisateur trouvé",
        });
      }
      dispatch(setUserId(user.id));
      dispatch(setUser(user));
      dispatch(setApplicationId(application.id));
      dispatch(setApplication(application));
      if (companies && companies.length > 0) {
        dispatch(setUserCompanies(companies));
        dispatch(setCompanyId(companies[0].id));
        dispatch(setCompanyPack(companies[0].pack));
        dispatch(setCompany(companies[0]));
      }

      permissions && dispatch(setPermissions(permissions));
      dispatch(setToken(token));
      dispatch(setUserRole(user.role));
      if (user.role !== UserTypes.Client) {
        dispatch(fetchAllApplications());
        dispatch(fetchAllPermissions());
      }
      setLoading(false);
      deleteValue("email");
      deleteValue("password");
      deleteValue("ActivityType");
      deleteValue("CompanyType");
      deleteValue("pack");
      deleteValue("AnswersObject");
      deleteValue("packOk");

      setTimeout(() => {
        navigate("/");
      }, 5000);
    } catch (error: any) {
      if (
        !error.response.data.auth &&
        error.response.data.message.toLowerCase().includes("no user found")
      ) {
        setLoading(false);
        setView("ERROR");
        return;
      }
      if (
        !error.response.data.auth &&
        error.response.data.message
          .toLowerCase()
          .includes("user is deactivated")
      ) {
        setLoading(false);
        setView("ERROR");
        return;
      }
      if (
        !error.response.data.auth &&
        error.response.data.message.toLowerCase().includes("password unvalid")
      ) {
        setLoading(false);
        setView("ERROR");
        return;
      }
      ErrorLogger("login form", error);
    }
  };

  const redirectUser = async () => {
    try {
      setLoading(true);
      const { data } = await api.post(
        `/api/User/All`,
        {
          where: {
            id: creds.user_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      const { data: userData } = data;

      const userFound = userData[0];
      const { companies, ...user } = userFound;
      dispatch(setUser(user));
      if (companies && companies.length > 0) {
        dispatch(setUserCompanies(companies));
        let comps = [...companies] as Company[];
        comps.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
        dispatch(setCompanyId(comps[0].id));
        dispatch(setCompanyPack(comps[0].pack));
        dispatch(setCompany(comps[0]));
      }
      setTimeout(() => {
        navigate("/");
      }, 5000);
    } catch (error: any) {
      console.log("redirectUser ", error);
    }
  };

  const checker = async () => {
    const email = getValue("email");
    const password = getValue("password");
    if (email && password) {
      await login();
    } else {
      setView("ERROR");
    }
  };

  useEffect(() => {
    if (searchParams.get("session_id")) {
      if (location && !location.search.includes("existing_user")) {
        checker();
      }
      if (location && location.search.includes("existing_user")) {
        redirectUser();
      }
    } else {
      setView("ERROR");
    }
  }, []);

  return (
    <>
      {view === "ERROR" ? (
        <div className="register-stepFive col-lg-7 col-md-12 mx-auto">
          <div className="card warning-message">
            <span className="iconMsg">
              <img src={WarningIcon} alt="img" />
            </span>
            <h4>Nous sommes désolés !</h4>
            <p>
              Problème d'enregistrement, veuillez contacter l'administrateur
              pour compléter votre processus d'enregistrement.
            </p>
            <h4>Nos coordonnés</h4>
            <address className="tel-email-wrapper">
              Email :{" "}
              <a href="mailto:info@noly-compta.fr">info@noly-compta.fr</a>
              <br />
              Numéro de téléphone : <a href="tel:0188610474">0188610474</a>
            </address>
          </div>
        </div>
      ) : (
        <div className="register-stepFive col-lg-7 col-md-12 mx-auto">
          <div className="card success-message">
            <span className="iconMsg">
              <img src={SuccessIcon} alt="img" />
            </span>
            <h4>Merci,</h4>
            <p>
              Votre compte a été créé, <br /> vous serez redirigé vers votre
              page d'accueil dans un instant ...
            </p>
            <Spinner color="light" type="border" size={"md"}>
              Loading...
            </Spinner>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentSuccessStep;
