import React, { useState, FormEvent, useEffect } from "react";

import "./RegisterStepTwo.scss";
import AssocIcon from "../../../../assets/images/svg/asso-icon.svg";
import CampIcon from "../../../../assets/images/svg/home.svg";
import EntreIcon from "../../../../assets/images/svg/camp-icon.svg";
import CivilIcon from "../../../../assets/images/svg/users-icon.svg";
import BuildingIcon from "../../../../assets/images/svg/Building-icon.svg";
import MeetIcon from "../../../../assets/images/svg/meet-icon.svg";

import MarchandiseIcon from "../../../../assets/images/register/marchandise.svg";
import PrestationIcon from "../../../../assets/images/register/prest.svg";
import AutreProfIcon from "../../../../assets/images/register/autre-prof.svg";
import ProfLib from "../../../../assets/images/register/prof-lib.svg";
import Location from "../../../../assets/images/register/location.svg";

import InfosIcon from "../../../../assets/images/svg/infos-icon.svg";
import CardRegister from "../../../../components/CardRegister/CardRegister";
import { Col, Form, Row } from "reactstrap";
import { RiArrowRightLine } from "react-icons/ri";
import { useLocalStorage } from "../../../../util/hooks/useLocalStorage";

const VALUE = "ActivityType";
const STEP_VALUE = "STEP";

type CardType = {
  title: string;
  ref: string;
  className: string;
  icon: any;
};

const RegisterStepTwo = ({ onClick }: { onClick: any }) => {
  const { setValue, getValue, deleteValue } = useLocalStorage();

  const CompanyCards: CardType[] = [
    {
      title: "Association",
      ref:
        getValue("CompanyType") === "company"
          ? "association"
          : "bilanassociation",
      className: "card-blue",
      icon: AssocIcon,
    },
    {
      title: "Société commerciale",
      ref:
        getValue("CompanyType") === "company"
          ? "commercial"
          : "bilancommercial",
      className: "card-green",
      icon: CampIcon,
    },
    {
      title: "Entreprise individuelle (EIRL) et profession libérale",
      ref: getValue("CompanyType") === "company" ? "eirl" : "bilaneirl",
      className: "card-orange",
      icon: EntreIcon,
    },
    {
      title: "SCI/LMNP/LMP",
      ref:
        getValue("CompanyType") === "company"
          ? "civilSociety"
          : "bilancivilSociety",
      className: "card-red",
      icon: BuildingIcon,
    },
  ];

  const EntrepCards: CardType[] = [
    {
      title: "Ventes de marchandises (BIC)",
      ref:
        getValue("CompanyType") === "entrep"
          ? "marchendise"
          : "bilanmarchendise",
      className: "card-blue",
      icon: MarchandiseIcon,
    },
    {
      title: "Prestations de services commerciales ou artisanales (BIC)",
      ref:
        getValue("CompanyType") === "entrep"
          ? "prestation"
          : "bilanprestation",
      className: "card-green",
      icon: PrestationIcon,
    },
    {
      title: "Autres prestations de services et professions libérales (BNC)",
      ref:
        getValue("CompanyType") === "entrep"
          ? "autresPrestations"
          : "bilanautresPrestations",
      className: "card-purple",
      icon: AutreProfIcon,
    },
    {
      title: "Professions libérales relevant de la CIPAV",
      ref:
        getValue("CompanyType") === "entrep"
          ? "profession"
          : "bilanprofession",
      className: "card-orange",
      icon: ProfLib,
    },
    {
      title: "Location de meublés de tourisme classés",
      ref: getValue("CompanyType") === "entrep" ? "location" : "bilanlocation",
      className: "card-red",
      icon: Location,
    },
  ];

  function submit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
  }

  return (
    <div className="register-stepTow col-lg-11 col-md-12 mx-auto">
      <Form>
        <Row className="row-wrap">
          {(getValue("CompanyType") === "company" ||
          getValue("CompanyType") === "bilan"
            ? CompanyCards
            : EntrepCards
          ).map((elt, index) => (
            <Col className="box-col" key={index}>
              <CardRegister
                className={elt.className}
                icon={elt.icon}
                title={elt.title}
                onClick={() => {
                  setValue(elt.ref, VALUE);
                  onClick();
                }}
              >
                <div className="card-footer">
                  <label className="btnBottom">
                    <RiArrowRightLine />
                    <input type="radio" name="rado-company" />
                  </label>
                </div>
              </CardRegister>
            </Col>
          ))}
        </Row>
      </Form>
    </div>
  );
};

export default RegisterStepTwo;
