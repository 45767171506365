import React from "react";

import "./AskError.scss";
import WarningIcon from "../../../../assets/images/icons-warning.png";

const AskErrorStep: React.FC = () => {
  return (
    <>
      <div className="register-stepFive col-lg-7 col-md-12 mx-auto">
        <div className="card warning-message">
          <span className="iconMsg">
            <img src={WarningIcon} alt="img" />
          </span>
          <h4>Nous sommes désolés !</h4>
          <p>
            Problème d'envoi d'email, veuillez contacter l'administrateur pour
            compléter votre processus d'enregistrement.
          </p>
          <h4>Nos coordonnés</h4>
          <address className="tel-email-wrapper">
            Email : <a href="mailto:info@noly-compta.fr">info@noly-compta.fr</a>
            <br />
            Numéro de téléphone : <a href="tel:0188610474">0188610474</a>
          </address>
        </div>
      </div>
    </>
  );
};

export default AskErrorStep;
